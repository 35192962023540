import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch, __RouterContext, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { venomHistory } from 'client/utils/history/venom-history';
import { isPageChanged } from 'client/utils/location';
import { makeRouteParams } from './routes';

/* eslint-disable react/jsx-pascal-case, no-restricted-syntax */

function renderSwitchRoutes(routes, redirects) {
  return (
    <Switch>
      {redirects && redirects.map(({ from, to }) => <Redirect key={from} exact from={from} to={to} />)}
      {routes.map(route => (
        <Route key={route.path} {...makeRouteParams(route)} />
      ))}
    </Switch>
  );
}

function renderInitialRoute(routeProps) {
  return <Route {...routeProps} />;
}

function ifRouteChanged(currentLocation, newLocation) {
  if (isPageChanged(currentLocation, newLocation)) {
    return true;
  }
  return false;
}

/**
 * App component
 */
export const App = (props = {}) => {
  const { store, routeProps, routes, currentRoute, location, redirects } = props;

  return (
    <Provider store={store}>
      <Router history={venomHistory}>
        <__RouterContext.Consumer>
          {context =>
            ifRouteChanged(location, context.location, currentRoute)
              ? renderSwitchRoutes(routes, redirects)
              : renderInitialRoute(routeProps)
          }
        </__RouterContext.Consumer>
      </Router>
    </Provider>
  );
};

/**
 * App component prop types definition
 */
App.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  routeProps: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  routes: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  currentRoute: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  redirects: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
};

App.defaultProps = {
  redirects: null,
};
