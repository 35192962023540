import { TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';

/**
 * get params with two words makes from props
 */
export function getParamsWithMakes({ match }) {
  return {
    ...match.params,
    make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
  };
}
