import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes } from 'client/data/constants/active-makes';
import { HTTP_GONE } from 'client/utils/http-status';

export const gone = {
  path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/car-buying-online/`,
  exact: true,
  page: getLoadablePage(
    () => import(/* webpackChunkName: "vdp_gone" */ '../pages/vdp/vdp-buy-online-gone-definition'),
    // TODO: PLAT-1417 Do refactoring for routes using extra params
    match => ({
      ...match.params,
    })
  ),
  chunkName: 'vdp_gone',
  status: HTTP_GONE,
};

export const routes = [
  {
    path: '/car-buying-online/search-cars/',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car-buying-online" */ '../pages/srp/car-buying-online-definition')
    ),
    chunkName: 'car-buying-online',
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/car-buying-online/`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "used_model_car_buying_online_inventory_vin_detail" */ '../pages/vdp/vdp-buy-online-definition'),
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'used_model_car_buying_online_inventory_vin_detail',
    methods: 'get,post',
  },
  {
    path: '/car-buying-online/utilities/payment-calculator',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "utilities_payment-calculator" */ '../pages/utilities/payment-calculator-definition')
    ),
    chunkName: 'utilities_payment-calculator',
  },
];
