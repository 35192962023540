import { isEqual, reduce } from 'lodash';

/**
 * performs deep comparison of two objects and returns prop names that are different
 * @example
 * diff(
 *  {a: 1, b: 2, c: { d: 3 } },
 *  {a: 5, b: 2, c: { d: 6 } })
 * ==> ['a', 'c']
 */
export function diff(a, b) {
  return reduce(a, (result, value, key) => (isEqual(value, b[key]) ? result : result.concat(key)), []);
}

/**
 * @param o
 * @returns {boolean} true if o is non-null (aka iterable) object
 */
export function isObject(o) {
  return o && o.constructor === Object;
}
