import { flatMapDeep, groupBy, map } from 'lodash';

export const fillInPartner = (conditionsQuestions, partner) =>
  map(conditionsQuestions, question => ({
    ...question,
    partner,
    answers:
      question.answers &&
      question.answers.map(answer => ({
        ...answer,
        detailQuestions:
          answer.detailQuestions &&
          answer.detailQuestions.map(adq => ({
            ...adq,
            partner,
          })),
      })),
  }));

export const transformQuestions = conditionsQuestions => ({
  questionsUngrouped: conditionsQuestions,
  questionsGroupedByCategory: groupBy(conditionsQuestions, 'category'),
  questionsFlatten: flatMapDeep(conditionsQuestions, question => [
    // Flatten to put parent question and it's data into the first level
    question,
    flatMapDeep(
      question.answers,
      // Flatten to put detail questions and it's data into the first level
      ({ id, detailQuestions }) =>
        detailQuestions &&
        detailQuestions.map(adq => ({
          ...adq,
          // Following added to help with business logic within the code
          parentQuestionId: question.id,
          parentAnswerId: id,
          isSubQuestion: true,
        }))
    ).filter(adq => adq), // Filter out null detail questions.
  ]),
});
