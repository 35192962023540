import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/deal/:year-:make-:model-deals-:city-:state',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "crowd-sourced-deal" */ '../pages/crowd-sourced-deal/crowd-sourced-deal-definition')
    ),
    chunkName: 'crowd-sourced-deal',
  },
  {
    path: '/deals/community',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "crowd-sourced-deal-paywall" */ '../pages/crowd-sourced-deal-paywall/crowd-sourced-deal-paywall-definition')
    ),
    chunkName: 'crowd-sourced-deal-paywall',
  },
];
