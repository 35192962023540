import { UPDATE_FEATURE_FLAGS } from './constants';

/**
 * UPDATE_FEATURE_FLAGS action definition
 *
 * @return {Object}       UPDATE_FEATURE_FLAGS action definition
 */
export function updateFeatureFlags(data) {
  return {
    type: UPDATE_FEATURE_FLAGS,
    data,
  };
}
