import { IS_NODE } from 'client/utils/environment';

const parse = IS_NODE
  ? require('querystring').parse
  : search =>
      decodeURI(search)
        .split('&')
        .reduce((q, param) => {
          const [key, value] = param.split('=');
          return {
            ...q,
            [key]: decodeURIComponent(value),
          };
        }, {});

export function isPageChanged(prevLocation, newLocation) {
  const prevPathname = prevLocation && prevLocation.pathname;
  const newPathname = newLocation && newLocation.pathname;

  return prevPathname !== newPathname;
}

export function isLocationChanged(prevLocation, newLocation) {
  const prevSearch = prevLocation && prevLocation.search;
  const newSearch = newLocation && newLocation.search;
  const prevHash = prevLocation && prevLocation.hash;
  const newHash = newLocation && newLocation.hash;

  return isPageChanged(prevLocation, newLocation) || prevSearch !== newSearch || prevHash !== newHash;
}

export function getQuery(location) {
  if (!location) return {};
  // we can have `query` prop if we are on server side
  if (location.query) {
    return location.query;
  }

  let search = location.search ? location.search : '';
  search = search.includes('?') ? search.slice(1) : search;
  if (search.length === 0) {
    return {};
  }

  return parse(search);
}
