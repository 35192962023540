import { EventToolbox } from 'client/utils/event-toolbox';

export const eventMiddleware = () => next => action => {
  const { meta } = action;

  if (meta && meta.event) {
    const { eventName, data } = meta.event;
    if (eventName) {
      EventToolbox.fireCustomEvent(eventName, data);
    }
  }

  return next(action);
};
