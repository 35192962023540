import { UPDATE_VERSION } from 'client/actions/constants';

// version format is MAJOR.MINOR.PATCH
// the refresh is required only when MAJOR and/or MINOR versions are changed
function needsRefresh(currentState, latestVersion) {
  if (!latestVersion || currentState.needsRefresh) {
    return false;
  }

  const [currentMajor, currentMinor] = currentState.version.split('.');
  const [latestMajor, latestMinor] = latestVersion.split('.');
  const isVersionChanged = currentMinor !== latestMinor || currentMajor !== latestMajor;

  return isVersionChanged;
}

export function versionReducer(state = {}, action = {}) {
  if (UPDATE_VERSION === action.type && needsRefresh(state, action.version)) {
    return {
      ...state,
      needsRefresh: true,
    };
  }

  return state;
}
