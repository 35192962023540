import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: [
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/pictures/:category(exterior|interior|colors)`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/pictures`,
  ],
  exact: true,
  page: getLoadablePage(() => import(/* webpackChunkName: "pictures" */ '../pages/pictures/pictures-definition')),
  chunkName: 'pictures',
};
