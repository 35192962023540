const DEFAULT_TIMEOUT_ERROR_MESSAGE = 'timeout exceeded';
const DEFAULT_RETRY_DELAY_MS = 500;

/**
 * Wraps a Promise-returning function so that the resulting Promise will be rejected if it is not resolved within the
 * given timeout. A custom error message can be supplied, which will be passed along in an Error on timeout.
 *
 * @param {function} promiseCall A function that returns a Promise.
 * @param {number} timeoutMS Timeout in milliseconds before the Promise will be rejected.
 * @param {string} [errorMessage] Error message to use when Promise is rejected.
 */
export function withTimeout(promiseCall, timeoutMS, errorMessage = DEFAULT_TIMEOUT_ERROR_MESSAGE) {
  return (...params) =>
    new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error(errorMessage));
      }, timeoutMS);

      promiseCall(...params)
        .then(result => {
          clearTimeout(timeout);
          resolve(result);
        })
        .catch(err => {
          clearTimeout(timeout);
          reject(err);
        });
    });
}

/**
 * Wraps a Promise-returning function so that it will be retried if the resulting Promise is rejected.
 * A custom delay can be provided to wait longer before a retry is attempted.
 *
 * @param {function} promiseCall A function that returns a Promise.
 * @param {number} retries Number of retries to attempt on rejection of the Promise.
 * @param {number} [delayMS] Time to wait until a retry is attempted in milliseconds.
 * @param {number} [retryCount] retry count.
 * @returns {Promise} Promise object represents response of promiseCall function
 */
function countRetries(promiseCall, retries, delayMS, retryCount) {
  return (...params) =>
    promiseCall(...params).catch(err => {
      if (!err.suspendRetries && retries > 0) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const [url, options] = params;
            const { headers = {} } = options || {};
            const retryNumber = retryCount + 1;
            countRetries(promiseCall, retries - 1, delayMS, retryNumber)(url, {
              ...options,
              headers: { ...headers, 'x-retry-count': retryNumber },
            })
              .then(resolve)
              .catch(reject);
          }, delayMS);
        });
      }

      return Promise.reject(err);
    });
}

/**
 * Wraps a Promise-returning function so that it will be retried if the resulting Promise is rejected.
 * A custom delay can be provided to wait longer before a retry is attempted.
 *
 * @param {function} promiseCall A function that returns a Promise.
 * @param {number} retries Number of retries to attempt on rejection of the Promise.
 * @param {number} [delayMS] Time to wait until a retry is attempted in milliseconds.
 * @returns {Promise} Promise object represents response of promiseCall function
 */
export function withRetry(promiseCall, retries, delayMS = DEFAULT_RETRY_DELAY_MS) {
  return countRetries(promiseCall, retries, delayMS, 0);
}

/**
 *  Wrap your promise in order to make it cancelable.
 *  https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 *
 * @param {Promise}  promiseCall A function that returns a Promise.
 * @returns {{promise: Promise, cancel: (function())}}
 *
 */
export const makePromiseCancelable = promiseCall => {
  let hasCanceled = false;
  const rejectPromise = (reject, error) => {
    reject(error);
  };

  const wrappedPromise = new Promise((resolve, reject) => {
    promiseCall.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : rejectPromise(reject, error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};
