import { isEmpty } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { EVENTS } from 'client/utils/event-constants';
import { UPDATE_VEHICLE } from 'client/actions/constants';

export const vehicleMiddleware = () => next => action => {
  const { vehicle, type, updateGtm } = action;

  if (type === UPDATE_VEHICLE && updateGtm) {
    if (!isEmpty(vehicle)) {
      EventToolbox.fireCustomEvent(EVENTS.UPDATE_GTM_VEHICLE, vehicle);
    }
  }

  return next(action);
};
