import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { CmsModel } from 'client/data/models/cms';
import { getModelState } from 'client/data/luckdragon/model';
import { parseContent } from 'client/data/cms/content';
import { getTopRatedDefaultYear } from 'site-modules/shared/utils/top-rated/content-path';

function preloadDefaultYear(store) {
  const preloader = new ModelPreloader(store);
  preloader.resolve(`content["${getTopRatedDefaultYear()}"]`, CmsModel);
  return preloader.load();
}
function getDefaultYear(store) {
  const modelState = getModelState(store.getState());
  return parseContent(modelState.get(`content["${getTopRatedDefaultYear()}"]`, CmsModel))
    .child('config')
    .metadata('defaultYear')
    .value();
}

export const types = [
  'convertible',
  'coupe',
  'crossover',
  'diesel',
  'hatchback',
  'hybrid',
  'electric-car',
  'electric-suv',
  'electric-truck',
  'sports-car',
  'minivan',
  'sedan',
  'suv',
  'truck',
  'wagon',
  'luxury',
  'ev',
  'luxury-ev',
  'luxury-sedan',
  'luxury-suv',
  'editors-choice',
  'best-of-the-best',
  'car',
];

export const years = ['2021', '2022', '2023', '2024'];

const INDEX_DEF_2021_2022 = year =>
  import(/* webpackChunkName: "top-rated-2022" */ 'site-modules/editorial/pages/top-rated/themes-2021-2022/top-rated-definition').then(
    page => page.default({ year, chunkName: 'top-rated-2022' })
  );
const INDEX_DEF_2023 = (year, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-2023" */ 'site-modules/editorial/pages/top-rated/themes-2023/top-rated-definition').then(
    page => page.default({ year, isDefaultYear, chunkName: 'top-rated-2023' })
  );
const INDEX_DEF_2024 = (year, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-2024" */ 'site-modules/editorial/pages/top-rated/themes-2024/top-rated-definition').then(
    page => page.default({ year, isDefaultYear, chunkName: 'top-rated-2024' })
  );
const INDEX_DEF_2025 = (year, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-2025" */ 'site-modules/editorial/pages/top-rated/themes-2025/top-rated-definition').then(
    page => page.default({ year, isDefaultYear, chunkName: 'top-rated-2025' })
  );

const INDEX_DEFINITIONS = {
  '2025': INDEX_DEF_2025,
  '2024': INDEX_DEF_2024,
  '2023': INDEX_DEF_2023,
  '2022': INDEX_DEF_2021_2022,
  '2021': INDEX_DEF_2021_2022,
};

const ARTICLE_DEF_2021_2022 = (year, type) =>
  import(/* webpackChunkName: "top-rated-article-2022" */ 'site-modules/editorial/pages/top-rated/themes-2021-2022/top-rated-article-definition').then(
    page => page.default({ year, type, chunkName: 'top-rated-article-2022' })
  );
const ARTICLE_DEF_2023 = (year, type, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-article-2023" */ 'site-modules/editorial/pages/top-rated/themes-2023/top-rated-article-definition').then(
    page => page.default({ year, type, isDefaultYear, chunkName: 'top-rated-article-2023' })
  );
const ARTICLE_DEF_2024 = (year, type, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-article-2024" */ 'site-modules/editorial/pages/top-rated/themes-2024/top-rated-article-definition').then(
    page => page.default({ year, type, isDefaultYear, chunkName: 'top-rated-article-2024' })
  );
const ARTICLE_DEF_2025 = (year, type, isDefaultYear) =>
  import(/* webpackChunkName: "top-rated-article-2025" */ 'site-modules/editorial/pages/top-rated/themes-2025/top-rated-article-definition').then(
    page => page.default({ year, type, isDefaultYear, chunkName: 'top-rated-article-2025' })
  );

const ARTICLE_DEFINITIONS = {
  '2025': ARTICLE_DEF_2025,
  '2024': ARTICLE_DEF_2024,
  '2023': ARTICLE_DEF_2023,
  '2022': ARTICLE_DEF_2021_2022,
  '2021': ARTICLE_DEF_2021_2022,
};

export const topRatedRoutes = [
  {
    /**
     * Route for currentYear "top-rated" landing page
     * https://www.edmunds.com/top-rated/
     */
    path: '/top-rated',
    exact: true,
    enableLooseEtag: true,
    preload: (_, store) => preloadDefaultYear(store),
    page: getLoadablePage((_, store) => {
      const defaultYear = getDefaultYear(store);
      return INDEX_DEFINITIONS[defaultYear](defaultYear, true);
    }),
  },
  {
    /**
     * Route for pastYear "top-rated" landing page, e.g.
     * https://www.edmunds.com/top-rated/2021
     */
    path: `/top-rated/:year(${years.join('|')})`,
    exact: true,
    enableLooseEtag: true,
    page: getLoadablePage(({ year }) => INDEX_DEFINITIONS[year](year)),
  },
  {
    /**
     * Route for currentYear single top-rated awards type article page, e.g.
     * https://www.edmunds.com/top-rated/suvs/
     */
    path: `/top-rated/:type(${types.join('|')})`,
    exact: true,
    enableLooseEtag: true,
    preload: (_, store) => preloadDefaultYear(store),
    page: getLoadablePage(({ type }, store) => {
      const defaultYear = getDefaultYear(store);
      return ARTICLE_DEFINITIONS[defaultYear](defaultYear, type, true);
    }),
  },
  {
    /**
     * Route for pastYears single top-rated awards type article page, e.g.
     * https://www.edmunds.com/top-rated/2021/suvs/
     */
    path: `/top-rated/:year(${years.join('|')})/:type(${types.join('|')})`,
    exact: true,
    enableLooseEtag: true,
    page: getLoadablePage(({ year, type }) => ARTICLE_DEFINITIONS[year](year, type)),
  },
];
