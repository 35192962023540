/**
 * these values were copied from
 * https://docs.google.com/spreadsheets/d/11icRNg5HmwswNqu5h_39IruHPhsQojkDDs6iFagkWfU/edit#gid=2027968462
 * by following steps
 * 1. file > download > csv
 * 2. convert csv to json by
 * 2.1 go to https://www.convertcsv.com/csv-to-json.htm, copy/paste the csv content
 * 2.2 choose output options to sort by page_label, then by device_type
 * 2.3 click on "csv to keyed JSON"
 * only sample rate is actually used, but the rest of the fields were commented out as opposed to removed
 * to be there for reference. Comments will be stripped out during build so they won't bloat production
 * bundles
 * 3. to avoid incidents like https://edmunds.atlassian.net/browse/PROD-419069 in future
 * the code handles gracefully if page samplerate override is missing altogether (for mobile and desktop) but
 * expectation from code is if data has an entry for desktop for a page it MUST also have for the mobile version
 * and vice versa. please verify this manually when regenerating content from future versions of GDOC
 *
 *
 * if needed to adjust sample rate please do not change value of sampleRateFromGDoc directly, instead,
 * add a sibling property sampleRate, that will take precedence over sampleRateFromGDoc
 */
export const overrides = {
  advice_buying_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 644,
      // 'Daily Chrome User Session': 3231,
      // '% of daily session': '19.92%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 19.92,
      sampleRate: 19.93, // this will take precedence over sampleRateFromGDoc: 19.92,
      // 'Actual Sampling Count': 644,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 904,
      // 'Daily Chrome User Session': '1,634',
      // '% of daily session': '55.31%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 55.31,
      // 'Actual Sampling Count': 904,
    },
  ],
  advice_buying_article_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 881,
      // 'Daily Chrome User Session': 25,
      // '% of daily session': '3524.03%',
      // '# of days to confidence': 36,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 25,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 60,
      // 'Daily Chrome User Session': 15,
      // '% of daily session': '400.77%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 15,
    },
  ],
  'advice_car-news_article': [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 162,
      // 'Daily Chrome User Session': 389,
      // '% of daily session': '41.63%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 41.63,
      // 'Actual Sampling Count': 162,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 437,
      // 'Daily Chrome User Session': 344,
      // '% of daily session': '127.08%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 344,
    },
  ],
  advice_driving_tips_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 50,
      // 'Daily Chrome User Session': 98,
      // '% of daily session': '51.37%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 51.37,
      // 'Actual Sampling Count': 50,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 284,
      // 'Daily Chrome User Session': 50,
      // '% of daily session': '567.12%',
      // '# of days to confidence': 6,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 50,
    },
  ],
  advice_fuel_economy_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 176,
      // 'Daily Chrome User Session': 588,
      // '% of daily session': '29.99%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 29,
      // 'Actual Sampling Count': 176,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 745,
      // 'Daily Chrome User Session': 335,
      // '% of daily session': '222.49%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 335,
    },
  ],
  advice_insurance_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 309,
      // 'Daily Chrome User Session': 102,
      // '% of daily session': '302.81%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 102,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 159,
      // 'Daily Chrome User Session': 64,
      // '% of daily session': '247.73%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 64,
    },
  ],
  advice_leasing_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 780,
      // 'Daily Chrome User Session': 953,
      // '% of daily session': '81.86%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 81.86,
      // 'Actual Sampling Count': 780,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': '1,107',
      // 'Daily Chrome User Session': 434,
      // '% of daily session': '255.06%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 434,
    },
  ],
  advice_leasing_article_index: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 124,
      // 'Daily Chrome User Session': 20,
      // '% of daily session': '618.24%',
      // '# of days to confidence': 7,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 20,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 28,
      // 'Daily Chrome User Session': 7,
      // '% of daily session': '407.08%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 7,
    },
  ],
  advice_loan_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 92,
      // 'Daily Chrome User Session': 186,
      // '% of daily session': '49.70%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 49.7,
      // 'Actual Sampling Count': 92,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 461,
      // 'Daily Chrome User Session': 159,
      // '% of daily session': '290.19%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100,
      // 'Actual Sampling Count': 159,
    },
  ],
  advice_maintenance_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 510,
      // 'Daily Chrome User Session': 645,
      // '% of daily session': '79.11%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 79.11,
      // 'Actual Sampling Count': 510,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 473,
      // 'Daily Chrome User Session': 606,
      // '% of daily session': '78.08%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 78.08,
      // 'Actual Sampling Count': 473,
    },
  ],
  advice_reviews_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 246,
      // 'Daily Chrome User Session': 565,
      // '% of daily session': '43.62%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 43.62,
      // 'Actual Sampling Count': 246,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 344,
      // 'Daily Chrome User Session': 390,
      // '% of daily session': '88.24%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 88.24,
      // 'Actual Sampling Count': 344,
    },
  ],
  advice_roadnoise_article_index: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 365,
      // 'Daily Chrome User Session': 36,
      // '% of daily session': '1014.81%',
      // '# of days to confidence': 11,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 36,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': '7,412',
      // 'Daily Chrome User Session': 14,
      // '% of daily session': '52940.27%',
      // '# of days to confidence': 530,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 14,
    },
  ],
  advice_safety_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 253,
      // 'Daily Chrome User Session': 150,
      // '% of daily session': '168.95%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 150,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 846,
      // 'Daily Chrome User Session': 95,
      // '% of daily session': '890.25%',
      // '# of days to confidence': 9,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 95,
    },
  ],
  advice_sell_article: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 582,
      // 'Daily Chrome User Session': 240,
      // '% of daily session': '242.60%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 240,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 409,
      // 'Daily Chrome User Session': 124,
      // '% of daily session': '329.59%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 124,
    },
  ],
  advice_sell_article_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '15,454',
      // 'Daily Chrome User Session': 164,
      // '% of daily session': '9423.00%',
      // '# of days to confidence': 95,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 164,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '72,683',
      // 'Daily Chrome User Session': 110,
      // '% of daily session': '66075.53%',
      // '# of days to confidence': 661,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 110,
    },
  ],
  advice_technology_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 290,
      // 'Daily Chrome User Session': 329,
      // '% of daily session': '88.24%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 88.24,
      // 'Actual Sampling Count': 290,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 556,
      // 'Daily Chrome User Session': 247,
      // '% of daily session': '224.94%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 247,
    },
  ],
  advice_truck_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 38,
      // 'Daily Chrome User Session': 1,
      // '% of daily session': '3796.72%',
      // '# of days to confidence': 38,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 1,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 26,
      // 'Daily Chrome User Session': 1,
      // '% of daily session': '2575.56%',
      // '# of days to confidence': 26,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 1,
    },
  ],
  advice_warranty_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 582,
      // 'Daily Chrome User Session': 246,
      // '% of daily session': '236.58%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 246,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 161,
      // 'Daily Chrome User Session': 185,
      // '% of daily session': '86.84%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 86.84,
      // 'Actual Sampling Count': 161,
    },
  ],
  'best_of_advice_best-suv-lease-deals_article': [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 67,
      // 'Daily Chrome User Session': 115,
      // '% of daily session': '58.37%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 58.37,
      // 'Actual Sampling Count': 67,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '2,648',
      // 'Daily Chrome User Session': 40,
      // '% of daily session': '6618.85%',
      // '# of days to confidence': 67,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 40,
    },
  ],
  'best_of_advice_best-used-cars_article': [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 415,
      // 'Daily Chrome User Session': 31,
      // '% of daily session': '1338.77%',
      // '# of days to confidence': 14,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 31,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '1,791',
      // 'Daily Chrome User Session': 11,
      // '% of daily session': '16283.92%',
      // '# of days to confidence': 163,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 11,
    },
  ],
  'best_of_advice_electric-car_article': [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 553,
      // 'Daily Chrome User Session': 404,
      // '% of daily session': '136.93%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 404,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '1,599',
      // 'Daily Chrome User Session': 242,
      // '% of daily session': '660.91%',
      // '# of days to confidence': 7,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 242,
    },
  ],
  best_of_advice_hybrid_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 119,
      // 'Daily Chrome User Session': 67,
      // '% of daily session': '177.19%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 67,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 961,
      // 'Daily Chrome User Session': 35,
      // '% of daily session': '2745.39%',
      // '# of days to confidence': 28,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 35,
    },
  ],
  best_of_advice_luxury_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 11,
      // 'Daily Chrome User Session': 64,
      // '% of daily session': '16.50%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 16.5,
      // 'Actual Sampling Count': 11,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 823,
      // 'Daily Chrome User Session': 23,
      // '% of daily session': '3577.91%',
      // '# of days to confidence': 36,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 23,
    },
  ],
  'best_of_advice_new-car-ratings_article': [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 315,
      // 'Daily Chrome User Session': 35,
      // '% of daily session': '898.96%',
      // '# of days to confidence': 9,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 35,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 499,
      // 'Daily Chrome User Session': 27,
      // '% of daily session': '1849.09%',
      // '# of days to confidence': 19,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 27,
    },
  ],
  best_of_advice_sedan_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 568,
      // 'Daily Chrome User Session': 490,
      // '% of daily session': '115.84%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 490,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '14,123',
      // 'Daily Chrome User Session': 683,
      // '% of daily session': '2067.83%',
      // '# of days to confidence': 21,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 683,
    },
  ],
  best_of_advice_suv_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 627,
      // 'Daily Chrome User Session': '1,203',
      // '% of daily session': '52.12%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 52.12,
      // 'Actual Sampling Count': 627,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '1,381',
      // 'Daily Chrome User Session': 564,
      // '% of daily session': '244.87%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 564,
    },
  ],
  best_of_advice_truck_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 359,
      // 'Daily Chrome User Session': 349,
      // '% of daily session': '102.90%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 349,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '3,741',
      // 'Daily Chrome User Session': 224,
      // '% of daily session': '1670.21%',
      // '# of days to confidence': 17,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 224,
    },
  ],
  best_of_advice_vehicles_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 796,
      // 'Daily Chrome User Session': 195,
      // '% of daily session': '408.10%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 195,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '3,574',
      // 'Daily Chrome User Session': 104,
      // '% of daily session': '3436.20%',
      // '# of days to confidence': 35,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 104,
    },
  ],
  best_selling_cars: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 573,
      // 'Daily Chrome User Session': 83,
      // '% of daily session': '690.86%',
      // '# of days to confidence': 7,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 83,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '14,779',
      // 'Daily Chrome User Session': 35,
      // '% of daily session': '42225.78%',
      // '# of days to confidence': 423,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 35,
    },
  ],
  calculators: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,294',
      // 'Daily Chrome User Session': 95,
      // '% of daily session': '1361.63%',
      // '# of days to confidence': 14,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 95,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 48,
      // 'Daily Chrome User Session': 39,
      // '% of daily session': '124.14%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 39,
    },
  ],
  calculators_affordability: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '3,279',
      // 'Daily Chrome User Session': 272,
      // '% of daily session': '1205.60%',
      // '# of days to confidence': 13,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 272,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '4,033',
      // 'Daily Chrome User Session': 153,
      // '% of daily session': '2635.79%',
      // '# of days to confidence': 27,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 153,
    },
  ],
  calculators_gas_guzzler: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,574',
      // 'Daily Chrome User Session': 22,
      // '% of daily session': '7153.37%',
      // '# of days to confidence': 72,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 22,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 17,
      // 'Daily Chrome User Session': 17,
      // '% of daily session': '99.66%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 99.66,
      // 'Actual Sampling Count': 17,
    },
  ],
  calculators_incentives_rebates: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 152,
      // 'Daily Chrome User Session': 13,
      // '% of daily session': '1165.72%',
      // '# of days to confidence': 12,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 13,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '13,957',
      // 'Daily Chrome User Session': 14,
      // '% of daily session': '99691.60%',
      // '# of days to confidence': 997,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 14,
    },
  ],
  calculators_lease: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 472,
      // 'Daily Chrome User Session': 272,
      // '% of daily session': '173.67%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 272,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '18,250',
      // 'Daily Chrome User Session': 75,
      // '% of daily session': '24333.49%',
      // '# of days to confidence': 244,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 75,
    },
  ],
  calculators_loan_payment: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 114,
      // 'Daily Chrome User Session': 294,
      // '% of daily session': '38.88%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 38.88,
      // 'Actual Sampling Count': 114,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '4,646',
      // 'Daily Chrome User Session': 111,
      // '% of daily session': '4185.27%',
      // '# of days to confidence': 42,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 111,
    },
  ],
  car_incentives: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 175,
      // 'Daily Chrome User Session': 222,
      // '% of daily session': '79.00%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 79.0,
      // 'Actual Sampling Count': 175,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 243,
      // 'Daily Chrome User Session': 82,
      // '% of daily session': '296.36%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 82,
    },
  ],
  car_incentives_make: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 204,
      // 'Daily Chrome User Session': 397,
      // '% of daily session': '51.45%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 51.45,
      // 'Actual Sampling Count': 204,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '1,695',
      // 'Daily Chrome User Session': 149,
      // '% of daily session': '1137.55%',
      // '# of days to confidence': 12,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 149,
    },
  ],
  car_incentives_model: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 692,
      // 'Daily Chrome User Session': 1103,
      // '% of daily session': '62.70%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 62.7,
      // 'Actual Sampling Count': 692,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 632,
      // 'Daily Chrome User Session': 440,
      // '% of daily session': '143.56%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 440,
    },
  ],
  car_incentives_type: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '5,353',
      // 'Daily Chrome User Session': 124,
      // '% of daily session': '4316.91%',
      // '# of days to confidence': 44,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 124,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 994,
      // 'Daily Chrome User Session': 81,
      // '% of daily session': '1226.82%',
      // '# of days to confidence': 13,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 81,
    },
  ],
  car_news_industry_article_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 456,
      // 'Daily Chrome User Session': 97,
      // '% of daily session': '470.14%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 97,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 79,
      // 'Daily Chrome User Session': 8,
      // '% of daily session': '988.44%',
      // '# of days to confidence': 10,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 8,
    },
  ],
  car_reviews_best_cars_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,033',
      // 'Daily Chrome User Session': 64,
      // '% of daily session': '1614.43%',
      // '# of days to confidence': 17,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 64,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '1,641',
      // 'Daily Chrome User Session': 28,
      // '% of daily session': '5860.35%',
      // '# of days to confidence': 59,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 28,
    },
  ],
  car_reviews_consumer_ratings_reviews_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 38,
      // 'Daily Chrome User Session': 24,
      // '% of daily session': '157.03%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 24,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 44,
      // 'Daily Chrome User Session': 11,
      // '% of daily session': '403.72%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 11,
    },
  ],
  car_reviews_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,523',
      // 'Daily Chrome User Session': '2,633',
      // '% of daily session': '57.84%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 57.84,
      // 'Actual Sampling Count': '1,523',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '8,041',
      // 'Daily Chrome User Session': 502,
      // '% of daily session': '1601.73%',
      // '# of days to confidence': 17,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 502,
    },
  ],
  car_reviews_road_tests_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 275,
      // 'Daily Chrome User Session': 84,
      // '% of daily session': '327.60%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 84,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '3,259',
      // 'Daily Chrome User Session': 13,
      // '% of daily session': '25073.05%',
      // '# of days to confidence': 251,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 13,
    },
  ],
  comparator: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 214,
      // 'Daily Chrome User Session': '1,880',
      // '% of daily session': '11.39%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 11.39,
      // 'Actual Sampling Count': 214,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 654,
      // 'Daily Chrome User Session': 562,
      // '% of daily session': '116.45%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 562,
    },
  ],
  comparator_make_model_vs_make_model: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '2,009',
      // 'Daily Chrome User Session': 133,
      // '% of daily session': '1510.59%',
      // '# of days to confidence': 16,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 133,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '5,236',
      // 'Daily Chrome User Session': 67,
      // '% of daily session': '7815.57%',
      // '# of days to confidence': 79,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 67,
    },
  ],
  cpo_programs_cars_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,188',
      // 'Daily Chrome User Session': 96,
      // '% of daily session': '1237.21%',
      // '# of days to confidence': 13,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 96,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '20,192',
      // 'Daily Chrome User Session': 31,
      // '% of daily session': '65136.80%',
      // '# of days to confidence': 652,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 31,
    },
  ],
  home_page: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,662',
      // 'Daily Chrome User Session': '11,033',
      // '% of daily session': '15.06%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 15.06,
      // 'Actual Sampling Count': '1,662',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '6,825',
      // 'Daily Chrome User Session': '6,504',
      // '% of daily session': '104.94%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '6,504',
    },
  ],
  lease_landing_inventory_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 499,
      // 'Daily Chrome User Session': 53,
      // '% of daily session': '941.99%',
      // '# of days to confidence': 10,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 53,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 13,
      // 'Daily Chrome User Session': 24,
      // '% of daily session': '53.45%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 53.45,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 53.45,
      // 'Actual Sampling Count': 13,
    },
  ],
  maintenance_how_to_article: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 990,
      // 'Daily Chrome User Session': 842,
      // '% of daily session': '117.58%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 842,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 278,
      // 'Daily Chrome User Session': 495,
      // '% of daily session': '56.23%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 56.23,
      // 'Actual Sampling Count': 278,
    },
  ],
  make_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 224,
      // 'Daily Chrome User Session': 1445,
      // '% of daily session': '15.51%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 15.51,
      // 'Actual Sampling Count': 224,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 672,
      // 'Daily Chrome User Session': 595,
      // '% of daily session': '112.92%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 595,
    },
  ],
  make_lease_landing_inventory_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '2,347',
      // 'Daily Chrome User Session': 482,
      // '% of daily session': '486.87%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 482,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 881,
      // 'Daily Chrome User Session': 305,
      // '% of daily session': '288.75%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 305,
    },
  ],
  make_model_lease_landing_inventory_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 935,
      // 'Daily Chrome User Session': 960,
      // '% of daily session': '97.38%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 97.38,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 97.38,
      // 'Actual Sampling Count': 935,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 832,
      // 'Daily Chrome User Session': 609,
      // '% of daily session': '136.67%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 609,
    },
  ],
  make_model_lease_landing_inventory_srp_ntl: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,443',
      // 'Daily Chrome User Session': 844,
      // '% of daily session': '170.99%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 844,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 252,
      // 'Daily Chrome User Session': 419,
      // '% of daily session': '60.03%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 60.03,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 60.03,
      // 'Actual Sampling Count': 252,
    },
  ],
  make_type_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,234',
      // 'Daily Chrome User Session': 378,
      // '% of daily session': '326.36%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 378,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 416,
      // 'Daily Chrome User Session': 97,
      // '% of daily session': '428.36%',
      // '# of days to confidence': 5,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 97,
    },
  ],
  new_cars_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '5,483',
      // 'Daily Chrome User Session': '2,882',
      // '% of daily session': '190.26%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '2,882',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '5,202',
      // 'Daily Chrome User Session': 478,
      // '% of daily session': '1088.38%',
      // '# of days to confidence': 11,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 478,
    },
  ],
  new_model_core: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '9,326',
      // 'Daily Chrome User Session': '21,976',
      // '% of daily session': '42.44%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 42.44,
      sampleRate: 100.0, // TODO: Remove 'sampleRate: 100' after POC experiment is done - https://edmunds.atlassian.net/browse/PLAT-3804
      // 'Actual Sampling Count': '9,326',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '49,611',
      // 'Daily Chrome User Session': '13,656',
      // '% of daily session': '363.29%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '13,656',
    },
  ],
  new_model_core_consumer_reviews: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 310,
      // 'Daily Chrome User Session': 2688,
      // '% of daily session': '11.53%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 11.53,
      // 'Actual Sampling Count': 310,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '3,228',
      // 'Daily Chrome User Session': '1,059',
      // '% of daily session': '304.82%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '1,059',
    },
  ],
  new_model_core_feature_specs: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 155,
      // 'Daily Chrome User Session': 2483,
      // '% of daily session': '6.24%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': 248,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 325,
      // 'Daily Chrome User Session': '1,247',
      // '% of daily session': '26.07%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 26.07,
      // 'Actual Sampling Count': 325,
    },
  ],
  new_model_core_mpg: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 56,
      // 'Daily Chrome User Session': 515,
      // '% of daily session': '10.96%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.96,
      // 'Actual Sampling Count': 56,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 220,
      // 'Daily Chrome User Session': 361,
      // '% of daily session': '61.01%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 61.01,
      // 'Actual Sampling Count': 220,
    },
  ],
  new_model_core_pictures: [
    {
      // device_type: 'Desktop',
      // todo https://edmunds.atlassian.net/browse/PROD-419069 this data is missing from GDOC, should be added
      sampleRate: 100,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 712,
      // 'Daily Chrome User Session': 670,
      // '% of daily session': '106.29%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 670,
    },
  ],
  new_model_core_tco: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 21,
      // 'Daily Chrome User Session': 802,
      // '% of daily session': '2.59%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': 80,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 93,
      // 'Daily Chrome User Session': 176,
      // '% of daily session': '52.87%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 52.87,
      // 'Actual Sampling Count': 93,
    },
  ],
  new_type_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '7,883',
      // 'Daily Chrome User Session': '7,668',
      // '% of daily session': '102.81%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '7,668',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '13,012',
      // 'Daily Chrome User Session': '3,644',
      // '% of daily session': '357.09%',
      // '# of days to confidence': 4,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '3,644',
    },
  ],
  other_tco_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '4,408',
      // 'Daily Chrome User Session': 598,
      // '% of daily session': '737.17%',
      // '# of days to confidence': 8,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 598,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 732,
      // 'Daily Chrome User Session': 97,
      // '% of daily session': '754.22%',
      // '# of days to confidence': 8,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 97,
    },
  ],
  preprod_new_model_core: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,935',
      // 'Daily Chrome User Session': '2,388',
      // '% of daily session': '81.02%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 81.02,
      // 'Actual Sampling Count': '1,935',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '24,868',
      // 'Daily Chrome User Session': 908,
      // '% of daily session': '2738.77%',
      // '# of days to confidence': 28,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 908,
    },
  ],
  tmv_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '4,066',
      // 'Daily Chrome User Session': 553,
      // '% of daily session': '735.27%',
      // '# of days to confidence': 8,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 553,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 392,
      // 'Daily Chrome User Session': 166,
      // '% of daily session': '236.07%',
      // '# of days to confidence': 3,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 166,
    },
  ],
  type_lease_landing_inventory_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 74,
      // 'Daily Chrome User Session': 9,
      // '% of daily session': '826.37%',
      // '# of days to confidence': 9,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 9,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 889,
      // 'Daily Chrome User Session': 15,
      // '% of daily session': '5929.39%',
      // '# of days to confidence': 60,
      sampleRateFromGDoc: 100.0,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': 15,
    },
  ],
  used_car_inventory_srp: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,381',
      // 'Daily Chrome User Session': '21,087',
      // '% of daily session': '6.55%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': '2,109',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 400,
      // 'Daily Chrome User Session': '18,224',
      // '% of daily session': '2.19%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': '1,822',
    },
  ],
  used_car_inventory_srp_ntl: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '1,974',
      // 'Daily Chrome User Session': '5,930',
      // '% of daily session': '33.28%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 33.28,
      sampleRate: 10.0, // this will take precedence over sampleRateFromGDoc: 33.28,
      // 'Actual Sampling Count': '1,974',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': 249,
      // 'Daily Chrome User Session': '6,354',
      // '% of daily session': '3.92%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': 635,
    },
  ],
  used_cars_index: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '2,059',
      // 'Daily Chrome User Session': '4,271',
      // '% of daily session': '48.20%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 48.2,
      // 'Actual Sampling Count': '2,059',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '6,931',
      // 'Daily Chrome User Session': '1,323',
      // '% of daily session': '523.86%',
      // '# of days to confidence': 6,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '1,323',
    },
  ],
  used_cars_tmv_appraiser: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 343,
      // 'Daily Chrome User Session': '6,046',
      // '% of daily session': '5.67%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': 605,
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '17,194',
      // 'Daily Chrome User Session': '1,653',
      // '% of daily session': '1040.14%',
      // '# of days to confidence': 11,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '1,653',
    },
  ],
  used_model_core: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': '3,177',
      // 'Daily Chrome User Session': '2,846',
      // '% of daily session': '111.63%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '2,846',
    },
    {
      // device_type: 'mobile',
      // 'Recommended Sample Size': '4,068',
      // 'Daily Chrome User Session': '2,543',
      // '% of daily session': '159.97%',
      // '# of days to confidence': 2,
      sampleRateFromGDoc: 100.0,
      // 'Actual Sampling Count': '2,543',
    },
  ],
  used_model_core_consumer_reviews: [
    {
      // device_type: 'Desktop',
      // 'Recommended Sample Size': 126,
      // 'Daily Chrome User Session': 6078,
      // '% of daily session': '2.08%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': 608,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': 994,
      // 'Daily Chrome User Session': '6,193',
      // '% of daily session': '16.05%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 16.05,
      // 'Actual Sampling Count': 994,
    },
  ],
  used_model_core_review: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 966,
      // 'Daily Chrome User Session': '10,018',
      // '% of daily session': '9.64%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10.0,
      // 'Actual Sampling Count': '1,002',
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': '2,903',
      // 'Daily Chrome User Session': '7,903',
      // '% of daily session': '36.73%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 36.73,
      // 'Actual Sampling Count': '2,903',
    },
  ],
  used_model_mydp_tmv_appraiser_style: [
    {
      // device_type: 'Desktop',

      // 'Recommended Sample Size': 11,
      // 'Daily Chrome User Session': 8885,
      // '% of daily session': '0.13%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 10,
      // 'Actual Sampling Count': 889,
    },
    {
      // device_type: 'mobile',

      // 'Recommended Sample Size': '1,324',
      // 'Daily Chrome User Session': '6,053',
      // '% of daily session': '21.88%',
      // '# of days to confidence': 1,
      sampleRateFromGDoc: 21.88,
      // 'Actual Sampling Count': '1,324',
    },
  ],
};

export function getSpeedcurveSampleRate(pageName, isMobile, defaultVal = 40) {
  if (!overrides[pageName]) {
    return defaultVal;
  }
  const data = isMobile ? overrides[pageName][1] : overrides[pageName][0];
  return data.sampleRate || data.sampleRateFromGDoc;
}
