import PropTypes from 'prop-types';
import { get } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';

const getVinStylesApi = vin => `/vehicle/v3/styles/vins/${vin}`;

export const getVinStylesDataWithMetadata = async (vin, context) => {
  let response;
  try {
    const API = !context ? EdmundsAPI : await withMetrics(EdmundsAPI, context);
    const results = await API.fetchJson(getVinStylesApi(vin));
    response = {
      results,
    };
  } catch (e) {
    response = {
      hasError: true,
      results: [],
    };
  }
  return response;
};

export const getVinStylesData = async (vin, context) => {
  const response = await getVinStylesDataWithMetadata(vin, context);
  return response.results;
};

const getSquishVinApi = squishVin =>
  `/vehicle/v3/styles?squishVins.squishVin=${squishVin.toUpperCase()}&fields=id,year,makeNiceId,makeName,modelNiceId,modelName`;

const getUniversalVinApi = vin =>
  `/inventory/v5/universalvin/${vin}?fields=vin,vehicleInfo.styleInfo,vehicleInfo.partsInfo,vehicleInfo.vehicleColors`;

export const getSquishVinData = async (squishVin, context) => {
  let response;
  try {
    const API = !context ? EdmundsAPI : await withMetrics(EdmundsAPI, context);
    response = await API.fetchJson(getSquishVinApi(squishVin));
  } catch (e) {
    response = {};
  }
  return get(response, 'results', []);
};

export const getUniversalVinData = async vin => {
  let response;
  try {
    response = await EdmundsAPI.fetchJson(getUniversalVinApi(vin));
  } catch (e) {
    response = [];
  }
  return response;
};

/**
 * NOTE: Vehicle API resolvers NOT specific to VINs can be found in the￼vehicle.js data model.
 * Platform team requested the separation to reduce bloat in vehicle.js.
 */
export const VehicleVinModel = createModelSegment('vehicleVin', [
  /**
   * https://www.edmunds.com/api/vehicle/v3/styles?squishVins.squishVin=1GKKNMLSKZ&fields=id,year,makeNiceId,makeName,modelNiceId,modelName
   */
  {
    path: 'squishVin["{squishVin}"].stylesBasicInfo',
    async resolve({ squishVin }, context) {
      return getSquishVinData(squishVin, context);
    },
  },
  {
    path: 'vin["{vin}"].stylesBasicInfo',
    async resolve({ vin }, context) {
      return getVinStylesData(vin, context);
    },
  },
]);

export function buildStylesBasicPathFromSquishVin({ squishVin }) {
  return squishVin ? `squishVin["${squishVin}"].stylesBasicInfo` : '';
}

export function buildStylesBasicPathFromVin({ vin }) {
  return vin ? `vin["${vin}"].stylesBasicInfo` : '';
}

const SquishStyle = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.number,
  makeName: PropTypes.string,
  makeNiceId: PropTypes.string,
  modelName: PropTypes.string,
  modelNiceId: PropTypes.string,
});

export const VehicleVinEntities = {
  SquishStyle,
  SquishStyles: PropTypes.arrayOf(SquishStyle),
};
