export const makes = [
  'acura',
  'alfa-romeo',
  'alfaromeo',
  'am-general',
  'amgeneral',
  'aston-martin',
  'astonmartin',
  'audi',
  'bentley',
  'bmw',
  'bugatti',
  'byton',
  'buick',
  'cadillac',
  'chevrolet',
  'chrysler',
  'daewoo',
  'dodge',
  'eagle',
  'ferrari',
  'fiat',
  'fisker',
  'ford',
  'genesis',
  'geo',
  'gmc',
  'honda',
  'hummer',
  'hyundai',
  'ineos',
  'infiniti',
  'isuzu',
  'jaguar',
  'jeep',
  'karma',
  'kia',
  'lamborghini',
  'land-rover',
  'landrover',
  'lexus',
  'lincoln',
  'lotus',
  'lucid',
  'maserati',
  'maybach',
  'mazda',
  'mclaren',
  'mercedes-benz',
  'mercedesbenz',
  'mercury',
  'mini',
  'mitsubishi',
  'nissan',
  'oldsmobile',
  'panoz',
  'plymouth',
  'polestar',
  'pontiac',
  'porsche',
  'ram',
  'rivian',
  'rolls-royce',
  'rollsroyce',
  'saab',
  'saturn',
  'scion',
  'smart',
  'spyker',
  'subaru',
  'suzuki',
  'tesla',
  'toyota',
  'vinfast',
  'volkswagen',
  'volvo',
];

export const TWO_WORDS_MAKE_NAMES_MAP = {
  alfaromeo: 'alfa-romeo',
  amgeneral: 'am-general',
  astonmartin: 'aston-martin',
  landrover: 'land-rover',
  mercedesbenz: 'mercedes-benz',
  rollsroyce: 'rolls-royce',
};
