/* eslint-disable-next-line venom/no-prohibited-functions */
import { clamp, inRange, min, memoize, get, forEach } from 'lodash';
import { ClientConfig } from 'client/configuration';

// ordered from max size to min size because it's handy to find the closest value
export const DEFAULT_SIZES = [[1600, 1067], [1280, 855], [717, 478], [600, 400], [500, 333], [300, 200], [175, 117]];

const S3_ASPECT_RATIO = [[16, 7], [3, 2]];

const S3_DEFAULT_SIZES = {
  '3x2': [[1600, 1067], [1280, 855], [815, 543], [600, 400], [300, 200], [175, 117]],
  '16x7': [[1600, 700], [1280, 560], [600, 263], [300, 131], [175, 77]],
};

export const SOURCES = {
  DAM: 'dam',
  MEDIA: 'media',
  S3MEDIA: 's3media',
  STATIC: 'static',
  EDMUNDSCDN: 'edmundsCdn',
  CONTENTSTACK: 'contentSrc',
};

const S3_IMAGE_TYPES = {
  PROFILE: 'profile',
  DEFAULT: 'default',
};

export const STOCKPHOTOS_THRESHOLD_YEAR = 2012;

const MIN_ASPECT_RATION = S3_ASPECT_RATIO[S3_ASPECT_RATIO.length - 1];
const MAX_ASPECT_RATION = S3_ASPECT_RATIO[0];
export const DEFAULT_SOURCE = SOURCES.MEDIA;

export const MIN_SIZE = DEFAULT_SIZES[DEFAULT_SIZES.length - 1];
export const MIN_WIDTH = MIN_SIZE[0];

export const MAX_SIZE = DEFAULT_SIZES[0];
export const MAX_WIDTH = MAX_SIZE[0];
export const MAX_HEIGHT = MAX_SIZE[1];
export const IMAGE_SIZE_AND_EXT_REG = /_([0-9]+|[0-9]+x[0-9]+)\.jpg$/i;

export const YOUTUBE_PREVIEW_SIZE = {
  DEFAULT: '',
  SD: 'sd',
  MQ: 'mq',
  HQ: 'hq',
  MAX_RES: 'maxres',
};

/**
 * Return the size closest to the requested size to fit
 * @param width requested size
 * @param aspect - desired aspect ratio. NOTE: null value means only width param usage
 * @return [width]
 * e.g.
 * [175,117]
 * [300,200]
 * [600,400]
 * [717, 478]
 * [1280,855]
 * [1600,1067]
 */
export function getClosestSizeToFit(width, aspect) {
  if (!inRange(width, MIN_WIDTH, MAX_WIDTH)) {
    const res = clamp(width, MIN_WIDTH, MAX_WIDTH);
    return res === MAX_WIDTH ? MAX_SIZE : MIN_SIZE;
  }

  const availSizes = aspect || DEFAULT_SIZES;
  const deltas = availSizes.map(([w]) => Math.abs(width - w));
  return availSizes[deltas.indexOf(min(deltas))];
}

/**
 * Return the aspect ratio closest to the requested size to fit
 * @param widthToFit requested size
 * @param heightToFit requested size
 * @return [width, height]
 * e.g.
 * [3,2]
 * [16,7]
 */
function getS3ClosestAspectRation(widthToFit, heightToFit) {
  if (
    !inRange(
      widthToFit / heightToFit,
      MIN_ASPECT_RATION[0] / MIN_ASPECT_RATION[1],
      MAX_ASPECT_RATION[0] / MAX_ASPECT_RATION[1]
    )
  ) {
    const res = clamp(
      widthToFit / heightToFit,
      MIN_ASPECT_RATION[0] / MIN_ASPECT_RATION[1],
      MAX_ASPECT_RATION[0] / MAX_ASPECT_RATION[1]
    );
    return res === MAX_ASPECT_RATION[0] / MAX_ASPECT_RATION[1] ? MAX_ASPECT_RATION : MIN_ASPECT_RATION;
  }
  const deltas = S3_ASPECT_RATIO.map(([w, h]) => Math.abs(widthToFit / heightToFit - w / h));
  return S3_ASPECT_RATIO[deltas.indexOf(min(deltas))];
}

/**
 * Return the size closest to the requested size to fit
 * @param widthToFit requested size
 * @param heightToFit requested size
 * @return [width, height]
 * e.g.
 * [1600, 1067],
 * [1600, 700],
 * [1280, 855],
 * [1280, 560],
 * [815, 543],
 * [600, 400],
 * [600, 263],
 * [300, 200],
 * [300, 131],
 * [175, 117],
 * [175, 77],
 */
function getS3ClosestSizeToFit(widthToFit, heightToFit) {
  const aspectRation = getS3ClosestAspectRation(widthToFit, heightToFit);
  const aspectRationLabel = `${aspectRation[0]}x${aspectRation[1]}`;
  const currentSizes = S3_DEFAULT_SIZES[aspectRationLabel];
  const minSize = currentSizes[currentSizes.length - 1];
  const minWidth = minSize[0];
  const maxSize = currentSizes[0];
  const maxWidth = maxSize[0];

  if (!inRange(widthToFit, minWidth, maxWidth)) {
    const res = clamp(widthToFit, minWidth, maxWidth);
    return res === maxWidth ? maxSize : minSize;
  }

  const deltas = currentSizes.map(([w]) => Math.abs(widthToFit - w));
  return currentSizes[deltas.indexOf(min(deltas))];
}

const firstSlashRgx = /^(\/)*/;
const damRgx = /^(dam:\/\/\/photo|dam\/photo){1}/;
const staticRgx = /^(static:\/\/\/)/;
const csRgx = /^(cs:\/\/\/)/;
const httpRx = /^http/;
const extensionRx = /\.(jpg|jpeg|png|gif)$/;

/* eslint-disable venom/no-prohibited-functions */
const getMediaUrl = memoize(() => `https:${ClientConfig.get('mediaCdnUrl')}`, () => 'media');
const getStaticUrl = memoize(() => `https:${ClientConfig.get('unversionedUrl')}`, () => 'static');
const getUnversionedCdnUrl = memoize(() => `https:${ClientConfig.get('unversionedCdnUrl')}`, () => 'cdn');
const getS3MediaUrl = memoize(() => `https:${ClientConfig.get('mediaCdnUrl')}/stockphotos`, () => 's3media');
const getEdmundsCdnUrl = memoize(() => `https:${ClientConfig.get('edmundsCdnUrl')}`, () => 'edmundsCdn');
const getCarmaxImgUrl = memoize(() => `https:${ClientConfig.get('carmaxImgUrl')}`, () => 'carmaxImg');
const getContentStackUrl = memoize(() => `${ClientConfig.get('contentStackUrl')}`, () => 'contentStack');
/* eslint-enable venom/no-prohibited-functions */

/**
 * Transforming dam urls
 * @param {String} src
 * ex.  dam:///photo/non-make/carbuying/carbuying_10261605
 *      dam/photo/honda/accord/2016/oem/2016_honda_accord_coupe_touring-v-6_fq_oem_3
 */
export function getDamPhotoUrl(src) {
  return `${getMediaUrl()}${src.replace(damRgx, '').replace(firstSlashRgx, '/')}`;
}

export function getContentPhotoUrl(src) {
  return `${getContentStackUrl()}${src.replace(csRgx, '').replace(firstSlashRgx, '/')}`;
}

export function getStaticPhotoUrl(src, isMobile) {
  const imageUrl = `${getStaticUrl()}${src.replace(staticRgx, '').replace(firstSlashRgx, '/')}`;

  return extensionRx.test(imageUrl) ? imageUrl : `${imageUrl}${isMobile ? 'mobile' : 'desktop'}.jpg`;
}

/**
 * Constructs the environment specific url specific to the media
 * @param {String} src
 */
export function getMediaImageUrl(src) {
  return `${getMediaUrl()}${src.replace(firstSlashRgx, '/')}`;
}

/**
 * Constructs the environment specific url specific to the media
 * @param {String} src
 */
export function getEdmundsImageUrl(src) {
  return `${getEdmundsCdnUrl()}${src.replace(firstSlashRgx, '/')}`;
}

/**
 * Constructs the environment specific url specific to the s3 media
 * @param {String} src
 * @param {String} imageType
 * @return {String} return s3 media profile path part, without size
 */
function getS3MediaImageUrl(src, imageType) {
  return `${getS3MediaUrl()}${src.replace(firstSlashRgx, '/')}/${imageType}`;
}

/**
 * Transforming unversioned images static urls
 * @param {String} src
 */
export function getStaticImageUrl(src) {
  return `${getStaticUrl()}${src.replace(firstSlashRgx, '/')}`;
}

/**
 * Transforming unversioned images cdn urls
 * @param {String} src
 */
export function getUnversionedCdnImageUrl(src) {
  return `${getUnversionedCdnUrl()}${src.replace(firstSlashRgx, '/')}`;
}

export function getCarmaxImageUrl(src) {
  return `${getCarmaxImgUrl()}${src.replace(firstSlashRgx, '/')}`;
}

/**
 *
 * @param {String} imageUrl
 * @param {Number} width
 */
export function addWidthAndExtToUrl(imageUrl, width) {
  const imagePath = extensionRx.test(imageUrl) || !width ? imageUrl : `${imageUrl}_${width}.jpg`;
  return imagePath;
}

/**
 *
 * @param {String} imageUrl
 * @param {Number} width
 * @return {String} return raw image url (src) with width added to the end of the image path
 */
function addS3WidthAndExtToUrl(imageUrl, width) {
  const imagePath = extensionRx.test(imageUrl) || !width ? imageUrl : `${imageUrl}-${width}.jpg`;
  return imagePath;
}

const source2UrlFnMap = {
  [SOURCES.DAM]: getDamPhotoUrl,
  [SOURCES.MEDIA]: getMediaImageUrl,
  [SOURCES.S3MEDIA]: getS3MediaImageUrl,
  [SOURCES.STATIC]: getStaticImageUrl,
  [SOURCES.EDMUNDSCDN]: getEdmundsImageUrl,
  [SOURCES.CONTENTSTACK]: getContentPhotoUrl,
};

/**
 *
 * @param {String} imagePath image identifier
 * @param {Number} width the requested image width
 * @param {String} source 'dam', 'media', 'static'
 * @param {String} imageType optional imageType parameter
 * @return {String} return raw image url (src) with width added to the end of the image path
 *  e.g. [image_path]_[image_width].[extension]
 */
export function getImageUrl(imagePath, width, source, imageType) {
  if (!imagePath) return null;

  if (imagePath.match(httpRx) || !source) return imagePath;

  const urlFn = source2UrlFnMap[source];
  if (!urlFn) return imagePath;

  return source === SOURCES.S3MEDIA
    ? addS3WidthAndExtToUrl(urlFn(imagePath, imageType), width)
    : addWidthAndExtToUrl(urlFn(imagePath), width);
}

export function isDamSrc(src) {
  return damRgx.test(src);
}

export function isStaticSrc(src) {
  return staticRgx.test(src);
}

export function isContentSrc(src) {
  return csRgx.test(src);
}

export function getValidImageUrl(src, defaultSource = SOURCES.MEDIA) {
  if (!src) {
    return null;
  }

  if (isDamSrc(src)) {
    return getDamPhotoUrl(src);
  }

  if (isContentSrc(src)) {
    return getContentPhotoUrl(src);
  }

  if (src[0] === '/') {
    return source2UrlFnMap[defaultSource](src);
  }

  return src;
}

export function getValidImageUrlWithSize(src, size) {
  if (!src) {
    return null;
  }

  if (isDamSrc(src)) {
    return getDamPhotoUrl(src);
  }

  if (src[0] === '/') {
    const newSrc = src.replace(IMAGE_SIZE_AND_EXT_REG, '');
    return getValidImageUrl(`${newSrc}_${size}.jpg`);
  }

  return src;
}

/**
 *
 * @param {String} image src
 * @return {String} return image size
 * For the oem and evox images we return 300x200 pixels size,
 * for the editorial images we return 300 instead of 276 pixels, Image size 276 will be deprecated in the future.
 */
export function getSmallImageSize(src) {
  if (!src) {
    return null;
  }
  return src.indexOf('/oem/') === -1 && src.indexOf('/evox/') === -1 ? '300' : '300x200';
}

export function onErrorReplaceWith(image) {
  return function handleError(e) {
    e.currentTarget.src = image;
  };
}

export function getMediaImageUrlBySize(imagePath, sizeToFit) {
  const imageWidth = getClosestSizeToFit(sizeToFit)[0];
  return getImageUrl(imagePath, imageWidth, SOURCES.MEDIA);
}

function getS3ImageBySize(imagePath, width, height, imageType) {
  const imageSize = getS3ClosestSizeToFit(width, height);
  const imageSizeStr = `${imageSize[0]}x${imageSize[1]}`;
  return getImageUrl(imagePath, imageSizeStr, SOURCES.S3MEDIA, imageType);
}

export function getS3MediaProfileImageUrlBySize(vehicle, widthToFit, heightToFit) {
  if (!vehicle) return null;

  const modelPath = `/${vehicle.make.slug}/${vehicle.model.slug}/${vehicle.year}`;
  const submodel = get(vehicle, 'submodels.slug');
  const imagePath = `${modelPath}${submodel ? `/${submodel}` : ''}`;
  return getS3ImageBySize(imagePath, widthToFit, heightToFit, S3_IMAGE_TYPES.PROFILE);
}

export function getS3MediaDefaultImageUrlBySize(vehicle, widthToFit, heightToFit) {
  if (!vehicle) return null;
  const modelPath = `/${vehicle.make.slug}/${vehicle.model.slug}${vehicle.year ? `/${vehicle.year}` : ''}`;
  const submodel = get(vehicle, 'submodels.slug');
  const imagePath = `${modelPath}${submodel ? `/${submodel}` : ''}`;
  return getS3ImageBySize(imagePath, widthToFit, heightToFit, S3_IMAGE_TYPES.DEFAULT);
}

export function getS3MediaTypeImageUrlBySize(vehicleType, widthToFit, heightToFit) {
  if (!vehicleType) return null;

  const imagePath = `/${vehicleType}`;
  return getS3ImageBySize(imagePath, widthToFit, heightToFit, S3_IMAGE_TYPES.DEFAULT);
}

export function getFullImageUrl(imagePath, sizeToFit) {
  const imageSrc = getValidImageUrl(imagePath);
  const imageWidth = getClosestSizeToFit(sizeToFit)[0];
  return addWidthAndExtToUrl(imageSrc, imageWidth);
}

const addSlashes = params => {
  const modifiedParams = {};
  forEach(params, (value, key) => {
    modifiedParams[key] = value && `${value}/`;
  });
  return modifiedParams;
};

/**
 * @param {Object} params vehicle parameters - makeName, modelName, year, submodel, type (all parameters are optional but al least 1 required)
 * @param {Number} width the requested image width
 * @param {Number} height the requested image height
 * @return {String} returns the default stockPhoto url by size.
 */
export function getDefaultStockPhotoBySize(params, width, height) {
  const { make = '', model = '', year = '', submodel = '', type = '' } = addSlashes(params);
  return `/stockphotos/${make}${model}${year}${submodel}${type}default-${width}x${height}.jpg`;
}

export function getProfileStockPhotoBySize(params, width, height) {
  const { make = '', model = '', year = '', submodel = '', type = '' } = addSlashes(params);
  return `/stockphotos/${make}${model}${year}${submodel}${type}profile-${width}x${height}.jpg`;
}

/**
 * @param videoId {string}
 * @param size {string} must be one of YOUTUBE_PREVIEW_SIZE values
 * @returns {string}
 */
export function getYoutubePreviewImage(videoId, size) {
  return `https://img.youtube.com/vi/${videoId}/${size}default.jpg`;
}

export function getVideoFullSizePreview(videoObj) {
  if (!videoObj) {
    return null;
  }

  const { contentType, src, thumbnailURL } = videoObj;

  switch (contentType) {
    case 'youtube': {
      return getYoutubePreviewImage(src, YOUTUBE_PREVIEW_SIZE.MAX_RES);
    }
    default: {
      return thumbnailURL;
    }
  }
}
