import { omit, pick, mapValues } from 'lodash';
import { RECEIVED_AB_CAMPAIGNS, COMPUTE_AND_FORCE_DONE } from 'client/actions/constants';

/**
 * Updates AB-testing published Venom campaigns data.
 *
 * @param  {Object} state  Current state
 * @param  {Object} action State update action
 * @return {Array}        New state
 */
export function publishedExperiments(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVED_AB_CAMPAIGNS:
      return action.data;
    case COMPUTE_AND_FORCE_DONE:
      // certain fields are no longer required after the campaign parameters are computed
      if (action.state.wtfabdebug) {
        // we need recipeNames from AX campaigns if wtfabdebug=true. For ease of coding, just don't trim anything
        return state;
      }
      return mapValues(state, campaign => {
        if (campaign.type === 'AX') {
          // recipeNames/candidates can reach hundreds KB for an AX campaign, so storing those in redux is
          // a performance issue. Unless wtfabdebug=true, since we don't do validation for AX, there is no
          // need to store recipe names, and we only need to keep candidate info for bucketed recipe instead
          // of all recipes.
          const trimmedCampaign = omit(campaign, ['json', 'recipeNames', 'salt']);
          const computedCampaign = action.state.experiments.computed[campaign.name];
          const computedRecipeName = computedCampaign.recipe.value;
          const forcedRecipeName = computedCampaign.recipe.forced;
          trimmedCampaign.candidates = pick(campaign.candidates, [computedRecipeName, forcedRecipeName]);
          return trimmedCampaign;
        }
        return omit(campaign, ['json', 'salt']);
      });
    default:
      return state;
  }
}
