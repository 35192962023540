import { EventToolbox } from 'client/utils/event-toolbox';

/**
 * Fires 'wtf_experiment' pixel for the specified campaign and recipe.
 *
 * @param {String} campaign  campaign name.
 * @param {String} recipe  recipe name.
 * @param {Object=} extraData extra data
 */
function trackWtfExperimentPixel(campaign, recipe, extraData) {
  setTimeout(() => {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_category: 'system',
        action_name: 'wtf_experiment',
        action_cause: 'page_load',
        campaign,
        recipe,
        ...extraData,
      },
    });
  }, 5);
}

/**
 * Fires tracking pixel for the given Venom experiment.
 *
 * @param {Object} campaign  experiment configuration.
 * @param {string} recipeName
 */
export function trackVenomExperimentPixel(campaign, recipeName) {
  if (recipeName) {
    trackWtfExperimentPixel(campaign.name, recipeName);
  }
}

/**
 * Fires tracking pixel for the given Ax experiment.
 *
 * @param {Object} campaign  experiment configuration.
 * @param {string} recipeName
 */
export function trackAxExperimentPixel(campaign, recipeName, axCandidate) {
  if (recipeName) {
    const extraData = {
      arm: recipeName,
    };
    trackWtfExperimentPixel(
      campaign.name,
      recipeName === 'ctrl' ? 'ctrl' : 'chal',
      axCandidate ? { ...extraData, candidate: axCandidate.candidateId } : extraData
    );
  }
}
