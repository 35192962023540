/**
 *
 * @param a1 first array (must be sorted)
 * @param a2 second array (must be sorted)
 * @param compareFunc similar to what you would pass to sort()
 * @returns {*[]} sorted array from merge of a1 and a2
 */
export function mergeSortedArrays(a1, a2, compareFunc = (a, b) => a - b) {
  let i = 0;
  let j = 0;
  let k = 0;
  const result = [];
  while (i < a1.length && j < a2.length) {
    if (compareFunc(a1[i], a2[j]) < 0) {
      result[k] = a1[i];
      i += 1;
    } else {
      result[k] = a2[j];
      j += 1;
    }
    k += 1;
  }
  return result.concat(a1.slice(i), a2.slice(j));
}
