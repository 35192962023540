import { EdmundsAPI } from 'client/data/api/api-client';
import { getMetricsHeaders, getMetricsTraceHeaders } from 'client/utils/metrics-header';
import { logger } from 'client/utils/isomorphic-logger';
import packageInfo from 'package.json';

/**
 * @deprecated - now that we have shouldCallParseApi (at the route level), we would like to move away from the use of
 *             - PARSABLE_URL_PATTERN which is very difficult to maintain and reason about because it is a complex regex
 */
const PARSABLE_URL_PATTERN = /^(?!\/$)\/(?!used-cars-for-sale\/)((used|new)-.+|deal\/(([\w\d]+[-]){2,})deals-.+\/?|.*lease-deals.*\/?|cars-for-sale-by-owner\/(|\?.*)|sell-car\/sell-my-car-in-.+\/?|\w+-dealership.*\/?|electric-car\/tax-credits-rebates-incentives\/.+\/?|)$/;
const PARSABLE_MMY_SRP_URL_PATTERN = /\/(.+)\/.+\/\d\d\d\d/;
const URLS_NOT_TO_CHECK_PATTERN = /^(?!(\/v\/api)|(\/v\/healthcheck)|(\/internal\/health\/basic)).*/;

const version = `${packageInfo.version}`;
/**
 * Checks if is a /v/api, health check api request, which we don't need to parse
 * @param {String} url URL to check
 */

export function shouldParseUrl(url) {
  return URLS_NOT_TO_CHECK_PATTERN.test(url);
}

/**
 * Checks if an url has a flat url structure
 * @param {String} url URL to check
 * @deprecated - now that we have shouldCallParseApi (at the route level), we would like to move away from the use of
 *             - isParseableUrl(url) which is very difficult to maintain and reason about because it is a complex regex
 */
export function isParseableUrl(url) {
  return PARSABLE_URL_PATTERN.test(url);
}

export function isParseableMMYUrl(url) {
  return PARSABLE_MMY_SRP_URL_PATTERN.test(url);
}

export function getMakeFromParseableMMYUrl(path) {
  if (!path) return null;
  const match = path.match(PARSABLE_MMY_SRP_URL_PATTERN);
  if (match) {
    return match[1];
  }
  return null;
}

export function getLastUrlPart(url) {
  if (url.includes('/sell-car/')) {
    const urlParts = url.split('?')[0].split('/');
    const nonEmptyUrlParts = urlParts.filter(part => part.length > 0);
    return `/${nonEmptyUrlParts.pop()}/`;
  }
  return url.split('?')[0];
}

/**
 * Function fetches parsed URL data
 * @param {String} url URL to be parsed e.g. /used-honda-accord-elida-oh/
 * @param {Function} onResponseCb callback function called on successfully fetching data
 * @param {Function} finallyCb callback function called at the end
 * @param {String} xTraceId
 */
export function parseUrl({ url, onResponseCb, finallyCb, xTraceId }) {
  const apiUrl = `/url/v2/parse?url=${getLastUrlPart(url)}`;
  const headers = xTraceId
    ? getMetricsTraceHeaders('preload', 'venom', version, xTraceId)
    : getMetricsHeaders('preload', 'venom', version);

  return EdmundsAPI.fetchJson(apiUrl, { headers })
    .then(response => {
      onResponseCb(response);
    })
    .catch(error => {
      logger('error', error);
    })
    .then(finallyCb);
}
