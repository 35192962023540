/* eslint-disable venom/no-venom-api-calls */

import { LOCATION_CHANGE, UPDATE_SESSION } from 'client/actions/constants';
import { VenomApi } from 'client/data/api/api-client';
import { isSpaPage } from 'client/utils/history/is-spa-page';
import { updateSession } from 'client/actions/visitor';
import { EventToolbox } from 'client/utils/event-toolbox';
import { isPageChanged } from './location';

function isNotFoundError(pageContext) {
  return pageContext.location.pathname === '/404.html' || pageContext.page.name === 'not_found';
}

function doSpaSync() {
  return VenomApi.fetchJson('/spa-sync/').catch(() => ({ caught: true }));
}

const createPath = location => {
  if (location == null || typeof location === 'string') return location;

  const { basename, pathname, search, hash } = location;
  let path = (basename || '') + pathname;

  if (search && search !== '?') {
    path += search;
  }

  return hash ? `${path}${hash}` : path;
};

export const pageReloadMiddleware = store => next => action => {
  const state = store.getState();
  if (action.type === LOCATION_CHANGE && state.venomVersion.needsRefresh) {
    window.location.reload();
  }

  const { venomEnv, edmundsEnv } = state;

  if (
    action.type === LOCATION_CHANGE &&
    !action.payload.location.pathname.includes('error-page') &&
    !state.pageContext.location.pathname.includes('error-page') &&
    !isNotFoundError(state.pageContext) &&
    isPageChanged(state.pageContext.location, action.payload.location)
  ) {
    if (action.payload.action === 'POP' && !isSpaPage(action.payload.location.pathname, venomEnv, edmundsEnv)) {
      window.location.assign(createPath(action.payload.location) || '');
      return undefined;
    }
  }
  const spaRulesEnabled = state.featureFlags.spaRules;
  if (
    action.type === LOCATION_CHANGE &&
    (isSpaPage(action.payload.location.pathname, venomEnv, edmundsEnv) || !spaRulesEnabled)
  ) {
    doSpaSync().then(({ sessionId, isNewSession }) => {
      if (!sessionId || (state.visitor.session === sessionId && state.visitor.isNewSession === isNewSession)) {
        // do not fire the custom event if nothing has changed so GTM update does not eat up 200ms for no reason
        return;
      }
      store.dispatch(updateSession({ session: sessionId, isNewSession }));
      EventToolbox.fireCustomEvent(UPDATE_SESSION, { sessionId, isNewSession });
    });
  }

  return next(action);
};
