import { isEqual } from 'lodash';
import { ClientTimer } from 'client/utils/timer';
import { getValue, setValue } from './json-path';
import { resolveRefs } from './json-ref';
import { invalidatePath, invalidateDependentPaths } from './invalidate';
import { getModelMetadata, updatePathMetadata } from './model-metadata';
import { createResolverCache } from './resolver-cache';
import { Abort } from './error';

/**
 * Applies a list of model changes to the given object, and returns it.
 *
 * @param {Object[]} delta - A list of path-value pairs to apply.
 * @param {Object} stateObj - The object to apply changes to.
 *
 * @returns {Object}
 */
export function applyModelChanges(delta, stateObj) {
  return delta.reduce(
    (newState, entry) => {
      const { path, value, metadata = {} } = entry;

      return updatePathMetadata(path, metadata, setValue(path, value, newState));
    },
    { ...stateObj }
  );
}

/**
 * @typedef StateContainer
 * @type {Object}
 *
 * @property {function} getState - Gets an object containing model state data.
 * @property {function} refreshState - Refreshes the model state data with the given list of deltas.
 * @property {Object} [resolverCache] - Optional object to be used for temporary storage of in-flight resolvers.
 */

function isStateContainer(stateObj) {
  return typeof stateObj.getState === 'function' && typeof stateObj.refreshState === 'function';
}

function getStateContainer(stateObj) {
  if (isStateContainer(stateObj)) {
    return stateObj;
  }

  let fullState = { ...stateObj };

  return {
    getState() {
      return fullState;
    },

    refreshState(delta) {
      fullState = applyModelChanges(delta, fullState);

      if (delta.refreshOnly) {
        return [];
      }

      return delta;
    },
  };
}

// Default global resolver cache
const defaultResolverCache = createResolverCache();

/**
 * @typedef ModelState
 * @type {Object}
 *
 * @property {function} get - Gets the current value stored at the given path in the given segment.
 * @property {function} resolve - Resolves the given path in the given segment and returns a Promise that is
 *   fulfilled with a list of paths that were changed and their values.
 * @property {function} update - Updates the value stored at the given path in the given segment and returns
 *   a Promise that is fulfilled with a list of paths that were changed and their values.
 */

/**
 * Gets a ModelState instance based on the current state stored in the given object.
 *
 * @param {Object|StateContainer} stateObj - An object storing the current state of the model.
 *   Can either be a simple object or a StateContainer, which provides getState() and refreshState() functions.
 *
 * @returns {ModelState}
 */
export function getModelState(stateObj) {
  const stateContainer = getStateContainer(stateObj);

  return {
    get(path, segment, processRefs = true) {
      const fullState = stateContainer.getState();

      if (fullState.featureFlags && fullState.featureFlags.modelLru && stateObj.refreshModelMetadata) {
        stateObj.refreshModelMetadata(
          {
            accessed: ClientTimer.getTimeElapsed(),
          },
          path,
          segment
        );
      }
      if (processRefs) {
        const segmentState = fullState[segment.root];

        return segmentState && resolveRefs(segmentState, path);
      }

      return getValue(segment.getPath(path), fullState);
    },

    resolve(path, segment, timing = {}) {
      const fullState = stateContainer.getState();
      const value = getValue(segment.getPath(path), fullState);
      if (typeof value !== 'undefined' && !(value && value.$partial)) {
        return Promise.resolve([]);
      }

      return segment
        .resolve(path, this, timing)
        .then(delta => stateContainer.refreshState(delta))
        .catch(error => {
          if (error instanceof Abort) {
            return [];
          }

          throw error;
        });
    },

    update(path, segment, value, timing = {}) {
      const segmentPath = segment.getPath(path);

      if (value === undefined) {
        return invalidatePath(segmentPath, this).then(delta => stateContainer.refreshState(delta));
      }

      const oldValue = getValue(segmentPath, stateContainer.getState());

      return segment
        .update(path, value, this, timing)
        .then(delta => {
          const result = stateContainer.refreshState(delta);

          const newValue = getValue(segmentPath, stateContainer.getState());
          if (isEqual(newValue, oldValue)) {
            // don't bother invalidating anything if the value didn't change
            return result;
          }

          return invalidateDependentPaths(segmentPath, this).then(dependentDelta => [
            ...result,
            ...stateContainer.refreshState(dependentDelta),
          ]);
        })
        .catch(error => {
          if (error instanceof Abort) {
            return [];
          }

          throw error;
        });
    },

    getMetadata() {
      return getModelMetadata(stateContainer.getState());
    },

    getResolverCache() {
      return stateContainer.resolverCache || defaultResolverCache;
    },
  };
}
