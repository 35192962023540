import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const kiaNewsLandingRoutes = [
  {
    /**
     * Route for "kia" landing page
     * https://www.edmunds.com/kia-news/
     */
    path: '/kia-news',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "kia-news-landing" */ '../pages/kia-news-landing/kia-news-landing-definition')
    ),
    chunkName: 'kia-news-landing',
  },
];
