import PropTypes from 'prop-types';

// https://qa-11-www.edmunds.com/api/zip/v2/58067?view=basic
export const DEFAULT_LOCATION = {
  zipCode: '58067',
  latitude: 46.054802,
  longitude: -97.502278,
  dma: '724',
  dmaRank: 2,
  stateCode: 'ND',
  city: 'Rutland',
};

export const Location = PropTypes.shape({
  zipCode: PropTypes.string,
  type: PropTypes.string,
  areaCode: PropTypes.string,
  timeZone: PropTypes.string,
  gmtOffset: PropTypes.number,
  dst: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  salesTax: PropTypes.number,
  dma: PropTypes.string,
  dmaRank: PropTypes.number,
  stateCode: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  inPilotDMA: PropTypes.bool,
  state: PropTypes.string,
  userSet: PropTypes.bool,
});
