import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { getPersonalizedSearchPageRoute } from 'site-modules/shared/utils/personalized-search/personalized-search-utils';

export const routes = [
  {
    path: getPersonalizedSearchPageRoute(),
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "personalized_search" */ '../pages/personalized-search-definition')
    ),
    chunkName: 'personalized_search',
  },
];
