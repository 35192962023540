import { TIME } from 'client/utils/time';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Returns current date
 * @returns {Date}
 */
export function getCurrentDate() {
  return new Date();
}

/**
 * Returns the date which is equal current date plus passed days
 * @param {Number} days
 * @returns {Date}
 */
export function addDaysToCurrentDate(days) {
  const result = getCurrentDate();
  result.setDate(result.getDate() + days);
  return result;
}

export function addDaysToCurrentDateExceptSunday(days) {
  const result = getCurrentDate();
  result.setDate(result.getDate() + days);

  if (result.getDay() === 0) {
    result.setDate(result.getDate() + 1);
  }

  return result;
}

export function getLastDayOfCurrentMonth() {
  const date = new Date();
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    month: lastDate.getMonth() + 1,
    day: lastDate.getDate(),
  };
}

/**
 * Creates new date object by passed String or Date value
 * @param {String | Date | Number} date
 * @returns {Date}
 */
export function createDate(date) {
  if (date) return new Date(date);
  return getCurrentDate();
}

/**
 * Sets defined hours to passed date
 * @param {String | Date} date
 * @param {Number} hours
 * @returns {Date}
 */
export function setHoursToDate(date, hours) {
  const newDate = createDate(date);
  newDate.setHours(hours);
  return newDate;
}

export function getMonth(date = getCurrentDate()) {
  return MONTHS[date.getMonth()];
}

export function getMonthYear(date = getCurrentDate()) {
  return `${getMonth(date)}, ${date.getFullYear()}`;
}

/**
 * Returns last N years (e.g. [2019, 2018, ...])
 * @param {number} [latestYearsCount=7]
 * @returns {number[]}
 */
export function getLatestYears(latestYearsCount = 7) {
  return Array.from({ length: latestYearsCount }, (v, ind) => new Date().getFullYear() - ind);
}

/**
 * Returns number of day of the week.
 * From 1 - Monday to 7 - Sunday
 * @returns {number}
 */
export function getDayOfWeek(date = getCurrentDate()) {
  return date.getDay() || 7;
}

export function isInDateRange(startDate, endDate) {
  const currentDate = getCurrentDate();

  return !((startDate && new Date(startDate) > currentDate) || (endDate && new Date(endDate) < currentDate));
}

/**
 * Returns difference between two timestamps in days (rounded up)
 * Math.ceil() rounds a number up to the next largest whole number or integer.
 * Math.ceil() is used so that less than 1 day is counted as 1 day.
 * @param {String | Date} endDate
 * @returns {number}
 */
export function getDifferenceInDays(endDate) {
  const currentDate = getCurrentDate();
  const diff = Math.abs(endDate - currentDate);
  return Math.ceil(diff / TIME.MS.ONE_DAY);
}

/**
 * Returns difference between two timestamps in days (rounded to closest integer)
 * @param {String | Date} endDate
 * @param {Date} startDate
 * @returns {number}
 */
export function getDifferenceInDaysRounded(endDate, startDate) {
  const currentDate = getCurrentDate();
  const diff = Math.abs(endDate - (startDate || currentDate));
  return Math.round(diff / TIME.MS.ONE_DAY);
}

export function adjustTimezone(date) {
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
}

/**
 * Checks if input can be parsed as Date
 * @param {String | Number} date
 * @returns {Boolean}
 */
export function isParsableDate(date) {
  return !!Date.parse(date);
}

/**
 * Checks is both dates are equal in year, month and day
 * @param {String | Number} firstDate
 * @param {String | Number} secondDate
 * @returns {Boolean}
 */
export function isSameDay(firstDate, secondDate) {
  const first = new Date(firstDate);
  const second = new Date(secondDate);

  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

/**
 * Returns difference between two timestamps in hours
 * @param {String | Date} endDate
 * @returns {number}
 */
export function getDifferenceInHours(endDate) {
  return (new Date(endDate).getTime() - Date.now()) / TIME.MS.ONE_HOUR;
}

export function convertUTCDateToLocalTimezone(date) {
  return createDate(
    createDate(date)
      .toISOString()
      .slice(0, -1)
  );
}
