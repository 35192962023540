import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

/**
 * route for tco page
 * @type {Array}
 */
export const tcoRoute = {
  path: '/tco.html',
  exact: true,
  page: getLoadablePage(() => import(/* webpackChunkName: "tco" */ '../pages/tco-definition')),
  chunkName: 'tco',
};
