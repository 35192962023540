import { pick } from 'lodash';
import { UPDATE_VEHICLE } from 'client/actions/constants';

export function experimentVehicleReducer(state = null, action = {}) {
  switch (action.type) {
    case UPDATE_VEHICLE:
      return action.vehicle && pick(action.vehicle, ['make', 'model']);
    default:
      return state;
  }
}
