import { PAGE_EVENTS } from 'client/constants/page-events';
import { ResponsiveToolkit } from 'client/utils/responsive-bootstrap-toolkit';
import { EVENTS } from 'client/utils/event-constants';
import {
  CLEAR_CURRENT_SCROLL_PROPS,
  PAGE_LOAD,
  PAGE_PRELOAD,
  PAGE_START_LOADING,
  PAGE_STATUS_UPDATE,
  PAGE_UNLOAD,
  SAVE_CURRENT_SCROLL_PROPS,
  WIDGET_VIEW,
} from './constants';

/**
 * PAGE_PRELOAD action definition
 *
 * @return {Object}       PAGE_PRELOAD action definition
 */
export function pagePreload(result) {
  return {
    type: PAGE_PRELOAD,
    result,
  };
}

/**
 * PAGE_START_LOADING action definition
 *
 * @param  {Object} page  Loading page
 * @return {Object}       PAGE_START_LOADING action definition
 */
export function pageStartLoading(page) {
  return {
    type: PAGE_START_LOADING,
    breakpoint: ResponsiveToolkit.current(),
    page,
  };
}

/**
 * PAGE_LOAD action definition
 *
 * @param  {Object} page  Loaded page
 * @return {Object}       PAGE_LOAD action definition
 */
export function pageLoad(page) {
  return {
    type: PAGE_LOAD,
    page,
    meta: {
      event: {
        eventName: PAGE_EVENTS.PAGE_LOAD,
        data: page,
      },
    },
  };
}

/**
 * PAGE_UNLOAD action definition
 *
 * @param  {Object} page  Loaded page
 * @return {Object}       PAGE_UNLOAD action definition
 */
export function pageUnload(page) {
  return {
    type: PAGE_UNLOAD,
    page,
    meta: {
      event: {
        eventName: PAGE_EVENTS.PAGE_UNLOAD,
        data: page,
      },
    },
  };
}

/**
 * PAGE_STATUS_UPDATE action definition
 *
 * @param  {Object} statusCode          http status code
 * @return {Object} PAGE_STATUS_UPDATE  action definition
 */
export function pageStatusUpdate(statusCode) {
  return {
    type: PAGE_STATUS_UPDATE,
    statusCode,
  };
}

/**
 * WIDGET_VIEW action definition
 *
 * @param  {Object} widgetId      Viewed widget
 * @param  {Object} pageHeight    Page height
 * @param  {Object} widgetHeight  Widget height
 * @param  {Object} widgetOffset  Widget offset
 * @return {Object}               PAGE_UNLOAD action definition
 */
export function widgetView(widgetId, pageHeight, widgetHeight, widgetOffset) {
  return dispatch => {
    const trackingData = {
      event_type: 'widget_view',
      event_data: {
        action_name: 'widget_view',
        action_cause: 'scroll',
        creative_id: widgetId,
        total_page_height: pageHeight,
        widget_height: widgetHeight,
        widget_y_position: widgetOffset,
      },
    };

    dispatch({
      type: WIDGET_VIEW,
      widgetId,
      meta: {
        event: {
          eventName: EVENTS.TRACK_ACTION,
          data: trackingData,
        },
      },
    });
  };
}

export function saveCurrentScrollProps(scrollY, bodyScrollHeight) {
  return {
    type: SAVE_CURRENT_SCROLL_PROPS,
    scrollY,
    bodyScrollHeight,
  };
}

export function clearCurrentScrollProps() {
  return {
    type: CLEAR_CURRENT_SCROLL_PROPS,
  };
}
