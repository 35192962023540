import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const categories = ['articles', 'press', 'insights', 'support'];

export const routes = [
  {
    path: [
      `/industry/:articleCategory(${categories.join('|')})/:articleId.:ext`,
      `/industry/:articleCategory(${categories.join('|')})`,
    ],
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "industry-article" */ '../pages/industry-article/industry-article-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'industry-article',
  },
  {
    path: ['/industry/:pageName.:ext', '/industry'],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "industry-page" */ '../pages/industry/industry-page-definition')
    ),
    chunkName: 'industry-page',
  },
  {
    path: '/industry-center/:articleCategory/:articleId.html',
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "industry-center-article" */ '../pages/industry-center-article/industry-center-article-definition'),
      match => match.params
    ),
    chunkName: 'industry-center-article',
  },
];
