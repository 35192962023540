import seedrandom from 'seedrandom';
import { isNode } from './environment';

const DEFAULT = 'default';

/**
 * Initializes seed random functions and sets Math.edmSeedRandoms
 * @param {String} seed
 */
export function createSeedRandoms(seed) {
  Math.edmSeedRandoms = {
    default: seedrandom(seed),
    sample: seedrandom(`${seed}-sample`),
    sampleSize: seedrandom(`${seed}-sampleSize`),
    shuffle: seedrandom(`${seed}-shuffle`),
  };

  return Math.edmSeedRandoms;
}

function getSeedRandom() {
  if (isNode() && global.httpContext) {
    const ctx = global.httpContext;
    if (ctx) {
      const edmSeedRandoms = ctx.get('edmSeedRandoms');
      return edmSeedRandoms || Math.edmSeedRandoms;
    }
  }
  return Math.edmSeedRandoms;
}

function seedRandom(seedKey = DEFAULT) {
  const edmSeedRandoms = getSeedRandom();
  if (edmSeedRandoms) {
    const rndFn = edmSeedRandoms[seedKey];
    return rndFn();
  }
  return Math.random();
}

/**
 * Seeded random
 * @returns {Number} a floating-point, pseudo-random number in the range from 0 inclusive up to but not including 1
 */
export function random() {
  return seedRandom();
}

/**
 * Seeded random
 * @returns {Number} an integer between 0 and Number.MAX_SAFE_INTEGER
 */
export function randomInt() {
  return Math.ceil(random() * Number.MAX_SAFE_INTEGER);
}

/**
 * The functions from lodash lib. used to generate the same randome values based on seed.
 * Using the same seed garantees the same randome values on sever and client side.
 * Before using these functions ensure createSeedRandoms is called
 */
/* eslint-disable */

/**
 * Gets a random element from `array`.
 *
 * @param {Array} array The array to sample.
 * @returns {*} Returns the random element.
 */
export function sample(array) {
  const length = array == null ? 0 : array.length;
  return length ? array[Math.floor(seedRandom('sample') * length)] : undefined;
}

/**
 * Does shallow a copy if the source array
 *
 * @private
 * @param {Array} source The array to copy values from.
 * @returns {Array} Returns a shallow copy of source `array`.
 */
function copyArray(source) {
  return source.slice(0);
}

/**
 * Gets `n` random elements at unique keys from `array` up to the
 * size of `array`.
 *
 * @param {Array} array The array to sample.
 * @param {number} [n=1] The number of elements to sample.
 * @returns {Array} Returns the random elements.
 */
export function sampleSize(array, n) {
  n = n == null ? 1 : n;
  const length = array == null ? 0 : array.length;
  if (!length || n < 1) {
    return [];
  }
  n = n > length ? length : n;
  let index = -1;
  const lastIndex = length - 1;
  const result = copyArray(array);
  while (++index < n) {
    const rand = index + Math.floor(seedRandom('sampleSize') * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }
  return result.slice(0, n);
}

/**
 * Creates an array of shuffled values.
 *
 * @param {Array} array The array to shuffle.
 * @returns {Array} Returns the new shuffled array.
 */
export function shuffle(array) {
  const length = array == null ? 0 : array.length;
  if (!length) {
    return [];
  }
  let index = -1;
  const lastIndex = length - 1;
  const result = copyArray(array);
  while (++index < length) {
    const rand = index + Math.floor(seedRandom('shuffle') * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }
  return result;
}
