import { ERROR_PAGES } from 'site-modules/shared/constants/error-page-names';
import { locationChange } from 'client/actions/location';
import { isLocationChanged } from 'client/utils/location';
import { matchRoute, loadRoutePage } from 'client/utils/router';
import { routes as getClientRoutes } from 'client/routes';
import { isSpaPage } from './is-spa-page';

// Application store
let store;
// History object
let venomHistory; // eslint-disable-line import/no-mutable-exports
// Listeners of location changes
let historyListeners = [];

const errorPageNames = Object.values(ERROR_PAGES);

function isErrorPage(pageName) {
  if (errorPageNames.includes(pageName)) {
    return true;
  }

  const status = typeof window !== 'undefined' && window.EDM.Venom.responseStatus;
  return status === 404 || status >= 500;
}

function getLinkPropsFromState(state) {
  const featureFlags = state && state.featureFlags;
  const spaLinksEnabled = !!(featureFlags && featureFlags.spaLinks);
  const spaRulesEnabled = !!(featureFlags && featureFlags.spaRules);
  const pageContext = state && state.pageContext;
  const page = pageContext && pageContext.page;
  const pageName = (page && page.name) || '';
  const location = (pageContext && pageContext.location) || {};
  const venomEnv = (state && state.venomEnv) || '';
  const edmundsEnv = (state && state.edmundsEnv) || '';
  return {
    spaLinksEnabled,
    spaRulesEnabled,
    pageName,
    previousPathname: location.pathname,
    venomEnv,
    edmundsEnv,
  };
}

function isSpaLink(path, { spaLinksEnabled, spaRulesEnabled, pageName, venomEnv, edmundsEnv }) {
  return spaLinksEnabled && (!spaRulesEnabled || isSpaPage(path, venomEnv, edmundsEnv)) && !isErrorPage(pageName);
}

export const createPath = location => {
  if (location == null || typeof location === 'string') return location;

  const { basename, pathname, search, hash } = location;
  let path = (basename || '') + pathname;

  if (search && search !== '?') {
    path += search;
  }

  return hash ? `${path}${hash}` : path;
};

export const isPathnameChanged = (previousPathname, path) => {
  const startQueryIndex = path.indexOf('?');
  const nextPathname = startQueryIndex > 0 ? path.slice(0, startQueryIndex) : path;
  return previousPathname !== nextPathname;
};

function ensureLocationChangeCompleted(location) {
  return new Promise(resolve => {
    // if all middlewares have already finished with LOCATION_CHANGE resolve immediately
    if (!isLocationChanged(store.getState().router.location, location)) {
      resolve();
    } else {
      // otherwise wait until middlewares finish with LOCATION_CHANGE
      const unsubscribe = store.subscribe(() => {
        if (!isLocationChanged(store.getState().router.location, location)) {
          unsubscribe();
          resolve();
        }
      });
    }
  });
}

function appendListener(fn) {
  let isActive = true;

  function listener(...args) {
    if (isActive) {
      fn(...args);
    }
  }

  historyListeners.push(listener);

  return function removeListener() {
    isActive = false;
    historyListeners = historyListeners.filter(item => item !== listener);
  };
}

let routes;

const getRoutes = () => {
  if (!routes) {
    routes = getClientRoutes();
  }
  return routes;
};

async function dispatchLocationChangeAction(location, action, initial = false) {
  if (initial) {
    store.dispatch(locationChange(location, action));
    return;
  }

  // before dispatching LOCATION_CHANGE load SPA page component
  const { ...spaFlags } = getLinkPropsFromState(store.getState());
  let shouldCallParseApi = false;
  if (isSpaLink(location.pathname, spaFlags)) {
    const { match, route } = await matchRoute(getRoutes(), location.pathname);
    if (route.preload) {
      await route.preload(match.params, store);
    }
    loadRoutePage(route, match, location, store);
    shouldCallParseApi = !!route.shouldCallParseApi;
  }
  store.dispatch(locationChange(location, action, shouldCallParseApi));
  ensureLocationChangeCompleted(location).then(() => {
    historyListeners.forEach(listener => listener(location, action));
  });
}

function makeVenomHistoryListener(history) {
  history.listen(dispatchLocationChangeAction);
  return appendListener;
}

/**
 * Creates history navigation method responsible for a proper navigation (e.g. SPA or regular)
 *
 * @param {Function} spaCallee SPA navigation function
 * @param {Function} locationCallee Regular navigation function
 */
export function createNavigationMethod(spaCallee, locationCallee) {
  if (typeof spaCallee !== 'function') throw new TypeError('spaCallee must be a function');
  if (typeof locationCallee !== 'function') throw new TypeError('locationCallee must be a function');

  return pathOrLocation => {
    const path = createPath(pathOrLocation) || '';
    if (!store) {
      locationCallee(path);
      return;
    }

    const { previousPathname, ...spaFlags } = getLinkPropsFromState(store.getState());
    const doReload = isPathnameChanged(previousPathname, path);
    if (isSpaLink(path, spaFlags) || !doReload) {
      spaCallee(path);
    } else {
      locationCallee(path);
    }
  };
}

/**
 * Creates Application history object.
 * Usually expected to be used as part of application router.
 * Provides intelligent navigation methods defining
 * when to do SPA and when regular type of transitions between application pages.
 * */

function createHistory(history) {
  const _push = history.push; // eslint-disable-line no-underscore-dangle
  // eslint-disable-next-line no-param-reassign
  history.push = createNavigationMethod(
    location => _push.call(history, location),
    location => window.location.assign(location)
  );
  const _replace = history.replace; // eslint-disable-line no-underscore-dangle
  // eslint-disable-next-line no-param-reassign
  history.replace = createNavigationMethod(
    location => _replace.call(history, location),
    location => window.location.replace(location)
  );
  const _goBack = history.goBack; // eslint-disable-line no-underscore-dangle
  // eslint-disable-next-line no-param-reassign
  history.goBack = createNavigationMethod(
    location => _goBack.call(history, location),
    location => window.history.back(location)
  );

  return history;
}

/**
 * Creates VenomHistory and returns store
 * @param {Object} history - history object created by createBrowserHistory or createMemoryHistory
 * @param {Function} getStore - if provided returns the current store
 * @return {Object} store
 */
export function createVenomHistory(history, getStore) {
  venomHistory = createHistory(history);
  if (typeof getStore === 'function') {
    store = getStore();
    venomHistory.listen = makeVenomHistoryListener(history);
  }
}

export { venomHistory };
