/**
 * Defines which transition event is supported by the browser
 *
 * @return {String} Supported transition event
 */
export const whichTransitionEvent = () => {
  const el = document.createElement('fakeelement');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  const supportedTransition = Object.keys(transitions).find(t => el.style[t] !== undefined);

  return supportedTransition && transitions[supportedTransition];
};

/**
 * Defines whether DOM is ready
 *
 * @return {Boolean} True, if DOM is ready, false otherwise
 */
export const isDomReady = () => {
  const domReadyStates = ['interactive', 'complete'];
  const readyState = window.document.readyState;
  return domReadyStates.includes(readyState);
};

export function getElementOffsetWidth(el) {
  return el.offsetWidth;
}

export const isDomComplete = () => window.document.readyState === 'complete';
