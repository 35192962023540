export const ROBOTS_MAP = {
  noIndex: 'noindex',
  index: 'index',
  noFollow: 'nofollow',
  follow: 'follow',
  noArchive: 'noarchive',
  noSnippet: 'nosnippet',
  noTranslate: 'notranslate',
  noImageIndex: 'noimageindex',
};

export const DEFAULT_TITLE = 'New Cars, Used Cars, Car Reviews and Pricing | Edmunds';
export const DEFAULT_DESCRIPTION =
  'Research new and used cars including car prices, view incentives and dealer inventory listings, compare vehicles, get car buying advice and reviews at Edmunds.com';
