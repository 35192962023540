import { Abort } from './error';

export function createResolverCache() {
  const cache = {};

  function cachedPromise(key, promiseFn) {
    const promise = promiseFn()
      .then(result => {
        delete cache[key];
        return result;
      })
      .catch(err => {
        delete cache[key];
        throw err;
      });

    cache[key] = promise;
    return promise;
  }

  return {
    resolve(key, resolver) {
      // resolvers will not run if there is already a resolve/update in-progress
      const fromCache = cache[key];
      if (fromCache) {
        // resolvers shouldn't run again unless the previous resolve was aborted
        return fromCache.then(
          delta => {
            const refreshDelta = [...delta];
            refreshDelta.refreshOnly = true;

            return refreshDelta;
          },
          error => {
            if (error instanceof Abort) {
              return cachedPromise(key, resolver);
            }

            throw error;
          }
        );
      }

      return cachedPromise(key, resolver);
    },
    update(key, updater) {
      // updaters will run after any in-progress resolves/updates to make sure they are processed in order
      const fromCache = cache[key];
      if (fromCache) {
        // updaters should always run regardless of outcome of previous resolve/update
        const doUpdate = () => cachedPromise(key, updater);
        return fromCache.then(doUpdate, doUpdate);
      }

      return cachedPromise(key, updater);
    },
  };
}
