import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { EVENTS } from 'client/utils/event-constants';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { UPDATE_SESSION } from 'client/actions/constants';
import { PUB_STATES } from 'client/constants/pub-states';

let trackingEventQueue = null;
let combinedPixel;
let combinedWidgetPixel;
let isIframe;

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function processItem(trackingEvent) {
  if (window.requestIdleCallback) {
    // new browsers will be more performant
    window.requestIdleCallback(() => {
      window.dataLayer.push(trackingEvent);
    });
  } else {
    window.dataLayer.push(trackingEvent);
  }
}

function isQueueEnabled() {
  return trackingEventQueue !== null;
}

function startQueueing() {
  trackingEventQueue = [];
}

function stopQueueing() {
  trackingEventQueue = null;
}

function putInQueue(trackingEvent) {
  trackingEventQueue.push(trackingEvent);
}

function processQueue(pageEnterEvent) {
  let queue = [];
  if (pageEnterEvent) {
    queue = [pageEnterEvent, ...trackingEventQueue];
  } else {
    queue = [...trackingEventQueue];
  }

  queue.forEach(trackingEvent => {
    processItem(trackingEvent);
  });
}

function combineEventDatas(trivialCombinedPixel, trackingEventEventData) {
  const combinedEventData = Object.assign({}, trivialCombinedPixel.event_data);

  // add keys and values from trackingEvent to the combined pixel
  Object.keys(trackingEventEventData).forEach(key => {
    if (!trivialCombinedPixel.event_data[key]) {
      combinedEventData[key] = '';

      for (let i = 0; i < trivialCombinedPixel.numberOfCombinedPixels; i += 1) {
        combinedEventData[key] = `${combinedEventData[key]}null!`;
      }

      combinedEventData[key] = `${combinedEventData[key]}${trackingEventEventData[key]}`;
    } else {
      combinedEventData[key] = `${combinedEventData[key]}!${trackingEventEventData[key]}`;
    }
  });

  return combinedEventData;
}

function setMissingKeys(trivialCombinedPixelEventData, trackingEventEventData) {
  const trackingEventEventDataCopy = Object.assign({}, trackingEventEventData);

  // fill in missing event_data keys for trackingEvent
  Object.keys(trivialCombinedPixelEventData).forEach(key => {
    if (!trackingEventEventDataCopy[key]) {
      trackingEventEventDataCopy[key] = 'null';
    }
  });

  return trackingEventEventDataCopy;
}

function combine(trivialCombinedPixel, trackingEvent) {
  const trackingEventCopy = Object.assign({}, trackingEvent);
  const trivialCombinedPixelCopy = Object.assign({}, trivialCombinedPixel);

  trackingEventCopy.event_data = setMissingKeys(trivialCombinedPixelCopy.event_data, trackingEventCopy.event_data);

  trivialCombinedPixelCopy.event_data = combineEventDatas(
    {
      event_data: trivialCombinedPixelCopy.event_data,
      numberOfCombinedPixels: trivialCombinedPixelCopy.event_type.length,
    },
    trackingEventCopy.event_data
  );

  trivialCombinedPixelCopy.event_type.push(trackingEventCopy.event_type);

  delete trackingEventCopy.event_type;
  delete trackingEventCopy.event_data;

  Object.assign(trivialCombinedPixelCopy, trackingEventCopy);

  return trivialCombinedPixelCopy;
}

function combineBasedOnEventType(trackingEvent) {
  if (trackingEvent.event_type === 'widget_view') {
    combinedWidgetPixel = combine(combinedWidgetPixel, trackingEvent);
  } else if (trackingEvent.event_type) {
    combinedPixel = combine(combinedPixel, trackingEvent);
  } else {
    const trackingEventCopy = Object.assign({}, trackingEvent);

    // merge context update params, and delete falsey values for sanity check
    // context updates should not have an event_type and event_data in the first place
    delete trackingEventCopy.event_type;
    delete trackingEventCopy.event_data;

    // context updates dont apply to widget_views
    Object.assign(combinedPixel, trackingEventCopy);
  }
}

function cleanCombinedPixel(trivialCombinedPixel) {
  const combinedPixelCopy = Object.assign({}, trivialCombinedPixel);

  // hard coded to fire a pixel mapped to empty event_data by default.
  if (!Object.keys(combinedPixelCopy.event_data).length) {
    delete combinedPixelCopy.event_data;
  }

  // hard coded to fire a pixel mapped to empty event_type by default.
  if (!combinedPixelCopy.event_type.length) {
    delete combinedPixelCopy.event_type;
  } else {
    if (combinedPixelCopy.event_type.length > 1) {
      combinedPixelCopy.event = 'edw_combined';
    }

    combinedPixelCopy.event_type = combinedPixelCopy.event_type.join('!');
  }

  return combinedPixelCopy;
}

function setCombinedPixel() {
  return {
    event_type: [],
    event_data: {},
  };
}

const cleanAndProcessCombinedPixel = () => {
  combinedPixel = cleanCombinedPixel(combinedPixel);
  combinedWidgetPixel = cleanCombinedPixel(combinedWidgetPixel);

  if (Object.keys(combinedPixel).length) {
    processItem(combinedPixel);
  }

  if (Object.keys(combinedWidgetPixel).length) {
    processItem(combinedWidgetPixel);
  }

  combinedPixel = setCombinedPixel();
  combinedWidgetPixel = setCombinedPixel();
};

const debouncedProcessItemProposalB = debounce(cleanAndProcessCombinedPixel, 100);

function excludeOrCombineTrackingEvent(trackingEvent) {
  const { event_data: eventData = {} } = trackingEvent;

  if (
    (trackingEvent.event_type === 'action_end' && eventData.action_name === 'lead_submission') ||
    (trackingEvent.event_type === 'action_completed' &&
      (eventData.action_name === 'calculate_payment' || eventData.action_name === 'list'))
  ) {
    processItem(trackingEvent);
  } else {
    combineBasedOnEventType(trackingEvent);

    if (combinedPixel.event_type.length === 45 || combinedWidgetPixel.event_type.length === 45) {
      debouncedProcessItemProposalB.cancel();
      cleanAndProcessCombinedPixel();
    } else {
      debouncedProcessItemProposalB();
    }
  }
}

function processQueueProposalB(pageEnterEvent) {
  const queue = [pageEnterEvent, ...trackingEventQueue];

  queue.forEach(trackingEvent => {
    excludeOrCombineTrackingEvent(trackingEvent);
  });
}

export function handleTrackActionProposalB(event, disableEDWPastLoad, browserCanCombineEDW) {
  const eventobj = event.detail;

  // no EDW pixels are fired on the page past the on load events when disableEDWpastload url param is true
  const disableEDWpastload = disableEDWPastLoad && eventobj?.event_data?.action_cause !== 'page_load';

  const disableEDWEmbed = isIframe && eventobj?.event_data?.action_name !== 'widget_view';

  if (!eventobj || disableEDWpastload || disableEDWEmbed) return;

  let trackingEvent;

  if (eventobj.event_type) {
    // new tracking event
    trackingEvent = {
      ts: eventobj.ts || Date.now(),
      event: eventobj.event_type, // define actual trigger event for GTM
      ...eventobj,
    };
  } else {
    // tracking context update only
    trackingEvent = {
      ...eventobj,
    };
  }

  if (isQueueEnabled()) {
    // put tracking events into the queue until 'page_enter' event
    if (eventobj.event_type && eventobj.event_type === 'page_enter') {
      processQueueProposalB(trackingEvent);
      stopQueueing();
    } else {
      putInQueue(trackingEvent);
    }
  } else if (browserCanCombineEDW) {
    excludeOrCombineTrackingEvent(trackingEvent);
  } else {
    processItem(trackingEvent);
  }
}

// TODO: Subject to removal once combinedEDWPixels is in prod
export function handleTrackAction(event, disableEDWPastLoad) {
  const eventobj = event.detail;

  // no EDW pixels are fired on the page past the on load events when disableEDWpastload url param is true
  const disableEDWpastload = disableEDWPastLoad && eventobj?.event_data?.action_cause !== 'page_load';

  const disableEDWEmbed = isIframe && eventobj?.event_data?.action_name !== 'widget_view';

  if (!eventobj || disableEDWpastload || disableEDWEmbed) return;

  let trackingEvent;
  if (eventobj.event_type) {
    // new tracking event
    trackingEvent = {
      ts: eventobj.ts || Date.now(),
      event: eventobj.event_type, // define actual trigger event for GTM
      ...eventobj,
    };
  } else {
    // tracking context update only
    trackingEvent = {
      ...eventobj,
    };
  }
  if (isQueueEnabled()) {
    // put tracking events into the queue until 'page_enter' event
    if (eventobj.event_type && eventobj.event_type === 'page_enter') {
      processQueue(trackingEvent);
      stopQueueing();
    } else {
      putInQueue(trackingEvent);
    }
  } else {
    processItem(trackingEvent);
  }
}

/**
 * Returns boolean after checking feature flag 'disableEDW'.
 *
 * @param  {Object} state
 */
export function usesDisableEDW({ featureFlags }) {
  return !!(featureFlags && featureFlags.disableEDW);
}

/**
 * Returns boolean after checking feature flag 'disableEDWpastload'.
 *
 * @param  {Object} state
 */
export function usesDisableEDWpastload({ featureFlags }) {
  return !!(featureFlags && featureFlags.disableEDWpastload);
}

/**
 * Returns boolean after checking feature flag 'combineEDWpixels'.
 *
 * @param  {Object} state
 */
export function usesCombineEDWpixels({ featureFlags }) {
  return !!(featureFlags && featureFlags.browserCanCombineEDW);
}

export const GTMEngagementHandler = {
  /**
   * Initializes GTM engagement handler.
   *
   * @param  {Object} getState App Redux store.getState function
   */
  init({ getState }) {
    const state = getState();

    if (usesDisableEDW(state)) return;

    window.dataLayer = window.dataLayer || [];
    isIframe = inIframe();

    const combineEDWCampaignValue = ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'PLAT-1719',
    });

    // https://edmunds.atlassian.net/browse/PLAT-1588. Clean up once done
    // state.featureFlags is defined in initial state

    if (combineEDWCampaignValue === 'chal' && state.featureFlags.combineEDW) {
      combinedPixel = setCombinedPixel();
      combinedWidgetPixel = setCombinedPixel();

      EventToolbox.on(EVENTS.TRACK_ACTION, e =>
        handleTrackActionProposalB(e, usesDisableEDWpastload(state), usesCombineEDWpixels(state))
      );
    } else {
      /**
       * Setups "trackAction" event listener pushing even data to GTM dataLayer
       */
      EventToolbox.on(EVENTS.TRACK_ACTION, e => handleTrackAction(e, usesDisableEDWpastload(state)));
    }

    /**
     * Setups "pageUnload" event listener starting store events in a queue
     */
    EventToolbox.on(PAGE_EVENTS.PAGE_UNLOAD, startQueueing);

    /**
     * session id / isNewSession is updated as part of a SPA transition, this event handler is to keep
     * gtm data in sync with redux state
     */
    EventToolbox.on(UPDATE_SESSION, data => {
      const { sessionId, isNewSession } = data.detail;
      window.dataLayer.push({ session_id: sessionId, is_first_page_of_session: isNewSession });
    });

    EventToolbox.on(EVENTS.UPDATE_GTM_VEHICLE, ({ detail: vehicle }) => {
      window.dataLayer.push({
        make_id: vehicle.make && vehicle.make.id,
        model_year_id: vehicle.modelYear && vehicle.modelYear.id,
        model_link_code: vehicle.model && vehicle.model.modelLinkCode,
        publication_state: (vehicle.publicationState || PUB_STATES.NEW).toLowerCase(),
        style_id: vehicle.style && vehicle.style.id,
      });
    });

    if (document.prerendering) {
      startQueueing();
      document.addEventListener(
        'prerenderingchange',
        () => {
          processQueue();
          stopQueueing();
        },
        { once: true }
      );
    }
  },
};
