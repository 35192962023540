import { compact } from 'lodash';

export const getNonAmpUrl = pathname => pathname && pathname.replace(/(\/)amp\/|^amp\//, '$1');

/**
 * https://web.dev/articles/text-fragments
 */
export function withTargetText(url, { hash = '', prefix, text, suffix }) {
  const targetText = compact([
    prefix && `${encodeURIComponent(prefix)}-`,
    encodeURIComponent(text),
    suffix && `-${encodeURIComponent(suffix)}`,
  ]);

  return `${url}#${hash}:~:text=${targetText}`;
}
