import { IS_NODE } from 'client/utils/environment';

let config = {};

export const ClientConfig = {
  load(obj = {}) {
    config = {
      ...config,
      ...obj,
    };
    return this;
  },

  save(obj = {}) {
    return {
      ...obj,
      ...config,
    };
  },

  get(key, defaultValue) {
    return config[key] || defaultValue;
  },

  /**
   * to be used only by platform team
   * @param key
   * @param val
   */
  set(key, val) {
    config[key] = val;
  },
};

// Whatever file accesses ClientConfig first and is on client side, load pre load
// pre load is always defined on client side
if (!IS_NODE) {
  // eslint-disable-next-line venom/no-prohibited-functions
  if (window.__PRELOADED_STATE__ !== undefined) {
    ClientConfig.load(window.__PRELOADED_STATE__.config); // eslint-disable-line venom/no-prohibited-functions
  }
}
