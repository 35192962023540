import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/v/testpage1',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "testpage1" */ '../pages/testpage1/testpage1')),
    chunkName: 'testpage1',
  },
  {
    path: '/v/testpage2',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "testpage2" */ '../pages/testpage2/testpage2')),
    chunkName: 'testpage2',
  },
];
