import { merge } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';

export const TrackingHandler = {
  /**
   * Set map for a page to operate during fireEvent
   *
   * @param {Object} map
   */
  useMap(map) {
    // Map should be object
    if (!map || typeof map !== 'object') {
      throw new Error('Tracking map should be object');
    }

    this.map = Object.assign({}, map);
  },

  /**
   * Add map for a temporary modal/drawer without overriding parent component maps
   *
   * @param {Object} map
   */
  tryAddMap(map) {
    // Map should be object
    if (!map || typeof map !== 'object') {
      throw new Error('Tracking map should be object');
    }

    this.map = Object.assign(this.map || {}, map);
  },

  /**
   * Fire specific event from current page map
   *
   * @param {String} id - id of event to search by
   * @param {Object} [overrides] - object to override default properties for event
   * @param {Object} [options] - options to pass to event-functions
   */
  fireEvent(id, overrides, options) {
    if (!this.map) return;
    let event = this.map[id];

    // If nothing found in maps
    if (!event) {
      throw new Error(`The tracking event "${id}" is not defined`);
    }

    // If function, event should be called with options to dynamically add params to event_data if needed
    if (typeof event === 'function') {
      event = event(options);
    }

    // Perform all necessary inherits and fire event
    EventToolbox.fireTrackAction(merge({}, event, overrides));
  },
};
