import { get } from 'lodash';
import { LOCATION_CHANGE, PAGE_LOAD, PAGE_UNLOAD } from 'client/actions/constants';
import { venomHistory } from 'client/utils/history/venom-history';
import { saveCurrentScrollProps, clearCurrentScrollProps } from 'client/actions/page';
import { Storage } from 'site-modules/shared/utils/storage';
import { isPageChanged } from './location';
import { scrollToTop } from './scroll';

// WARNING: another occurrence of this key is in scroll-restoration.ejs.
export const SESSION_STORAGE_KEY = 'scrollToRestore';

const storage = new Storage('sessionStorage');

// WARNING: another occurrence of these constants is in scroll-restoration.ejs.
const MAX_BODY_SCROLL_HEIGHT_WAIT = 1000;
export const BODY_SCROLL_HEIGHT_DELAY = 100;
export const MAX_BODY_SCROLL_HEIGHT_CHECKS = Math.floor(MAX_BODY_SCROLL_HEIGHT_WAIT / BODY_SCROLL_HEIGHT_DELAY);

export const maybeSetScrollY = (scrollY, bodyScrollHeight, counter = 0) => {
  if (document.body.scrollHeight >= bodyScrollHeight) {
    window.scrollTo(window.scrollX, scrollY);
    return;
  }
  if (counter === MAX_BODY_SCROLL_HEIGHT_CHECKS) {
    window.scrollTo(window.scrollX, scrollY);
    return;
  }
  setTimeout(() => {
    maybeSetScrollY(scrollY, bodyScrollHeight, counter + 1);
  }, BODY_SCROLL_HEIGHT_DELAY);
};

export const scrollRestorationMiddleware = store => {
  let isPathnameChanged;

  venomHistory.listen((location, action) => {
    if (action === 'POP') {
      // there is a navigation back, to the previous page.
      const scrollProps = get(store.getState(), 'pageContext.scrollProps');
      if (
        scrollProps !== undefined &&
        scrollProps.scrollY !== undefined &&
        scrollProps.bodyScrollHeight !== undefined
      ) {
        // save scroll position to be restored.
        storage.set(SESSION_STORAGE_KEY, scrollProps);
      }
    }
  });
  return next => action => {
    if (action.type === LOCATION_CHANGE) {
      isPathnameChanged = isPageChanged(get(store.getState(), 'pageContext.location'), action.payload.location);
    }
    if (action.type === LOCATION_CHANGE && isPathnameChanged) {
      const payloadAction = action.payload.action;
      if (payloadAction === 'PUSH') {
        next(saveCurrentScrollProps(window.scrollY, document.body.scrollHeight));
      } else if (payloadAction === 'POP') {
        // clear 'scrollProps' in Redux state to be on the safe side.
        next(clearCurrentScrollProps());
      }
    }

    const disableScrollToTop = get(store.getState(), 'pageContext.page.options.disableScrollToTop');
    const disableScrollToTopByPath = get(store.getState(), 'pageContext.page.options.disableScrollToTopByPath');
    const skipScrollToTop =
      (disableScrollToTop && !isPathnameChanged) || (disableScrollToTopByPath && isPathnameChanged);

    if (action.type === PAGE_UNLOAD && !skipScrollToTop) {
      scrollToTop();
    }
    if (action.type === PAGE_LOAD) {
      const scrollProps = storage.get(SESSION_STORAGE_KEY);
      if (scrollProps !== null && scrollProps.scrollY && scrollProps.bodyScrollHeight) {
        maybeSetScrollY(scrollProps.scrollY, scrollProps.bodyScrollHeight);
      }
      if (scrollProps !== null) {
        // clear session storage.
        storage.remove(SESSION_STORAGE_KEY);
      }
    }
    return next(action);
  };
};
