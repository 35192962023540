import { get } from 'lodash';

export const scrollToPos = (x = 0, y = 0) => {
  window.scrollTo(x, y);
};

export const scrollToHash = (hash, options = { behavior: 'smooth' }) => {
  setTimeout(() => {
    if (!hash || hash.indexOf('#') !== 0) return false;
    const name = hash.replace('#', '');
    const element = get(document.getElementsByName(name), '[0]') || document.getElementById(name);
    if (!element) return false;
    element.scrollIntoView(options);
    return true;
  }, 0);
};

export const scrollToTop = () => {
  if (window.location.hash !== '') {
    return;
  }
  scrollToPos(0, 0);
};

export const scrollToElement = hash => {
  const element = document.getElementById(hash);
  if (!element) return;
  const position = element.getBoundingClientRect();

  window.scrollTo({ top: position.top + window.pageYOffset, left: 0, behavior: 'smooth' });
};
