import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import {
  SITEMAP_CATEGORIES_ROUTES,
  VEHICLE_MAKES_SITEMAP_ROUTE,
  STATIC_SITEMAPS,
  CONTENT_SITEMAPS_ROUTES,
} from 'client/site-modules/sitemap/constants/routes';
import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';

// Filter out one-word version of two-words makes such as "alfaromeo"
const filteredMakes = makes.filter(make => !TWO_WORDS_MAKE_NAMES_MAP[make]);

export const routes = {
  path: [
    ...SITEMAP_CATEGORIES_ROUTES,
    ...STATIC_SITEMAPS,
    ...CONTENT_SITEMAPS_ROUTES,
    VEHICLE_MAKES_SITEMAP_ROUTE,
    `/:make(${filteredMakes.join('|')})-sitemap.html`,
    `/:make(${filteredMakes.join('|')})-:model-sitemap.html`,
  ],
  exact: true,
  page: getLoadablePage(() => import(/* webpackChunkName: "sitemap" */ '../pages/sitemap/sitemap')),
  chunkName: 'sitemap',
  enableLooseEtag: true,
};
