import { INVENTORY_TYPES } from 'client/constants/inventory-types';

/**
 * Returns true if type is 'new', 'NEW'
 * @param {string} type
 * @returns {boolean}
 */
export function isNew(type) {
  return typeof type === 'string' && type.toUpperCase() === INVENTORY_TYPES.NEW;
}
