import { UPDATE_SESSION, UPDATE_LOCATION_CONTEXT, SESSION_ENTRY } from 'client/actions/constants';

/**
 *
 * @param {string} state.session session id
 * @param {boolean} state.isNewSession true for the first page of a new session
 *
 * @param action
 * @returns {{}|{session: *, isNewSession: (Object.isNewSession|boolean)}}
 */
export function visitorReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_SESSION: {
      if (state.session === action.session && state.isNewSession === action.isNewSession) {
        // do not update redux to avoid unnecessary re-renders if nothing about session has changed
        return state;
      }
      return {
        ...state,
        session: action.session,
        isNewSession: action.isNewSession,
      };
    }
    case UPDATE_LOCATION_CONTEXT: {
      return {
        ...state,
        location: action.location,
      };
    }
    case SESSION_ENTRY: {
      return {
        ...state,
        sessionEntryParams: action.sessionEntryParams,
      };
    }
    default:
      return state;
  }
}
