import { EventToolbox } from 'client/utils/event-toolbox';
import { DRAWER_EVENTS } from 'site-modules/shared/components/drawer/events';
import { getCreativeId } from 'client/engagement-handlers/helper';
import { TrackingConstant } from 'client/tracking/constant';

export function handleDrawerOpen(event) {
  const drawerElement = event.target;
  const {
    dataset: { noWidgetView },
  } = drawerElement;
  const creativeId = getCreativeId(drawerElement);

  const eventObj = {
    event_type: 'widget_view',
    event_data: {
      action_name: 'widget_view',
      action_cause: event.type || TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: creativeId,
    },
  };

  if (creativeId && !noWidgetView) {
    EventToolbox.fireTrackAction(eventObj);
  }
}

EventToolbox.on(DRAWER_EVENTS.OPEN, handleDrawerOpen);
EventToolbox.on(DRAWER_EVENTS.CLOSED, handleDrawerOpen);
