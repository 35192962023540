import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/insider/inventory',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "insider-inventory" */ '../pages/insider-inventory/insider-inventory'),
      () => ({
        slug: 'inventory',
      })
    ),
    chunkName: 'insider-inventory',
  },
  {
    path: '/insider/search',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "insider-saved-searches" */ '../pages/saved-searches/saved-searches'),
      () => ({
        slug: 'search',
      })
    ),
    chunkName: 'insider-saved-searches',
  },
  {
    path: '/insider/appraisal',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "insider-appraisal" */ '../pages/insider-appraisal/insider-appraisal'),
      () => ({
        slug: 'appraisal',
      })
    ),
    chunkName: 'insider-appraisal',
  },
  {
    path: '/insider/favorite-models',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "insider-favorite-models" */ '../pages/favorite-models/favorite-models'),
      () => ({
        slug: 'favorite-models',
      })
    ),
    chunkName: 'insider-favorite-models',
  },
  {
    path: '/insider/settings/',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "settings" */ '../pages/settings/settings'),
      () => ({
        slug: 'settings',
      })
    ),
    chunkName: 'settings',
  },
  {
    path: '/insider/login-page',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "insider-login-page" */ '../pages/login-page/login-page')),
    chunkName: 'insider-login-page',
  },
  {
    path: '/insider',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "dashboard" */ '../pages/dashboard/dashboard')),
    chunkName: 'dashboard',
  },
];
