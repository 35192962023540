export const PUB_STATES = {
  NEW: 'NEW',
  NEW_USED: 'NEW_USED',
  USED: 'USED',
  PRE_PROD: 'PRE_PROD',
  FUTURE: 'FUTURE',
  DISABLED: 'DISABLED',
};

export const PUB_STATES_LOWERCASE = {
  NEW: 'new',
  NEW_USED: 'new_used',
  USED: 'used',
  PRE_PROD: 'pre_prod',
  FUTURE: 'future',
  DISABLED: 'disabled',
};

export const PUB_STATES_MAPPING_FOR_USER = {
  NEW: PUB_STATES.NEW,
  NEW_USED: PUB_STATES.NEW,
  USED: PUB_STATES.USED,
  PRE_PROD: PUB_STATES.NEW,
  FUTURE: PUB_STATES.NEW,
};

export const PUB_STATES_NEW = [PUB_STATES.NEW, PUB_STATES.FUTURE, PUB_STATES.NEW_USED, PUB_STATES.PRE_PROD];
export const PUB_STATES_USED = [PUB_STATES.NEW_USED, PUB_STATES.USED];
export const PUB_STATES_ALL = [
  PUB_STATES.FUTURE,
  PUB_STATES.PRE_PROD,
  PUB_STATES.NEW,
  PUB_STATES.NEW_USED,
  PUB_STATES.USED,
];
