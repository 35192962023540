import { pwtState } from 'client/utils/pwt/get-pwt-bids';

const bidEligibleAdUnits = [];

export function getBidEligibleAdUnits() {
  return bidEligibleAdUnits;
}

export function pushToBidEligibleAdUnits(elem) {
  bidEligibleAdUnits.push(elem);
}

export function getPwtRebidArray(rebidEligibleArray) {
  const filteredEligibleAdUnits = getBidEligibleAdUnits().filter(elem => rebidEligibleArray.includes(elem.slotId));
  const filteredPwtAdUnits = pwtState.pwtAdUnits.filter(elem => rebidEligibleArray.includes(elem.divId));
  return filteredEligibleAdUnits.map((el, index) => Object.assign(el, filteredPwtAdUnits[index]));
}
