/*
 * Map of element tag names to action_cause
 */
import { get } from 'lodash';

const ACTION_CAUSE = {
  LINK: 'link_click',
  A: 'link_click',
  BUTTON: 'button_click',
  IMG: 'image_click',
  SELECT: 'select_change',
  INPUT: 'user_input',
  FORM: 'button_click',
};

const CONTROL_ELEMENTS = ['INPUT', 'SELECT', 'OPTION'];
const CONTROL_ELEMENT_CLASS_NAMES = ['slick-arrow'];

/*
 * default value for action_cause
 */
const DEFAULT_ACTION_CAUSE = 'link_click';

/**
 *
 * @param element
 * @return {*}
 */
function getActionCause(element) {
  let tagName = element.tagName;
  if (element.className.includes('btn-link')) {
    tagName = 'LINK';
  } else if (element.matches('[class~="btn"]')) {
    tagName = 'BUTTON';
  }
  return ACTION_CAUSE[tagName] || DEFAULT_ACTION_CAUSE;
}

/**
 * An element is a control element if it is either an <input>, <select>, <option> element or it is
 * any element that has a class of 'slick-arrow'.
 *
 * @param element
 * @return {boolean}
 */
function isControlElement(element) {
  return (
    CONTROL_ELEMENTS.includes(get(element, 'tagName')) ||
    (get(element, 'classList') && CONTROL_ELEMENT_CLASS_NAMES.some(className => element.classList.contains(className)))
  );
}

/**
 *
 * @param element
 */
function getActionValue(element) {
  let trackingValue = element.getAttribute('data-tracking-value');

  if (typeof trackingValue === 'undefined' || trackingValue === null) {
    trackingValue = isControlElement(element) ? element.value : element.textContent.trim();

    // TODO: if do not have nested elements
  }
  return trackingValue;
}

function isAdsRefreshDisabled(element) {
  const noRefresh = element.getAttribute('data-no-refresh');
  const isDisabled = (element.getAttribute('href') && !element.hasAttribute('data-force-refresh')) || noRefresh;
  return isDisabled;
}

/**
 * Ignores the value attribute
 * @param element
 * @returns {boolean}
 */
function ignoreTrackingValue(element) {
  return element.hasAttribute('data-tracking-ignore-value');
}

export const Helper = {
  getActionCause,
  isControlElement,
  getActionValue,
  isAdsRefreshDisabled,
  ignoreTrackingValue,
};
