/**
 * client side entry point for the app
 *
 */
import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { MODE_VALUE, MODE } from 'shared/constants/flags';
import { App } from './app';
import './utils/dom-shim';
import { initStore } from './store';
import { EngagementHandler } from './engagement-handlers';
import { ClientConfig } from './configuration';
import { createLogger } from './utils/create-logger';
import { logger } from './utils/isomorphic-logger';
import { ClientTimer } from './utils/timer';
import { getUspapi } from './utils/uspapi';
import { createSeedRandoms } from './utils/seed-randomizers';
import { createVenomHistory } from './utils/history/venom-history';
import { matchRoute, loadRoutePage } from './utils/router';
import { notFound } from './site-modules/venom/routes/not-found';
import { error } from './site-modules/venom/routes/error';
import { getRoutes, getClientRedirects } from './routes';
import { HTTP_OK, HTTP_ERROR_500, HTTP_NOT_FOUND } from './utils/http-status';
import { ResponsiveToolkit } from './utils/responsive-bootstrap-toolkit';

window.onunhandledrejection = e => {
  logger('error', e, 'Unhandled Rejection');
};

window.webappStart = () => {
  ClientTimer.init();
  const initialState = window.__PRELOADED_STATE__; // eslint-disable-line venom/no-prohibited-functions
  const breakpoint = ResponsiveToolkit.current();
  initialState.pageContext.breakpointDeprecated2 = breakpoint;
  initialState.pageContext.isSmallDeprecated2 = ['xs', 'sm'].includes(breakpoint);
  // create client loger
  window.EDM = window.EDM || {};
  window.EDM.Venom = window.EDM.Venom || {};
  window.EDM.Venom.logger = createLogger(initialState);

  createSeedRandoms(initialState.seed);

  let store;
  createVenomHistory(createBrowserHistory(), () => {
    store = initStore(initialState);
    return store;
  });

  const uspapi = getUspapi(store);
  window.__uspapi = uspapi.__uspapi; // eslint-disable-line no-underscore-dangle
  window.addEventListener('message', uspapi.handleUspapiMessage);

  const el = document.querySelector('.venom-app');

  // configuration
  ClientConfig.load(store.getState().config);

  // tracking
  EngagementHandler.init(store);

  const status = window.EDM.Venom.responseStatus || HTTP_OK;
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);

  const subdomain = store.getState().request.subdomain;
  const routes = getRoutes(status, subdomain);
  const redirects = getClientRedirects();

  const renderApp = (route, component) => {
    const ff = store.getState().featureFlags;
    if (ff.concurrentMode || urlParams.get(MODE) === MODE_VALUE.NOSS) {
      createRoot(el).render(
        <App
          store={store}
          routeProps={{ path: route.path, component }}
          currentRoute={route}
          routes={routes}
          location={{ ...window.location }}
          redirects={redirects}
        />
      );
    } else {
      hydrateRoot(
        el,
        <App
          store={store}
          routeProps={{ path: route.path, component }}
          currentRoute={route}
          routes={routes}
          location={{ ...window.location }}
          redirects={redirects}
        />
      );
    }
  };

  const matchAndRenderApp = async () => {
    await matchRoute(routes, window.location.pathname)
      .then(async ({ match, route }) => {
        if (route.preload) {
          await route.preload(match.params, store);
        }
        loadRoutePage(route, match, window.location, store).then(page => renderApp(route, page));
      })
      .catch(err => {
        if (err.status === HTTP_NOT_FOUND) {
          return loadRoutePage(notFound, {}, {}).then(page => renderApp(notFound.path, page));
        }
        if (err.status === HTTP_ERROR_500) {
          return loadRoutePage(error, {}, {}).then(page => renderApp(error.path, page));
        }
        throw err;
      });
  };

  matchAndRenderApp();
};
