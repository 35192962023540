import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { HTTP_NOT_FOUND, HTTP_GONE } from 'client/utils/http-status';

const PATHS = ['/', '/:pageCategory/:pageName', '/:pageName'];
const exact = true;

const notFound = [
  {
    path: '*',
    exact,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "dealer-center-page-not-found" */ '../pages/not-found/page-not-found-definition')
    ),
    chunkName: 'dealer-center-page-not-found',
    status: HTTP_NOT_FOUND,
    enableLooseEtag: true,
  },
];

const gone = [
  {
    path: '*',
    page: getLoadablePage(() => import(/* webpackChunkName: "dealer-center-gone" */ '../pages/gone/page-gone')),
    chunkName: 'dealer-center-gone',
    status: HTTP_GONE,
    enableLooseEtag: true,
  },
];

const error = [
  {
    path: '*',
    page: getLoadablePage(() => import(/* webpackChunkName: "dealer-center-error" */ '../pages/error/page-error')),
    chunkName: 'dealer-center-error',
    enableLooseEtag: true,
  },
];

const testRoutes = [
  {
    path: '/errors/gone',
    exact,
    page: getLoadablePage(() => import(/* webpackChunkName: "dealer-center-gone" */ '../pages/gone/page-gone')),
    chunkName: 'dealer-center-gone',
    status: HTTP_GONE,
    enableLooseEtag: true,
  },
  {
    path: '/errors/internal-server-error',
    exact,
    page: getLoadablePage(() => import(/* webpackChunkName: "dealer-center-error" */ '../pages/error/page-error')),
    chunkName: 'dealer-center-error',
    enableLooseEtag: true,
  },
];

const routes = [
  {
    path: PATHS,
    exact,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "dealer-center-page" */ '../pages/dealer-center/dealer-center-definition'),
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'dealer-center-page',
    enableLooseEtag: true,
  },
];

export { routes, notFound, gone, error, testRoutes };
