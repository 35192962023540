// START: Top-rated Content
export function getTopRatedDefaultYear() {
  return '/research/top-rated/default-year';
}

export function getTopRatedThemeContentPath(year) {
  return `/research/top-rated/${year}/theme`;
}

export function getTopRatedIndexContentPath(year) {
  return `/research/top-rated/${year}/index`;
}

export function getTopRatedArticleContentPath(slug, year) {
  return `/research/top-rated/${year}/${slug}`;
}
