import { get } from 'lodash';
import { createDate } from 'site-modules/shared/utils/date-utils';

export function isDiscontinuedDate(endDate) {
  return endDate && createDate() > createDate(endDate);
}

export function filterDiscontinuedStyles(styles) {
  return (
    styles &&
    styles.filter(style => {
      const endDate =
        get(style, 'attributeGroups.STYLE_INFO.attributes.STYLE_END_DATE.value') || get(style, 'styleEndDate');

      if (!endDate) {
        return true;
      }

      return !isDiscontinuedDate(endDate);
    })
  );
}
