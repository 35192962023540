import { forEach } from 'lodash';
import { getValue, parsePath, pathTypes, pathOperations } from './json-path';

const POINTER_PATTERN = /^#(\/[^/]+)*$/;

function createRefCache() {
  const instances = {};

  return {
    add(ref, json) {
      if (Array.isArray(json)) {
        instances[ref] = [...json];
      } else if (json && typeof json === 'object') {
        instances[ref] = { ...json };
      } else {
        instances[ref] = json;
      }

      return instances[ref];
    },

    get(ref) {
      return instances[ref];
    },
  };
}

export function validateJsonPointer(pointerStr) {
  return POINTER_PATTERN.test(pointerStr);
}

export function parseJsonPointer(pointerStr) {
  if (validateJsonPointer(pointerStr)) {
    return pointerStr
      .substring(2)
      .split('/')
      .map(component => {
        const componentValue = decodeURIComponent(component)
          .replace('~1', '/')
          .replace('~0', '~');
        let value = componentValue;
        let type = pathTypes.string_literal;

        // excludes numeric strings with leading zeros
        if (/^0$|^[1-9]\d*$/.test(componentValue)) {
          const intValue = parseInt(componentValue, 10);
          if (intValue <= Number.MAX_SAFE_INTEGER) {
            value = intValue;
            type = pathTypes.numeric_literal;
          }
        }

        return {
          value,
          type,
          operation: pathOperations.subscript,
        };
      });
  }

  return null;
}

export function getJsonPointer(path) {
  const pathArr = typeof path === 'string' ? parsePath(path) : path;
  const refTokens = pathArr.map(component => {
    if (component.type === pathTypes.numeric_literal) {
      return component.value;
    }

    return encodeURIComponent(component.value.replace('~', '~0').replace('/', '~1'));
  });
  if (refTokens.length) {
    return `#/${refTokens.join('/')}`;
  }

  return '#';
}

function resolveRef(ref, root, refCache) {
  const refInstance = refCache.get(ref);
  if (refInstance) {
    return refInstance;
  }

  const value = getValue(parseJsonPointer(ref), root);
  // eslint-disable-next-line no-use-before-define
  return resolveJson(value, ref, root, refCache);
}

function resolveJson(json, pointer, root, refCache) {
  const cachedInstance = refCache.get(pointer);
  if (cachedInstance) {
    return cachedInstance;
  }

  const result = refCache.add(pointer, json);

  forEach(result, (value, key) => {
    if (value) {
      if (value.$ref) {
        const ref = value.$ref;

        if (Array.isArray(ref)) {
          result[key] = ref.map(refValue => resolveRef(refValue, root, refCache));
        } else if (validateJsonPointer(ref)) {
          result[key] = resolveRef(ref, root, refCache);
        }
      } else if (typeof value === 'object') {
        result[key] = resolveJson(value, `${pointer}/${key}`, root, refCache);
      }
    }
  });

  return result;
}

export function resolveRefs(root, path) {
  const pathArr = typeof path === 'string' ? parsePath(path) : path;
  if (pathArr) {
    const json = getValue(pathArr, root);

    if (json && json.$ref) {
      return resolveRef(json.$ref, root, createRefCache());
    }
    return resolveJson(json, getJsonPointer(pathArr), root, createRefCache());
  }

  return resolveJson(root, '#', root, createRefCache());
}
