import { ClientTimer } from 'client/utils/timer';
import { mergeSortedArrays } from 'client/utils/merge-sorted-arrays';
import { reduce, isArray } from 'lodash';
import { PathExpression } from './path-expression';
import { getDisplayName } from './display-name';
import { validateJsonPointer } from './json-ref';
import { Abort } from './error';

const segments = {};
/**
 * Registers segment
 * @param {String} name
 * @param {Object} segment
 */
function registerSegment(name, segment) {
  segments[name] = segment;
}

/**
 * Get segment object by name
 */
export function getSegment(name) {
  return segments[name];
}

/**
 * Extract path and segment name,
 * e.g. 'myModel.myData.something' ==> ['myData.something', 'myModel']
 * @param {String} fullPath - Model path with segment name, `${name}.${path}`
 * @returns {Object} { name, path }
 */
export function getPathAndSegmentName(fullPath) {
  const firstDotIndex = fullPath.indexOf('.');
  return {
    name: fullPath.substring(0, firstDotIndex),
    path: fullPath.substr(firstDotIndex + 1),
  };
}

/**
 * Matches the given path against the segment's path definitions, and passes the match to the
 * given callback. If no match is found, then null will be passed to the callback.
 *
 * @param {string} path - The path to match.
 * @param {ModelSegment} segment - The segment to match against.
 * @param {function} callback - Function to call when match is complete.
 */
export function matchPath(path, segment, callback) {
  let match = null;
  let matchedPath;
  segment.pathDefinitions.some(pathDef => {
    match = pathDef.pathExp.exec(path);
    if (match) {
      matchedPath = pathDef;
      return true;
    }

    return false;
  });

  return callback(match, matchedPath);
}

function createContext(segment, modelState, displayName, timing) {
  return {
    delta: [],
    displayName,
    dependencies: new Set(),
    timing,

    resolveValue(path, targetSegment = segment, dependency = true) {
      return modelState.resolve(path, targetSegment, timing).then(delta => {
        this.delta = [...this.delta, ...delta];

        if (dependency) {
          this.dependencies.add(targetSegment.getPath(path));
        }

        return modelState.get(path, targetSegment);
      });
    },

    updateValue(path, value, targetSegment = segment) {
      return modelState.update(path, targetSegment, value, timing).then(delta => {
        this.delta = [...this.delta, ...delta];

        return modelState.get(path, targetSegment);
      });
    },

    abort() {
      return Promise.reject(new Abort(displayName));
    },
  };
}

function parseJsonPointer(pointerStr) {
  if (validateJsonPointer(pointerStr)) {
    return pointerStr
      .substring(2)
      .split('/')
      .filter(s => !!s.length)
      .join('.');
  }
  return '';
}

function mergeWithExisting(path, segment, value, modelState) {
  if (typeof value === 'object' && !isArray(value)) {
    const existingValue = modelState.get(path, segment, false) || {};
    return {
      ...existingValue,
      ...value,
    };
  }

  return value;
}

function processIdentity(segment, value, modelState, metadata) {
  if (typeof value === 'object' && value !== null) {
    const valueIsArray = isArray(value);

    const processedValue = valueIsArray ? [...value] : { ...value };

    const identityDelta = reduce(
      value,
      (result, current, key) => {
        const { data, delta } = processIdentity(segment, current, modelState);

        processedValue[key] = data;

        return [...result, ...delta];
      },
      []
    );

    if (valueIsArray) {
      return {
        data: processedValue,
        delta: identityDelta,
      };
    }

    const { $identity, ...data } = processedValue;

    if (typeof $identity === 'string') {
      const identityPath = parseJsonPointer($identity);
      const mergedData = mergeWithExisting(identityPath, segment, data, modelState);

      return {
        data: { $ref: $identity },
        delta: [
          ...identityDelta,
          {
            path: segment.getPath(identityPath),
            value: mergedData,
            metadata,
          },
        ],
      };
    }

    return {
      data,
      delta: identityDelta,
    };
  }

  return {
    data: value,
    delta: [],
  };
}

const undefinedMetadata = {
  created: undefined,
  accessed: undefined,
  dependencies: undefined,
  ttl: undefined,
};

function getMetadata(value, ttl, dependencies = new Set()) {
  if (value === undefined) {
    return undefinedMetadata;
  }

  const timeElapsed = ClientTimer.getTimeElapsed();
  const ttlAttr = typeof ttl === 'number' ? { ttl } : {};

  const meta = {
    created: timeElapsed,
    accessed: timeElapsed,
    dependencies: Array.from(dependencies.values()),
    ...ttlAttr,
  };

  return meta;
}

function getDelta(path, segment, value, modelState, metadata = getMetadata(value)) {
  const { data, delta } = processIdentity(segment, value, modelState, metadata);

  return [
    ...delta,
    {
      path: segment.getPath(path),
      value: data,
      metadata,
    },
  ];
}

function calculateSpecificity(pathDefinition) {
  const { path } = pathDefinition;

  return path.split('.').length + (path.split('[').length - 2);
}

function sortBySpecificity(pathDef1, pathDef2) {
  return pathDef2.specificity - pathDef1.specificity;
}

/**
 * Resolves the given segment/path using the given ModelState instance.
 * Returns a list of path-value pairs that were changed during the resolve.
 *
 * @param {string} path - The path to resolve.
 * @param {ModelSegment} segment - The segment to match against.
 * @param {ModelState} modelState - The ModelState instance.
 *
 * @returns {Object[]}
 */
function resolvePath(path, segment, modelState, timing) {
  const myTiming = {
    depth: null,
    duration: 0, // declaring prop here to ensure the property order
    startTime: Date.now(),
    endTime: 0, // if no (match && pathDef.resolve), or there was a match but resolved value was already in cache then assume instantenuous. make this default endTime to avoid undefined for these cases.
    name: `resolve:${segment.root}.${path}`,
  };
  /* eslint-disable-next-line no-param-reassign */
  timing[`resolve:${segment.root}.${path}`] = myTiming;

  return matchPath(path, segment, (match, pathDefinition) => {
    if (match && pathDefinition.resolve) {
      myTiming.pathDefinition = pathDefinition.path;
      myTiming.fromCache = true;
      const cacheKey = segment.getPath(match.$path);
      return modelState.getResolverCache().resolve(cacheKey, () => {
        delete myTiming.fromCache;
        const { ttl } = pathDefinition;
        const { displayName } = pathDefinition;
        const context = createContext(segment, modelState, displayName, myTiming);

        return pathDefinition.resolve(match, context).then(data => {
          myTiming.endTime = Date.now();
          myTiming.duration = myTiming.endTime - myTiming.startTime;
          const metadata = getMetadata(data, ttl, context.dependencies);
          const delta = getDelta(match.$path, segment, data, modelState, metadata);
          return [...context.delta, ...delta];
        });
      });
    }

    return Promise.resolve(getDelta(path, segment, null, modelState, getMetadata(null)));
  });
}

/**
 * Updates the values of given segment/path using the given ModelState instance.
 * Returns a list of path-value pairs that were changed during the update.
 *
 * @param {string} path - The path to update.
 * @param {ModelSegment} segment - The segment to match against.
 * @param {*} value - The value to store.
 * @param {ModelState} modelState - The ModelState instance.
 *
 * @returns {Object[]}
 */
function updatePath(path, segment, value, modelState, timing) {
  const myTiming = {
    depth: null,
    duration: 0, // declaring this here to preserve the property order
    startTime: Date.now(),
    endTime: Date.now(), // if no match, or there was a match but resolved value was already in cache then assume instantenuous. make this default endTime to avoid undefined for these cases.
    name: `update:${segment.root}.${path}`,
  };
  /* eslint-disable-next-line no-param-reassign */
  timing[`update:${segment.root}.${path}`] = myTiming;

  return matchPath(path, segment, (match, pathDefinition) => {
    const { ttl } = pathDefinition || {};
    if (match) {
      const cacheKey = segment.getPath(match.$path);
      return modelState.getResolverCache().update(cacheKey, () => {
        if (pathDefinition.update) {
          myTiming.pathDefinition = pathDefinition.path;
          const displayName = pathDefinition && pathDefinition.displayName;
          const context = createContext(segment, modelState, displayName, myTiming);

          return pathDefinition.update(value, match, context).then(actualValue => {
            myTiming.endTime = Date.now();
            myTiming.duration = myTiming.endTime - myTiming.startTime;
            const metadata = getMetadata(actualValue, ttl, context.dependencies);
            const delta = getDelta(path, segment, actualValue, modelState, metadata);
            return [...context.delta, ...delta];
          });
        }

        return Promise.resolve(getDelta(path, segment, value, modelState, getMetadata(value, ttl), modelState));
      });
    }

    return Promise.resolve(getDelta(path, segment, value, modelState, getMetadata(value, ttl), modelState));
  });
}

/**
 * @typedef ModelSegment
 * @type {Object}
 *
 * @property {string} root - The root path where the segment is stored in the model.
 * @property {PathDefinition[]} pathDefinitions - Definitions for paths relative to the model segment.
 * @property {function} getPath - Gets a full model path given a relative path.
 * @property {function} resolve - Resolves the given relative path from the given ModelState instance.
 * @property {function} update - Updates the value stored at the given relative path using the given
 *   modelState instance.
 */

/**
 * @typedef PathDefinition
 * @type {Object}
 *
 * @property {string} path - A path expression string used to match one or more paths.
 * @property {function} resolve - A function that resolves the value stored at the path.
 *   Returns a Promise fulfilled with the value.
 * @property {function} update - A function to be called prior to updating the value stored at the path.
 *   Returns a Promise fulfilled with the actual value to store at the path.
 * @property {string} [displayName] - A string for defining a path.
 */

/**
 * Creates a ModelSegment from the given path definitions.
 *
 * @param {string} name - The name of the segment, will also be used as the root.
 * @param {Object[]} pathConfigs - Configurations for each path in the segment.
 * @param {boolean} allowMergeToExisting if false any existing segment with same name will be replaced with the newly
 * created one - this is the legacy and default behavior. If true, the newly created pathConfigs will be merged with
 * pathConfigs of existing segment with same name, if one exists - this essentially allows for multiple models to share
 * the same root name.
 *
 * @returns {ModelSegment}
 */
export function createModelSegment(name, pathConfigs, allowMergeToExisting = false) {
  const pathDefinitions = pathConfigs
    .map(config => ({
      ...config,
      pathExp: new PathExpression(config.path),
      specificity: calculateSpecificity(config),
      displayName: getDisplayName(config),
    }))
    .sort(sortBySpecificity);

  if (allowMergeToExisting && segments[name]) {
    segments[name].pathDefinitions = mergeSortedArrays(
      segments[name].pathDefinitions,
      pathDefinitions,
      sortBySpecificity
    );
    return segments[name];
  }

  const segment = {
    root: name,
    pathDefinitions,

    getPath: path => `${name}.${path}`,

    /**
     *
     * @param path
     * @param modelState
     * @param timing caller should pass in an object and timing metadata will be added to that object down the chain
     * @returns {Object[]}
     */
    resolve(path, modelState, timing = {}) {
      return resolvePath(path, this, modelState, timing);
    },

    /**
     *
     * @param path
     * @param value
     * @param modelState
     * @param timing caller should pass in an object and timing metadata will be added to that object down the chain
     * if not passed in u won't get the timing info. the only place currently not passed in is in
     * client/data/luckdragon/invalidate.js during SPA transition
     * @returns {Object[]}
     */
    update(path, value, modelState, timing = {}) {
      return updatePath(path, this, value, modelState, timing);
    },
  };

  registerSegment(name, segment);

  return segment;
}
