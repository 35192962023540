import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routesError = [
  {
    path: '*',
    page: getLoadablePage(() => import(/* webpackChunkName: "test_microsite_error" */ '../pages/error/error')),
    chunkName: 'test_microsite_error',
    inlineCritical: false,
  },
];

export const routesErrorDemo = [
  {
    path: '/error',
    page: getLoadablePage(() => import(/* webpackChunkName: "test_microsite_error" */ '../pages/error/error')),
    chunkName: 'test_microsite_error',
    inlineCritical: false,
  },
];
