import { HTTP_GONE } from 'client/utils/http-status';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const gone = {
  path: '*',
  page: getLoadablePage(() => import(/* webpackChunkName: "gone_page" */ '../pages/gone/gone')),
  chunkName: 'gone_page',
  status: HTTP_GONE,
};
/**
 * this route is used to demo the error page
 */
export const routes = [gone];
