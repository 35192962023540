/**
 * Performs the nice-name transformation:
 *  - makes string characters lower cased
 *  - replace spaces by "-"
 *  - removes characters like "()+,./\:"
 * @param string
 * @returns string
 */
export function makeNiceName(string) {
  return (
    string &&
    string
      .trim()
      .toLowerCase()
      .replace(/\s-\s/g, '-')
      .replace(/\s/g, '-')
      .replace(/\+/g, 'plus')
      .replace(/!/g, 'exclaim')
      .replace(/[(),./\\:]/g, '')
  );
}

export function makeDDPCityStateNiceName(string) {
  return string && string.toLowerCase().replace(/[- ]*/g, '');
}

export function makeDDPCityStateName(string) {
  return string && string.toLowerCase().replace(/[\s]+/g, '-');
}
