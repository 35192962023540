import { PAGE_START_LOADING } from 'client/actions/constants';

export function requestReducer(state = {}, action = {}) {
  if (PAGE_START_LOADING === action.type) {
    return {
      ...state,
      url: action.page.location.pathname,
    };
  }

  return state;
}
