import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { getQAEntriesFromContent } from 'client/site-modules/shared/utils/faq';

// Use this function to transform content from .atom file
export function transformContentForFaq(content, visitorLocation) {
  const { title } = content;

  const caption = content.legacy ? content.content : content.metadata('htmlContent').value();
  const wrapperType = content.metadata('wrapperType').value() || WRAPPER_TYPES.accordion;
  const entries = getQAEntriesFromContent(content, visitorLocation);

  return { title, caption, entries, wrapperType };
}

// Checks if answer has own self-referring link (may happen with content from .atom files) so it won't be inserted twice
export function checkOwnLearnMoreLink({ answer, pathname, canonical }) {
  const pathnameString = canonical ? canonical.split('edmunds.com')[1] : pathname;
  const hrefRegExp = new RegExp(`href=".*${pathnameString}"`);
  return hrefRegExp.test(answer);
}

export function getLearnMoreLink({
  pathname,
  className = '',
  canonical,
  linkText = 'Learn more',
  withTracking,
  linkNum,
}) {
  if (!pathname && !canonical) return '';

  const href = canonical || `https://www.edmunds.com${pathname}`;
  const tracking =
    withTracking && linkNum
      ? `?utm_source=google&utm_medium=organic_search&utm_campaign=faq_zero_box&utm_content=link${linkNum}`
      : '';
  return `<a href="${href}${tracking}"${className ? ` class="${className}"` : ''}>${linkText}</a>`;
}

export function insertLearnMoreLink({ answer, pathname, canonical, learnMoreLink }) {
  if (!learnMoreLink) return answer;
  if (checkOwnLearnMoreLink({ answer, pathname, canonical })) return answer;
  return `${answer} ${learnMoreLink}`;
}
