import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { getLearnMoreLink, insertLearnMoreLink } from 'site-modules/shared/components/faq/utils';

function getQAEntity({
  question,
  answer,
  pathname,
  canonical,
  generateLearnMoreLinks,
  learnMoreLinkText,
  withTracking,
  linkNum,
}) {
  const learnMoreLink = generateLearnMoreLinks
    ? getLearnMoreLink({ pathname, canonical, linkText: learnMoreLinkText, withTracking, linkNum })
    : '';

  const answerText = Array.isArray(answer) ? answer.join(' ') : answer;

  return {
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: insertLearnMoreLink({ answer: answerText, learnMoreLink, pathname, canonical }),
    },
  };
}

/**
 * Get FAQ Schema
 * @param {Object[]} entries Array of objects with "question" and "answer" values
 * @param {String} questionLabel Key for questions values. "question" by default
 * @param {String} answerLabel Key for answers values. "answer" by default
 * @param {Boolean} generateLearnMoreLinks Determine if "Learn more" link should be generated
 * @param {Boolean} withTracking Determine if "Learn more" link will have tracking appended
 * @param {String} learnMoreLinkText Link text for "learn more" link
 * @param {String} pathname Pathname where "Learn more" link will point
 * @param {String} canonical Pathname where "Learn more" link will point. Overrides "pathname" if provided
 * @returns {Object}
 */
export function getFAQSchema({
  entries,
  questionLabel = 'question',
  answerLabel = 'answer',
  generateLearnMoreLinks = true,
  withTracking,
  learnMoreLinkText,
  pathname,
  canonical,
}) {
  if (!entries || !entries.length) return null;

  return {
    '@context': SCHEMA_URL,
    '@type': 'FAQPage',
    mainEntity: entries.map((qaPair, ind) => {
      const question = qaPair[questionLabel];
      const answer = qaPair[answerLabel];
      return getQAEntity({
        question,
        answer,
        generateLearnMoreLinks,
        withTracking,
        learnMoreLinkText,
        pathname,
        canonical,
        linkNum: ind + 1,
      });
    }),
  };
}
