export const BEST_DEAL_DESC = 'bestDeal:desc';
export const BEST_DEAL_PRICE = 'bestDealPrice';
export const PRICE_DESC = 'price:desc';
export const PRICE_ASC = 'price:asc';
export const DISTANCE_ASC = 'distance:asc';
export const MILEAGE_DESC = 'mileage:desc';
export const MILEAGE_ASC = 'mileage:asc';
export const SUGGESTED_PRICE_DESC = 'suggestedprice:desc';
export const SUGGESTED_PRICE_ASC = 'suggestedprice:asc';
export const PUBLISH_DATE_DESC = 'publishDate:desc';
export const PUBLISH_DATE_ASC = 'publishDate:asc';
export const MODEL_YEAR_DESC = 'modelYear:desc';
export const MODEL_YEAR_ASC = 'modelYear:asc';
export const RANGE_ASC = 'electricityrange:asc';
export const RANGE_DESC = 'electricityrange:desc';
export const expandedRadiusDueToLowResultsSort = DISTANCE_ASC;
