import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: `/partners/affiliates/:index(indexred|indexorange|index4).html`,
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "indexred" */ '../pages/indexred/indexred-definition')),
    chunkName: 'indexred',
  },
];
