import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';

export const routes = [
  {
    path: [`/:make(${makes.join('|')})/:model/connect`, `/:make(${makes.join('|')})/:model/:year/connect`],
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "new_model_core_connect" */ '../pages/connect/connect-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
        make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
      })
    ),
    chunkName: 'new_model_core_connect',
  },
];
