import { combineReducers } from 'redux';
import { experimentVehicleReducer } from './experiment-vehicle-reducer';

/**
 * Experiments page context reducer.
 *
 * @type {Function}
 */
export const experimentPageContextReducer = combineReducers({
  vehicle: experimentVehicleReducer,
});
