/* eslint-disable func-names, no-param-reassign, no-plusplus, no-var */

if (typeof Element !== 'undefined') {
  // matches polyfill
  (function(ElementPrototype) {
    ElementPrototype.matches =
      ElementPrototype.matches ||
      ElementPrototype.matchesSelector ||
      ElementPrototype.mozMatchesSelector ||
      ElementPrototype.msMatchesSelector ||
      ElementPrototype.oMatchesSelector ||
      ElementPrototype.webkitMatchesSelector ||
      function(selector) {
        var node = this;
        var nodes = (node.parentNode || node.document).querySelectorAll(selector);
        var i = -1;
        while (nodes[++i] && nodes[i] !== node);
        return !!nodes[i];
      };
  })(Element.prototype);

  // closest polyfill
  (function(ElementPrototype) {
    ElementPrototype.closest =
      ElementPrototype.closest ||
      function(selector) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(selector)) return el;
          el = el.parentElement;
        } while (el !== null);
        return null;
      };
  })(Element.prototype);
}
