import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: [
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/write-review`,
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/write-review/:reviewId`,
    ],
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "new_model_core_write_review" */ '../pages/write-review/write-review-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => match.params
    ),
    chunkName: 'new_model_core_write_review',
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/update-consumer-review/review-:reviewId`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "new_model_core_update_review" */ '../pages/update-review/update-review-definition')
    ),
    chunkName: 'new_model_core_update_review',
  },
];
