/**
 * Time duration constants
 *   MS - in milliseconds
 *   SEC - in seconds
 *
 * @type {Object}
 */
export const TIME = {
  MS: {
    FIVE_MINUTES: 1000 * 60 * 5,
    FIFTEEN_MINUTES: 1000 * 60 * 15,
    TWENTY_MINUTES: 1000 * 60 * 20,
    THIRTY_MINUTES: 1000 * 60 * 30,
    ONE_HOUR: 1000 * 60 * 60,
    ONE_DAY: 1000 * 60 * 60 * 24,
    ONE_MONTH: 1000 * 60 * 60 * 24 * 30,
    ONE_YEAR: 1000 * 60 * 60 * 24 * 365,
    TEN_YEARS: 1000 * 60 * 60 * 24 * 365 * 10,
  },
  SEC: {
    FIFTEEN_MINUTES: 60 * 15,
    THIRTY_MINUTES: 60 * 30,
    ONE_HOUR: 60 * 60,
    ONE_DAY: 60 * 60 * 24,
    ONE_YEAR: 60 * 60 * 24 * 365,
    TEN_YEARS: 60 * 60 * 24 * 365 * 10,
  },
};
