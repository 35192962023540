import { DFPManager } from '@edmunds/react-dfp';
import { uniqBy } from 'lodash';
import { getPwtRebidArray } from './get-bid-eligible-array';
import { destroyPwtBids } from './destroy-pwt-bids';

export async function pwtAdRefresher(biddableArray) {
  DFPManager.getGoogletag().then(async googletag => {
    const rebidArray = getPwtRebidArray(biddableArray);
    const filteredRebidArray = uniqBy(rebidArray, 'slotId');
    await destroyPwtBids.init(filteredRebidArray, googletag, biddableArray);
  });
}
