import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

// Add any paths patterns that should explicitly 404 here
const explicit404Paths = [
  // public api routes
  '/v/api/**',
  // internal api routes
  '/internal/api/**',
];

export const notFound = {
  path: '*',
  page: getLoadablePage(() => import(/* webpackChunkName: "not_found_page" */ '../pages/not-found/not-found')),
  chunkName: 'not_found_page',
  status: HTTP_NOT_FOUND,
};

export const routes = [notFound];

export const explicit404Routes = [
  {
    ...notFound,
    path: explicit404Paths,
  },
];
