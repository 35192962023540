export const FEATURE_SPEC_TOOLTIP_MAP = {
  KWH_PER_100: 'kwh_per_100',
  TIME_TO_CHARGE: 'time_to_charge',
  EV_RANGE: 'ev_range',
  MPGE: 'mpge',
  COMBINED_MPGE: 'combined_mpge',
  MPGE_FULL: 'mpge_full',
};

export const FEATURE_SPEC_NAME_TOOLTIP_MAP = {
  'EPA kWh/100 mi': FEATURE_SPEC_TOOLTIP_MAP.KWH_PER_100,
  'EPA Time to charge battery (at 240V)': FEATURE_SPEC_TOOLTIP_MAP.TIME_TO_CHARGE,
  'EPA time to charge battery (at 240V)': FEATURE_SPEC_TOOLTIP_MAP.TIME_TO_CHARGE,
  'EPA Electricity Range': FEATURE_SPEC_TOOLTIP_MAP.EV_RANGE,
  'EPA electricity range': FEATURE_SPEC_TOOLTIP_MAP.EV_RANGE,
  'EPA city/highway MPGe': FEATURE_SPEC_TOOLTIP_MAP.MPGE,
  'EPA Combined MPGe': FEATURE_SPEC_TOOLTIP_MAP.MPGE_FULL,
  'EPA combined MPGe': FEATURE_SPEC_TOOLTIP_MAP.MPGE_FULL,
};

export const FEATURE_SPEC_TOOLTIP = {
  [FEATURE_SPEC_TOOLTIP_MAP.KWH_PER_100]:
    'This value tells you how much energy in kilowatt-hours a vehicle would use to travel 100 miles.  Unlike mpg, however, where a larger number is better (for example, a vehicle that gets 30 mpg is better than one that gets 20 mpg), a smaller number is better in kWh/100 miles because you are using less battery energy per mile.',
  [FEATURE_SPEC_TOOLTIP_MAP.TIME_TO_CHARGE]:
    "This can be tough to pin down, but we assume for simplicity that the 240V power source will enable the vehicle's onboard charger to operate at full capacity, and that the battery is fully depleted and will be recharged to 100%. Given those assumptions, the value provided is simply the battery's capacity divided by the onboard charger's power rating. For example, a battery rated at 100 kWh will need 12.5 hours to recharge fully using an 8.0-kW charger.",
  [FEATURE_SPEC_TOOLTIP_MAP.EV_RANGE]:
    "This value is the estimated number of miles that a vehicle can travel in combined city and highway driving (using a mix of 55% highway and 45% city driving) before needing to be recharged, according to the EPA's testing methodology.",
  [FEATURE_SPEC_TOOLTIP_MAP.MPGE]:
    'MPGe is the official metric that the EPA uses to measure the efficiency of alternative-fuel (including electric) vehicles. Just like regular MPG shows how far a car will travel on one gallon of gas, MPGe shows how far a vehicle will drive on 33.7kWh of electricity — the energy equivalent of one gallon of gasoline.',
  [FEATURE_SPEC_TOOLTIP_MAP.MPGE_FULL]:
    'MPGe is the official metric that the EPA uses to measure the efficiency of alternative-fuel (including electric) vehicles. Just like regular MPG shows how far a car will travel on one gallon of gas, MPGe shows how far a vehicle will drive on 33.7kWh of electricity - the energy equivalent of one gallon of gasoline. Combined MPGe is the combined total of 45% city MPGe + 55% highway MPGe.',
};

export const FEATURES_SPECS_CREATIVE_ID = 'edm-features-specs-table';

export const ELECTRIC_FUEL_TYPE = 'electric';
