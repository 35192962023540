import { getReduxModelStateFromStore } from './redux-model';

export function getBindingPath(binding, props) {
  const { prop } = binding;
  let { path } = binding;

  // check for prop binding
  if (prop) {
    path = props[prop];
  }

  // check for path binding that uses a function
  if (typeof path === 'function') {
    return path(props);
  }

  return path;
}

export function getWrappedProps(props, context, modelBindings, component) {
  const wrapped = {};

  const store = props.store || context.store;
  const modelState = getReduxModelStateFromStore(store);

  // Attempt to choose which props to pass through based on propTypes, fallback on all props
  const propNames = Object.keys(component.propTypes || props);
  propNames.forEach(propName => {
    const prop = props[propName];
    const binding = modelBindings[propName];

    if (prop && binding) {
      // bound prop so get model value and process it with transform when applicable
      const { segment, transform, resolveRefs } = binding;
      const path = getBindingPath(binding, props);

      const modelValue = modelState.get(path, segment, resolveRefs);
      if (modelValue && typeof transform === 'function') {
        wrapped[propName] = transform(modelValue, props);
      } else {
        wrapped[propName] = modelValue;
      }
    } else {
      // unbound prop so just pass it through
      wrapped[propName] = prop;
    }
  });

  return wrapped;
}
