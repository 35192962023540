const METADATA_ROOT = '$luckdragon';

function getAttributesForPath(path, metaObj) {
  return metaObj[path] || {};
}

function setAttributesForPath(path, attributes, metaObj) {
  return {
    ...metaObj,
    [path]: {
      ...getAttributesForPath(path, metaObj),
      ...attributes,
    },
  };
}

export function updatePathMetadata(path, attributes, stateObj) {
  const metaObj = stateObj[METADATA_ROOT] || {};

  return {
    ...stateObj,
    [METADATA_ROOT]: setAttributesForPath(path, attributes, metaObj),
  };
}

export function getModelMetadata(stateObj) {
  const metaObj = stateObj[METADATA_ROOT] || {};

  return {
    getPaths() {
      return Object.keys(metaObj);
    },

    getPathAttribute(path, attribute) {
      return getAttributesForPath(path, metaObj)[attribute];
    },
  };
}
