import { EventToolbox } from 'client/utils/event-toolbox';

/**
 * NOTE FOR FUTURE DEVELOPERS:
 *
 * The below logic can also be implemented as Redux middleware module instead of direct subscription to Redux.
 * Such middleware would listen to Luckdragon MODEL_SAVE actions for the particular location path
 * and dispatch custom DOM event by using Redux event middleware,
 * e.g. enriching the original MODEL_SAVE action payload with data like this:
 *  meta: {
 *    event: {
 *      eventName: LOCATION_EVENTS.UPDATE_LOCATION,
 *      data: location,
 *    },
 *  },
 * Then this location-engagement-handler.js should be changed and
 * listen to LOCATION_EVENTS.UPDATE_LOCATION events instead of subscription to Redux.
 *
 * Consider the above approach if more cases like location change tracking should be supported and
 * only if you see that application would benefit from that type of solution design.
 *
 * Feel free to contact @venom-team in case of any questions.
 */

// creates location change handler function
function createLocationTracker(getState) {
  // location selector
  const selectLocation = () => getState().visitor.location;
  // get initial location data
  let location = selectLocation();

  // location change tracker
  // it's called on every store change
  return function trackLocationChange() {
    const newLocation = selectLocation();
    // do nothing if location zip codes have not changed
    if (
      location === newLocation ||
      (location.zipCode === newLocation.zipCode && location.ipZipCode === newLocation.ipZipCode)
    ) {
      return;
    }

    // ok so location has changed
    // 1st save current location
    location = newLocation;
    // 2nd fire location change pixel
    EventToolbox.fireTrackAction({
      user_zip_code: location.zipCode,
      ip_zip_code: location.ipZipCode,
    });
  };
}

export const LocationEngagementHandler = {
  init({ subscribe, getState }) {
    // create location change tracker
    const locationTracker = createLocationTracker(getState);
    // subscribe to app redux store updates
    subscribe(locationTracker);
  },
};
