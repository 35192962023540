import isObject from 'lodash/isObject';

const KEY_PATTERN = /(?:\[['"])?\.?\{(?:[^\s.}]+)\}(?:['"]\])?|(?:['"]\])$/g;
const ARRAY_STYLE_PROPERTY_NAME_PATTERN = /\[['"]?(\w*)['"]?\]/g;

/**
 * Cleans key expressions from path
 *
 * @example
 * // returns 'blah'
 * clearKey('blah["{key}"]');
 *
 * @example
 * // returns 'blah.foo'
 * clearKey('blah.{key}.foo');
 *
 * @param {string} path
 *
 * @return {string} path without key expressions
 */
function clearKey(path) {
  return path.replace(KEY_PATTERN, '');
}

/**
 * Converts square bracers style path to dotted style
 * @example
 * // returns 'blah.foo.goo.1'
 * convertPathToDottedStyle('blah.{key}.foo["goo"]["{key2}"][1]')
 * @param {string} path
 *
 * @return {string} converted string
 */
function convertPathToDottedStyle(path) {
  return path.replace(ARRAY_STYLE_PROPERTY_NAME_PATTERN, (match, p1) => `.${p1}`);
}

/**
 *
 * @param {object} config
 *
 * @return {string}
 */
export function getDisplayName(config) {
  if (!isObject(config) || typeof config === 'function') {
    throw new TypeError(`config parameter should be an object, "${typeof config}" given`);
  }

  const { displayName, path } = config;
  if (!(typeof path === 'string')) {
    throw new TypeError(`config.path should be a string, "${typeof path}" given`);
  }

  return displayName || convertPathToDottedStyle(clearKey(path));
}
