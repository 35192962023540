import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/most-popular-cars',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "best_selling_cars" */ '../pages/best-selling-cars/best-selling-cars-definition')
    ),
    chunkName: 'best_selling_cars',
  },
  {
    path: '/most-popular-car-colors',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "most_popular_car_colors" */ '../pages/most-popular-colors/most-popular-colors-definition')
    ),
    chunkName: 'most_popular_car_colors',
  },
  {
    path: '/car-loan-apr-interest-rate',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car_loan_apr_interest_rate" */ '../pages/car-loan-apr-interest-rate/car-loan-apr-interest-rate-definition')
    ),
    chunkName: 'car_loan_apr_interest_rate',
  },
];
