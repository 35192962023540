import { EdmundsAPI } from 'client/data/api/api-client';
import { getMetricsHeaders } from 'client/utils/metrics-header';
import { DEFAULT_LOCATION } from 'client/constants/location';

/**
 * Returns location for certain zip code
 * @param {String} zipCode
 * @param {Object} { pageName, venomVersion }
 * @returns {Promise}
 */
export function getLocationByZipCode(zipCode, { pageName, venomVersion }) {
  return EdmundsAPI.fetchJson(`/zip/v2/${zipCode}`, {
    headers: getMetricsHeaders('getLocationByZipCode', pageName, venomVersion),
  });
}

/**
 * Returns boolean if a user is in unknown zip state.
 * @param {Object} visitorLocation
 * @param {boolean} checkUserSet
 * @returns {Boolean}
 */
export function isUnknownZip(visitorLocation = {}, checkUserSet = true) {
  const { userSet, zipCode } = visitorLocation;
  return (!checkUserSet || userSet === false) && zipCode === DEFAULT_LOCATION.zipCode;
}
