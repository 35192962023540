import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes } from 'client/data/constants/active-makes';

export const routes = [
  {
    path: '/car-comparisons',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car-comparisons" */ '../pages/comparator/comparator-definition')
    ),
    chunkName: 'car-comparisons',
  },
  {
    path: '/inventory-comparisons',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "inventory-comparisons" */ '../pages/inventory-comparisons/inventory-comparisons')
    ),
    chunkName: 'inventory-comparisons',
  },
  {
    path: `/car-comparisons/:make1(${makes.join('|')})-:model1-vs-:make2(${makes.join('|')})-:model2`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "comparator_make_model_vs_make_model" */ '../pages/make-model-vs-make-model/make-model-vs-make-model')
    ),
    chunkName: 'comparator_make_model_vs_make_model',
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/trims`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "trims_comparator" */ '../pages/trims-comparator/trims-comparator-definition')
    ),
    chunkName: 'trims_comparator',
  },
];
