/**
 * This is a fork implementation of plural npm package available on npm:
 * https://www.npmjs.com/package/plural
 *
 * The reason of fork is significantly decreased bundle size by removing huge list of unused irregulars (please
 * see irregulars we may need below)
 */
const irregularPlurals = {
  basis: 'bases',
  child: 'children',
  criterion: 'criteria',
  diagnosis: 'diagnoses',
  index: 'indices',
  is: 'are',
  man: 'men',
  person: 'people',
  radius: 'radii',
  self: 'selves',
  that: 'those',
  this: 'these',
  woman: 'women',
  has: 'have',
  was: 'were',
};

const nonChangingPlurals = ['chassis', 'series', 'performance'];

/**
 * Checks for irregular plurals and return it or if there is no irregular - creates plural
 *
 * @example getPlural('woman') // women
 * @example getPlural('vehicle') // vehicles
 * @example getPlural('vehicle' , 1) // vehicle
 * @example getPlural('vehicle' , 2) // vehicles
 * @example getPlural('have', 'has' , 2) // has
 *
 * @param str {string} - word to make plural
 * @param [pluralValue] {string/number} - word to make plural from or counter
 * @param [counter] {string}
 * @param lowerCase {Boolean} - should lower case plural ending
 * @returns {*}
 */
export function getPlural(str, pluralValue, counter, lowerCase) {
  let plural = pluralValue;
  let count = counter;

  if (!str || typeof str !== 'string') {
    return '';
  }

  if (typeof plural === 'number') {
    count = plural;
  }

  if (nonChangingPlurals.includes(str.toLowerCase())) {
    return str;
  } else if (str in irregularPlurals) {
    plural = irregularPlurals[str];
  } else if (typeof plural !== 'string') {
    plural =
      str.slice(-1) === str.slice(-1).toUpperCase()
        ? `${str}${lowerCase ? 's' : 'S'}`
        : `${str.replace(/(?:s|x|z|ch|sh)$/i, '$&e').replace(/([^aeiou])y$/i, '$1ie')}s`.replace(/i?e?s$/i, m => {
            const isTailLowerCase = str.slice(-1) === str.slice(-1).toLowerCase();
            return isTailLowerCase || lowerCase ? m.toLowerCase() : m.toUpperCase();
          });
  }

  return count === 1 ? str : plural;
}

/**
 * Changes the last word in the string to plural
 *
 * @param str { string }
 * @returns {string}
 */
export function getLastWordPlural(str) {
  const wordsArray = str.split(' ');
  const pluralLastWord = getPlural(wordsArray.pop(), undefined, undefined, true);
  wordsArray.push(pluralLastWord);

  return wordsArray.join(' ');
}
