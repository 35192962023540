import { HTTP_ERROR_500, HTTP_NOT_FOUND } from 'client/utils/http-status';

/**
 * Create 500 error
 *
 * @param {message}
 * @return {Error}
 */
function create500Error(message) {
  const ex = new Error(message);
  ex.status = HTTP_ERROR_500;
  return ex;
}

/**
 * Update error with correct status
 *
 * @param {Error}
 * @return {Error}
 */
function get500Error(error) {
  const ex = error;
  ex.status = HTTP_ERROR_500;
  return ex;
}

/**
 * Handle received params and return correct Error object.
 * If error not passed - new error will be created with passed errorMessage.
 * @param {Error} error - existing error object
 * @param {String} errorMessage
 * @return {Error}
 */
export function handle500Error({ error, errorMessage }) {
  return error ? get500Error(error) : create500Error(errorMessage);
}

/**
 * Create 404 error
 *
 * @param {message}
 * @return {Error}
 */
export function create404Error(message) {
  const ex = new Error(message);
  ex.redirectPageTo404 = true;
  ex.status = HTTP_NOT_FOUND;
  return ex;
}

/**
 * Update error with correct status and redirect to 'Not found' page
 *
 * @param {Error}
 * @return {Error}
 */
function get404Error(error) {
  const ex = error;
  ex.redirectPageTo404 = true;
  ex.status = HTTP_NOT_FOUND;
  return ex;
}

/**
 * Handle received params and return correct Error object.
 * If error not passed - new error will be created with passed errorMessage.
 * @param {Error} error - existing error object
 * @param {String} errorMessage
 * @return {Error}
 */
export function handle404Error({ error, errorMessage }) {
  return error ? get404Error(error) : create404Error(errorMessage);
}
