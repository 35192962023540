const PLACEHOLDER_PATTERN = /\{([^\s.}]+)\}/g;
const PLACEHOLDER_REGEXP_NOSPACE = '([^\\s.]+)';
const PLACEHOLDER_REGEXP = '([^\\f\\n\\r\\t\\v\\]]+)'; // allow paths to contain spaces and dots within bracket syntax
const REGEXP_RESERVED = /[.*+?^${}()|[\]\\]/g;
const ENDING_REGEXP = '(?=\\.|\\[|$)';
const PATH_GROUP = '$path';

function processMatch(match, groupNames) {
  return match.reduce(
    (obj, value, index) => ({
      ...obj,
      [groupNames[index]]: value,
    }),
    {}
  );
}

function escape(regExpString) {
  return regExpString.replace(REGEXP_RESERVED, '\\$&');
}

function parsePathExpressionString(pathExpString) {
  const result = {
    regExp: '^',
    groupNames: [PATH_GROUP],
  };

  let lastIndex = 0;
  let match = PLACEHOLDER_PATTERN.exec(pathExpString);
  const isNonBracketSyntax = () => pathExpString.charAt(match.index - 1) === '.';
  while (match) {
    result.regExp += escape(pathExpString.substring(lastIndex, match.index));
    result.regExp += isNonBracketSyntax() ? PLACEHOLDER_REGEXP_NOSPACE : PLACEHOLDER_REGEXP;
    result.groupNames.push(match[1]);

    lastIndex = PLACEHOLDER_PATTERN.lastIndex;
    match = PLACEHOLDER_PATTERN.exec(pathExpString);
  }
  result.regExp += escape(pathExpString.substring(lastIndex)) + ENDING_REGEXP;

  result.regExp = new RegExp(result.regExp);
  return result;
}

/**
 * @typedef PathExpression
 * @type {Object}
 *
 * @property {function} test - Returns true/false whether the given path string matches the expression.
 * @property {function} exec - Matches the given path string against the expression and returns an object containing
 *   any placeholders mapped to their actual value from the path. Returns null if the path does not match.
 */
export function PathExpression(pathExpString) {
  const { regExp, groupNames } = parsePathExpressionString(pathExpString);

  this.test = function test(path) {
    return regExp.test(path);
  };

  this.exec = function exec(path) {
    const match = regExp.exec(path);
    if (match) {
      return processMatch(match, groupNames);
    }

    return null;
  };
}
