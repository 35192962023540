/**
 * For refresh rate limiter for adhesion banner
 */

export const DELAY_TIME = 1000;

export const getEnableRefreshAds = (latestRefresh, lastRefresh, delayTime) =>
  !lastRefresh || !delayTime || latestRefresh - lastRefresh > delayTime;

export const getDelayTime = (delayTime = DELAY_TIME, extraDelay = 0) => delayTime + extraDelay;
