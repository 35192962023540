import { PAGE_START_LOADING } from 'client/actions/constants';
import { getQuery } from 'client/utils/location';

export function locationReducer(state = {}, action = {}) {
  switch (action.type) {
    case PAGE_START_LOADING: {
      // fix for issue with hash in PageContext.location, EVAL-2247
      // return state.pathname === location.pathname && state.search === location.search && state.hash === location.hash

      const { location } = action.page;
      if (state.pathname === location.pathname && state.search === location.search) {
        // on a hard refresh the location from action will be same as one from server's initial state so skip update in that case
        return state;
      }
      const query = getQuery(location);
      return {
        ...state,
        ...location,
        query,
      };
    }
    default:
      return state;
  }
}
export function previousLocationReducer(state = {}, action = {}) {
  switch (action.type) {
    case PAGE_START_LOADING: {
      return {
        ...state,
        ...action.page.previousLocation,
      };
    }
    default:
      return state;
  }
}
