const BED_LENGTH_REGEX = /\d+'\d+”/;

export function getBedLengthValue(bedLength) {
  let bedLengthNumber = bedLength;
  if (typeof bedLength === 'string' && !bedLength.match(BED_LENGTH_REGEX)) {
    bedLengthNumber = parseFloat(bedLength);
  }

  if (!bedLengthNumber || typeof bedLengthNumber !== 'number') {
    return bedLength;
  }

  const feet = `${Math.floor(bedLengthNumber / 12)}'`;
  const inches = `${Math.round(bedLengthNumber % 12)}”`;
  return `${feet}${inches}`;
}
