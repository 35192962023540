import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { getModelState } from 'client/data/luckdragon/model';
import { getWrappedProps, getBindingPath } from './react-binding-helper';
import { resolveModel, updateModel } from './model-actions';

/**
 * Connects a React Component to model data using the given bindings.
 * Returns a wrapper component that passes model data into the connected component's props
 * according to the bindings.
 *
 * @param {class|function} component - The component to connect to the model.
 * @param {Object} modelBindings - An object mapping the component's props to the model.
 *
 * @returns {class}
 */
export function connectToModel(component, modelBindings) {
  // wrapper component
  class ModelWrapper extends Component {
    static propTypes = {
      loadModel: PropTypes.func.isRequired,
    };

    static contextType = ReactReduxContext;

    constructor(props) {
      super(props);

      this.componentInstance = null;

      this.loadingState = {
        isLoading: {},
      };
    }

    componentDidMount() {
      this.loadModel();
    }

    componentDidUpdate() {
      this.loadModel();
    }

    loadModel = () => {
      this.loadingState = this.props.loadModel(this.loadingState, this.props);
    };

    updateComponentInstance = instance => {
      this.componentInstance = instance;
    };

    wrapProps = props => {
      const wrap = getWrappedProps(props, this.context, modelBindings, component);

      return wrap;
    };

    render() {
      return React.createElement(component, this.wrapProps(this.props));
    }
  }

  function mapStateToProps(reduxState, ownProps) {
    const modelState = getModelState(reduxState);

    const modelProps = {};
    Object.keys(modelBindings).forEach(bindingProp => {
      const binding = modelBindings[bindingProp];
      const path = getBindingPath(binding, ownProps);

      if (path && path.length) {
        // once we have the path we can get its value
        modelProps[bindingProp] = modelState.get(path, binding.segment, false);
      }
    });
    return modelProps;
  }

  function mapDispatchToProps(dispatch) {
    return {
      loadModel: (loadingState, props) => {
        const newLoadingState = {
          isLoading: {},
        };

        const { isLoading } = loadingState;

        Object.keys(modelBindings).forEach(prop => {
          const binding = modelBindings[prop];
          const path = getBindingPath(binding, props);

          if (path && path.length) {
            const propValue = props[prop];

            if (
              typeof propValue === 'undefined' ||
              (typeof propValue === 'object' && propValue && propValue.$partial)
            ) {
              if (!isLoading[path]) {
                dispatch(resolveModel(path, binding.segment, component.name || component.type?.name));
              }

              newLoadingState.isLoading[path] = true;
            }
          }
        });

        return newLoadingState;
      },
      /**
       * Updates segment path value
       *
       * @param {string} path
       * @param {{}} segment ModelSegment
       * @param {*} value
       */
      setModelValue: (path, segment, value) => dispatch(updateModel(path, segment, value)),
    };
  }

  connectToModel.mapStateToProps = mapStateToProps;
  connectToModel.mapDispatchToProps = mapDispatchToProps;

  // delegate to redux
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModelWrapper);
}

/**
 * Binds a component prop to a specific path in the model.
 * The component will always receive model data from the given path.
 *
 * @param {string|function} path - The model path to bind to, or a function that generates the path given the props.
 * @param {ModelSegment} segment - The ModelSegment to bind to.
 * @param {function} [transform] - Optional transform function that should preprocess the model value.
 * @param {boolean} [resolveRefs] - Optional param for initiating ref resolution inside of object. Can be set to `false`
 *                                  as a performance enhancement if the path in a segment does not contain refs inside
 *                                  (by not cloning objects before saving references).
 */
export function bindToPath(path, segment, transform, resolveRefs = true) {
  return {
    segment,
    path,
    transform,
    resolveRefs,
  };
}

/**
 * Binds a component prop to a dynamic model path passed into the wrapper component as a prop.
 * The prop used by the wrapper component does not need to match the prop used by the inner component.
 *
 * @param {string} prop - The name of the wrapper component prop that will contain the model path.
 * @param {ModelSegment} segment - The ModelSegment to bind to.
 * @param {function} [transform] - Optional transform function that should preprocess the model value.
 * @param {boolean} [resolveRefs] - Optional param for initiating ref resolution inside of object. Can be set to `false`
 *                                  as a performance enhancement if the prop in a segment does not contain refs inside
 *                                  (by not cloning objects before saving references).
 */
export function bindToProp(prop, segment, transform, resolveRefs = true) {
  return {
    segment,
    prop,
    transform,
    resolveRefs,
  };
}
