import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';

export const routes = [
  {
    path: `/:make(${makes.join('|')})/:model/highlights`,
    exact: true,
    page: getLoadablePage(params =>
      import(/* webpackChunkName: "highlights_landing" */ '../pages/highlights/highlights-definition').then(page =>
        page.default({
          ...params,
          make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
        })
      )
    ),
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year/highlights`,
    exact: true,
    page: getLoadablePage(params =>
      import(/* webpackChunkName: "highlights_landing" */ '../pages/highlights/highlights-definition').then(page =>
        page.default({
          ...params,
          make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
        })
      )
    ),
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year/:submodel/highlights`,
    exact: true,
    page: getLoadablePage(params =>
      import(/* webpackChunkName: "highlights_landing" */ '../pages/highlights/highlights-definition').then(page =>
        page.default({
          ...params,
          make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
        })
      )
    ),
  },
];
