import { merge } from 'lodash';

const MEMBER_CHAR = '.';
const INDEXER_START_CHAR = '[';
const TERMINATOR_CHAR = '';
const ESCAPE_CHAR = '\\';
const WHITESPACE_CHAR = /^\s$/;
const DIGIT_CHAR = /^\d$/;

const IDENTIFIER_PATTERN_STR = '[^.[]+';
const INDEXER_PATTERN_STR = '(\\[\\s*"([^"]|\\\\")+"\\s*])|(\\[\\s*\'([^\']|\\\\\')+\'\\s*])|(\\[\\s*\\d+\\s*])';
const PATH_PATTERN = new RegExp(
  `^((${IDENTIFIER_PATTERN_STR})|${INDEXER_PATTERN_STR})((\\.${IDENTIFIER_PATTERN_STR})|${INDEXER_PATTERN_STR})*$`
);

export const pathTypes = {
  identifier: 1,
  string_literal: 2,
  numeric_literal: 3,
};

export const pathOperations = {
  member: 1,
  subscript: 2,
};

function JSONPathError(message) {
  return new Error(`JSONPath Error: ${message}`);
}

function getParser(pathStr) {
  let offset = 0;

  function advance() {
    offset += 1;
    return pathStr.charAt(offset);
  }

  function skipWhitespace() {
    while (WHITESPACE_CHAR.test(pathStr.charAt(offset))) {
      advance();
    }
  }

  function parseStringLiteral() {
    const literal = [];
    const quoteChar = pathStr.charAt(offset);

    let escape = false;
    let char = advance();
    while (char.length && (char !== quoteChar || escape)) {
      if (char === ESCAPE_CHAR && !escape) {
        escape = true;
      } else {
        literal.push(char);
        escape = false;
      }

      char = advance();
    }

    if (char === quoteChar) {
      advance();
    }

    return {
      value: literal.join(''),
      type: pathTypes.string_literal,
    };
  }

  function parseNumericLiteral() {
    const literal = [];

    let char = pathStr.charAt(offset);
    while (char.length && DIGIT_CHAR.test(char)) {
      literal.push(char);
      char = advance();
    }

    return {
      value: parseInt(literal.join(''), 10),
      type: pathTypes.numeric_literal,
    };
  }

  function parseLiteral() {
    const char = pathStr.charAt(offset);
    if (char === '"' || char === "'") {
      return parseStringLiteral();
    }

    return parseNumericLiteral();
  }

  function parseIdentifier() {
    const identifier = [];

    let char = pathStr.charAt(offset);
    while (char.length && char !== MEMBER_CHAR && char !== INDEXER_START_CHAR) {
      identifier.push(char);
      char = advance();
    }

    return {
      value: identifier.join(''),
      type: pathTypes.identifier,
    };
  }

  function parseMember() {
    advance(); // .
    return {
      ...parseIdentifier(),
      operation: pathOperations.member,
    };
  }

  function parseIndexer() {
    advance(); // [
    skipWhitespace();
    const literal = parseLiteral();
    skipWhitespace();
    advance(); // ]

    return {
      ...literal,
      operation: pathOperations.subscript,
    };
  }

  return {
    next() {
      switch (pathStr.charAt(offset)) {
        case TERMINATOR_CHAR:
          return null;
        case MEMBER_CHAR:
          return parseMember();
        case INDEXER_START_CHAR:
          return parseIndexer();
        default:
          return parseIdentifier();
      }
    },
  };
}

export function validatePath(pathStr) {
  return PATH_PATTERN.test(pathStr);
}

export function parsePath(pathStr) {
  if (!validatePath(pathStr)) {
    throw JSONPathError(`'${pathStr}' is not a valid path`);
  }

  const result = [];
  const parser = getParser(pathStr);
  let part = parser.next();
  while (part) {
    result.push(part);
    part = parser.next();
  }

  return result;
}

function getNodes(path, obj) {
  if (typeof obj !== 'object') {
    throw JSONPathError(`'${obj}' is not an object`);
  }

  const pathArr = typeof path === 'string' ? parsePath(path) : path;

  let parent = {
    parent: null,
    get() {
      return obj;
    },
    pathStr() {
      return '$';
    },
  };

  return pathArr.map(component => {
    const node = {
      parent,
      path: component,
      get() {
        const parentObj = this.parent.get();
        if (typeof parentObj === 'object' && parentObj) {
          return parentObj[this.path.value];
        }

        return undefined;
      },
      /**
       * sets a new value
       * @param note for future: value might contain non-enumerable $partial prop from prev call to markAsNotPartial()
       * so we should not have a { ...value } in this function
       */
      set(value) {
        const parentObj = this.parent.get();
        if (typeof parentObj === 'object') {
          const isArray = Array.isArray(parentObj);
          if (this.path.type === pathTypes.numeric_literal && !isArray) {
            throw JSONPathError(`Could not set value of '${this.pathStr()}', expected array but found object`);
          }

          // change must be non-destructive so go back up the hierarchy, creating new instances along the way
          if (this.parent.set) {
            const newParent = isArray ? [...parentObj] : { ...parentObj };
            if (parentObj && Object.prototype.hasOwnProperty.call(parentObj, '$partial')) {
              // non-enumerable props don't get copied with the ... operator, so do it manually
              Object.defineProperty(newParent, '$partial', {
                configurable: true,
                enumerable: false,
                value: parentObj.$partial,
              });
            }
            newParent[this.path.value] = value;

            this.parent.set(newParent);
          } else {
            // the root node can be assigned to directly
            parentObj[this.path.value] = value;
          }
        } else if (typeof parentObj === 'undefined') {
          const emptyParent = this.path.type === pathTypes.numeric_literal ? [] : {};
          Object.defineProperty(emptyParent, '$partial', {
            configurable: true,
            enumerable: false,
            value: true,
          });
          emptyParent[this.path.value] = value;

          this.parent.set(emptyParent);
        } else {
          throw JSONPathError(`Could not set value of '${this.pathStr()}', parent '${obj}' is not an object`);
        }
      },
      delete() {
        const parentObj = this.parent.get();

        // if parentObj diesn't exist we don't need to do anything
        if (typeof parentObj !== 'undefined') {
          this.set(undefined);
        }
      },
      pathStr() {
        const pathValue = this.path.type === pathTypes.string_literal ? `"${this.path.value}"` : this.path.value;
        const pathOperation = this.path.operation === pathOperations.subscript ? `[${pathValue}]` : `.${pathValue}`;
        return `${this.parent.pathStr()}${pathOperation}`;
      },
    };

    parent = node;
    return node;
  });
}

export function getValue(path, obj) {
  return getNodes(path, obj)
    .pop()
    .get();
}

/**
 * creates a copy of the @param obj, marks it as not partial, and returns it.
 * this function assumes it was called only in case obj had the $partial set to true, so it sets it to false
 * then it iterates over all props of obj and if and only if those have the $partial prop defined it sets them
 * to false.
 * in the end we have a redux state where if certain paths were $partial then became not $partial they will have
 * a $partial prop set to false, but if a certain path was never ever $partial it won't have this prop defined -
 * this distinction was made to help with debugging.
 *
 * @param obj
 * @returns {...*[]|*}
 */
function markAsNotPartial(obj) {
  if (!obj || typeof obj !== 'object') {
    // null, undefined, or anything other than object or array
    return obj;
  }
  const newObj = { ...obj };

  Object.defineProperty(newObj, '$partial', { configurable: true, enumerable: false, value: false });

  Object.keys(newObj).forEach(key => {
    const propVal = newObj[key];
    if (!propVal || typeof propVal !== 'object') {
      return;
    }
    if (Object.prototype.hasOwnProperty.call(propVal, '$partial')) {
      Object.defineProperty(propVal, '$partial', {
        configurable: true,
        enumerable: false,
        value: false,
      });
    }
  });
  return newObj;
}

/**
 *
 * @param path
 * @param value
 * @param obj
 * @returns {*}
 */
export function setValue(path, value, obj) {
  const pathNode = getNodes(path, obj).pop();
  if (value === undefined) {
    pathNode.delete();
  } else {
    const pathNodeVal = pathNode.get();
    let newVal = value;
    if (pathNodeVal && pathNodeVal.$partial) {
      newVal = merge({ ...pathNodeVal }, value);
      newVal = markAsNotPartial(newVal);
    }
    pathNode.set(newVal);
  }

  return obj;
}

/**
 * same as JSON.stringify() except it also serializes the private non-enumerable properties set by json-path
 * @param obj
 * @returns {string}
 */
export function serialize(obj) {
  return JSON.stringify(obj, (key, val) => {
    if (typeof val === 'object' && val && Object.prototype.hasOwnProperty.call(val, '$partial')) {
      // without the last `if` the parsing will break if obj contains a prop with empty string as the name i.e. { '': 123 }
      Object.defineProperty(val, '$partial', {
        configurable: true,
        enumerable: true,
        value: val.$partial,
      });
    }
    return val;
  });
}

function processUnserializedObjHelper(obj) {
  if (typeof obj === 'object' && obj) {
    if (Object.prototype.hasOwnProperty.call(obj, '$partial')) {
      Object.defineProperty(obj, '$partial', {
        configurable: true,
        enumerable: false,
        value: obj.$partial,
      });
    }
    Object.keys(obj).forEach(key => {
      obj[key] = processUnserializedObjHelper(obj[key]); // eslint-disable-line no-param-reassign
      // this is a recursive function making deep copy of the whole tree would be expensive
    });
  }
  return obj;
}
/**
 * processes an object retrieved from JSON.parse(str) where str was a string previously serialized with json-path:serialize()
 * @param obj the param itself will not be mutated
 * @returns {*} the transformed result
 */
export function processUnserializedObj(obj) {
  return processUnserializedObjHelper({ ...obj });
}
