import { UPDATE_SESSION } from './constants';

/**
 * Updates session id
 * @param {String} session - session id
 * @param {Boolean} isNewSession - true for first page of a new session
 */
export function updateSession({ session, isNewSession }) {
  return {
    type: UPDATE_SESSION,
    session,
    isNewSession,
  };
}
