import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: '/sold',
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "self-reported-sale" */ '../pages/self-reported-sale/self-reported-sale-definition')
  ),
  chunkName: 'self-reported-sale',
};
