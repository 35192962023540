import { UPDATE_DEALER, PAGE_UNLOAD } from 'client/actions/constants';

export function dealerReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_DEALER:
      return { ...action.dealer };
    case PAGE_UNLOAD:
      return {};
    default:
      return state;
  }
}
