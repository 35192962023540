import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/certified-cars',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "cpo_cars_landing" */ '../pages/index/index')),
    chunkName: 'cpo_cars_landing',
  },
];
