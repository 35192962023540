import { updateVersion } from 'client/actions/version';
// eslint-disable-next-line venom/no-venom-api-calls
import { VenomApi } from 'client/data/api/api-client';
import { TIME } from 'client/utils/time';

// 5 minutes delay
const DELAY = TIME.MS.FIVE_MINUTES;

function getClientSync() {
  // eslint-disable-next-line venom/no-venom-api-calls
  return VenomApi.fetchJson('/client-sync/');
}

export const ClientSyncEngagementHandler = {
  featureFlags: undefined,
  /**
   * Setups a polling mechanism that pings the client-sync API every 5 minutes
   *
   * @param  {Function} dispatch Dispatch app redux store actions
   * @return {void}
   */
  init({ dispatch }) {
    setInterval(
      () =>
        getClientSync()
          .then(response => {
            dispatch(updateVersion(response.version));
            this.featureFlags = response.featureFlags;
          })
          .catch(error => {
            if (error.message !== 'Failed to fetch') {
              throw error;
            } else {
              // eslint-disable-next-line no-console
              console.warn(error);
            }
          }),
      DELAY
    );
  },
  getFeatureFlags() {
    return this.featureFlags;
  },
};
