import { ADD_CREDIT_SCORE_DATA } from 'client/actions/constants';

export function carBuyingReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADD_CREDIT_SCORE_DATA:
      return {
        ...state,
        creditResponse: action.creditScore,
      };
    default:
      return state;
  }
}
