import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/calculators',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "calculators" */ '../pages/calculators-definition')),
    chunkName: 'calculators',
    enableLooseEtag: true,
  },
  {
    path: '/calculators/affordability.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_affordability" */ '../pages/calculators-affordability-definition')
    ),
    chunkName: 'calculators_affordability',
  },
  {
    path: '/calculators/simplified-pricing.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_loan_payment" */ '../pages/calculators-loan-payment-definition')
    ),
    chunkName: 'calculators_loan_payment',
  },
  {
    path: '/calculators/incentives-rebates.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_incentives_rebates" */ '../pages/calculators-incentives-rebates-definition')
    ),
    chunkName: 'calculators_incentives_rebates',
    enableLooseEtag: true,
  },
  {
    path: '/calculators/gas-guzzler.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_gas_guzzler" */ '../pages/calculators-gas-guzzler-definition')
    ),
    chunkName: 'calculators_gas_guzzler',
    enableLooseEtag: true,
  },
  {
    path: '/calculators/car-lease.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_lease" */ '../pages/calculators-lease-definition')
    ),
    chunkName: 'calculators_lease',
  },
  {
    path: '/calculators/lease-vs-buy-calculator',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "calculators_lease_vs_buy" */ '../pages/calculators-lease-vs-buy-definition')
    ),
    chunkName: 'calculators_lease_vs_buy',
    enableLooseEtag: true,
  },
];
