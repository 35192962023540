import { getSegment, getPathAndSegmentName } from './segment';

export function invalidatePath(path, modelState) {
  const { path: segmentPath, name: segmentName } = getPathAndSegmentName(path);
  const segment = getSegment(segmentName);

  if (modelState.get(segmentPath, segment, false) === undefined) {
    return Promise.resolve([]);
  }

  return (
    segment
      .update(segmentPath, undefined, modelState)
      // eslint-disable-next-line no-use-before-define
      .then(delta => invalidateDependentPaths(path, modelState).then(dependentDelta => [...delta, ...dependentDelta]))
  );
}

export function invalidateDependentPaths(path, modelState) {
  const metadata = modelState.getMetadata();
  const includesPath = pathToTest => pathToTest === path || pathToTest.includes(`${path}.`);

  return Promise.all(
    metadata.getPaths().reduce((result, metaPath) => {
      if (metaPath !== path) {
        const dependencies = metadata.getPathAttribute(metaPath, 'dependencies');
        if (dependencies && dependencies.some(includesPath)) {
          return [...result, invalidatePath(metaPath, modelState)];
        }
      }

      return result;
    }, [])
  ).then(deltas => deltas.reduce((finalDelta, delta) => [...finalDelta, ...delta], []));
}
