import { DFPManager } from '@edmunds/react-dfp';
import { logger } from 'client/utils/isomorphic-logger';

export const destroyPwtBids = {
  init: async (slotArray, googletag, refreshRule) => {
    const documentElement = document.documentElement;
    if (documentElement.scrollHeight <= documentElement.scrollTop + documentElement.clientHeight) return;
    destroyPwtBids.slotIds = slotArray.map(elem => elem.slotId);
    destroyPwtBids.slotArray = slotArray;
    destroyPwtBids.refreshRule.push(refreshRule);
    if (typeof window.PWT.removeKeyValuePairsFromGPTSlots === 'function') {
      window.PWT.initAdserverSet = false;

      // first remove already added OpenWrap targeting key value pairs from slots
      await window.PWT.removeKeyValuePairsFromGPTSlots(destroyPwtBids.getFilteredSlots(googletag.pubads().getSlots()));
      // now call OpenWrap to get bids and add respective key value pairs
      // destroyPwtBids.requestNewBid(window.googletag.pubads().getSlots());
      if (!destroyPwtBids.isInited) destroyPwtBids.requestNewBid(googletag.pubads().getSlots());
    } else {
      DFPManager.refresh(...destroyPwtBids.refreshRule);
    }
  },

  refreshRule: [],

  isInited: false,

  requestNewBid: pubadSlots => {
    destroyPwtBids.isInited = true;
    const filteredSlots = destroyPwtBids.getFilteredSlots(pubadSlots);
    const adSizeFilteredConfForGPT = destroyPwtBids.getAdSizeFilteredConf(window.PWT.generateConfForGPT(filteredSlots));
    window.PWT.requestBids(adSizeFilteredConfForGPT, destroyPwtBids.onLoadCallback);
  },

  onLoadCallback: adUnitsArray => {
    try {
      window.PWT.addKeyValuePairsToGPTSlots(adUnitsArray);
    } catch (error) {
      logger('warn', 'Slots did not get key value pairs added properly', error);
    }
    DFPManager.refresh(...destroyPwtBids.refreshRule);
    destroyPwtBids.isInited = false;
    destroyPwtBids.refreshRule = [];
  },

  getFilteredSlots: slots => slots.filter(slot => destroyPwtBids.slotIds.includes(slot.getSlotElementId())),

  getAdSizeFilteredConf: configs =>
    configs.map((slot, index) => {
      const slotObj = slot;
      const { slotId, divId, adUnitId, adUnitIndex, sizes } = destroyPwtBids.slotArray[index];
      slotObj.code = slotId;
      slotObj.divId = divId;
      slotObj.adUnitId = adUnitId;
      slotObj.adUnitIndex = adUnitIndex;
      slotObj.sizes = sizes;
      slotObj.mediaTypes.banner.sizes = sizes;
      return slotObj;
    }),
};
