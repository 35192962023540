import { LOCATION_CHANGE } from 'client/actions/constants';
import { getReduxModelStateFromStore } from 'client/data/luckdragon/redux/redux-model';
import { UrlModel, URL_CONTEXT_PATH } from 'client/data/models/url';
import { VisitorModel } from 'client/data/models/visitor';
import { XHeaders } from 'client/data/models/x-headers';
import { isParseableUrl, parseUrl } from './parseable-url';
import { isPageChanged } from './location';

/**
 * When a SPA transition is initiated, this code handles the creation the urlContext in the redux state,
 * which lives at the root of the redux state under `url`.
 *
 * The type of SPA transition that will execute the truthy case of this function is when a user clicked on a SPA <Link>
 * (ie. LOCATION_CHANGE action is dispatched) and the updated location is a new page.
 *
 * Below is example of the parse api (which is served by url-rest-web) that gets called by this method:
 * https://qa-11-www.edmunds.com/api/url/v2/parse?url=/ford/f-150/2021/raptor/
 */
export const urlContextMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    const prevLocation = store.getState().pageContext.location;
    const modelState = getReduxModelStateFromStore(store);

    if (isPageChanged(prevLocation, action.payload.location)) {
      const url = action.payload.location.pathname;
      const shouldCallParseApi = action.payload.shouldCallParseApi;

      // now that we have shouldCallParseApi, we would like to move away from the use of isParseableUrl(url) which is
      // very difficult to maintain and reason about because it is a complex regex
      if (isParseableUrl(url) || shouldCallParseApi) {
        const xTraceId = modelState.get('x-trace-id', XHeaders);
        return parseUrl({
          url,
          onResponseCb: response => {
            if (response.attributes && response.attributes.nationalScope) response.attributes.nationalScope = false;

            modelState.update(URL_CONTEXT_PATH, UrlModel, response).then(() => {
              if (response.location) {
                modelState.update('location', VisitorModel, response.location);
              }
            });
          },
          finallyCb: () => next(action),
          xTraceId,
        });
      }

      return modelState.update(URL_CONTEXT_PATH, UrlModel, undefined).then(() => next(action));
    }
  }

  return next(action);
};
