import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { isNode } from 'client/utils/environment';
import { logger } from 'client/utils/isomorphic-logger';
import { changeForcedAbParameter, changeForcedAxDimensions, registerExperimentOnPage } from 'client/actions/experiment';
import { trackAxExperimentPixel, trackVenomExperimentPixel } from './helpers/track-pixel';
import { isCampaignActiveByFilters } from './helpers/utils';

// todo eslint rule to disallow any method in this file to contain word "campaign", use "experiment" instead

// todo eslint rule to disallow any invocation of ExperimentUtil.debug.anything() except by plat team

const wtfEnabled = state => {
  if (!state.featureFlags || !Object.prototype.hasOwnProperty.call(state.featureFlags, 'wtf')) {
    // return true to maintain backword compatibility with existing tests
    return true;
  }
  return state.featureFlags.wtf;
};
const wtfabdebugEnabled = state => state.wtfabdebug;
let getStateOnClient;
let dispatchOnClient;
let trackedCampaigns;

const emptyArray = [];

/**
 * @example { campaign1: { activeAndEligible: true } }
 * @type {{}}
 */
let pageExperiments = {};

let self;

let isInitialized = false;

function log(...args) {
  if (getStateOnClient && wtfabdebugEnabled(getStateOnClient())) {
    logger('debug', ...args);
  }
}

/**
 * adds campaign/recipe name to speeducrve
 * @param state
 * @param campaignName
 */
function addRecipeToLux(state, campaignName, recipeName) {
  if (window.LUX) {
    window.LUX.addData(`exp-${campaignName}`, recipeName);
  }
}

function getAxCandidate(publishedCampaign, recipeName) {
  return (publishedCampaign && publishedCampaign.candidates && publishedCampaign.candidates[recipeName]) || null;
}
/**
 * Handles tracking pixels for experiments.
 *
 * @param {Object} targetCampaign Experiment's campaign.
 * @param {string} recipeName
 */
function handleExperimentPixel(targetCampaign, recipeName) {
  const type = targetCampaign.type.toUpperCase();
  if (type === 'VENOM') {
    trackVenomExperimentPixel(targetCampaign, recipeName);
  } else if (type === 'AX') {
    const axCandidate = getAxCandidate(targetCampaign, recipeName);
    trackAxExperimentPixel(targetCampaign, recipeName, axCandidate);
  }
}

function handlePageUnload() {
  pageExperiments = {};
  trackedCampaigns.clear();
}

/**
 * Handles page update event and re-fires tracking pixels of activated campaigns.
 */
function handlePageUpdate() {
  const state = getStateOnClient();
  trackedCampaigns.forEach(name => {
    const targetCampaign = state.experiments.published[name];
    const campaignName = targetCampaign.name;
    handleExperimentPixel(targetCampaign, self.getAssignedRecipeName({ campaignName }));
  });
}

/**
 * @returns {boolean} true if experiment is
 * - bucketed to a recipe - i.e. published with ctrl 1%, chal 1%, and not bucketed to any of recipes is considered not active
 */
function isExperimentActive({ publishedCampaign, computedCampaign }) {
  if (!publishedCampaign || !computedCampaign) {
    return false;
  }
  return (
    computedCampaign.recipe.value &&
    (computedCampaign.recipe.value !== '_default' && computedCampaign.recipe.value !== '_DEFAULT_ARM_')
  );
}

/**
 * returns assigned (aka computed aka bucketed) recipe name from redux
 * note that this returns assigned, not forced, value
 * @param state
 * @param campaignName
 * @param defaultVal
 * @returns {*|string|boolean}
 * will return defaultVal = false in any of the following cases
 * - experiment is not published
 * - experiment filters (e.g. make, model, dma) were not a match for current page/user
 * - finally, if none of the recipes were bucketed, - e.g. experiment was configured with
 *   ctrl 1%, and chal 0%
 *
 * otherwise will return the {string} name of the bucketed recipe
 */
function getAssignedRecipeName({ state, campaignName, defaultVal, isPageExperiment }) {
  if (state.forceDefault) {
    return defaultVal;
  }

  const publishedCampaign = state.experiments.published[campaignName];
  if (!publishedCampaign) {
    return defaultVal; // experiment not in published means it's not active
  }

  const computedCampaign = state.experiments.computed[campaignName];

  const active = isExperimentActive({ state, publishedCampaign, computedCampaign });
  if (!active) {
    return defaultVal;
  }

  const eligible = isCampaignActiveByFilters(publishedCampaign, state, isPageExperiment);
  if (!eligible) {
    return defaultVal;
  }
  return computedCampaign.recipe.value;
}

/**
 *
 * @param state
 * @param campaignName
 * @param usedRecipeName i.e. the recipe that got rendered by <Experiment />
 * @param {boolean} isPageExperiment the pageExperiment prop from <Experiment />
 *
 */
function markRecipeUsed({ campaignName, usedRecipeName, isPageExperiment }) {
  const state = getStateOnClient();
  const computedCampaign = state.experiments.computed[campaignName];
  const publishedCampaign = state.experiments.published[campaignName];
  const isPublished = !!publishedCampaign;

  const isEligible = isCampaignActiveByFilters(publishedCampaign, state, isPageExperiment);

  if (!isEligible && trackedCampaigns.has(campaignName)) {
    // campaignName was been tracked which means it has been isEligible at some point
    // now it has become not isEligible (i.e. page vehicle changed dynamically )
    // we should remove this from being considered tracked, so that next time
    // it becomes isEligible again we want the tracking pixel to fire again
    trackedCampaigns.delete(campaignName);
  }

  const isActive = isExperimentActive({ state, publishedCampaign, computedCampaign });
  // e.g. isPublished with ctrl 1%, chal 1%, and not bucketed to any of recipes is considered not isActive

  if (wtfabdebugEnabled(state)) {
    // fire redux action so panel can update
    const pe = pageExperiments[campaignName];
    const alreadyDispatchedForTheseParams =
      pe && pe.isPublished === isPublished && pe.isEligible === isEligible && pe.isActive === isActive;
    if (!alreadyDispatchedForTheseParams) {
      // storing calculated values, but not copying anything else from computed etc, less to keep in sync
      pageExperiments[campaignName] = { isPublished, isActive, isEligible, isPageExperiment };
      // dispatch action so experiment panel can update
      dispatchOnClient(registerExperimentOnPage(campaignName, isPublished, isEligible, isActive, isPageExperiment));
    }
  }

  // storing calculated values, but not copying anything else from computed etc, less to keep in sync
  pageExperiments[campaignName] = { isPublished, isActive, isEligible, isPageExperiment };

  const isPixelTrackedAlready = trackedCampaigns.has(campaignName);
  let doTrack = isPublished && isEligible && isActive && !isPixelTrackedAlready;
  let trackingInfo = publishedCampaign;
  if (!isPublished || !isActive) {
    const isForceAssigned = self.getForcedRecipeName({ state, campaignName });
    if (isForceAssigned) {
      doTrack = true;
      trackingInfo = { ...publishedCampaign, name: campaignName, type: 'VENOM' };
    }
  }
  if (doTrack) {
    handleExperimentPixel(trackingInfo, usedRecipeName);
    addRecipeToLux(state, campaignName, usedRecipeName);
    trackedCampaigns.add(campaignName);
    log(`experiment-engagement-handler:handleRegisterExperimentOnPage() tracking trigered for ${campaignName}`);
  }
}

/**
 * ExperimentUtil - venom's interface for interaction with wtf
 * - handles firing of tracking pixels, adding experiments info to LUX, wtf related cookies
 * - getters from this should be used for anything and everything instead of reading from redux directly
 */
export const ExperimentUtil = {
  /**
   * helpers for debugging - e.g. wtfabdebugpanel
   */
  debug: {
    /**
     * Returns current campaigns registered on the page by markRecipeUsed() - wether published or not.
     *
     * Used by experiments panel, also meant to be used for troubleshooting purposes.
     *
     * please do not use this in production facing components - e.g. when wtfabdebug is disabled this will
     * always return same object reference so using it inside of mapStateToProps will not work as expected
     */
    getExperimentsOnPage(state = getStateOnClient()) {
      if (!wtfEnabled(state)) {
        return state.experiments.page; // this is initialized to {} in default controller
      }
      if (wtfabdebugEnabled(state)) {
        // when panel is enabled this info is saved in redux to trigger the panel updates
        return state.experiments.page; // immutable object
      }
      return pageExperiments; // mutable object (aka not immutable)
    },

    /**
     * Returns current active campaigns on the page with recipe name.
     * used only by AdsContext.
     * Please be wary of using this method, it will return a different instance
     * each time it is invoked, if not used properly this can cause unnecessary rerenders
     * should not be used inside of mapStateToProps
     * @returns {[]}
     */
    getVenomAssignmentsOnPage(state = getStateOnClient()) {
      if (!wtfEnabled(state)) {
        return emptyArray;
      }
      const venomCampaignNames = Object.keys(pageExperiments).filter(
        campaignName =>
          (
            (state.experiments.published[campaignName] && state.experiments.published[campaignName].type) ||
            ''
          ).toUpperCase() === 'VENOM' || self.getForcedRecipeName({ campaignName })
      );
      return venomCampaignNames.map(campaignName => {
        const forcedOrAssignedRecipeName = ExperimentUtil.getForcedOrAssignedRecipeName({ campaignName });
        return {
          campaignName,
          forcedOrAssignedRecipeName,
        };
      });
    },

    forceAxDimensions(campaignName, dimensions, dispatch = dispatchOnClient) {
      log(
        `experiment-engagement-handler:forceAxRecipeDimensions() campaignName:${campaignName}, dimensions: ${dimensions}`
      );
      dispatch(changeForcedAxDimensions(campaignName, dimensions));
    },

    /**
     * forces specified recipe on specified campaign
     * called by wtf debug panel on checkbox selections and also
     * on the server side when parsing the url params
     *
     * this method will work only on published campaigns
     *
     * hard refresh with fassignment param will work on unpublished campaigns as well cause
     * those are parsed on server side outside of the eng handler
     *
     * @param campaignName
     * @param recipeName
     */
    forceRecipe(campaignName, recipeName, dispatch = dispatchOnClient) {
      log(`experiment-engagement-handler:markRecipeUsed() campaignName:${campaignName}, recipeName: ${recipeName}`);
      dispatch(changeForcedAbParameter(campaignName, 'recipe', recipeName));
    },
  },

  /**
   * returns computed (aka bucketed) recipe name for a given campaign. prerequisites are
   * - campaign with campaignName is published
   * - campaign is active by filters
   *
   * @param state Redux store state.
   * @param {String} campaignName e.g. 'my-campaign'
   * @param {String} defaultVal default value if no recipe was assigned - e.g. wtf dashboard allocation
   * of ctrl: 1%, chal: 1% and none of these were bucketed
   * @returns {String} computed (aka bucketed) recipe name for the given campaign. - e.g. 'chal'
   *
   * @example ExperimentUtil.getAssignedRecipeName('my-campaign') // returns 'ctrl' or 'chal'
   */
  getAssignedRecipeName({ state = getStateOnClient(), campaignName, isPageExperiment = false, defaultVal = false }) {
    if (!wtfEnabled(state)) {
      return false;
    }
    return getAssignedRecipeName({ state, campaignName, isPageExperiment, defaultVal });
  },

  /**
   * Returns published Ax Candidate for the given campaign and recipe
   * @param state
   * @param campaignName
   * @param recipeName
   * @returns {Object}
   *
   * @example
   * getAxCandidate({ campaignName: 'my-ax-campaign', recipeName: 'chal-325958-10' })
   * => {
   *   candidateId: '3fce4542-11ff-46a3-bb8c-52ae48acc734',
   *   candidateName: 'chal-325958-10',
   *   dimensions: {
   *     activeTab: 'type_tab',
   *     buttonText: 'search',
   *   },
   * }
   */
  getAxCandidate({ state = getStateOnClient(), campaignName, recipeName }) {
    return getAxCandidate(state.experiments.published[campaignName], recipeName);
  },

  /**
   * Returns object that contains computed parameters for each campaign.
   * from Redux.
   * @return {Object} computed parameters for each campaign.
   */
  getComputedExperiments(state = getStateOnClient()) {
    // no check for wtfEnabled cause this prop is initialized in default controller
    return state.experiments.computed;
  },

  getForcedAxDimensions({ state = getStateOnClient(), campaignName }) {
    const computed = state.experiments.computed[campaignName];
    return (computed && computed.forcedDimensions) || null;
  },

  getForcedRecipeName({ state = getStateOnClient(), campaignName }) {
    if (!wtfEnabled(state)) {
      return false;
    }
    const campaign = state.experiments.computed[campaignName];
    return campaign && campaign.recipe && campaign.recipe.forced ? campaign.recipe.forced : false;
  },

  getForcedOrAssignedRecipeName({
    state = getStateOnClient(),
    campaignName,
    isPageExperiment = false,
    defaultVal = false,
  }) {
    if (!wtfEnabled(state)) {
      return defaultVal;
    }

    return (
      self.getForcedRecipeName({ state, campaignName }) ||
      getAssignedRecipeName({ state, campaignName, isPageExperiment, defaultVal })
    );
  },

  getForcedOrAssignedAxRecipe({ state = getStateOnClient(), campaignName }) {
    const assignedRecipeName = self.getAssignedRecipeName({ state, campaignName });
    const forcedRecipeName = self.getForcedRecipeName({ state, campaignName });
    const recipeName = forcedRecipeName || assignedRecipeName;
    const candidate = self.getAxCandidate({ state, campaignName, recipeName });
    const publishedDimensions = (candidate && candidate.dimensions) || null;
    const dimensions = self.getForcedAxDimensions({ state, campaignName }) || publishedDimensions;

    return {
      recipeName,
      dimensions,
    };
  },

  /**
   * Returns active campaigns from redux store
   * @return {Object} active campaigns data.
   */
  getPublishedExperiments(state = getStateOnClient()) {
    // no check for wtfEnabled cause this prop is initialized in default controller
    return state.experiments.published;
  },

  /**
   * Returns all recipe names for the campaign with the given name.
   *
   * @param {Object} state  Current Redux store state.
   * @param {string} campaignName  target campaign name.
   * @return {Array} all recipe names for the campaign with the given name.
   */
  getRecipeNames({ state = getStateOnClient(), campaignName }) {
    if (!wtfEnabled(state)) {
      return emptyArray;
    }
    const campaign = this.getPublishedExperiments(state)[campaignName];
    return (campaign && campaign.recipeNames) || emptyArray;
  },

  /**
   * Initializes experiment engagement handler.
   * called in app init on client, page owners should never call this method
   *
   * on server init() is not called, and methods of this singleton are used as stateless
   * utility functions - by passing state/dispatch as needed to every call
   *
   * @param getState
   * @param dispatch
   */
  init(getState, dispatch) {
    if (isNode()) {
      throw new Error('ExperimentUtil.init() should not be called on server side');
    }
    getStateOnClient = getState;
    dispatchOnClient = dispatch;
    const state = getState();

    if (!wtfEnabled(state)) {
      return;
    }

    pageExperiments = {};
    trackedCampaigns = new Set();

    EventToolbox.on(PAGE_EVENTS.PAGE_UNLOAD, handlePageUnload);
    EventToolbox.on(PAGE_EVENTS.PAGE_UPDATE, handlePageUpdate);
    window.EDM.Venom.ExperimentUtil = this;
    isInitialized = true;
  },

  /**
   * this was added for unit tests, page owners would not need to use this in production code
   * @returns {boolean}
   */
  isInitialized() {
    return isInitialized;
  },

  /**
   * this will fire the tracking pixels, and add the campaign info to LUX
   * called by <Experiment /> automatically
   *
   * when using an experiment outside of react, e.g. in EJS template, you should call this method yourself
   *
   * note this method is intended to be invoked only on client side
   *
   * @example say you used 'chal' recipe of 'test-123' experiment in EJS. To ensure tracking pixels fire accordingly,
   * you would need to add the following script block anywhere after the call to `webappStart();`, we recommend adding it
   * immediately before the closing `</body>` tag
   * <script>
   *   window.EDM.Venom.ExperimentUtil.markRecipeUsed({ campaignName: 'test-123', usedRecipeName: 'chal'})
   * </script>
   *
   * @param {string} campaignName
   * @param {string} recipeName
   * @param {boolean} isPageExperiment the pageExperiment prop from <Experiment />
   */
  markRecipeUsed({ campaignName, usedRecipeName, isPageExperiment = false }) {
    if (!wtfEnabled(getStateOnClient())) {
      return;
    }
    markRecipeUsed({ campaignName, usedRecipeName, isPageExperiment });
  },
};

self = ExperimentUtil;
