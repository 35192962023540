import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  // 0
  path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/cost-to-own`,
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "cost_to_own" */ '../pages/cost-to-own/cost-to-own-definition')
  ),
  chunkName: 'cost_to_own',
};
