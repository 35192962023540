import { combineReducers } from 'redux';
import { emptyReducer } from 'client/reducers/empty-reducer';
import { pageReducer } from './page-reducer';
import { adsContextReducer } from './ads-context-reducer';
import { vehicleReducer } from './vehicle-reducer';
import { legacyPageReducer } from './legacy-page-reducer';
import { dealerReducer } from './dealer-reducer';
import { extTagAttributeReducer } from './external-tag-attribute-reducer';
import { scrollPropsReducer } from './scroll-props-reducer';
import { breakpointReducer, isSmallReducer } from './breakpoint-reducer';
import { serverReducer } from './server-reducer';
import { loadingReducer } from './loading-reducer';
import { locationReducer, previousLocationReducer } from './location-reducer';
import { isAppExtensionPageReducer } from './app-extension-reducer';

/**
 * Page context reducer
 *
 * @type {Function}
 */
export const pageContextReducer = combineReducers({
  ads: adsContextReducer,
  apiGatewayInvalidate: (state = false) => state,
  breakpointDeprecated: breakpointReducer,
  breakpointDeprecated2: breakpointReducer,
  dealer: dealerReducer,
  externalTagAttribute: extTagAttributeReducer,
  isSmallDeprecated: isSmallReducer,
  isSmallDeprecated2: isSmallReducer,
  isAppExtensionPage: isAppExtensionPageReducer,
  legacy: legacyPageReducer,
  loading: loadingReducer,
  location: locationReducer,
  page: pageReducer,
  previousLocation: previousLocationReducer,
  server: serverReducer,
  scrollProps: scrollPropsReducer,
  vehicle: vehicleReducer,
  sharedValues: emptyReducer,
});
