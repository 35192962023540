import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "test_microsite" */ '../pages/demo/demo')),
    chunkName: 'test_microsite',
  },
];
