export const ENTRY_RENDER_TYPE = {
  AD: 'ad',
  ARTICLE_MENU: 'article-menu',
  CARD: 'card',
  HTML: 'html',
  PHOTO: 'photo',
  RELATED_CONTENT: 'related-content',
  VIDEO: 'video',
  VIDEOS: 'videos',
  TABLE: 'table',
  ARTICLE_OFFERS_AD: 'article-offers-ad',
};

export const ENTRY_ID = {
  AUTHOR: 'author',
  FAQ: 'faq',
  HERO_CONTENT: 'hero-content',
  COMPARE: 'compare',
  PRICING_CARDS: 'pricing-cards',
};

export const FULL_WIDTH_ENTRIES = [ENTRY_ID.PRICING_CARDS];

export const HERO_PHOTO_SIZE = {
  MOBILE: 500,
  DESKTOP: 1280,
};

export const BUTTONS_DEFAULT_TITLE = {
  PRIMARY: 'See all for sale',
  SECONDARY: 'Pricing & Reviews',
};

export const COMPARE_MODULE_PHOTO_SIZE = 175;

// 4 lines of 16px text
export const TRUNCATED_HTML_HEIGHT = 85;

export const DATA_POINTS_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const CONTENT_COLLAPSE_STATE = {
  FULL: 'full',
  PARTIAL: 'partial',
  NONE: 'none',
};

export const RELATED_CONTENT_CONFIG = {
  ASPECT_RATIO: '3:2',
  ITEM_CLASS_NAME: 'mt-2',
  PHOTO_SIZE: 300,
  COLUMN_NUMBER: {
    MOBILE: 2,
    DESKTOP: 3,
  },
  MAX_CONTENT_LENGTH: {
    MOBILE: 6,
    DESKTOP: 6,
  },
};

export const VEHICLE_CARD_PHOTO_SIZE = {
  width: 300,
  height: 200,
};

export const VEHICLE_CAROUSEL_PHOTO_WIDTH = 1280;
