import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: '/shopcars',
  exact: true,
  page: getLoadablePage(
    () => import(/* webpackChunkName: "home_page" */ '../pages/home/home'),
    () => ({ pageName: 'home_page_shopcars', ads: { sect8: 'shopcars' } })
  ),
  chunkName: 'home_page',
};
