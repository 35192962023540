import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { isFullUrl, isRelativeUrl, isFullUrlWithoutProtocol } from 'site-modules/shared/utils/is-relative-url';

const HOST_PROTOCOL = 'https:';

export function getBreadcrumbUrl(url) {
  let breadcrumbUrl = url;
  if (isRelativeUrl(url)) {
    breadcrumbUrl = `${HOST_PROTOCOL}//www.edmunds.com${url}`;
  } else if (isFullUrlWithoutProtocol(url)) {
    breadcrumbUrl = `${HOST_PROTOCOL}//${url}`;
  } else if (!isFullUrl(url)) {
    breadcrumbUrl = `${HOST_PROTOCOL}${url}`;
  }
  return breadcrumbUrl;
}

/**
 * Populate single jsonLd breadcrumb list item
 *
 * @param {{title: string, uri: string}} breadcrumb - breadcrumb object available for destructuring
 * @param {number} index - index of breadcrumb in array of breadcrumbs
 * @return {{position: number, item: {name: string}}}
 */
function getBreadcrumbListItem(breadcrumb, index) {
  const { title, href } = breadcrumb;
  return {
    '@type': 'ListItem',
    position: index + 1, // position should start at 1
    item: {
      '@id': href ? `${getBreadcrumbUrl(href)}` : '',
      name: title,
    },
  };
}

/**
 * Populate jsonLd schema for breadcrumbs data
 *
 * @param {[]} breadcrumbs - array of breadcrumb links available for populating schema list items
 * @return {{itemListElement: []}}
 */
export function getBreadcrumbSchema(breadcrumbs = []) {
  return (
    Array.isArray(breadcrumbs) &&
    !!breadcrumbs.length && {
      '@context': SCHEMA_URL,
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map(getBreadcrumbListItem),
    }
  );
}
