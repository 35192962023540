// Everything is in Alphabetical Order
export const AUTO_INSURANCE = 'auto-insurance';
export const AUTO_SHOWS = 'auto-shows';
export const AUTO_WARRANTY = 'auto-warranty';
export const BEST_FAMILY_CARS = 'best-family-cars';
export const BEST_USED_CARS = 'best-used-cars';
export const CAR_AWARDS = 'car-awards';
export const CAR_BUYING = 'car-buying';
export const CAR_CARE = 'car-care';
export const CAR_LEASING = 'car-leasing';
export const CAR_LOAN = 'car-loan';
export const CAR_MAINTENANCE = 'car-maintenance';
export const CAR_NEWS = 'car-news';
export const TEST_CAR_NEWS = 'test-car-news';
export const CAR_PICTURES = 'car-pictures';
export const CAR_REVIEWS = 'car-reviews';
export const CAR_SAFETY = 'car-safety';
export const CAR_SUBSCRIPTION_SERVICE = 'car-subscription-service';
export const CAR_TECHNOLOGY = 'car-technology';
export const CAR_VIDEOS = 'car-videos';
export const DRIVING_TIPS = 'driving-tips';
export const FUEL_ECONOMY = 'fuel-economy';
export const HOW_TO = 'how-to';
export const LUXURY = 'luxury';
export const NEW_CAR_RATINGS = 'new-car-ratings';
export const PRE_PRODUCTION_MODELS = 'pre-production-models';
export const CERTIFIED_CARS = 'certified-cars';
export const ROADNOISE = 'roadnoise';
export const SELL_CAR = 'sell-car';
export const SUV_LEASE_DEALS = 'best-suv-lease-deals';
export const SEDAN_LEASE_DEALS = 'best-sedan-lease-deals';
export const LUXURY_LEASE_DEALS = 'best-luxury-lease-deals';
export const BEST_CARS_UNDER_20K = 'best-cars-under-20k';
export const TOP_10 = 'top-10';
export const VEHICLES = 'vehicles';
export const RESEARCH = 'research';
export const LONG_TERM_ROAD_TEST = 'long-term-road-test';
export const USED_CAR_RATINGS = 'used-car-ratings';

export const RESEARCH_ARTICLE_LANDING_SLUGS = [
  AUTO_SHOWS,
  AUTO_WARRANTY,
  CAR_AWARDS,
  CAR_CARE,
  CAR_LOAN,
  CAR_PICTURES,
  CAR_REVIEWS,
  CAR_SUBSCRIPTION_SERVICE,
  ROADNOISE,
  TOP_10,
];

export const RESEARCH_ARTICLE_SLUGS = [
  AUTO_SHOWS,
  AUTO_WARRANTY,
  CAR_AWARDS,
  CAR_CARE,
  CAR_LOAN,
  CAR_PICTURES,
  CAR_REVIEWS,
  CAR_SUBSCRIPTION_SERVICE,
  CAR_VIDEOS,
  NEW_CAR_RATINGS,
  ROADNOISE,
  TOP_10,
];

// Slugs which has updated atom files for landing pages (CORE-1841) to have a similar template to /car-news/, unlike those in RESEARCH_ARTICLE_SLUGS.
export const RESKINNED_RESEARCH_ARTICLE_SLUGS = [
  AUTO_INSURANCE,
  CAR_BUYING,
  CAR_LEASING,
  CAR_MAINTENANCE,
  CAR_NEWS,
  TEST_CAR_NEWS,
  CAR_SAFETY,
  CAR_TECHNOLOGY,
  CAR_VIDEOS,
  DRIVING_TIPS,
  FUEL_ECONOMY,
  HOW_TO,
  PRE_PRODUCTION_MODELS,
  CERTIFIED_CARS,
  USED_CAR_RATINGS,
];

export const RESEARCH_ARTICLE_SLUGS_FOR_MULTILEVEL_CATEGORY_LANDING = [CAR_REVIEWS, CAR_TECHNOLOGY];

export const BEST_OF_SLUGS = [
  BEST_FAMILY_CARS,
  BEST_USED_CARS,
  LUXURY,
  SUV_LEASE_DEALS,
  SEDAN_LEASE_DEALS,
  LUXURY_LEASE_DEALS,
  VEHICLES,
  BEST_CARS_UNDER_20K,
];

export const SLUGS = {
  AUTO_INSURANCE,
  AUTO_SHOWS,
  AUTO_WARRANTY,
  BEST_FAMILY_CARS,
  BEST_USED_CARS,
  CAR_AWARDS,
  CAR_BUYING,
  CAR_CARE,
  CAR_LEASING,
  CAR_LOAN,
  CAR_MAINTENANCE,
  CAR_NEWS,
  CAR_PICTURES,
  CAR_REVIEWS,
  CAR_SAFETY,
  CAR_SUBSCRIPTION_SERVICE,
  CAR_TECHNOLOGY,
  CAR_VIDEOS,
  DRIVING_TIPS,
  FUEL_ECONOMY,
  HOW_TO,
  LUXURY,
  NEW_CAR_RATINGS,
  PRE_PRODUCTION_MODELS,
  ROADNOISE,
  SUV_LEASE_DEALS,
  TOP_10,
  VEHICLES,
};
