import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: '/profile/auth-action',
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "profile-auth-action" */ '../pages/auth-action/auth-action-page')
  ),
  chunkName: 'profile-auth-action',
};
