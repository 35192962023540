import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const typeBeforeBuyRoutes = [
  {
    path: '/:type/before-buy.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "type-before-buy" */ '../pages/type-before-buy/type-before-buy-definition')
    ),
    chunkName: 'type-before-buy',
  },
];
