import { createStore } from 'redux';
import { getModelState } from 'client/data/luckdragon/model';
import { createResolverCache } from 'client/data/luckdragon/resolver-cache';
import { IS_NODE } from 'client/utils/environment';
import { saveModel, updateModelMetadataAsync } from './model-actions';
import { modelReducer } from './model-reducer';

function createStateContainer(dispatch, getState, resolverCache) {
  return {
    getState,

    refreshState(delta, path, segment) {
      if (delta && delta.length && !delta.refreshOnly) {
        dispatch(saveModel(path, segment, delta));
      }
      return [];
    },

    refreshModelMetadata(metadata, path, segment) {
      dispatch(updateModelMetadataAsync(path, segment, metadata));
    },

    resolverCache,
  };
}

/**
 * Gets a ModelState instance based on redux state.
 * This version is best suited for cases where the original store is not available (as in a redux thunk)
 * The current state will be retrieved using the given getState function, and state will be refreshed using
 * the given dispatch function with MODEL_SAVE actions.
 *
 * @param {function} dispatch - Function used for dispatching redux actions.
 * @param {function} getState - Function used for getting current redux state.
 * @param {Object} [resolverCache] - Optional object that will be used to temporarily store in-flight resolvers.
 *
 * @returns {ModelState}
 */
export function getReduxModelState(dispatch, getState, resolverCache) {
  return getModelState(createStateContainer(dispatch, getState, resolverCache));
}

/**
 * Gets a ModelState instance based on redux state.
 * The current state will be retrieved using the given redux store instance, and state will be refreshed by
 * dispatching MODEL_SAVE actions to the given store.
 *
 * @param {Object} store - The redux store.
 *
 * @returns {ModelState}
 */
export function getReduxModelStateFromStore(store) {
  // if there is a resolverCache defined on the store, use it instead of the global cache
  const { resolverCache } = store;

  return getReduxModelState(action => store.dispatch(action), () => store.getState(), resolverCache);
}

/**
 * Creates a redux store that is integrated with the luckdragon model.
 * Provides same interface as Redux's createStore, but automatically integrates model reducer with the provided reducer.
 *
 * @param {function} reducer The main reducer for the store, will be integrated with the modelReducer
 * @param {Object} [initialState] Optional initial state of the store
 * @param {function} [enhancer] Optional store enhancer to provide additional capabilities to the store
 *
 * @returns {Store}
 */
export function createReduxModelStore(reducer, initialState, enhancer) {
  function reduxModelReducer(state = {}, action = {}) {
    const newState = modelReducer(state, action);
    return {
      ...newState,
      ...reducer(newState, action),
    };
  }

  createReduxModelStore.reduxModelReducer = reduxModelReducer;

  const store = createStore(reduxModelReducer, initialState, enhancer);

  // Browsers can only have one store, so the global resolver cache can be used.
  // However, it is possible for a server to have multiple stores, such as in SSR, so create a separate
  // resolver cache for each store instance.
  if (IS_NODE) {
    store.resolverCache = createResolverCache();
  }

  return store;
}
