import { FORM_SUBMITTED, PAGE_UNLOAD } from 'client/actions/constants';

const reduceError = error =>
  error && {
    message: error.message,
    fieldErrors: error.fieldErrors || {},
  };

const updateFormState = (state, action, isSubmitted) => {
  const { formId, fields, isValid = true, error } = action;

  const formState = state[formId];

  return {
    ...state,
    [formId]: {
      ...formState,
      fields,
      isSubmitted,
      isValid,
      error: reduceError(error),
    },
  };
};

export function formReducer(state = {}, action = {}) {
  const { type } = action;

  switch (type) {
    case FORM_SUBMITTED:
      return updateFormState(state, action, true);
    case PAGE_UNLOAD:
      // clear all form state when page is unloaded
      return {};
    default:
      return state;
  }
}
