import { get } from 'lodash';

/**
 * Name of the recipe that corresponds to the residual allocation (e.g. we have a campaign with recipes ctrl 25% and
 * chal 30% - then the default recipe will have 100 - 25 - 30 = 45% allocation).
 */
export const DEFAULT_RECIPE = '_default';

/**
 * Returns whether the given campaign is a Venom type campaign and its computed recipe is a default recipe.
 *
 * @param  {Object} campaign  target campaign configuration to check.
 * @param  {Object} computedParams  object that contains computed parameters for the given campaign.
 * @return {boolean} whether the given campaign is a Venom type campaign and has default recipe as the computed one.
 */
export const isVenomCampaignDefaultRecipe = (campaign, computedParams) =>
  campaign.type.toLowerCase() === 'venom' && computedParams.recipe.value === DEFAULT_RECIPE;

const isCampaignActiveByFilter = (targetCampaign, state, campaignField, stateField) => {
  const filterValue = targetCampaign[campaignField];
  if (!Array.isArray(filterValue) || filterValue.length === 0) {
    // filter is of unexpected type or not specified.
    return true;
  }
  const stateValue = get(state, stateField);
  if (!stateValue) {
    return false;
  }
  return filterValue.indexOf(stateValue) !== -1;
};

/**
 * Returns whether the given campaign is active considering its DMA filters and the current DMA.
 *
 * @param  {Object}  targetCampaign  target campaign.
 * @param  {Object}  state  App redux state.
 * @return {boolean} whether the given campaign is active according to DMA.
 */
export const isCampaignActiveByDma = (targetCampaign, state) =>
  isCampaignActiveByFilter(targetCampaign, state, 'dmas', 'visitor.location.dma');

const checkKeepVehicleInPreload = (state, baseStateField, isPageExperiment) =>
  isPageExperiment && get(state, 'pageContext.loading.isPreloaded') === false
    ? `experiments.${baseStateField}`
    : baseStateField;

/**
 * Returns whether the given campaign is active considering its makes filter and the current page vehicle make.
 *
 * @param  {Object}  targetCampaign  target campaign.
 * @param  {Object}  state  App redux state.
 * @param  {boolean} isPageExperiment  whether the experiment is page-level.
 * @return {boolean} whether the given campaign is active according to make.
 */
export const isCampaignActiveByMake = (targetCampaign, state, isPageExperiment) => {
  const stateField = checkKeepVehicleInPreload(state, 'pageContext.vehicle.make.niceName', isPageExperiment);
  return isCampaignActiveByFilter(targetCampaign, state, 'makes', stateField);
};

/**
 * Returns whether the given campaign is active considering its models filter and the current page vehicle model.
 *
 * @param  {Object}  targetCampaign  target campaign.
 * @param  {Object}  state  App redux state.
 * @param  {boolean} isPageExperiment  whether the experiment is page-level.
 * @return {boolean} whether the given campaign is active according to model.
 */
export const isCampaignActiveByModel = (targetCampaign, state, isPageExperiment) => {
  const stateField = checkKeepVehicleInPreload(state, 'pageContext.vehicle.model.niceName', isPageExperiment);
  return isCampaignActiveByFilter(targetCampaign, state, 'models', stateField);
};

/**
 * Returns whether the given campaign is active considering its filters if present: DMA, make, model etc.
 *
 * @param  {Object}  targetCampaign  target campaign.
 * @param  {Object}  state  App redux state.
 * @param  {boolean} isPageExperiment  whether the experiment is page-level.
 * @return {boolean} whether the given campaign is active according to its filters.
 */
export const isCampaignActiveByFilters = (targetCampaign, state, isPageExperiment) =>
  targetCampaign &&
  isCampaignActiveByDma(targetCampaign, state) &&
  isCampaignActiveByMake(targetCampaign, state, isPageExperiment) &&
  isCampaignActiveByModel(targetCampaign, state, isPageExperiment);
