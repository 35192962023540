import { applyModelChanges } from 'client/data/luckdragon/model';
import { updatePathMetadata } from 'client/data/luckdragon/model-metadata';
import { ACTION_MODEL_SAVE, ACTION_MODEL_PRELOAD, ACTION_MODEL_METADATA } from './model-actions';

/**
 * Redux reducer that handles changes to model state and merges them into the store.
 *
 * @param {Object} state - The current state of the redux store.
 * @param {Object} action - The action to reduce.
 *
 * @returns {Object}
 */
export function modelReducer(state = {}, action = {}) {
  const { type, modelDelta } = action;

  let newState = state;

  if ((type === ACTION_MODEL_SAVE || type === ACTION_MODEL_PRELOAD) && modelDelta) {
    newState = applyModelChanges(modelDelta, { ...state });
  } else if (type === ACTION_MODEL_METADATA) {
    const { path, metadata } = action;
    newState = updatePathMetadata(path, metadata, newState);
  }

  return newState;
}
