import { RADIUS, SORT_BY, CREDIT_PROVIDER } from 'site-modules/shared/constants/allowed-inventory-request-params';
import {
  BEST_DEAL_DESC,
  PRICE_DESC,
  PRICE_ASC,
  DISTANCE_ASC,
  MILEAGE_DESC,
  MILEAGE_ASC,
  PUBLISH_DATE_DESC,
  PUBLISH_DATE_ASC,
  MODEL_YEAR_DESC,
  MODEL_YEAR_ASC,
  RANGE_ASC,
  RANGE_DESC,
} from 'site-modules/shared/constants/sort-types';

export const NATIONWIDE = 'Nationwide';
export const NATIONWIDE_VALUE = '6000';

export const RADIUS_FACET = {
  type: RADIUS,
  values: [
    {
      name: '10 miles',
      value: '10',
      selected: false,
    },
    {
      name: '25 miles',
      value: '25',
      selected: false,
    },
    {
      name: '50 miles',
      value: '50',
      selected: false,
    },
    {
      name: '75 miles',
      value: '75',
      selected: false,
    },
    {
      name: '100 miles',
      value: '100',
      selected: false,
    },
    {
      name: '200 miles',
      value: '200',
      selected: false,
    },
    {
      name: '500 miles',
      value: '500',
      selected: false,
    },
  ],
};

export const RADIUS_FACET_EXTENDED = {
  ...RADIUS_FACET,
  values: [
    ...RADIUS_FACET.values,
    {
      name: NATIONWIDE,
      value: NATIONWIDE_VALUE,
      selected: false,
    },
  ],
};

export const SORT_BY_FILTER = {
  type: SORT_BY,
  values: [
    {
      name: 'Distance',
      value: DISTANCE_ASC,
      selected: false,
      displayRank: 3,
    },
    {
      name: 'Price: Low to High',
      value: PRICE_ASC,
      selected: false,
      displayRank: 4,
    },
    {
      name: 'Price: High to Low',
      value: PRICE_DESC,
      selected: false,
      displayRank: 5,
    },
    {
      name: 'Date Listed: Newest First',
      value: PUBLISH_DATE_DESC,
      selected: false,
      displayRank: 10,
    },
    {
      name: 'Date Listed: Oldest First',
      value: PUBLISH_DATE_ASC,
      selected: false,
      displayRank: 11,
    },
    {
      name: 'Year: Newest First',
      value: MODEL_YEAR_DESC,
      selected: false,
      displayRank: 12,
    },
    {
      name: 'Year: Oldest First',
      value: MODEL_YEAR_ASC,
      selected: false,
      displayRank: 13,
    },
  ],
};

export const SORT_BY_FILTER_BOL = {
  ...SORT_BY_FILTER,
  values: [
    ...SORT_BY_FILTER.values,
    {
      name: 'Best Deals',
      value: BEST_DEAL_DESC,
      selected: true,
      displayRank: 2,
    },
  ],
};

export const BEST_MATCH_VALUE = 'bestMatch';
export const SORT_BY_FILTER_SRP = {
  ...SORT_BY_FILTER,
  values: [
    ...SORT_BY_FILTER.values,
    {
      name: 'Best Match',
      value: BEST_MATCH_VALUE,
      selected: true,
      displayRank: 1,
    },
    {
      name: 'Best Deals',
      value: BEST_DEAL_DESC,
      selected: false,
      displayRank: 2,
    },
  ],
};
export const SORT_BY_MILEAGE = [
  {
    name: 'Mileage: Low to High',
    value: MILEAGE_ASC,
    selected: false,
    displayRank: 8,
  },
  {
    name: 'Mileage: High to Low',
    value: MILEAGE_DESC,
    selected: false,
    displayRank: 9,
  },
];

export const SORT_BY_RANGE = [
  {
    name: 'EPA Range: Low to High',
    value: RANGE_ASC,
    selected: false,
    displayRank: 6,
  },
  {
    name: 'EPA Range: High to Low',
    value: RANGE_DESC,
    selected: false,
    displayRank: 7,
  },
];

export const CREDIT_PROVIDER_FACET = {
  type: CREDIT_PROVIDER,
};

export const CAPITAL_ONE_CREDIT_PROVIDER = 'CapitalOne';
