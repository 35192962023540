import { DFPManager } from '@edmunds/react-dfp';
import { shouldRefreshAds } from 'client/actions/ads-refresh';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { getPwtEnabled } from 'client/utils/pwt/get-pwt-enabled-page';
import { pwtAdRefresher } from 'client/utils/pwt/ad-refresher';

const SCROLL_PX_BREAKPOINT_STEP = 1000;
const USER_EVENT_NAMES = ['mousedown', 'touchstart', 'mouseover', 'wheel', 'keydown'];
let scrollCounter = 0;
let scrollLastPosition = 0;
let hasUserInitiatedInteraction = false;

const checkFirstUserInteractionOnPageload = () => {
  hasUserInitiatedInteraction = true;
  USER_EVENT_NAMES.forEach(eventName => {
    document.removeEventListener(eventName, checkFirstUserInteractionOnPageload, false);
  });
};

export async function scrollStepAdsRefresher(getState, currentYPosition, trigger) {
  const currentScrollCounter = Math.abs(currentYPosition - scrollLastPosition);
  scrollCounter += currentScrollCounter;
  scrollLastPosition = currentYPosition;
  const refreshOnScrollStep = scrollCounter > SCROLL_PX_BREAKPOINT_STEP;
  const pwtEnabled = getPwtEnabled(getState());

  if (!hasUserInitiatedInteraction) {
    USER_EVENT_NAMES.forEach(eventName => {
      document.addEventListener(eventName, checkFirstUserInteractionOnPageload, false);
    });
  }

  if (
    hasUserInitiatedInteraction &&
    refreshOnScrollStep &&
    RefreshRules.refreshOnRefreshZones.adhesion.size &&
    shouldRefreshAds(trigger, getState)
  ) {
    scrollCounter %= SCROLL_PX_BREAKPOINT_STEP;
    const refreshRulesOnRefreshZonesAdhesion = Array.from(RefreshRules.refreshOnRefreshZones.adhesion);
    if (pwtEnabled) {
      pwtAdRefresher(refreshRulesOnRefreshZonesAdhesion);
    } else {
      DFPManager.refresh(...refreshRulesOnRefreshZonesAdhesion);
    }
  }
}

export function refreshAdsOnScroll(dispatch, getState, trigger) {
  if (RefreshRules.refreshOn.scroll.size && shouldRefreshAds(trigger, getState)) {
    const refreshRulesOnScroll = Array.from(RefreshRules.refreshOn.scroll);
    DFPManager.refresh(...refreshRulesOnScroll);
  }
}
