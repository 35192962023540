import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { CmsModel } from 'client/data/models/cms';
import { getResearchArticleContentPath } from 'site-modules/editorial/utils/content-path';
import { ARTICLE_CATEGORY, DEFAULT_CODE } from 'site-modules/editorial/pages/obd-dtc/constants';

/**
 * route for each vehicle type
 * @type {Array}
 */
export const obdDtcRoutes = [
  /**
   * https://www.edmunds.com/obd-dtc/p0420/
   */
  {
    path: ['/obd-dtc', '/obd-dtc/:codeId.html'],
    exact: true,
    preload: ({ codeId = DEFAULT_CODE }, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(`content["${getResearchArticleContentPath(ARTICLE_CATEGORY, codeId)}"]`, CmsModel);
      // if atom file not found, 404 error, automatic redirect to "Not Found" page, see server/redux-router-engine.js

      return preloader.load();
    },
    page: getLoadablePage(({ codeId = DEFAULT_CODE }) =>
      import(/* webpackChunkName: "odb-dtc" */ '../pages/obd-dtc/obd-dtc-code-definition').then(page =>
        page.default({ codeId })
      )
    ),
    chunkName: 'obd-dtc-code',
  },
];
