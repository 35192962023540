import { FeatureFlagModel } from 'client/data/models/feature-flag';

// TODO: Remove after core-4062-default-year FF cleanup
export async function getDefaultYearUrl({ context, makeSlug, modelSlug }) {
  const isDefaultYearV1 = await context.resolveValue('core-4062-default-year', FeatureFlagModel);

  return isDefaultYearV1
    ? `/coreresearch/v1/defaultyear/${makeSlug}/${modelSlug}`
    : `/coreresearch/v2/defaultyear/${makeSlug}/${modelSlug}`;
}
