import PropTypes from 'prop-types';
import { reduce, map, flattenDepth } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { VehicleModel, buildModelsPath, buildSubmodelYearsPath } from 'client/data/models/vehicle';

const SitemapLink = PropTypes.shape({
  location: PropTypes.string,
  href: PropTypes.string,
  locationContext: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
    city: PropTypes.string,
    stateCode: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    price: PropTypes.string,
  }),
  title: PropTypes.string,
  published: PropTypes.string,
});

const Sitemap = PropTypes.shape({
  totalNumber: PropTypes.number,
  totalPages: PropTypes.number,
  results: PropTypes.arrayOf(SitemapLink),
});

export const SitemapEntities = {
  Sitemap,
  SitemapLink,
};

export const PAGE_SIZE = 69;

export const CMS_CATEGORIES = {
  'auto-insurance': [],
  'auto-warranty': [],
  'car-buying': [],
  'car-leasing': [],
  'car-loan': [],
  'car-maintenance': [],
  'car-news': ['auto-industry', 'first-impressions', 'tips-advice'],
  'car-reviews': ['features'],
  'car-safety': [],
  'car-technology': [],
  'certified-cars': [],
  'driving-tips': [],
  'fuel-economy': [],
  'how-to': [],
  'sell-car': [],
  'industry/press': [],
};

export const SPECIAL_CATEGORIES = {
  'car-maintenance': 'research/car-maintenance',
};

const getCmsCategoriesMapping = () =>
  reduce(
    CMS_CATEGORIES,
    (result, subcategories, category) => ({
      ...result,
      [`/${category}/sitemap.html`]: SPECIAL_CATEGORIES[category] || `research/${category}/,${category}/`,
      ...subcategories.reduce(
        (cmsSubcategories, subcategory) => ({
          ...cmsSubcategories,
          [`/${category}/${subcategory}/sitemap.html`]: `research/${category}/${subcategory}/,${category}/${subcategory}/`,
        }),
        {}
      ),
    }),
    {}
  );

export const CATEGORIES_MAPPING = {
  '/cpo-make.html': 'USED_CERTIFIED_PRE_OWNED_MAKE',
  '/cpo-model.html': 'USED_CERTIFIED_PRE_OWNED_MAKE_MODEL',
  '/lease-model.html': 'MAKE_MODEL_LEASE_DEALS',
  '/make-lease.html': 'LEASE_DEALS',
  '/type-lease.html': 'TYPE_LEASE_DEALS',
  '/uci-black.html': ['USED_BLACK_MAKE', 'USED_BLACK_MAKE_MODEL'],
  '/uci-cars-location.html': 'USED_CITY_STATE',
  '/uci-make-location.html': 'USED_MAKE_CITY_STATE',
  '/uci-model-location.html': 'USED_MAKE_MODEL_CITY_STATE',
  '/uci-make-type.html': 'USED_MAKE_TYPE',
  '/uci-make.html': 'USED_MAKE',
  '/uci-model.html': 'USED_MAKE_MODEL',
  '/uci-price-under-sitemap.html': 'USED_CARS_UNDER_CITY_STATE',
  '/uci-type-sitemap.html': 'USED_TYPE_CITY_STATE',
  '/uci-type.html': 'USED_TYPE',
  '/uci-white.html': ['USED_WHITE_MAKE', 'USED_WHITE_MAKE_MODEL'],
  '/uci-year-location.html': 'USED_YEAR_MAKE_MODEL_CITY_STATE',
  ...getCmsCategoriesMapping(),
};

export const CMS_CATEGORIES_KEYS = Object.keys(CMS_CATEGORIES);

export const buildPagePath = pathname => `page["${pathname}"]`;

export const buildVehicleSitemapPath = ({ make, model }) =>
  model ? `makes["${make}"].models["${model}"]` : `makes["${make}"].models`;

const buildSeoSitemapUrl = category =>
  `/seo/v1/sitemap/?category=${category.toLowerCase().replace(/_/g, '-')}&pagenum=1&pagesize=${PAGE_SIZE}`;

export const SitemapModel = createModelSegment('sitemap', [
  {
    path: 'page["{page}"]',
    async resolve(match, context) {
      const { page } = match;
      const category = CATEGORIES_MAPPING[page];

      if (!category) return {};

      if (Array.isArray(category)) {
        const categoriesData = await Promise.all(
          category.map(cat =>
            withMetrics(EdmundsAPI, context)
              .fetchJson(buildSeoSitemapUrl(cat))
              .then(response => response)
          )
        );

        return categoriesData.reduce(
          ({ totalNumber, totalPages, results }, categoryData) => ({
            totalNumber: totalNumber + categoryData.totalNumber,
            totalPages: totalPages + categoryData.totalPages,
            results: results.concat(categoryData.results),
          }),
          { totalNumber: 0, totalPages: 0, results: [] }
        );
      }

      return withMetrics(EdmundsAPI, context)
        .fetchJson(buildSeoSitemapUrl(category))
        .then(response => response);
    },
  },
  {
    path: 'makes',
    async resolve(match, context) {
      const makes = await context.resolveValue('makes', VehicleModel);
      const results = Object.values(makes).map(({ name, slug }) => ({
        location: `/${slug}-sitemap.html`,
        title: `${name} Overview`,
      }));

      return {
        results,
      };
    },
  },
  {
    path: 'makes["{make}"].models',
    async resolve(match, context) {
      const { make } = match;
      const models = await context.resolveValue(buildModelsPath({ make }), VehicleModel);
      const results = Object.values(models).map(({ name, slug, make: modelMake }) => ({
        location: `/${modelMake.slug}-${slug}-sitemap.html`,
        title: `${modelMake.name} ${name} Overview`,
      }));

      return {
        results,
      };
    },
  },
  {
    path: 'makes["{make}"].models["{model}"]',
    async resolve(match, context) {
      const { make, model } = match;
      const submodels = await context.resolveValue(buildSubmodelYearsPath({ make, model }), VehicleModel);
      const results = map(submodels, ({ name, years, make: submodelMake, model: submodelModel }, slug) =>
        Object.keys(years).map(year => [
          {
            location: `/${submodelMake.slug}/${submodelModel.slug}/${year}/${slug}/`,
            title: `${year} ${submodelMake.name} ${name}`,
          },
          {
            location: `/${submodelMake.slug}/${submodelModel.slug}/${year}/${slug}/review/`,
            title: `${year} ${submodelMake.name} ${name} Review`,
          },
        ])
      );

      return {
        results: flattenDepth(results, 2),
      };
    },
  },
]);
