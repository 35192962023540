import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { DEALER_REVIEW_PAGES, DEALER_COMMENT_PAGES } from 'client/constants/dealer-reviews';

const reviewPages = Object.keys(DEALER_REVIEW_PAGES);
const commentPages = Object.values(DEALER_COMMENT_PAGES);

export const routes = [
  {
    path: `/dealerships/:dealerState/:dealerCity/:logicalName/:reviewType(${reviewPages.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "dealer_review" */ '../pages/dealer-review/dealer-review'),
      match => ({
        ...match.params,
        reviewType: DEALER_REVIEW_PAGES[match.params.reviewType],
      })
    ),
    chunkName: 'dealer_review',
  },
  {
    path: `/dealerships/:dealerState/:dealerCity/:logicalName/(${commentPages.join('|')})-:reviewId.html`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "dealer_review_comment" */ '../pages/dealer-review-comment/dealer-review-comment'),
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'dealer_review_comment',
  },
];
