/**
 * This module provides constants for HTTP status codes
 */
export const HTTP_OK = 200;
export const HTTP_ACCEPTED = 202;
export const HTTP_NO_CONTENT = 204;
export const HTTP_MOVED_PERMANENTLY = 301;
export const HTTP_REDIRECT = 302;
export const HTTP_304_NOT_MODIFIED = 304;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_NOT_FOUND = 404;
export const HTTP_REQUEST_TIMEOUT = 408;
export const HTTP_GONE = 410;
export const HTTP_ERROR_500 = 500;

/**
 * The function returns true if status indicates a client error, which is not recoverable through retry.
 * However, 408 HTTP_REQUEST_TIMEOUT is the one exception to this rule since it indicates a timeout.
 * Since api gateway uses 408 to communicate timeouts to venom, we need to take this into account and
 * continue to retry when the response is a 408.
 *
 * @param {Number} status HTTP status code
 */
export function isClientError(status) {
  return status !== HTTP_REQUEST_TIMEOUT && status >= 400 && status < 500;
}
