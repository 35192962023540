import { isNumber } from 'lodash';
import { getRangeString } from './string';

function toLocaleStringSupportsLocales() {
  const number = 0;
  try {
    number.toLocaleString('i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

const hasLocaleSupport = toLocaleStringSupportsLocales();

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * Takes a number in and returns a formatted price with dollar sign
 * and cents (if min or max defined) or without cents (by default).
 * @param {string|number} price
 * @param {number} [minimumFractionDigits=0]
 * @param {number} [maximumFractionDigits=0]
 * @returns {string}
 */
export function formatPriceString(price, minimumFractionDigits = 0, maximumFractionDigits = 0) {
  return hasLocaleSupport
    ? price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits,
        maximumFractionDigits,
      })
    : `$${price.toLocaleString()}`;
}

/**
 * Takes a number in and returns a formatted price with dollar sign.
 * @param {string|number} price
 * @param {number} [minimumFractionDigits=0]
 * @param {number} [maximumFractionDigits=0]
 * @returns {string}
 */
export function formatPriceStringV2(price) {
  return formatter.format(price);
}

/**
 * Returns formatted price and if price is not valid, returns passed default value (N/A by default).
 * @param {string|number} price
 * @param {string} defaultValue
 * @param {number} minimumFractionDigits
 * @param {number} maximumFractionDigits
 * @returns {string}
 */
export function getPriceString(price, defaultValue = 'N/A', { minimumFractionDigits, maximumFractionDigits } = {}) {
  return price ? formatPriceString(price, minimumFractionDigits, maximumFractionDigits) : defaultValue;
}

/**
 * Returns formatted price and if price is not valid, returns passed default value (N/A by default).
 * @param {string|number} price
 * @param {string} defaultValue
 * @param {number} minimumFractionDigits
 * @param {number} maximumFractionDigits
 * @returns {string}
 */
export function getPriceStringV2(price, defaultValue = 'N/A') {
  return price ? formatPriceStringV2(price) : defaultValue;
}

/**
 * Returns price range with formatted price strings. Returns only 1 value if min === max
 */
export function getPriceRangeString(minValue, maxValue) {
  return getRangeString(getPriceString(minValue), getPriceString(maxValue));
}

export const PRICE_VALUE_REGEX = /^0+\B/;

/**
 * Returns formatted price value
 * example - price '01020', return 1020
 * example - price '', return 0
 * @param {string} price
 * @param {number} [defaultValue=0]
 * @returns {number}
 */
export function parsePriceValue(price, defaultValue = 0) {
  return parseFloat(price.replace(PRICE_VALUE_REGEX, '')) || defaultValue;
}

/**
 * Rounds price value to nearest ten
 * example - price 28038 return 28040
 * example - price 28398 return 28400
 * @param {number} price
 * @returns {number}
 */
export function roundToNearestTen(price) {
  return Math.round(price / 10) * 10;
}

/**
 * Returns a shortened price display
 * @param {number} price
 * @returns {string}
 */
export function formatPriceKString(price) {
  return `$${Math.round(Math.abs(price) / 100) / 10}k`;
}

export const getCleanValue = value => (isNumber(value) ? value : +value.replace(/\D/g, ''));
