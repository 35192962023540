export const isNode = () => {
  try {
    return Object.prototype.toString.call(global.process) === '[object process]';
  } catch (ex) {
    return false;
  }
};

export const IS_NODE = isNode();

export const ENVIRONMENT_URL = `http://${
  IS_NODE && process.env.ENVIRONMENT ? `${process.env.ENVIRONMENT}-` : ''
}www.edmunds.com`;
