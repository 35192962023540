/* eslint-disable venom/no-venom-api-calls */
import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { UdmAPI, VenomApi } from 'client/data/api/api-client';
import { HTTP_NO_CONTENT } from 'client/utils/http-status';
import { DEFAULT_LOCATION, Location } from 'client/constants/location';
import { logger } from 'client/utils/isomorphic-logger';
import { PageModel } from './page';
import { UrlModel } from './url';

const History = PropTypes.shape({
  wiredLabel: PropTypes.number,
  mobileLabel: PropTypes.number,
  userEvents: PropTypes.arrayOf(
    PropTypes.shape({
      bodyType: PropTypes.string,
      useIn: PropTypes.string,
      pageName: PropTypes.string,
      userId: PropTypes.string,
      timeStamp: PropTypes.number,
      type: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.number,
      styleId: PropTypes.string,
    })
  ),
});

export const VisitorEntities = {
  Location,
  History,
};

export const SEGMENT_TYPES = {
  SEGMENT: 'segment',
  MODEL: 'model',
};

export const VisitorModel = createModelSegment('visitor', [
  {
    path: 'segment',
    async resolve(match, context) {
      const [userId, pageName] = await Promise.all([
        context.resolveValue('id'),
        context.resolveValue('page.name', PageModel),
      ]);

      const request = {
        retries: 0,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pageName,
          userId,
          timeStamp: Date.now(),
        }),
      };

      try {
        const segment = await UdmAPI.fetchJson('/segment', request);
        if (segment.status === HTTP_NO_CONTENT)
          logger('warn', 'Request to UDM api succeeded, but returned with status 204 NO CONTENT');
        segment.segmentType = segment.status === HTTP_NO_CONTENT ? SEGMENT_TYPES.MODEL : SEGMENT_TYPES.SEGMENT;
        return Promise.resolve(segment);
      } catch (e) {
        return Promise.resolve({ segmentType: SEGMENT_TYPES.MODEL });
      }
    },
  },
  {
    path: 'location',
    resolve() {
      return Promise.resolve({
        ...DEFAULT_LOCATION,
        ipZipCode: DEFAULT_LOCATION.zipCode,
        userSet: false,
      });
    },
    async update({ zipCode, latitude, longitude, locationResponse } = {}, match, context) {
      let url;

      if (zipCode) {
        url = `/location/zip/${zipCode}`;
      } else if (latitude && longitude) {
        url = `/location/geo/${latitude},${longitude}`;
      }

      if (!url && !locationResponse) return Promise.reject('The zip code or latitude and longitude are required.');

      const response = locationResponse || (await VenomApi.fetchJson(url, { credentials: 'same-origin' }));

      if (!(response.success && response.location && response.location.zipCode)) {
        return Promise.reject('Invalid location received.');
      }

      const urlContext = await context.resolveValue('context', UrlModel, false);
      const isNational = urlContext && urlContext.attributes && urlContext.attributes.nationalScope;
      if (isNational) {
        await context.updateValue('context.attributes.nationalScope', false, UrlModel);
      }

      return response.location;
    },
  },
  {
    path: 'location.address',
    resolve(match, context) {
      return context.resolveValue('location');
    },
    async update({ zipCode, latitude, longitude } = {}) {
      let url;

      if (zipCode) {
        url = `/location/zip/${zipCode}`;
      } else if (latitude && longitude) {
        url = `/location/geo/${latitude},${longitude}`;
      }

      if (!url) return Promise.reject('The zip code or latitude and longitude are required.');

      const response = await VenomApi.fetchJson(url, { credentials: 'same-origin' });

      if (!(response.success && response.location && response.location.zipCode)) {
        return Promise.reject('Invalid location received.');
      }

      return response.location;
    },
  },
  {
    path: 'privacy',
    async update({ doNotSell }) {
      if (doNotSell === undefined) return Promise.reject('The doNotSell is required.');

      const url = '/privacy/';
      const response = await VenomApi.fetchJson(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ doNotSell }),
      });
      return response;
    },
  },
]);
