import { isEmpty } from 'lodash';
import { makes } from 'client/data/constants/active-makes';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import {
  VehicleModel,
  buildEstimatedStyleIdsPath,
  buildStylesPath,
  buildVehicleStylePath,
} from 'client/data/models/vehicle';
import { PartnerOfferModel, buildPartnerOfferVehicleInfoPath } from 'client/data/models/partner-offer-model';
import { getQuery } from 'client/utils/location';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makeNiceName as getNiceName } from 'site-modules/shared/utils/nice-name';
import { transformStylesToStyleIds, getSelectedStyleId } from 'site-modules/appraiser/pages/appraiser/appraiser-utils';

const AppraiserPreload = async (params, store) => {
  const { make, model, year } = params;
  const state = store.getState();
  const location = state.pageContext?.location;

  const preloader = new ModelPreloader(store);

  const estimatedStyleIdsPath = buildEstimatedStyleIdsPath(params);
  const stylesPath = buildStylesPath({
    make: getNiceName(make),
    model: getNiceName(model),
    year,
  });

  preloader.resolve(estimatedStyleIdsPath, VehicleModel);
  preloader.resolve(stylesPath, VehicleModel);
  preloader.resolve('estimatedAppraisalVin', VehicleModel);

  return preloader.load().then(modelState => {
    const estimatedStyleIds = modelState.get(estimatedStyleIdsPath, VehicleModel);
    const { styleIds: queryStyleIds } = getQuery(location);
    const allStylesForVehicle = modelState.get(stylesPath, VehicleModel);
    const allStyleIdsForVehicle = Array.isArray(allStylesForVehicle)
      ? transformStylesToStyleIds(allStylesForVehicle)
      : [];

    const selectedStyleId = getSelectedStyleId(estimatedStyleIds, queryStyleIds, allStyleIdsForVehicle);

    preloader.resolve(buildVehicleStylePath({ styleId: selectedStyleId }), VehicleModel);
    return preloader.load();
  });
};

const shouldImportWithVinLpTabs = ({ params, store }) => {
  const { make, model, year } = params;
  const state = store.getState();
  const modelState = getModelState(state);

  const location = state.pageContext?.location;
  const { vin, styleIds: queryStyleIds } = getQuery(location);

  const estimatedAppraisalVin = modelState.get('estimatedAppraisalVin', VehicleModel);
  const estimatedStyleIds = modelState.get(buildEstimatedStyleIdsPath({ make, model, year }), VehicleModel);
  const allStylesForVehicle = modelState.get(
    buildStylesPath({ make: getNiceName(make), model: getNiceName(model), year }),
    VehicleModel
  );
  const allStyleIdsForVehicle = Array.isArray(allStylesForVehicle)
    ? transformStylesToStyleIds(allStylesForVehicle)
    : [];

  const selectedStyleId = getSelectedStyleId(estimatedStyleIds, queryStyleIds, allStyleIdsForVehicle);
  const estimatedVehicle = selectedStyleId ? modelState.get(`styles.${selectedStyleId}`, VehicleModel) : null;

  const isQueryPredefinedVin = !!vin;
  const modsData = !isEmpty(estimatedVehicle)
    ? modelState.get(
        buildPartnerOfferVehicleInfoPath({
          vin: estimatedAppraisalVin || getQuery(location).vin,
          modsId: getQuery(location).modsId,
        }),
        PartnerOfferModel
      )
    : null;
  const modsDataStyleId = modsData?.styleId ? modsData.styleId.toString() : '';
  const styleIds = queryStyleIds || modsDataStyleId;

  const hasOneOrLessStyleId = !styleIds || styleIds.split(',').length === 1;
  return !(isQueryPredefinedVin && hasOneOrLessStyleId);
};

const routes = [
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/appraisal-value`,
    exact: true,
    preload: AppraiserPreload,
    page: getLoadablePage((params, store) => {
      if (shouldImportWithVinLpTabs({ params, store })) {
        return import(/* webpackChunkName: "appraiser-with-vin-lp-tabs" */ '../pages/appraiser/appraiser-definition-with-vin-lp-tabs');
      }

      return import(/* webpackChunkName: "appraiser" */ '../pages/appraiser/appraiser-definition');
    }),
    chunkName: 'appraiser',
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/appraisal-value/index.app`,
    exact: true,
    preload: AppraiserPreload,
    page: getLoadablePage((params, store) => {
      if (shouldImportWithVinLpTabs({ params, store })) {
        return import(/* webpackChunkName: "appraiser-with-vin-lp-tabs-app" */ '../pages/appraiser/appraiser-definition-with-vin-lp-tabs-app');
      }

      return import(/* webpackChunkName: "appraiser-app" */ '../pages/appraiser/appraiser-definition-app');
    }),
    chunkName: 'appraiser-app',
  },
];

export { routes };
