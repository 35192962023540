import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(intro)', '/financing/prequalify-and-shop/:step(intro)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_intro" */ '../pages/steps/intro')),
    chunkName: 'financing_intro',
    enableLooseEtag: true,
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(basics)', '/financing/prequalify-and-shop/:step(basics)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_basics" */ '../pages/steps/basics')),
    chunkName: 'financing_basics',
    enableLooseEtag: true,
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(personal)', '/financing/prequalify-and-shop/:step(personal)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_personal" */ '../pages/steps/personal')),
    chunkName: 'financing_personal',
    methods: 'get,post',
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(residence)', '/financing/prequalify-and-shop/:step(residence)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_residence" */ '../pages/steps/residence')),
    chunkName: 'financing_residence',
    enableLooseEtag: true,
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(employment)', '/financing/prequalify-and-shop/:step(employment)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_employment" */ '../pages/steps/employment')),
    chunkName: 'financing_employment',
    enableLooseEtag: true,
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(review)', '/financing/prequalify-and-shop/:step(review)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_review" */ '../pages/steps/review')),
    chunkName: 'financing_review',
    methods: 'get,post',
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(payments)', '/financing/:step(payments)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_results" */ '../pages/steps/payments')),
    chunkName: 'financing_results',
    methods: 'get,post',
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(confirmation)', '/financing/:step(confirmation)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_confirmation" */ '../pages/steps/confirmation')),
    chunkName: 'financing_confirmation',
  },
  {
    path: ['/financing/:vin([A-Z0-9]{17})/:step(error)', '/financing/prequalify-and-shop/:step(error)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "financing_error" */ '../pages/steps/error')),
    chunkName: 'financing_error',
    enableLooseEtag: true,
  },
  {
    path: ['/car-loan/prequalify-auto-loan'],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "prequalify_landing" */ '../pages/prequalify-landing/landing')
    ),
    chunkName: 'prequalify_landing',
    enableLooseEtag: true,
  },
];
