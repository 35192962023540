import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';

const lineItemIdsStorage = [];
const adTrackingDebounceTimer = 250;

const emptyLineItemIdsStorage = () => {
  lineItemIdsStorage.length = 0;
};

export const fireDefaultEvent = debounce(() => {
  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: {
      action_category: 'system',
      action_name: 'ad_load',
      action_cause: 'action_completed',
      line_item_id: lineItemIdsStorage.join(','),
    },
  });
  emptyLineItemIdsStorage();
}, adTrackingDebounceTimer);

export const fireHeavyAdInterventionEvent = lineItemId => {
  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: {
      action_category: 'system',
      action_name: 'show_content',
      subaction_name: 'ad_unload',
      action_cause: 'ad_unload',
      value: lineItemId,
    },
  });
};

export const handleAdLoad = event => {
  if (event.resetLineItemIdsStorage) {
    emptyLineItemIdsStorage();
  }
  if (!event.lineItemId) return;

  lineItemIdsStorage.push(event.lineItemId);
  fireDefaultEvent();
};
