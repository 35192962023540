export const INVENTORY_TYPES = {
  CPO: 'CPO',
  NEW: 'NEW',
  USED: 'USED',
};

export const INVENTORY_TYPES_LOWERCASE = {
  CPO: 'cpo',
  NEW: 'new',
  USED: 'used',
};

export const INVENTORY_TYPES_CAPITALIZED = {
  NEW: 'New',
  USED: 'Used',
};
