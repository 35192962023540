/**
 *
 * Module to encapsulate basic event trigger calls, like ga/gtm pixel tracking, etc.
 *
 *
 */
import gator from 'node-gator';
import { isNode } from 'client/utils/environment';
import { isDomReady } from './dom';
import { EVENTS } from './event-constants';

let customEvents = {};

/**
 * Default event target https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
 */
function getDefaultTarget() {
  return typeof window !== 'undefined' ? window.document : null;
}

/**
 * adds custom event
 * @param {string} events space separated event names
 * @param cb
 */
function addCustomEvent(events, cb) {
  events.split(' ').forEach(event => {
    if (!customEvents[event]) {
      customEvents[event] = new Set();
    }
    customEvents[event].add(cb);
  });
}

/**
 * removes custom event
 * @param {string} events space separated event names
 * @param cb
 */
function removeCustomEvent(events, cb) {
  events.split(' ').forEach(event => {
    const customEvent = customEvents[event];
    if (!customEvent) {
      return;
    }
    customEvent.delete(cb);
  });
}

export const EventToolbox = {
  /**
   * Dispatches a custom event (with the provided event name and data)
   *
   * @param  {String}      eventName              Event name
   * @param  {Object}      data                   Event data
   * @param  {EventTarget} [target=undefined]     optional DOM node the handler can use to extract more info
   * this param remains to support existing usages from original implementation of customEvents which was based
   * on DOM CustomEvent. Given that current implementation of custom events are not DOM based, passing target to this
   * method should be reassessed going forward
   * @return {void}
   */
  fireCustomEvent(eventName, data = {}, target = undefined) {
    if (isNode()) {
      return; // custom events are disabled on server side cause of some unnecessary isomorphic fires in codebase
    }
    /* istanbul ignore if */
    if (!customEvents[eventName]) {
      return;
    }
    customEvents[eventName].forEach(cb => {
      cb({
        detail: {
          ...data,
          ts: data.ts || Date.now(),
        },
        target,
      });
    });
  },

  /**
   * Predefined 'trackAction' event
   *
   * @param {Object} data Event data
   */
  fireTrackAction(data) {
    this.fireCustomEvent(EVENTS.TRACK_ACTION, data);
  },

  /**
   * Predefined 'pageView' event
   *
   * @param {Object} data Event data
   */
  firePageView(data) {
    this.fireCustomEvent(EVENTS.PAGE_VIEW, data);
  },

  /**
   * Subscribe event
   *
   * @param {String}      events          Event type/name
   * @param {String}      selector        Element selector for events delegation (optional)
   * @param {Function}    handler         Event handler function
   * @param {Boolean}     isBrowserEvent  is browser triggered (aka not custom) event
   * @param {EventTarget} target          EventTarget object (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget)
   */
  on(events, handler, isBrowserEvent, selector, target = getDefaultTarget()) {
    if (isBrowserEvent) {
      if (selector) {
        gator(target).on(events.split(' '), selector, handler);
      } else {
        gator(target).on(events.split(' '), handler);
      }
    } else {
      addCustomEvent(events, handler);
    }
  },

  /**
   * Unsubscribe event
   * - this function will automatically look for, if specified events found in custom events, will be unsubscribed
   * - same will be done automatically for previously subscribed browser events. Note that this applies only to
   * browser events previously subscribed via this utility.
   * @param {String}      events          Event type/name
   * @param {Function}    handler         Event handler function
   * @param {Boolean}     isBrowserEvent  is browser triggered (aka not custom) event
   * @param {String}      selector        Element selector for events delegation (optional)
   * @param {EventTarget} target          EventTarget object (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget)
   */
  off(events, handler, isBrowserEvent, selector, target = getDefaultTarget()) {
    if (isBrowserEvent) {
      if (selector) {
        gator(target).off(events.split(' '), selector, handler);
      } else {
        gator(target).off(events.split(' '), handler);
      }
    } else {
      removeCustomEvent(events, handler);
    }
  },

  clearAllCustomEvents() {
    customEvents = {};
  },

  /**
   * Runs JavaScript code as soon as the page's Document Object Model (DOM) becomes safe to manipulate
   *
   * @param   {Function} cb Function executing when DOM is ready
   * @return  {void}
   */
  domReady(cb) {
    if (isDomReady()) {
      // if DOM is ready execute code immediately
      cb();
    } else {
      // if DOM is not ready wait until it's ready
      window.addEventListener('DOMContentLoaded', cb);
    }
  },
};
