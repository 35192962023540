import { PAGE_START_LOADING, PAGE_META } from 'client/actions/constants';

export function isAppExtensionPageReducer(state = null, action = {}) {
  switch (action.type) {
    case PAGE_START_LOADING:
    case PAGE_META:
      // update state only on SPA transitions. for a hard refresh page info is
      // already in the state from server so no need update and trigger re-renders
      return action.isAppExtensionPage || state;
    default:
      return state;
  }
}
