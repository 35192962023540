import { matchPath } from 'client/data/luckdragon/segment';
import { logger } from 'client/utils/isomorphic-logger';
import { getReduxModelState } from './redux-model';

export const ACTION_MODEL_LOADING = 'MODEL_LOAD';
export const ACTION_MODEL_SAVE = 'MODEL_SAVE';
export const ACTION_MODEL_PRELOAD = 'MODEL_PRELOAD';
export const ACTION_MODEL_METADATA = 'MODEL_METADATA';

/**
 * Redux action that marks a model path as loading.
 *
 * @param {string} path - The path that is loading.
 * @param {ModelSegment} segment - The model segment that contains the path.
 *
 * @param returns {Object}
 */
export function loadingModel(path, segment) {
  return {
    type: ACTION_MODEL_LOADING,
    path,
    segment,
  };
}

/**
 * Redux action that updates the state with changes to the model state.
 *
 * @param {string} path - The path that is loading.
 * @param {ModelSegment} segment - The model segment that contains the path.
 * @param {Array} modelDelta - List of changes to the model state as path-to-value mappings.
 *
 * @returns {Object}
 */
export function saveModel(path, segment, modelDelta) {
  return {
    type: ACTION_MODEL_SAVE,
    path,
    segment,
    modelDelta,
  };
}

/**
 * Redux action that bulk preloads the state with a large delta.
 *
 * @param {Array} modelDelta - List of changes to the model state as path-to-value mappings.
 *
 * @returns {Object}
 */
export function preloadModel(modelDelta) {
  return {
    type: ACTION_MODEL_PRELOAD,
    modelDelta,
  };
}

/**
 * Async redux action that resolves a model path.
 *
 * @param {String} path - The path to resolve.
 * @param {ModelSegment} segment - The segment that contains the path.
 *
 * @returns {Promise}
 */
export function resolveModel(path, segment, componentName) {
  return async (dispatch, getState) => {
    const modelState = getReduxModelState(dispatch, getState);

    const currentValue = modelState.get(path, segment, false);
    if (currentValue && !currentValue.$partial) {
      return Promise.resolve();
    }
    const timing = {};
    const resolvedModelState = await modelState.resolve(path, segment, timing).catch(error => logger('error', error));
    const reduxState = getState();
    const profilerEnabled = reduxState?.featureFlags?.profiler;
    if (profilerEnabled) {
      dispatch({
        type: 'PROFILER_ACTION_CMP_UPDATE',
        componentName,
        timing,
        requestStartTime: getState().request.startTime,
      });
    }
    return resolvedModelState;
  };
}

/**
 * Async redux action that updates a model path with a new value.
 *
 * @param {String} path - The path to resolve.
 * @param {ModelSegment} segment - The segment that contains the path.
 * @param {*} value - The new value.
 *
 * @returns {Promise}
 */
export function updateModel(path, segment, value) {
  return async (dispatch, getState) => {
    const modelState = getReduxModelState(dispatch, getState);
    const timing = {};
    const result = await modelState.update(path, segment, value, timing);
    const reduxState = getState();
    const profilerEnabled = reduxState?.featureFlags?.profiler;
    if (profilerEnabled) {
      dispatch({
        type: 'PROFILER_ACTION_CMP_UPDATE',
        componentName: 'unknown',
        timing,
        requestStartTime: getState().request.startTime,
      });
    }
    return result;
  };
}

export function updateModelMetadata(path, metadata) {
  return {
    type: ACTION_MODEL_METADATA,
    path,
    metadata,
  };
}

export function updateModelMetadataAsync(path, segment, metadata) {
  return (dispatch, getState) => {
    setTimeout(() => {
      matchPath(
        path,
        segment,
        match => {
          if (match) {
            const stateMetadata = getReduxModelState(dispatch, getState).getMetadata();
            let previousAccess = stateMetadata.getPathAttribute(`${segment.root}.${match.$path}`, 'accessed');
            previousAccess = previousAccess === undefined ? 0 : previousAccess;
            const delta = metadata.accessed - previousAccess;

            // dispatch after 600ms or if path was never accessed
            if (delta >= 600 || previousAccess === 0) {
              dispatch(updateModelMetadata(`${segment.root}.${match.$path}`, metadata));
            }
          }
        },
        0
      );
    });
  };
}
