/* eslint-disable no-param-reassign */
function transform(timing, baseTime) {
  Object.keys(timing).forEach(key => {
    if (key === 'name') {
      // name property is useful for plat team developer if debugging this feature,
      // but not really useful to product team developer
      delete timing[key];
    } else if (key === 'startTime' || key === 'endTime') {
      timing[key] -= baseTime; // transform Date.now() to time elapsed since server start time
    } else if (typeof timing[key] === 'object' && timing[key] !== null) {
      transform(timing[key], baseTime);
      timing.depth = Math.max(timing[key].depth + 1, timing.depth || 0);
    }
  });
  timing.depth = 'depth' in timing && timing.depth != null ? timing.depth : 0;
  return timing;
}

/**
 * timings
 *
 * @param  {Object} state  Current state
 * @param  {Object} action State update action
 * @return {Object}        New state
 */
export function profilerReducer(state = {}, action = {}) {
  switch (action.type) {
    case 'PROFILER_ACTION_PRELOADER_UPDATE': {
      const relStartTime = action.timing.startTime - action.requestStartTime;
      const relEndTime = action.timing.endTime - action.requestStartTime;
      const key = `preloader.load()-${relStartTime}-${relEndTime}`;
      const transformed = transform(action.timing, action.requestStartTime);
      transformed.depth -= 1; // have depths show as preloader.load():1 => resolve:1 => fetch:0 as opposed to preloader.load():2
      const newState = {
        ...state,
        [key]: transformed,
      };
      return newState;
    }
    case 'PROFILER_ACTION_CMP_UPDATE': {
      const { componentName } = action;
      const newState = {
        ...state,
        [`component-${componentName}`]: {
          ...state[`component-${componentName}`],
          ...transform(action.timing, action.requestStartTime),
        },
      };
      return newState;
    }
    default:
      return state;
  }
}
