export const URL_PATHS = {
  APPRAISAL_LANDING: '/appraisal/',
  DOPPELGANGER_LANDING: '/appraisal/promotions/chicagobears/',
  SELL_CAR_LANDING: '/sell-car/',
  TMV_LANDING: '/tmv.html',
};

export const LANDING_URL_PATHS = [
  URL_PATHS.APPRAISAL_LANDING,
  URL_PATHS.DOPPELGANGER_LANDING,
  URL_PATHS.SELL_CAR_LANDING,
  URL_PATHS.TMV_LANDING,
];
