import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';

function pageShowListenerWrapper(debugBfCache) {
  return function pageShowListener(event) {
    const loadedFromCache = event.persisted;

    const [navEntry] = window.performance.getEntriesByType('navigation');
    const notRestoredReasons = navEntry.notRestoredReasons;

    if (debugBfCache) {
      // eslint-disable-next-line no-console
      console.log('[back-forward-browsing-engagement-handler] pageShowListener(event)', {
        navigationType: navEntry.type,
        loadedFromCache,
        notRestoredReasons,
        more: {
          navEntry,
          event,
        },
      });
    }
    if (navEntry.type === 'back_forward' || notRestoredReasons || loadedFromCache) {
      // during testing the navigation type, intermittently, showed as navigationType: 'reload' on back button
      // browsing when it should have showed 'back_forward'.  so this field cannot be counted on. as a
      // workaround we added || notRestoredReasons || loadedFromCache to the condition
      if (window?.newrelic) {
        window.newrelic.addPageAction('BfNavigation', {
          loadedFromCache,
          notRestoredReasons: JSON.stringify(notRestoredReasons?.reasons),
        });
      }
    }
  };
}

export const BackForwardBrowsingEngagementHandler = {
  /**
   * Setups "pageshow" event listener
   *
   * @param  {Object} getState Returns App redux store state
   * @return {void}
   */
  init({ getState }) {
    const {
      featureFlags: { debugBfCache, dummyBfCacheUnload, bfCacheMonitoring },
    } = getState();

    if (!bfCacheMonitoring || !window.performance?.getEntriesByType) {
      return; // ff disabled or an old unsupported browser
    }

    const listener = pageShowListenerWrapper(debugBfCache);

    window.addEventListener('pageshow', listener);
    EventToolbox.on(PAGE_EVENTS.PAGE_UNLOAD, () => window.removeEventListener('pageshow', listener));

    if (dummyBfCacheUnload) {
      // subscribe to unload event to ensure bf cache will not work (for testing purposes)
      window.addEventListener('unload', event => {
        // eslint-disable-next-line no-console
        console.log('[back-forward-browsing-engagement-handler] Dummy unload event callback()', { event });
      });
    }

    if (debugBfCache) {
      window.addEventListener('pagehide', event => {
        if (event.persisted) {
          // eslint-disable-next-line no-console
          console.log(
            '[back-forward-browsing-engagement-handler] pagehide cb() - This page *might* be entering the bfcache.',
            { event }
          );
        } else {
          // eslint-disable-next-line no-console
          console.log(
            '[back-forward-browsing-engagement-handler] pagehide cb() - This page will unload normally and be discarded.',
            { event }
          );
        }
      });
    }
  },
};
