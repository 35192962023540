import { UPDATE_URL_CONTEXT } from 'client/actions/constants';
/**
 * Updates URL Context
 *
 * @param  {Object} state  Current state
 * @param  {Object} action State update action
 * @return {Object}        New state
 */
export function urlContextReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_URL_CONTEXT: {
      return {
        ...state,
        context: action.urlContext,
      };
    }
    default:
      return state;
  }
}
