import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const CAR_NEWS_TYPES = ['truck', 'ev'];

export const carNewsRoutes = [
  {
    path: `/car-news`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car-news-landing" */ '../pages/car-news/index/index-definition')
    ),
    chunkName: 'car-news-landing',
    enableLooseEtag: true,
  },
  {
    path: `/car-news/:type(${CAR_NEWS_TYPES.join('|')})`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "type-news" */ '../pages/car-news/type-news/type-news-definition')
    ),
    chunkName: 'type-news',
  },
];
