import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/dealerships/dealer-api-comparison-example',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "dealer_api_comparison" */ '../pages/dealer-api-comparison/dealer-api-comparison')
    ),
    chunkName: 'dealer_api_comparison',
  },
];
