import { validation } from 'site-modules/shared/components/form-validation/validation';

/**
 * Returns squishVIN according to provided VIN number
 * squishVIN consists of VIN digits 1-8 followed by VIN digits 10-11
 * the method returns false if the provided VIN number is in unexpected format.
 *
 * @param {string} vin VIN number
 * @returns {string} squishVIN
 */
export const getSquishVIN = vin => validation.validateVIN(vin) && `${vin.substring(0, 8)}${vin.substring(9, 11)}`;
