import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { HTTP_GONE } from 'client/utils/http-status';

export const VDP_PAGES = ['', 'featured-listing', 'highlights'];

export const gone = {
  path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/:vdpPage(${VDP_PAGES.join('|')})`,
  exact: true,
  page: getLoadablePage(
    () => import(/* webpackChunkName: "vdp_gone" */ '../pages/vdp/vdp-gone-definition'),
    // TODO: PLAT-1417 Do refactoring for routes using extra params
    match => ({
      ...match.params,
    })
  ),
  chunkName: 'vdp_gone',
  status: HTTP_GONE,
};

export const routes = [
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "used_model_car_inventory" */ '../pages/vdp/vdp-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'used_model_car_inventory',
    methods: 'get,post',
    enableLooseEtag: true,
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/featured-listing/`,
    exact: true,
    chunkName: 'vla_used_model_car_inventory',
    page: getLoadablePage(
      () => import(/* webpackChunkName: "vla_used_model_car_inventory" */ '../pages/vdp/vla-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    methods: 'get,post',
    enableLooseEtag: true,
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/highlights`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "highlights_used_model_car_inventory" */ '../pages/vdp/vdp-highlights-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'highlights_used_model_car_inventory',
    methods: 'get,post',
    enableLooseEtag: true,
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/vin/:vin/history-report/`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "history-report" */ '../pages/vdp/history-report-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'history-report',
    inlineCritical: false,
    enableLooseEtag: true,
  },
];
