import { LOCATION_CHANGE } from './constants';

/**
 * @param location
 * @param action
 * @param shouldCallParseApi {boolean} - an optional param (which is set on a route) that indicates that the parse api
 *                                     - should be called.
 */
export const locationChange = (location, action, shouldCallParseApi = false) => ({
  type: LOCATION_CHANGE,
  payload: {
    location,
    action,
    shouldCallParseApi,
  },
});
