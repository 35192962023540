import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: [
      '/:make(honda)/:model(civic)/:year(2007)/long-term-road-test/:model(civic)-:submodel(si).html',
      '/car-reviews/long-term-road-test/:year(1966|1989)-:make(chevrolet|yugo)-:model(corvette|gvl)/',
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/long-term-road-test`,
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/long-term-road-test`,
    ],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "long_term_road_test" */ '../pages/long-term-road-test/long-term-road-test-definition')
    ),
    chunkName: 'long_term_road_test',
  },
];
