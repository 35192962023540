import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { isNode } from 'client/utils/environment';
import { rootReducer } from './reducers/root-reducer';
import { createReduxModelStore } from './data/luckdragon/redux/redux-model';
import { debounceMiddleware } from './utils/redux-debounce-middleware';
import { pageReloadMiddleware } from './utils/page-reload-middleware';
import { performanceMiddleware } from './utils/performance-middleware';
import { urlContextMiddleware } from './utils/url-context-middleware';
import { eventMiddleware } from './utils/event-middleware';
import { vehicleMiddleware } from './utils/vehicle-middleware';
import { featureFlagsMiddleware } from './utils/feature-flags-middleware';
import { scrollRestorationMiddleware } from './utils/scroll-restoration-middleware';
import { timeouts } from './constants/redux-timeouts';

// Setups store specific for client (client specific multiple middlewares, dev tools setup)
function initClientStore(initialState) {
  // Store middleware setup
  const reduxDevToolsOptions = {
    maxAge: 200, // maximum allowed actions to be stored in the history tree. https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#maxage
  };
  const debouncer = debounceMiddleware(timeouts);
  const storeEnhancer = composeWithDevTools(reduxDevToolsOptions)(
    applyMiddleware(
      thunk,
      debouncer,
      pageReloadMiddleware,
      performanceMiddleware,
      urlContextMiddleware,
      vehicleMiddleware,
      eventMiddleware,
      featureFlagsMiddleware,
      scrollRestorationMiddleware
    )
  );
  return createReduxModelStore(rootReducer, initialState, storeEnhancer);
}

// Setups store specific for server
function initServerStore(initialState) {
  // Store middleware setup
  const storeEnhancer = applyMiddleware(thunk);
  return createReduxModelStore(rootReducer, initialState, storeEnhancer);
}

/**
 * Initializes app redux store with the provided initial state data
 * and saves it to the exported store variable, so modules can access it directly when required
 *
 * @param {Object} initialState Initial app state data, usually transfered from server-side
 * @returns {Object} Application redux store
 */
export function initStore(initialState) {
  return isNode() ? initServerStore(initialState) : initClientStore(initialState);
}
