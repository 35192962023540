import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { GTMEngagementHandler } from './gtm-engagement-handler/gtm-engagement-handler';
import { PageEngagementHandler } from './page-engagement-handler/page-engagement-handler';
import { ElementEngagementHandler } from './element-engagement-handler/element-engagement-handler';
import { ScrollEngagementHandler } from './scroll-engagement-handler/scroll-engagement-handler';
import { BreakpointEngagementHandler } from './breakpoint-engagement-handler/breakpoint-engagement-handler';
import './drawer-engagement-handler/drawer-engagement-handler';
import './ads-engagement-handler/ads-engagement-handler';
import { LocationEngagementHandler } from './location-engagement-handler/location-engagement-handler';
import { SpeedcurveEngagementHandler } from './speedcurve-engagement-handler/speedcurve-engagement-handler';
import { PerformanceEngagementHandler } from './performance-engagement-handler/performance-engagement-handler';
import { BackForwardBrowsingEngagementHandler } from './back-forward-browsing-engagement-handler/back-forward-browsing-engagement-handler';
/**
 * engagement handlers registered by page at runtime
 * @type {Array}
 */
const runtimeEngagementHandlers = new Set();

let getStateRef;
let dispatchRef;
let subscribeRef;

let initialized = false;

export const EngagementHandler = {
  /**
   * Inits app engagement handler
   * invoked by venom team on app init, page authors should NOT call this method
   * @param  {Function} getState Returns app redux store state
   * @param  {Function} dispatch Dispatch app redux store actions
   * @return {void}
   */
  init({ getState, dispatch, subscribe }) {
    GTMEngagementHandler.init({ getState });
    PageEngagementHandler.init({ getState });
    ElementEngagementHandler.init({ getState, dispatch });
    ExperimentUtil.init(getState, dispatch);
    ScrollEngagementHandler.init({ dispatch, getState });
    BreakpointEngagementHandler.init({ dispatch, getState });
    LocationEngagementHandler.init({ subscribe, getState });
    PerformanceEngagementHandler.init({ getState });
    BackForwardBrowsingEngagementHandler.init({ getState });

    // window.LUX will be undefined when `speedcurve` is disabled via feature flag
    if (window.LUX) {
      SpeedcurveEngagementHandler.init();
    }

    runtimeEngagementHandlers.forEach(engagementHandler => {
      engagementHandler.init({ getState, dispatch, subscribe });
    });
    getStateRef = getState;
    dispatchRef = dispatch;
    subscribeRef = subscribe;

    initialized = true;
  },
  /**
   * to be called by page authors to register their engagement handlers
   * @param engagementHandler should be an object that has an init() method
   * which will be passed the following params init({ dispatch, getState, subscribe })
   */
  register(engagementHandler) {
    if (runtimeEngagementHandlers.has(engagementHandler)) {
      return;
    }
    runtimeEngagementHandlers.add(engagementHandler);

    if (initialized) {
      engagementHandler.init({ getState: getStateRef, dispatch: dispatchRef, subscribe: subscribeRef });
    }
  },
};
