import { REGISTER_EXPERIMENT_ON_PAGE } from 'client/actions/constants';

/**
 * Updates list of names of experiments that are present on the current page.
 *
 * @param  {Array} state  Current state
 * @param  {Object} action State update action
 * @return {Array}        New state
 */
export const pageExperiment = (state = {}, action = {}) => {
  switch (action.type) {
    case REGISTER_EXPERIMENT_ON_PAGE: {
      const { name, isPublished, isActive, isEligible, isPageExperiment } = action;
      const existing = state[name];
      if (
        existing &&
        existing.isPublished === isPublished &&
        existing.isActive === isActive &&
        existing.isEligible === isEligible
      ) {
        return state;
      }
      const newState = {
        ...state,
        [name]: {
          isPublished,
          isActive,
          isEligible,
          isPageExperiment,
        },
      };
      return newState;
    }
    default:
      return state;
  }
};
