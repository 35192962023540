import { get, capitalize } from 'lodash';

const LINE_BREAK_SYMBOL = '\n';
/**
 * Takes a string and strips any HTML tags
 *
 * @param str
 * @returns {String}
 */
export function stripHtml(str) {
  return (str || '').replace(/(<([^>]+)>)/gi, '');
}

/**
 * Takes a string(url) and strips out "http".
 * @param {String} str
 * @returns {String}
 */
export function stripHttp(str) {
  return (str || '').replace(/^http:(.+)/, '$1');
}

/**
 * Removes all non-numeric characters from a string
 * @param {string} str
 * @returns {string}
 */
export const stripNonNumeric = str => (str ? str.replace(/[^0-9]/g, '') : str);

/**
 * Takes a string and split it by line breaks
 * @param str
 * @returns {Array}
 */
export function splitByLineBreak(str) {
  return str.trim().split(LINE_BREAK_SYMBOL);
}

/**
 * Convert string to boolean considering 'true' and 'false' stringy values coming from API
 * @param str
 * @returns {boolean}
 */
export function toBooleanOrString(str) {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return str;
  }
}

/**
 * Truncate string with ellipsis after defined number of symbols
 * @param str
 * @param numberOfSymbols
 * @returns {String}
 */
export function ellipsisTruncate(str, numberOfSymbols) {
  return str.length > numberOfSymbols ? `${str.substr(0, numberOfSymbols)}...` : str;
}

export function capitalizeEachWord(str) {
  return str?.replace(/\b\w/g, l => l.toUpperCase());
}

/**
 * Replaces dashes('-') with spaces(' ') and capitalizes each word
 * @param slug
 * @returns {String}
 */
export function slugToCapitalizedString(slug) {
  return capitalizeEachWord(slug.replace(/-/g, ' '));
}

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

/**
 * Returns the word with the "a" or "an" article based on its first letter
 * @param {String} word
 * @param {Boolean} capitalizeArticle
 * @returns {String}
 */
export function getWordWithRightArticle(word, capitalizeArticle) {
  const firstLetter = get(word, [0], '');
  const isFirstLetterVowel = VOWELS.includes(firstLetter.toLowerCase());
  const article = isFirstLetterVowel ? 'an' : 'a';

  return `${capitalizeArticle ? capitalize(article) : article} ${word}`;
}

export function getSubdomainWithoutPrefix(hostname) {
  const domainParts = hostname.split('.');
  if (domainParts.length < 3) {
    return null;
  }
  const subdomain = domainParts[0].toLowerCase() === 'www' ? domainParts[1] : domainParts[0];
  const subdomainExceptions = ['venom', 'edmunds', 'sandbox'];
  for (let i = 0; i < subdomainExceptions.length; i += 1) {
    if (subdomain.includes(subdomainExceptions[i])) {
      return null;
    }
  }
  const prefixes = ['qa-21-', 'qa-11-', 'qa-ext11-', 'prod-21-', 'prod-11-', 'qa-', 'prod-'];
  for (let i = 0; i < prefixes.length; i += 1) {
    const prefix = prefixes[i];
    if (subdomain.startsWith(prefix)) {
      const slicedSubdomain = subdomain.slice(prefix.length);
      if (slicedSubdomain === 'www') {
        return null;
      }
      return subdomain.slice(prefix.length);
    }
  }
  return subdomain;
}
