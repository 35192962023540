import * as API from 'site-modules/shared/constants/allowed-inventory-request-params';

/**
 * type values used for APIs, not url params
 */
export const CAR_TYPES = {
  CONVERTIBLE: 'convertible',
  COUPE: 'coupe',
  CROSSOVER: 'crossover',
  DIESEL: 'diesel',
  ELECTRIC: 'electric',
  HATCHBACK: 'hatchback',
  HYBRID: 'hybrid',
  LUXURY: 'luxury',
  MINIVAN: 'minivan',
  SEDAN: 'sedan',
  SPORTS_CAR: 'sports-car',
  SUV: 'suv',
  TRUCK: 'truck',
  WAGON: 'wagon',
  EXOTIC: 'exotic',
  ECONOMY: 'economy',
  SUBCOMPACT: 'subcompact',
  MIDSIZE: 'midsize',
  MUSCLE: 'muscle',
  COMPACT: 'compact',
  ELECTRICSUVS: 'suv,electric',
  ELECTRICAWD: 'electric,awd',
  HYBRIDSUVS: 'suv,hybrid',
  LARGESUVS: 'suv,large',
  SMALLSUVS: 'suv,small',
  MIDSIZESUVS: 'suv,midsize',
  LUXURYSUVS: 'suv,luxury',
  THREEROWSUVS: 'suv,3-row',
  SMALLSEDANS: 'sedan,small',
  MIDSIZESEDANS: 'sedan,midsize',
  LARGESEDANS: 'sedan,large',
  LUXURYSEDANS: 'sedan,luxury',
  SPORTSEDANS: 'sedan,sport',
  SMALLTRUCKS: 'truck,small',
  MIDSIZETRUCKS: 'truck,midsize',
  LARGETRUCKS: 'truck,large',
  HEAVYDUTYTRUCKS: 'truck,heavy-duty',
  SMALLWAGONS: 'wagon,small',
  MIDSIZEWAGONS: 'wagon,midsize',
  LUXURYCOUPES: 'coupe,luxury',
  SPORTSCOUPES: 'coupe,sports-cars',
  MUSCLECARS: 'coupe,muscle-cars',
  CONVERTIBLECARS: 'convertible,cars',
  SMALLHATCHBACKS: 'hatchback,small',
  HOTHATCHES: 'hatchback,hot-hatches',
  LUXURYELECTRICCARS: 'electric,luxury-electric-cars',
  LUXURYELECTRICSUVS: 'electric,luxury-electric-suvs',
  LUXURYHYBRIDCARS: 'hybrid,luxury',
  PLUGINYHYBRIDCARS: 'hybrid,plug-in-hybrid-cars',
  PLUGINYHYBRIDSUVS: 'hybrid,plug-in-hybrid-suvs',
};

/**
 * Existing types that have a type page
 * CAR_TYPES is an inconsistent mapping for this page.
 * ie, type page 'electric' is not the correct url param for electric cars
 */
export const PAGE_CAR_TYPES = {
  SUV: 'suv',
  SEDAN: 'sedan',
  COUPE: 'coupe',
  HATCHBACK: 'hatchback',
  TRUCK: 'truck',
  WAGON: 'wagon',
  CONVERTIBLE: 'convertible',
  MINIVAN: 'minivan',
  ELECTRIC: 'electric-car',
  HYBRID: 'hybrid',
  EXOTIC: 'exotic',
  ECONOMY: 'economy',
  SUBCOMPACT: 'subcompact',
  MIDSIZE: 'midsize',
  MUSCLE: 'muscle',
  COMPACT: 'compact',
  CROSSOVER: 'crossover',
  SPORTS_CAR: 'sports-car',
};

/**
 * Existing types that have a make/type page
 * CAR_TYPES is an inconsistent mapping for this page.
 * ie, make/type page does not support 'sports-car' and
 * 'electric' is not the correct url param for electric cars
 */
export const PAGE_CAR_MAKE_TYPES = {
  CONVERTIBLE: 'convertible',
  COUPE: 'coupe',
  CROSSOVER: 'crossover',
  DIESEL: 'diesel',
  ELECTRIC: 'electric-car',
  HATCHBACK: 'hatchback',
  HYBRID: 'hybrid',
  LUXURY: 'luxury',
  MINIVAN: 'minivan',
  SEDAN: 'sedan',
  SUV: 'suv',
  TRUCK: 'truck',
  WAGON: 'wagon',
};

export const PAGE_CAR_MAKE_TYPE_SET = new Set(Object.values(PAGE_CAR_MAKE_TYPES));

export const TYPE_SLUG_TO_FILTER_MAP = {
  [PAGE_CAR_MAKE_TYPES.CONVERTIBLE]: {
    [API.BODY_TYPE]: ['Convertible'],
  },
  [PAGE_CAR_MAKE_TYPES.COUPE]: {
    [API.BODY_TYPE]: ['Coupe'],
  },
  [PAGE_CAR_MAKE_TYPES.CROSSOVER]: {
    [API.BODY_TYPE]: ['SUV'],
  },
  [PAGE_CAR_MAKE_TYPES.DIESEL]: {
    [API.ENGINE_TYPE]: ['diesel'],
  },
  [PAGE_CAR_MAKE_TYPES.ELECTRIC]: {
    [API.ENGINE_TYPE]: ['electric'],
  },
  [CAR_TYPES.ELECTRIC]: {
    [API.ENGINE_TYPE]: ['electric'],
  },
  [PAGE_CAR_MAKE_TYPES.HATCHBACK]: {
    [API.BODY_TYPE]: ['Hatchback'],
  },
  [PAGE_CAR_MAKE_TYPES.HYBRID]: {
    [API.ENGINE_TYPE]: ['hybrid'],
  },
  [PAGE_CAR_MAKE_TYPES.LUXURY]: {
    [API.DISPLAY_PRICE]: ['70000-*'],
  },
  [PAGE_CAR_MAKE_TYPES.MINIVAN]: {
    [API.BODY_TYPE]: ['Minivan'],
  },
  [PAGE_CAR_MAKE_TYPES.SEDAN]: {
    [API.BODY_TYPE]: ['Sedan'],
  },
  [PAGE_CAR_MAKE_TYPES.SUV]: {
    [API.BODY_TYPE]: ['SUV'],
  },
  [PAGE_CAR_MAKE_TYPES.TRUCK]: {
    [API.BODY_TYPE]: ['Pickup'],
  },
  [PAGE_CAR_MAKE_TYPES.WAGON]: {
    [API.BODY_TYPE]: ['Station Wagon'],
  },
};

/**
 * for subtype page routes
 * example: /suv/small/
 * example: /coupe/sports-cars/
 */
export const PAGE_CAR_SUBTYPES = {
  HYBRID: 'hybrid',
  ELECTRIC: 'electric',
  SMALL: 'small',
  MIDSIZE: 'midsize',
  LARGE: 'large',
  LUXURY: 'luxury',
  '3_ROW': '3-row',
  SPORT: 'sport',
  HEAVY_DUTY: 'heavy-duty',
  SPORTS_CARS: 'sports-cars',
  MUSCLE_CARS: 'muscle-cars',
  CARS: 'cars',
  HOT_HATCHES: 'hot-hatches',
  LUXURY_ELECTRIC_CARS: 'luxury-electric-cars',
  LUXURY_ELECTRIC_SUVS: 'luxury-electric-suvs',
  PLUG_IN_HYBRID_CARS: 'plug-in-hybrid-cars',
  PLUG_IN_HYBRID_SUVS: 'plug-in-hybrid-suvs',
  AWD: 'awd',
};

/**
 * for subtype page
 * When displayName does not include type category
 * example: displayName = "Sport compacts"
 * example: /sedan/sport/
 */
export const SUBTYPE_MATCH_WHITELIST = {
  sedan: ['sport compacts'],
};
