import { LEGACY_PAGE_UPDATE, PAGE_UNLOAD } from 'client/actions/constants';

export function legacyPageReducer(state = {}, action = {}) {
  switch (action.type) {
    case LEGACY_PAGE_UPDATE:
      return { ...action.legacyPageInfo };
    case PAGE_UNLOAD:
      return {};
    default:
      return state;
  }
}
