const TEST_KEY = 'STORAGE-7c1f3709-3f94-4a50-b261-a57f18fe1e79';

/**
 * Parse JSON if represented string is JSON string, in another case return null.
 * @param str
 * @returns {boolean/null}
 */
function parseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

/**
 * Storage wrapper for better work with localStorage or sessionStorage.

 * Init storage by `storage = new Storage('localStorage')`.
 * Get items from storage with `storage.get('key')`.
 * Set item to storage with `storage.set('key', value)`.
 */
export class Storage {
  constructor(storageName) {
    this.storage = null;
    const isStorageNameValid = storageName === 'localStorage' || storageName === 'sessionStorage';
    if (typeof window !== 'undefined' && isStorageNameValid) {
      try {
        this.storage = window[storageName];
        if (this.storage) {
          this.storage.setItem(TEST_KEY, 'test');
          this.storage.removeItem(TEST_KEY);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Storage('${storageName}')`, e);
      }
    }
  }
  /**
   * Check storage existing
   * @returns {boolean}
   */
  storageInitialized() {
    return !!this.storage;
  }

  /**
   * Get item from storage by key and parse it
   * @param key
   * @returns {*}
   */
  get(key) {
    if (this.storageInitialized()) {
      try {
        const item = this.storage.getItem(key);
        return parseJSON(item) || item;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Storage('${this.storageName}')`, e);
        return null;
      }
    }
    return null;
  }

  /**
   * Set item to storage by key and value.
   * Value springfield before set.
   * @param key
   * @param value
   */
  set(key, value) {
    if (this.storageInitialized()) {
      try {
        this.storage.setItem(key, JSON.stringify(value));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Storage('${this.storageName}')`, e);
      }
    }
  }

  /**
   * Remove an item of a storage by key.
   */
  remove(key) {
    if (this.storageInitialized()) {
      this.storage.removeItem(key);
    }
  }
}
