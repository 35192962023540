import { CATEGORIES_MAPPING } from 'client/data/models/sitemap';

export const SITEMAP_CATEGORIES_ROUTES = Object.keys(CATEGORIES_MAPPING);
export const VEHICLE_MAKES_SITEMAP_ROUTE = '/makes-sitemap.html';
export const STATIC_SITEMAPS = ['/uci-sitemaps.html'];
export const SITEMAP_CATEGORY_ROUTE = '/about/sitemap-category.html';
export const ALPHABETICAL_SITEMAP_ROUTE = '/about/sitemap.html';
export const CONTENT_SITEMAPS = {
  [SITEMAP_CATEGORY_ROUTE]: 'sitemaps/about/sitemap-category',
  [ALPHABETICAL_SITEMAP_ROUTE]: 'sitemaps/about/sitemap',
};
export const CONTENT_SITEMAPS_ROUTES = Object.keys(CONTENT_SITEMAPS);
