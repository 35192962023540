export const ICO_SCRIPT_SRC = 'https://trade-in-offers.edmunds.com/static/js/edm-ico.js';
export const ICO_UNIFIED_CONTAINER_SRC = 'https://content-container.edmunds.com/';
export const ICO_TARGET_CLASS = 'edmunds-instant-offer';
export const ICO_TEST_DEALER_ID = '850449';
export const ICO_TEST_ZIP_CODE = '22310';
export const ICO_WIDGET_STORE_TYPE_NAME = 'cms.instantOffer';
export const ICO_ON_EDMUNDS_CREATIVE_IDS = {
  VEHICLE_LOOKUP: 'ico_vehicle_lookup',
  REDUCED_HEIGHT_STANDARD_EDMUNDS: 'ico_reduced_height_standard_edmunds',
  APPRAISAL_ZIP_CODE: 'ico_appraisal_zip_code',
};

export const ICO_WIDGET_DISPLAY_TYPES = {
  HERO: 'hero',
  HIDDEN: 'hidden',
  STANDARD: 'standard',
  STANDARD_AUTOCOMPLETE: 'standard-autocomplete',
  REDUCED_HEIGHT_AUTOCOMPLETE: 'reduced-height-autocomplete',
  REDUCED_HEIGHT_STANDARD: 'reduced-height-standard',
  REDUCED_HEIGHT_STANDARD_EDMUNDS: 'reduced-height-standard-edmunds',
  REDUCED_HEIGHT_STANDARD_EDMUNDS_THEME: 'reduced-height-standard-edmunds-theme',
  STANDARD_TRIGGER: 'standard-trigger',
  NO_BANNER_OVERLAY: 'no-banner-overlay',
  BANNER_OVERLAY: 'banner-overlay',
  DEFAULT: 'default',
  DEFAULT_EDMUNDS_THEME: 'default-edmunds-theme',
  SHOWROOM: 'showroom',
};

/**
 * Will be compared to raw keys BEFORE transformation using ICO_DATASET_KEYS_TRANSFORM_MAP
 */
export const ICO_ON_EDMUNDS_SUPPRESSED_CONFIG_KEYS = [
  /* Dealer branding (requested in WIDG-301): */
  'bannerbghex',
  'headerbghex',
  'primarycustomcolorhex',
  'secondarycustomcolorhex',
  'configmake',
];

/* See WIDG-445 & Sync with original copy at services-extension repo (under datasetKeysTransformMap['cms.instantOffer']): */
export const ICO_DATASET_KEYS_TRANSFORM_MAP = {
  tradein: 'voitype',
};

/* Sync with original OFFER_CTA_TYPES instance at edmunds-instant-offer repo: */
export const ICO_OFFER_CTA_TYPES = {
  APPLY_TO_DEAL: 'apply-to-deal',
  APPLY_TO_CALCULATOR: 'apply-to-calculator',
  CHAT_WITH_DEALER: 'chat-with-dealer',
};

export const ICO_WIDGET_EVENTS = {
  OPEN: '_TRADE_IN_OFFER_TRIGGER_CLICK',
  CLOSE: '_TRADE_IN_CLOSED',
  LEAD_FORM_SUBMIT: '_TRADE_IN_OFFER_LEAD_FORM_SUBMIT',
};

export const ICO_WIDGET_USER_ACTIONS = {
  ABANDONED: 'abandoned',
  CLICKED_CTA: 'clicked-cta',
};

export const ICO_NATIONWIDE_LANDING_PAGE = {
  PAGE_NAME: 'ico_nationwide_landing_page',
  CATEGORY: 'car_inventory',
  ZIPCODE_PAGE_NAME: 'ico_appraisal_zip_code',
  BREADCRUMB_TITLE: 'Instant Cash Offer',
  PAGE_HEADER: 'Sell My Car Online for\u00A0Cash',
  PAGE_SUB_HEADER:
    'Selling your car has never been easier. Just answer a few simple questions to see your online instantly. Then bring in your vehicle to a local dealer to get your check',
};

export const DEALER_TYPE = {
  ICO: 'ico_dealer',
  KMX: 'kmx_dealer',
};

export const ICO_PRODUCT_IDS = {
  ICO_VENOM: 'ico-venom',
};

export const NUM_ICO_DEALERS = 1;
