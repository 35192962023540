import debounce from 'lodash/debounce';
import mapValues from 'lodash/mapValues';

export const debounceMiddleware = (config = {}) => () => next => {
  const debouncers = mapValues(config, option => {
    if (typeof option === 'number') {
      return debounce(next, option);
    }

    const { wait = 0, ...options } = option;

    return debounce(next, wait, options);
  });

  return action => {
    const { meta = {} } = action;
    const debouncer = debouncers[meta.debounce];

    if (debouncer) {
      return debouncer(action);
    }

    return next(action);
  };
};
