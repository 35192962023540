import { CLEAR_CURRENT_SCROLL_PROPS, SAVE_CURRENT_SCROLL_PROPS } from 'client/actions/constants';

export function scrollPropsReducer(state = {}, action = {}) {
  switch (action.type) {
    case SAVE_CURRENT_SCROLL_PROPS:
      return {
        ...state,
        scrollY: action.scrollY,
        bodyScrollHeight: action.bodyScrollHeight,
      };
    case CLEAR_CURRENT_SCROLL_PROPS:
      return {};
    default:
      return state;
  }
}
