import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/dealerships',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "dealerships_landing" */ '../pages/dealerships-landing/dealerships-landing-definition')
    ),
    chunkName: 'dealerships_landing',
  },
];
