import { pick, pickBy, identity, forEach, get } from 'lodash';

const FIELDS_TO_SAVE = [
  'appraisalOffers',
  'appraisalOffersHistory',
  'appraisalMultiOffers',
  'appraisalMultiOffersHistory',
  'alerts',
  'leads',
  'mergedAttributes',
  'person',
  'pii',
  'savedSearches',
  'vehicles',
  'lastLeadReference',
  'financing',
  'edmundsMembership',
  'edmundsDeals',
];

/**
 * Return date that is ISO format.
 * @returns {string}
 */
export const getSyncDate = () => new Date().toISOString();

const getSavableVinData = idm => {
  // eslint-disable-next-line no-param-reassign
  forEach(get(idm, 'vehicles.vins', {}), vehicle => delete vehicle.submittedTs);
  return idm;
};

/**
 * Get all defined savable data.
 * Savable Data: See "FIELDS_TO_SAVE".
 * @param {Object} idm data
 * @returns {Object} savable data without null or undefined values
 */
export const getSavableData = idm => getSavableVinData(pick(pickBy(idm, identity), FIELDS_TO_SAVE));
