import { last, get } from 'lodash';
import { PUB_STATES } from 'client/constants/pub-states';
import { isUsed as isUsedPubState } from 'site-modules/shared/utils/publication-states';
import {
  getMakeLinkText,
  getMakeLinkUrl,
  getMakeModelLinkText,
  getMakeModelLinkUrl,
  getMakeModelYearLinkText,
  getMakeModelYearLinkUrl,
  getMakeModelSubmodelYearLinkUrl,
  getMakeModelSubmodelYearLinkText,
  getMakeModelYearReviewLinkUrl,
} from './vehicle-link-constructor';

export function getUrlPathFromParams(params, section) {
  return `/${params.make}/${params.model}/${params.year}/${section}/`;
}

export function buildCoreBreadcrumbs({
  makeModelSubmodelYear,
  params,
  defaultYear,
  pageDisplayName = '',
  urlPath = '',
  includeLastLink = false,
  isMainCorePage = false,
  isSeot3630Enabled = false,
  seot3630Submodel = null,
}) {
  if (!makeModelSubmodelYear) {
    return [];
  }
  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    submodels: { name: submodelName, slug: submodelSlug },
    year,
  } = makeModelSubmodelYear;

  const isUsed = params.year ? isUsedPubState(get(makeModelSubmodelYear, 'pubStates', {})) : false;

  const HOME_BREADCRUMB = { title: 'Home', href: '/' };
  const MAKE_BREADCRUMB = {
    title: getMakeLinkText({ makeName }),
    href: getMakeLinkUrl({ makeSlug }),
  };

  const MODEL_BREADCRUMB = {
    title: getMakeModelLinkText({ makeName, modelName }),
    href: getMakeModelLinkUrl({ makeSlug, modelSlug }),
  };

  const YEAR_BREADCRUMB = {
    title: getMakeModelYearLinkText(
      {
        makeName,
        modelName,
        year,
        publicationState: isUsed ? PUB_STATES.USED : undefined,
      },
      isUsed ? 'For Sale' : ''
    ),
    href: getMakeModelYearLinkUrl({ makeSlug, modelSlug, year }),
  };

  const YEAR_REVIEW_BREADCRUMB = {
    title: 'Review',
    href: getMakeModelYearReviewLinkUrl({ makeSlug, modelSlug, year }),
  };

  const SUBMODEL_YEAR_BREADCRUMB = params.submodel
    ? {
        title: getMakeModelSubmodelYearLinkText({
          makeName,
          year,
          submodelName,
          publicationState: isUsed ? PUB_STATES.USED : undefined,
        }),
        href: getMakeModelSubmodelYearLinkUrl({ makeSlug, modelSlug, year, submodelSlug }),
      }
    : null;

  const CURR_PAGE_BREADCRUMB = {
    title: pageDisplayName,
    href: urlPath,
  };

  const CURR_PAGE_MODEL_BREADCRUMB = {
    title: getMakeModelLinkText({ makeName, modelName }),
    href: urlPath,
  };

  /*
  SEOT-3630
  swap out model breadcrumb
  for submodel (Sedan, Coupe, Convertible, Minivan)
  if core-breadcrumbs feature flag is enabled
  */
  const SEOT3630_SUBMODEL_BREADCRUMB =
    isSeot3630Enabled && seot3630Submodel
      ? {
          title: `${seot3630Submodel}s`,
          href: `/${seot3630Submodel}/`.toLowerCase(),
        }
      : '';

  const breadcrumbs = [];

  breadcrumbs.push(HOME_BREADCRUMB, MAKE_BREADCRUMB, MODEL_BREADCRUMB);

  if (params.year) {
    if (parseInt(year, 10) !== defaultYear) {
      breadcrumbs.push(YEAR_BREADCRUMB);
    }

    if (isUsed && isMainCorePage) {
      breadcrumbs.push(YEAR_REVIEW_BREADCRUMB);
    }

    if (SUBMODEL_YEAR_BREADCRUMB) {
      breadcrumbs.push(SUBMODEL_YEAR_BREADCRUMB);
    }
  }

  // SEOT3630 Replace Make with Chal Submodel
  if (SEOT3630_SUBMODEL_BREADCRUMB) {
    breadcrumbs.splice(1, 1, SEOT3630_SUBMODEL_BREADCRUMB);
  }

  if (urlPath) {
    breadcrumbs.push(params.year ? CURR_PAGE_BREADCRUMB : CURR_PAGE_MODEL_BREADCRUMB);
  }

  // Remove href from last breadcrumb
  if (!includeLastLink) {
    const lastBreadcrumb = last(breadcrumbs);
    lastBreadcrumb.href = '';
    breadcrumbs[breadcrumbs.length - 1] = lastBreadcrumb;
  }

  return breadcrumbs;
}
