import { PAGE_START_LOADING, PAGE_META, PAGE_ADS_CUSTOM_TARGETING } from 'client/actions/constants';

export function pageReducer(state = {}, action = {}) {
  switch (action.type) {
    case PAGE_START_LOADING:
    case PAGE_META:
      // update state only on SPA transitions. for a hard refresh page info is
      // already in the state from server so no need update and trigger re-renders
      return state.name === action.page.name ? state : { ...state, ...action.page };
    case PAGE_ADS_CUSTOM_TARGETING:
      return {
        ...state,
        adsCustomTargeting: action.adsCustomTargeting,
      };
    default:
      return state;
  }
}
