import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';
import { types } from 'client/data/constants/car-types';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { UNITED_STATES_LIST } from 'client/data/constants/united-states-list';

export const routes = [
  {
    path: '/car-incentives',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "car_incentives" */ '../pages/incentives-definition')),
    chunkName: 'car_incentives',
  },
  {
    path: '/car-incentives/about.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car_incentives_about" */ '../pages/incentives-about-definition')
    ),
    chunkName: 'car_incentives_about',
  },
  {
    path: `/:make(${makes.join('|')})/car-incentives.html`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_incentives_make" */ '../pages/incentives-make-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
      })
    ),
    chunkName: 'car_incentives_make',
  },
  {
    path: `/:type(${types.join('|')})/car-incentives.html`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_incentives_type" */ '../pages/incentives-type-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        type: match.params.type,
      })
    ),
    chunkName: 'car_incentives_type',
  },
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/deals`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_incentives_model" */ '../pages/incentives-model-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => match.params
    ),
    chunkName: 'car_incentives_model',
  },
  {
    path: [
      `/electric-car/tax-credits-rebates-incentives`,
      `/electric-car/tax-credits-rebates-incentives/:state(${UNITED_STATES_LIST.join('|')})`,
    ],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "electric_car_incentives" */ '../pages/electric-car-incentives-definition')
    ),
    chunkName: 'electric_car_incentives',
  },
];
