import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: '/insider/process-search',
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "insider-process-search" */ '../pages/process-search/process-search')
  ),
  chunkName: 'insider-process-search',
};
