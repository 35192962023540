/* eslint-disable venom/no-malformed-magic-comment */
import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { MAKE_ARTICLES } from 'site-modules/shared/constants/editorial/make-articles';

export const MAKES_LANDINGS = makes.filter(make => !MAKE_ARTICLES.includes(make));

export const routes = [
  {
    path: `/:make(${MAKES_LANDINGS.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "make_index" */ 'client/site-modules/make/pages/make/make-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => match.params
    ),
    chunkName: 'make_index',
  },
];
