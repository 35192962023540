import dateFormat from 'dateformat';

const WEEKENDS = [6, 0]; // Saturday and Sunday accordingly
const MILLISECONDS_IN_MINUTE = 60000;
const PACIFIC_TIME = {
  STANDARD: 28800000,
  DAYLIGHT: 25200000,
};

/**
 * Gets standard timezone offset
 * @param {Date} date
 * @returns {number}
 */
function stdTimezoneOffset(date) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);

  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

/**
 * Checks PST or PDT time
 * @param {Date} date
 * @returns {boolean}
 */
function isDaylightSavingsTime(date) {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
}

/**
 * Transforms local time to America/Los_Angeles timezone
 * @param {Date} date
 * @returns {Date}
 */
function getLATime(date) {
  const utc = date.getTime() + date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE;
  return isDaylightSavingsTime(date) ? new Date(utc - PACIFIC_TIME.DAYLIGHT) : new Date(utc - PACIFIC_TIME.STANDARD);
}

/**
 * Determines if date is weekend
 * @param {Date} date
 * @returns {boolean}
 */
function isWeekend(date) {
  return WEEKENDS.includes(date.getDay());
}

/**
 * Defines whether a local Los Angeles time meets working ours
 * @param {Object} workingHoursRange - should contain min and max fields
 * @returns {boolean}
 */
export function isWorkingHours(workingHoursRange) {
  const date = new Date();
  const currentLATime = getLATime(date);
  if (isWeekend(date)) return false;
  const currentHour = currentLATime.getHours();
  return currentHour >= workingHoursRange.min && currentHour < workingHoursRange.max;
}

/**
 * Return current year
 * @param {Date|number} date
 * @returns {string}
 */
export const getCurrentYear = date => dateFormat(date, 'yyyy');

export const getCurrentMonth = date => dateFormat(date, 'mmmm');

/**
 * @param {Date|number} date
 * @param {string} mask
 * @returns {Date}
 */
export function formatDate(date, mask = 'yyyy/mm/dd') {
  return dateFormat(date, mask);
}

/**
 * Note: valid only for USA.
 * Get DST start time in local time zone - second sunday of March 02:00
 * @param {number|string} year - current year
 * @param {number} gmtOffset - standard offset (without dst applied)
 * @returns {Date} DST start time with offset applied
 */
function getSecondSundayOfMarch(year, gmtOffset) {
  const secondSunday = new Date(year, 2, 7);
  secondSunday.setDate(7 + (7 - secondSunday.getDay()));
  return new Date(`${formatDate(secondSunday)} 02:00 ${gmtOffset}`);
}

/**
 * Note: valid only for USA.
 * Get DST end time in local time zone - first sunday of November 02:00
 * @param {number|string} year - current year
 * @param {number} gmtOffset - standard offset (without dst applied)
 * @returns {Date} DST end time with offset applied
 */
function getFirstSundayOfNovember(year, gmtOffset) {
  const firstSunday = new Date(year, 10, 7);
  firstSunday.setDate(7 - firstSunday.getDay());
  return new Date(`${formatDate(firstSunday)} 02:00 ${gmtOffset}`);
}

/**
 * Note: valid only for USA.
 * Calculates correct offset considering DST (Daylight Saving Time)
 * @param {number} gmtOffset
 * @param {string} dst
 * @returns {number}
 */
export function getOffset(gmtOffset, dst) {
  const parsedDst = parseInt(dst, 10);
  if (!dst) {
    return gmtOffset;
  }

  const currentTime = Date.now();
  const currentYear = getCurrentYear(currentTime);
  const dstStart = getSecondSundayOfMarch(currentYear, gmtOffset);
  const dstEnd = getFirstSundayOfNovember(currentYear, gmtOffset);
  const isDstObserved = currentTime >= dstStart.getTime() && currentTime <= dstEnd.getTime();
  return isDstObserved ? gmtOffset + parsedDst : gmtOffset;
}
