import { isUsedState } from './publication-states';

function checkUsed(publicationState, linkText) {
  const isUsed = isUsedState(publicationState);
  if (isUsed) {
    return `Used ${linkText}`;
  }
  return linkText;
}

/**
 * get<something>LinkUrl methods don't contain forward slash at the end
 * because of `additionalLinkPath` param, which can be also
 *  - a *.html page
 *  - a ?query=param
 */

export function getMakeLinkUrl(vehicleData, additionalLinkPath = '') {
  const { makeSlug } = vehicleData;
  return `/${makeSlug}/${additionalLinkPath}`.trim();
}

export function getMakeLinkText(vehicleData, additionalLinkText = '', isPlural = false) {
  const { publicationState, makeName } = vehicleData;
  const linkText = `${makeName}${isPlural ? 's' : ''} ${additionalLinkText}`.trim();
  return checkUsed(publicationState, linkText);
}

export function getMakeModelLinkUrl(vehicleData, additionalLinkPath = '') {
  const { makeSlug, modelSlug } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${additionalLinkPath}`.trim();
}

export function getMakeModelLinkText(vehicleData, additionalLinkText = '', isPlural = false) {
  const { publicationState, makeName, modelName } = vehicleData;
  const linkText = `${makeName} ${modelName}${isPlural ? 's' : ''} ${additionalLinkText}`.trim();
  return checkUsed(publicationState, linkText);
}

export function getMakeModelYearLinkUrl(vehicleData, additionalLinkPath = '') {
  const { makeSlug, modelSlug, year } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${year}/${additionalLinkPath}`.trim();
}

export function getMakeModelYearLinkText(vehicleData, additionalLinkText = '') {
  const { publicationState, makeName, modelName, year } = vehicleData;
  const linkText = `${year} ${makeName} ${modelName} ${additionalLinkText}`.trim();
  return checkUsed(publicationState, linkText);
}

export function getMakeModelSubmodelYearLinkUrl(vehicleData, additionalLinkPath = '') {
  const { makeSlug, modelSlug, submodelSlug, year } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${year}/${submodelSlug}/${additionalLinkPath}`.trim();
}

export function getMakeModelSubmodelYearLinkText(vehicleData, additionalLinkText = '') {
  const { publicationState, makeName, submodelName, year } = vehicleData;
  const linkText = `${year} ${makeName} ${submodelName} ${additionalLinkText}`.trim();
  return checkUsed(publicationState, linkText);
}

export function getMakeModelYearStyleLinkUrl(vehicleData, additionalLinkPath = '') {
  const { makeSlug, modelSlug, year, slug } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${year}/${slug}/${additionalLinkPath}`.trim();
}

export function getMakeModelYearStyleLinkText(vehicleData, additionalLinkText = '') {
  const { publicationState, makeName, modelName, year, trimName } = vehicleData;
  const linkText = `${year} ${makeName} ${modelName} ${trimName} ${additionalLinkText}`.trim();
  return checkUsed(publicationState, linkText);
}

export function getMakeModelYearReviewLinkUrl(vehicleData) {
  const { makeSlug, modelSlug, year } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${year}/review/`.trim();
}

export function getMakeModelSubmodelYearReviewLinkUrl(vehicleData) {
  const { makeSlug, modelSlug, submodelSlug, year } = vehicleData;
  return `/${makeSlug}/${modelSlug}/${year}/${submodelSlug}/review/`.trim();
}
