/* eslint-disable no-prototype-builtins */
import { VenomVersion } from 'client/data/models/version';
import { PageModel } from 'client/data/models/page';
import { RequestModel } from 'client/data/models/request';
import { XHeaders } from 'client/data/models/x-headers';

export const CLIENT_ARTIFACT = 'venom';

function resolveMetricOptions(options, context) {
  const metricHeaders = {
    'x-artifact-id': CLIENT_ARTIFACT,
  };

  const promises = [
    context.resolveValue('page.name', PageModel, false),
    context.resolveValue('page.category', PageModel, false),
    context.resolveValue('apiGatewayInvalidate', PageModel, false),
    context.resolveValue('version', VenomVersion, false),
    context.resolveValue('x-trace-id', XHeaders, false),
    context.resolveValue('x-trace-seq', XHeaders, false),
    context.resolveValue('protocol', RequestModel, false),
    context.resolveValue('hostName', RequestModel, false),
    context.resolveValue('url', RequestModel, false),
  ];

  return Promise.all(promises).then(
    ([pageName, pageCategory, apiGatewayInvalidate, venomVersion, xTraceId, xTraceSeq, protocol, hostName, url]) => {
      metricHeaders['x-client-action-name'] = `${pageName}.${context.displayName}`;
      metricHeaders['x-artifact-version'] = venomVersion;
      metricHeaders['x-trace-id'] = xTraceId;

      if (pageName) metricHeaders['x-edw-page-name'] = pageName;
      if (pageCategory) metricHeaders['x-edw-page-cat'] = pageCategory;

      if (xTraceSeq) metricHeaders['x-trace-seq'] = xTraceSeq;

      const referer = `${protocol}://${hostName}${url}`;
      metricHeaders['x-referer'] = referer;
      metricHeaders.Referer = referer;

      const cacheHeaders = apiGatewayInvalidate ? { 'Cache-Control': 'no-cache' } : {};

      const headers = {
        ...options.headers,
        ...metricHeaders,
        ...cacheHeaders,
      };
      return {
        ...options,
        headers,
      };
    }
  );
}

function isEdmundsGraphQL(api) {
  return typeof api.query === 'function';
}

export function withMetrics(api, context) {
  const { timing } = context;
  return isEdmundsGraphQL(api)
    ? {
        query(query, variables = {}, options = {}) {
          const myTiming = { duration: null, startTime: Date.now() };
          timing[`query:${JSON.stringify(query)}`] = myTiming;
          return resolveMetricOptions(options, context).then(optionsWithMetrics =>
            api.query(query, variables, optionsWithMetrics).then(response => {
              myTiming.endTime = Date.now();
              myTiming.duration = myTiming.endTime - myTiming.startTime;
              return response;
            })
          );
        },
      }
    : {
        fetch(resourcePath, options = {}) {
          const myTiming = { duration: null, startTime: Date.now() };
          timing[`fetch:${resourcePath}`] = myTiming;
          return resolveMetricOptions(options, context).then(optionsWithMetrics =>
            api.fetch(resourcePath, optionsWithMetrics).then(response => {
              myTiming.endTime = Date.now();
              myTiming.duration = myTiming.endTime - myTiming.startTime;
              return response;
            })
          );
        },
        fetchJson(resourcePath, options = {}) {
          const myTiming = { duration: null, startTime: Date.now() };
          timing[`fetch:${resourcePath}`] = myTiming;
          return resolveMetricOptions(options, context).then(optionsWithMetrics =>
            api.fetchJson(resourcePath, optionsWithMetrics).then(response => {
              myTiming.endTime = Date.now();
              myTiming.duration = myTiming.endTime - myTiming.startTime;
              return response;
            })
          );
        },
      };
}
