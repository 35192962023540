import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { SITE_SERVED_ADS } from 'site-modules/site-served-ad/constants/ads';

export const routes = [
  /**
   * Example: https://www.edmunds.com/site-served-units/incentives-native-ad/
   */
  {
    path: `/site-served-units/:adName(${SITE_SERVED_ADS.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "site_served_units" */ '../pages/ads'),
      match => ({
        adName: match.params.adName,
      })
    ),
    methods: 'get,post',
    chunkName: 'site_served_units',
  },
];
