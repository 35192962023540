import { TrackingConstant } from 'client/tracking/constant';
import {
  APPRAISAL_PAGE_CREATIVE_ID,
  PARTNER_OFFER_TRACKING_CREATIVE_ID,
} from 'site-modules/shared/constants/appraisal/appraisal';

/**
 * Consolidates common API response status tracking data and logic,
 *   with a generator that parameterizes unique values/overrides (against baseTrackingObject),
 *   including with special conditions, for explicitness--which may otherwise be missed.
 * @returns {{SHARED_BASE_TRACKING_OBJECTS, generateTrackingObject()}} As direct properties
 */
export const API_RESPONSE_STATUS_TRACKING = (() => {
  // Factory IIFE starts
  const SHARED_BASE_TRACKING_OBJECTS = {
    TMV_API_EPO: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        subaction_name: TrackingConstant.SUBACTION_TMV_APPRAISAL,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
      },
    },

    STYLE_API_VAC: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        subaction_name: TrackingConstant.SUBACTION_GRAPHQL_VEHICLE_STYLE_REQUEST,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.SYSTEM_ACTION_CAUSE,
        creative_id: APPRAISAL_PAGE_CREATIVE_ID,
      },
    },
  };

  return {
    SHARED_BASE_TRACKING_OBJECTS,

    /**
     * @param rawErrorStatus Must receive and track raw `undefined` values (e.g., unreachable API) as is. See EVAL-4096
     *   Tip: When pre-processing to pass-through `undefined` values,
     *   be sure to test for its existence if needed, like by doing: `"rawErrorStatus" in apiResponseObject === true`
     *
     *   Tips from Platform team in EVAL-4096:
     *     - Success Status tracking should be avoided where we can, since such tracking will be too excessive,
     *         as our API passes 99% of the time.
     *     - Test to ensure tracking only ever happens on client-side.
     *  @param drawerCreativeIdOverride Explicit override for Drawers (on VDP, etc.) if truthy;
     *   otherwise, creative ID should be passed in the other base param
     */
    generateTrackingObject({ baseTrackingObject, rawErrorStatus, drawerCreativeIdOverride }) {
      return {
        ...baseTrackingObject,
        event_data: {
          ...baseTrackingObject.event_data,
          ...(drawerCreativeIdOverride ? { creative_id: drawerCreativeIdOverride } : {}),
          value: `${rawErrorStatus}_ERROR`,
        },
      };
    },
  };
})(); // Factory IIFE ends
