import { EventToolbox } from 'client/utils/event-toolbox';

let sentLUXBeacon;
let sendLUXBeaconOnLoad;

function sendLUXBeacon() {
  window.LUX.send();
  sentLUXBeacon = true;
}

export function trySendLUXBeaconOnLoad() {
  sentLUXBeacon = false;
  sendLUXBeaconOnLoad = setTimeout(sendLUXBeacon, 15000);
}

export function trySendLUXBeaconBeforeUnload() {
  if (!sentLUXBeacon) {
    clearTimeout(sendLUXBeaconOnLoad);
    sendLUXBeacon();
  }
}

export const SpeedcurveEngagementHandler = {
  /**
   * Event listeners are unloaded after an unload. From page to page, these
   * event callbacks are attached once and dettached once.
   * SPA transitions are handled at the router level. This means these two
   * event callbacks are not reattached to the window multiple times for SPA.
   */
  init() {
    EventToolbox.on('load', trySendLUXBeaconOnLoad, true, false, window);
    EventToolbox.on('beforeunload', trySendLUXBeaconBeforeUnload, true, false, window);
  },
};
