import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { HTTP_GONE } from 'client/utils/http-status';

export const routesGone = [
  {
    path: '*',
    page: getLoadablePage(() => import(/* webpackChunkName: "test_microsite_gone" */ '../pages/gone/gone')),
    chunkName: 'test_microsite_gone',
    inlineCritical: false,
    status: HTTP_GONE,
  },
];

export const routesGoneDemo = [
  {
    path: '/gone',
    page: getLoadablePage(() => import(/* webpackChunkName: "test_microsite_gone" */ '../pages/gone/gone')),
    chunkName: 'test_microsite_gone',
    inlineCritical: false,
    status: HTTP_GONE,
  },
];
