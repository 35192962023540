import { ADS_CHANGE_ZONE, ADS_REFRESH, ADUNITS_REFRESH } from 'client/actions/constants';

export function adsContextReducer(state = { adsts: {}, refreshZones: [] }, action = {}) {
  switch (action.type) {
    case ADS_REFRESH:
      return {
        ...state,
        adsts: action.adsts,
        refreshZones: action.refreshZones,
      };
    case ADUNITS_REFRESH:
      return {
        ...state,
        trigger: action.trigger,
        tsPerUnits: {
          ...state.tsPerUnits,
          ...action.tsPerUnits,
        },
      };
    case ADS_CHANGE_ZONE:
      return {
        ...state,
        adCurrentZone: action.adCurrentZone,
        adZones: action.adZones,
      };
    default:
      return state;
  }
}
