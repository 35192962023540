import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const error = {
  path: '*',
  page: getLoadablePage(() => import(/* webpackChunkName: "error_page" */ '../pages/error/error')),
  chunkName: 'error_page',
  inlineCritical: false,
};
// this route is used to demo the error page, error page template is generated from this source
export const routes = [
  {
    ...error,
    path: '/v/error-page',
  },
  {
    path: '/v/error-page-e2e',
    page: getLoadablePage(() => import(/* webpackChunkName: "error_page_e2e" */ '../pages/error/error-e2e')),
    chunkName: 'error_page_e2e',
    inlineCritical: false,
  },
];
