import { PAGE_LOAD, PAGE_PRELOAD, PAGE_STATUS_UPDATE, PAGE_UNLOAD } from 'client/actions/constants';

export function loadingReducer(state = {}, action = {}) {
  switch (action.type) {
    case PAGE_PRELOAD:
      return {
        ...state,
        isPreloadSuccess: action.result,
        isPreloaded: true,
      };
    case PAGE_LOAD:
      // fired immediately after PAGE_PRELOAD, todo: merge into single action
      return {
        ...state,
        isLoaded: true,
      };
    case PAGE_UNLOAD:
      return {
        ...state,
        isPreloaded: false,
        isLoaded: false,
        statusCode: null,
        isPreloadSuccess: undefined,
      };
    case PAGE_STATUS_UPDATE:
      return {
        ...state,
        statusCode: action.statusCode,
      };
    default:
      return state;
  }
}
