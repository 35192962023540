import { parseContent } from 'client/data/cms/content';
import { ENTRY_ID } from 'site-modules/editorial/constants/best';

const HOME_BREADCRUMB = { title: 'Home', href: '/' };

export function isBestContent(contentId) {
  return !!contentId && contentId.includes('research/best/');
}

export function isBestPageName(edwPrefix) {
  return !!edwPrefix && edwPrefix.includes('best_of');
}

export function buildBreadcrumbs(configEntry, pageTitle, pathname) {
  const configLinks = configEntry.child('breadcrumb').links();

  return (configLinks.length ? configLinks : [HOME_BREADCRUMB])
    .map(({ title, href }) => ({ title, href }))
    .concat({
      title: pageTitle,
      href: pathname,
    });
}

export function insertPricingCardsEntry(entries) {
  const authorEntryIndex = entries.findIndex(({ id }) => id === ENTRY_ID.AUTHOR);

  if (authorEntryIndex < 0) {
    return entries;
  }

  return [
    ...entries.slice(0, authorEntryIndex + 1),
    parseContent({ id: ENTRY_ID.PRICING_CARDS }),
    ...entries.slice(authorEntryIndex + 1),
  ];
}
