import { isNode } from './environment';

class ClientTimerSingelton {
  constructor() {
    this.clientStartTimestamp = 0;
  }

  /**
   * Initialize client timer
   * @param {Number} timestamp when client started working (webappStart is called)
   */
  init(timestamp = Date.now()) {
    this.clientStartTimestamp = timestamp;
  }

  /**
   * returns the number of milliseconds that have elapsed since
   * the webapp was started on client side.
   */
  getTimeElapsed() {
    return isNode() ? 0 : Date.now() - this.clientStartTimestamp;
  }

  /**
   * returns timestamp derived from the time elapsed since start
   */
  getTimestampFromTimeElapsed(timeElapsed) {
    return this.clientStartTimestamp + timeElapsed;
  }
}

export const ClientTimer = new ClientTimerSingelton();
