import { shuffle } from 'client/utils/seed-randomizers';
import { logger } from 'client/utils/isomorphic-logger';

export const FORMATS = {
  number: /^[\d.]+$/,
  truthy: /^(true)|(yes)|(y)|(1)$/i,
};

const TIME_PATTERN = /T\d{2}:\d{2}/;
const SECONDS_PATTERN = /T\d{2}:\d{2}:\d{2}/;
const TIMEZONE_PATTERN = /[+-]\d{1,2}:\d{2}$/;

function normalizeDate(dateStr) {
  // timezone offset is not a constant value due to daylight saving
  // therefore, it needs to be redefined everytime a call is made, inside the scope of the function call, instead of only one time at the time of server start at the top level scope
  // PST = '-08:00', PDT = '-07:00'
  // getTimezoneOffset returns the difference in minutes, i.e. 420 for '-07:00' in PDT, and 480 for '-08:00' in PST
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
  const isPST = new Date().getTimezoneOffset() === 480;
  const timezoneOffSet = isPST ? '-08:00' : '-07:00';
  if (!TIMEZONE_PATTERN.test(dateStr)) {
    if (!SECONDS_PATTERN.test(dateStr)) {
      if (!TIME_PATTERN.test(dateStr)) {
        return `${dateStr}T00:00:00${timezoneOffSet}`;
      }
      return `${dateStr}:00${timezoneOffSet}`;
    }

    return `${dateStr}${timezoneOffSet}`;
  }

  return dateStr;
}

export function parseDate(dateStr) {
  const dateToParse = normalizeDate(dateStr);

  return Date.parse(dateToParse);
}

function createBinding(obj, key) {
  const stringValue = obj[key];

  return {
    value(defaultValue = '') {
      return (stringValue || defaultValue).trim();
    },

    number(defaultValue = 0.0) {
      const value = this.value();
      if (FORMATS.number.test(value)) {
        return parseFloat(value);
      }
      return defaultValue;
    },

    boolean(defaultValue = false) {
      const value = this.value();
      if (value) {
        return FORMATS.truthy.test(value);
      }

      return defaultValue;
    },

    array(delimiter) {
      return this.value().split(delimiter);
    },

    json(defaultValue = {}) {
      try {
        return JSON.parse(this.value());
      } catch (error) {
        logger('warn', 'cms.content.js json(): failed to parse value');
        return defaultValue;
      }
    },
  };
}

function bindMetadata(contentMetadata) {
  return Object.keys(contentMetadata).reduce(
    (binding, name) => ({
      ...binding,
      [name]: createBinding(contentMetadata, name),
    }),
    {}
  );
}

const NULL_BINDING = createBinding({});

export function parseContent(jsonData = {}) {
  const links = jsonData.links || [];
  const contentMetadata = jsonData.contentMetadata || {};
  const childEntries = jsonData.childEntries || [];

  const metadataBinding = bindMetadata(contentMetadata);
  const childrenById = childEntries.reduce((byId, child) => {
    const childId = child.id;
    if (childId) {
      return {
        ...byId,
        [childId]: child,
      };
    }

    return byId;
  }, {});
  const parsedChildren = {};

  const contentData = Object.assign({}, jsonData);
  delete contentData.contentMetadata;

  return {
    ...contentData,
    links() {
      return links;
    },
    getAllMetadata() {
      const metadata = {};
      const keys = Object.keys(metadataBinding);
      keys.forEach(key => {
        if (this.hasMetadata(key)) {
          metadata[key] = this.metadata(key).value();
        }
      });

      return metadata;
    },
    /**
     * returns the value for specified metadata.
     *
     * If metadata with the spceified name does not exist it will return a default empty value, - this was
     * meant to prevent typos/errors in CMS from crashing the page, however, if the metadata is meant to be
     * optional, please use the hasMetadata() to check for existence before using this method.
     * @param name
     * @returns {string | {number(*=): (*|*), boolean(*=): (*|*), array(*=): *, json(*=): (*|*|undefined), value(*=): *}}
     */
    metadata(name) {
      return metadataBinding[name] || NULL_BINDING;
    },
    hasMetadata(name) {
      return Object.prototype.hasOwnProperty.call(metadataBinding, name);
    },
    children() {
      return childEntries.map(child => parseContent(child));
    },
    /**
     * parses and returns the child with specified id.
     *
     * If child with the spceified id does not exist it will return a default empty child, - this was
     * meant to prevent typos/errors in CMS from crashing the page, however, if the child is meant to be
     * optional, please use the hasChild() to check for existence before using this method.
     * @param childId
     * @returns {*|(T&U&{metadata(*): (string|{number, (*=): (*), boolean, (*=): (*), array, (*=): *, json, (*=): (*|undefined), value, (*=): *}), children(): *, hasChild(*): *, links(): *, getAllMetadata(): *, hasMetadata(*=): *, child(*): *})}
     */
    child(childId) {
      const childContent =
        parsedChildren[childId] || (parsedChildren[childId] = parseContent(childrenById[childId] || {}));

      return childContent;
    },
    hasChild(childId) {
      return childId in childrenById;
    },
  };
}

export function parseText(text) {
  const result = text.replace(/(<([^>]+)>)/gi, '').split(';');
  return result.filter(value => value.length).map(value => value.trim());
}

export const transform = feed => parseContent(feed);

/**
 * Function creates metadata map from key's array and content object.
 * If key is missing in content then it will be assigned to ''
 *
 * @param {Array} keys - Array of strings, each item is a metadata name
 * @param {Object} content - content object produced by Content.parseContent()
 *
 * @return {Object} metadata map //example: { key: metadataValue, key2: anotherValue }
 */
export function extractMetadata(keys, content) {
  const metadata = {};
  keys.forEach(item => {
    metadata[item] = content.metadata(item).value();
  });

  return metadata;
}

export const DEFAULT_CONTENT = {
  ...parseContent({}),
  isDefault: true,
};

function sortByPositionMetadata(a, b) {
  return a.metadata('position').value() - b.metadata('position').value();
}

/**
 * Function positions entries with specified positions and randomizes the rest entries
 *
 * @param {Object} content - content object produced by Content.parseContent()
 * @param {function} randomizeCb - callback to randomize entries
 *
 * @return {Array} array of positioned entries
 */
export function positionEntries(content = [], randomizeCb) {
  // Container for entries to be shuffled
  const randomizedContent = [];
  // Container for entries with specified positions
  let sortedByPosition = [];

  content.forEach(entry => {
    if (entry.hasMetadata('position')) {
      sortedByPosition.push(entry);
    } else {
      randomizedContent.push(entry);
    }
  });
  // Sort entries by their specified positions
  sortedByPosition = sortedByPosition.sort(sortByPositionMetadata);
  // Randomize entries using passed callback or default shuffle from lodash
  const result = typeof randomizeCb === 'function' ? randomizeCb(randomizedContent) : shuffle(randomizedContent);

  // Add sorted content back into result array according to their position
  sortedByPosition.forEach(entry => {
    result.splice(entry.metadata('position').value() - 1, 0, entry);
  });

  return result;
}

function getEntryPosition(entry) {
  return entry.contentMetadata.position;
}

export function orderByPosition(content = []) {
  // Container for entries to be shuffled
  const randomizedContent = [];
  // Container for entries with specified positions
  let sortedByPosition = [];

  content.forEach(entry => {
    if (Object.prototype.hasOwnProperty.call(entry.contentMetadata, 'position')) {
      sortedByPosition.push(entry);
    } else {
      randomizedContent.push(entry);
    }
  });
  // Sort entries by their specified positions
  sortedByPosition = sortedByPosition.sort((a, b) => getEntryPosition(a) - getEntryPosition(b));
  // Randomize entries using passed callback or default shuffle from lodash
  const result = shuffle(randomizedContent);

  // Add sorted content back into result array according to their position
  sortedByPosition.forEach(entry => {
    result.splice(getEntryPosition(entry) - 1, 0, entry);
  });

  return result;
}
/**
 * Get entry by id if exist or null.
 * @param {object} content - data parsed with parseContent method.
 * @param {string} entryId - entry id to return
 * @returns {object|null}
 */
export function getEntryById(content, entryId) {
  return content && content.entries && content.entries.length
    ? content.entries.find(entry => entry.id === entryId) || null
    : null;
}

/**
 * Get all metadata with name-xx where xx is index.
 * @param {Object} entry
 * @param {string} name
 * @param {Boolean} withHyphen
 * @return {Array} array of metadata values
 */
export function getNameIndexMetadata(entry, name, withHyphen = true) {
  let index = 1;
  let metadata;
  const allMetadata = [];

  do {
    metadata = entry && entry.metadata(withHyphen ? `${name}-${index}` : `${name}${index}`).value();
    if (metadata) {
      allMetadata.push(metadata);
      index += 1;
    }
  } while (metadata);

  return allMetadata;
}
