import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { getQuery } from 'client/utils/location';
import { getParamsWithMakes } from 'client/utils/router-params';
import { PageModel } from 'client/data/models/page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';

export const quickQuotesLeadFormRoutes = [
  /**
   * Non-CPO urls are handled via Shopping page template
   *
   * https://www.edmunds.com/lead-form/quickquotes.html?make=acura&model=rdx&year=2018
   * https://www.edmunds.com/acura/rdx/2018/quickquotes.html
   * https://www.edmunds.com/acura/rdx/quickquotes.html
   * https://www.edmunds.com/lead-form/jlrquickquotes.html?make=jaguar&model=f-pace&year=2018
   * https://www.edmunds.com/land-rover/range-rover-sport/2020/jlrquickquotes.html
   */
  {
    path: [
      '/lead-form/quickquotes.html',
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/quickquotes.html`,
      `/:make(${makes.join('|')})/:model/quickquotes.html`,
      '/lead-form/jlrquickquotes.html',
      `/:make(jaguar|land-rover)/:model/:year(\\d{4})/jlrquickquotes.html`,
    ],
    exact: true,
    methods: 'get,post',
    preload: (params, store) => new ModelPreloader(store).load(),
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const location = modelState.get('location', PageModel);
      const isJLR = location.pathname.includes('jlrquickquotes');
      const pageParams = {
        ...getParamsWithMakes({ match: { params } }),
        ...getQuery(location),
        pathname: location.pathname,
        isJLR,
      };

      const showShopping = pageParams.make && pageParams.model && pageParams.inventorytype !== 'cpo';

      if (showShopping) {
        return import(/* webpackChunkName: "new_model_shopping" */ '../pages/shopping/shopping-definition').then(page =>
          page.default({ isQuickQuotes: true, isJLR })
        );
      }

      return import(/* webpackChunkName: "quick-quotes-lead-form" */ '../pages/quick-quotes-lead-form-definition').then(
        page => page.default(pageParams)
      );
    }),
  },
];
