import { capitalize, uniq, get } from 'lodash';
import { PUB_STATES_MAPPING_FOR_USER, PUB_STATES, PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { isNew as isNewType } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isUsed as isUsedType } from 'site-modules/shared/utils/inventory-utils/is-used';

export function getMappedStateForUser(pubState) {
  return pubState && typeof pubState === 'string' ? PUB_STATES_MAPPING_FOR_USER[pubState.toUpperCase()] : null;
}

export function isUsedState(pubState) {
  return pubState && typeof pubState === 'string'
    ? PUB_STATES_MAPPING_FOR_USER[pubState.toUpperCase()] === PUB_STATES.USED
    : false;
}

export function isNewState(pubState) {
  return !isUsedState(pubState);
}

export function isDisabledState(pubState) {
  return pubState && typeof pubState === 'string' ? pubState.toUpperCase() === PUB_STATES.DISABLED : false;
}

export function getPublicationStateForMakeModel(pubStates, forceUsed = false) {
  return !forceUsed && (pubStates.NEW_USED || pubStates.NEW || pubStates.PRE_PROD) ? 'new' : 'used';
}

export function isUsed(pubStates) {
  return getPublicationStateForMakeModel(pubStates) === 'used';
}

export function isAlsoUsed(pubStates) {
  return !pubStates.PRE_PROD && !pubStates.NEW;
}

export function isNew(pubStates) {
  return getPublicationStateForMakeModel(pubStates) === 'new';
}

// return preprod only if it is the only pubstate
export function isPreProd(pubStates) {
  return Object.keys(pubStates).length === 1 ? !!pubStates.PRE_PROD : false;
}

/**
 * @example
 * filterPubStates(
 *  [
 *    {pubStates: {NEW: true, USED: false}},
 *    {pubStates: {NEW: false, USED: true}}
 *  ],
 *  ['NEW'])
 * => [{pubStates: {NEW: true, USED: false}}]
 *
 * @example
 * filterPubStates([{pubStates: {NEW: true, USED: false}}, {pubStates: {NEW: false, USED: true}}], ['NEW', 'FALSE'])
 * => [{pubStates: {NEW: true, USED: false}}, {pubStates: {NEW: false, USED: true}}]
 *
 * @example
 * filterPubStates([{ publicationStates: ['NEW','NEW_USED','USED'] }, { publicationStates: ['USED'] }], ['NEW'])
 * => [{ publicationStates: ['NEW','NEW_USED','USED'] }]
 *
 * @param arr
 * @param pubStates
 */
export function filterPubStates(arr, pubStates) {
  return arr.filter(item =>
    pubStates.some(
      pubState => get(item, `publicationStates`, []).includes(pubState) || get(item, `pubStates["${pubState}"]`)
    )
  );
}

export function getDisplayInventoryTypeName(pubState) {
  return pubState === PUB_STATES.NEW || pubState === PUB_STATES.USED ? capitalize(pubState) : pubState;
}

/**
 * Get publication state by vehicle type
 * @param {String} type
 * @return {String} publication state
 */
export function getPublicationStateByType(type) {
  if (isNewType(type)) {
    return PUB_STATES_LOWERCASE.NEW;
  }
  if (isUsedType(type)) {
    return PUB_STATES_LOWERCASE.USED;
  }
  return PUB_STATES_LOWERCASE.NEW_USED;
}

/**
 * In case of possible multiple types (SRP),
 * determine proper publication state
 * @param {String} types comma-separated list of inventory types
 * @returns {string}
 */
export function getPublicationStateByMultipleTypes(types) {
  const pubStatesList = (types || '').split(',').map(getPublicationStateByType);
  const uniquePubStatesList = uniq(pubStatesList);

  return uniquePubStatesList.length === 1 ? uniquePubStatesList[0] : PUB_STATES_LOWERCASE.NEW_USED;
}

export function handlePublicationState(ps) {
  let result = typeof ps === 'string' ? ps.toLowerCase() : 'new';
  if (result === 'new_used' || result === 'future' || result === 'preprod') {
    result = 'new';
  }
  return result;
}
