import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/glossary/',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "glossary" */ '../pages/glossary/glossary-definition')),
    chunkName: 'glossary',
    enableLooseEtag: true,
  },
  {
    path: '/glossary/glossary-categorized.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "glossary-categorized" */ '../pages/glossary-categorized/glossary-categorized-definition')
    ),
    chunkName: 'glossary-categorized',
    enableLooseEtag: true,
  },
  {
    path: '/glossary/:term.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "glossary-term" */ '../pages/glossary-term/glossary-term-definition')
    ),
    chunkName: 'glossary-term',
    enableLooseEtag: true,
  },
];
