import { LUXURY, SUV_LEASE_DEALS, VEHICLES, PRE_PRODUCTION_MODELS } from 'site-modules/editorial/constants/slugs';
import { MAKE_ARTICLES } from 'site-modules/shared/constants/editorial/make-articles';

const CATEGORY_LANDING_MAP = {
  research: 'research/index-test',
  [PRE_PRODUCTION_MODELS]: `research/${PRE_PRODUCTION_MODELS}/index`,
  'used-car-ratings': 'research/used-car-ratings/index',
};

export function getCategoryLandingContentPath(category, splat) {
  return splat
    ? `research/${category}/${splat}/index`
    : CATEGORY_LANDING_MAP[category] || `research/${category}/index-new`;
}

export function getBestContentPath(category, splat, articleId, path = 'index') {
  return `research/best/${category}${splat ? `/${splat}` : ''}${articleId ? `/${articleId}` : ''}${
    path ? `/${path}` : ''
  }`;
}

// START: Best of article
export function getBestOfArticleContentPath(slug, articleId, path = 'index') {
  if (slug === SUV_LEASE_DEALS) {
    return `research/lease-deals/${slug}/${path}`;
  }

  let articlePath = `/${path}`;

  if (slug === VEHICLES && path === 'index') {
    articlePath = '';
  }

  return `research/${slug}${articleId ? `/${articleId}` : ''}${articlePath}`;
}

export function getBestOfArticlesIndexPath(slug, articleId) {
  return `${getBestContentPath(slug, articleId)},/${getBestOfArticleContentPath(slug, articleId)}`;
}

export function getBestOfArticlesConfigPath(slug, articleId) {
  const configPath = slug === LUXURY ? 'articles/config' : 'config';

  if (slug === VEHICLES) {
    return getBestOfArticleContentPath(slug, '', configPath);
  }

  return getBestOfArticleContentPath(slug, articleId, configPath);
}
// END: Best of article

// START: Type article
export function getTypeArticleContentPath(slug, articleId) {
  if (MAKE_ARTICLES.includes(slug)) return `/${slug}/${slug}-buying-guide`;

  const legacyPath = slug === LUXURY ? `/research/${slug}/articles/${articleId}` : `/${slug}/articles/${articleId}`;
  return `${getBestContentPath(slug, 'articles', articleId, null)},${
    articleId ? legacyPath : `/research/${slug}/index`
  }`;
}

export function getTypeArticleConfigPath(slug) {
  return `${slug === LUXURY ? `research/${slug}/articles/config` : `${slug}/articles/config,/research/${slug}/config`}`;
}
// END: Type article

// START: Research Article
export function getResearchArticleContentPath(articleCategory, articleId, splat) {
  return splat
    ? `research/${articleCategory}/${splat}/${articleId},/${articleCategory}/${splat}/${articleId}&fetchcontents=true`
    : `research/${articleCategory}/${articleId},/${articleCategory}/${articleId}&fetchcontents=true`;
}

export function getResearchArticleIndexPath(articleCategory, articleId, splat, skipDli) {
  return `${getBestContentPath(articleCategory, splat, articleId, '')},/${getResearchArticleContentPath(
    articleCategory,
    articleId,
    splat
  )}${skipDli ? '&skipDli=true' : ''}`;
}
// END: Research article

// START: About article
export function getAboutArticleContentPath(splat, articleId) {
  return `/about/${splat ? `${splat}/` : ''}${articleId}`;
}
// END: About article

// START: Press article
export function getPressArticleContentPath(articleId) {
  return `/about/press/${articleId}`;
}
// END: Press article

export function getOneColumnNoHeroArticlePath(articleCategory, articleId) {
  return `research/${articleCategory}/articles/${articleId}`;
}

export function getAffiliateArticleContentPath(articleId = 'index') {
  return `/research/about/affiliate-program/${articleId}`;
}

export function getEdmundsInfoContentPath(articleId = 'index') {
  return `/research/about/edmunds-info/${articleId}`;
}

export function getMobileArticleContentPath(articleId) {
  return `/research/about/mobile/${articleId}`;
}

export function getTypeBeforeBuyContentPath(type) {
  return `/${type}/before-buy`;
}

export function getTypeArticlesLandingPath(type) {
  return `/${type}/articles/index`;
}

export function getAuthorContentPath(author) {
  return `research/about/editors/${author}`;
}
