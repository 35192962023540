import PropTypes from 'prop-types';
import { isFunction, get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { parseContent } from 'client/data/cms/content';
import { fetchContent } from 'client/data/cms/fetch-content';
import { getJsonPointer } from 'client/data/luckdragon/json-ref';

export function buildAuthorPath({ contentPath }) {
  return `${contentPath}.author`;
}

export function buildCoAuthorPath({ contentPath }) {
  return `${contentPath}.coAuthor`;
}

export function buildEditorPath({ contentPath }) {
  return `${contentPath}.editor`;
}

export function buildAllAuthorsPath({ contentPath }) {
  return `content["${contentPath}.allAuthors"]`;
}

export const CmsPaths = {
  buildAuthorPath,
  buildEditorPath,
  buildCoAuthorPath,
  buildAllAuthorsPath,
};

export const CmsModel = createModelSegment('cms', [
  {
    path: 'content["{feedPath}"]',
    resolve({ feedPath }, context) {
      return fetchContent(feedPath, context);
    },
  },
  {
    path: 'content["{feedPath}.withContent"]',
    resolve({ feedPath }, context) {
      return fetchContent(`${feedPath}&fetchcontents=true`, context);
    },
  },
  /**
   * @see buildAuthorPath
   */
  {
    path: 'content["{feedPath}.author"]',
    async resolve({ feedPath }, context) {
      const articleContent = await context.resolveValue(`content["${feedPath}"]`);
      const articleAuthorPath = get(articleContent, 'contentMetadata.authorPath', '');

      if (articleAuthorPath) {
        return fetchContent(articleAuthorPath, context);
      }

      return {};
    },
  },
  /**
   * @see buildCoAuthorPath
   */
  {
    path: 'content["{feedPath}.coAuthor"]',
    async resolve({ feedPath }, context) {
      const articleContent = await context.resolveValue(`content["${feedPath}"]`);
      const articleCoAuthorPath = get(articleContent, 'contentMetadata.coAuthorPath', '');

      if (articleCoAuthorPath) {
        return fetchContent(articleCoAuthorPath, context);
      }

      return {};
    },
  },
  /**
   * @see buildEditorPath
   */
  {
    path: 'content["{feedPath}.editor"]',
    async resolve({ feedPath }, context) {
      const articleContent = await context.resolveValue(`content["${feedPath}"]`);
      const articleEditorPath = get(articleContent, 'contentMetadata.editorPath', '');

      if (articleEditorPath) {
        return fetchContent(articleEditorPath, context);
      }

      return {};
    },
  },
  /**
   * @see buildAllAuthorsPath
   */
  {
    path: 'content["{feedPath}.allAuthors"]',
    async resolve({ feedPath }, context) {
      const articleContent = await context.resolveValue(`content["${feedPath}"]`);

      const authorPath = `content["${buildAuthorPath({ contentPath: feedPath })}"]`;
      const coAuthorPath = `content["${buildCoAuthorPath({ contentPath: feedPath })}"]`;
      const editorPath = `content["${buildEditorPath({ contentPath: feedPath })}"]`;

      const authorsPromise = (articleContent?.authors?.[0]?.authorPath
        ? // new format
          Promise.all(
            articleContent.authors.map(({ authorPath: authorContentPath }) => fetchContent(authorContentPath, context))
          ).then(([author, coAuthor]) =>
            Promise.all([context.updateValue(authorPath, author), context.updateValue(coAuthorPath, coAuthor)])
          )
        : // old format
          Promise.all([context.resolveValue(authorPath), context.resolveValue(coAuthorPath)])
      ).then(() => ({
        author: { $ref: getJsonPointer(authorPath) },
        coAuthor: { $ref: getJsonPointer(coAuthorPath) },
      }));

      const editorPromise = context.resolveValue(editorPath).then(() => ({
        editor: {
          $ref: getJsonPointer(editorPath),
        },
      }));

      return Promise.all([authorsPromise, editorPromise]).then(([{ author, coAuthor }, { editor }]) => ({
        author,
        coAuthor,
        editor,
      }));
    },
  },
]);

export const CmsEntities = {
  Content: PropTypes.shape({
    links: PropTypes.func.isRequired,
    metadata: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    child: PropTypes.func.isRequired,
  }),
};

export function bindToContent(feedPath, entryId) {
  return bindToPath(
    props => {
      const path = isFunction(feedPath) ? feedPath(props) : feedPath;
      return path ? `content["${path}"]` : null;
    },
    CmsModel,
    feed => {
      const feedContent = parseContent(feed);
      if (entryId) {
        return feedContent.child(entryId);
      }
      return feedContent;
    }
  );
}
