import { LOCATION_CHANGE } from 'client/actions/constants';

export function routerReducer(state = {}, action = {}) {
  if (LOCATION_CHANGE === action.type) {
    return {
      ...state,
      action: action.payload.action,
      location: { ...action.payload.location },
    };
  }

  return state;
}
