import { pageContextReducer } from './page-context/page-context-reducer';
import { experimentReducer } from './experiment-reducer';
import { carBuyingReducer } from './car-buying-reducer';
import { profileReducer } from './profile-reducer';
import { versionReducer } from './version-reducer';
import { formReducer } from './form-reducer';
import { featureFlagsReducer } from './feature-flags-reducer';
import { visitorReducer } from './visitor-reducer';
import { facetStateReducer } from './facet-state-reducer';
import { routerReducer } from './router-reducer';
import { requestReducer } from './request-reducer';
import { redirectReducer } from './redirect-reducer';
import { urlContextReducer } from './url-context-reducer';
import { financingReducer } from './financing-reducer';
import { profilerReducer } from './profiler-reducer';

/**
 * Root reducer combining all page level reducers used in the app
 *
 * @type {Function}
 */
export const rootReducer = (state = {}, action = {}) => {
  const router = routerReducer(state.router, action);
  const request = requestReducer(state.request, action);
  const redirect = redirectReducer(state.redirect, action);
  const pageContext = pageContextReducer(state.pageContext, action);
  const experiments = experimentReducer(state.experiments, action);
  const profile = profileReducer(state.profile, action);
  const carBuying = carBuyingReducer(state.carBuying, action);
  const venomVersion = versionReducer(state.venomVersion, action);
  const forms = formReducer(state.forms, action);
  const featureFlags = featureFlagsReducer(state.featureFlags, action);
  const visitor = visitorReducer(state.visitor, action);
  const facetState = facetStateReducer(state.facetState, action);
  const url = urlContextReducer(state.url, action);
  const financing = financingReducer(state.financing, action);
  const $profiler = profilerReducer(state.$profiler, action);

  return {
    ...state,
    router,
    request,
    redirect,
    pageContext,
    experiments,
    carBuying,
    profile,
    venomVersion,
    forms,
    featureFlags,
    visitor,
    facetState,
    url,
    financing,
    $profiler,
  };
};
