/*
 * Car type data
 */
export const CAR_TYPES = [
  {
    name: 'Convertible',
    slug: 'convertible',
  },
  {
    name: 'Coupe',
    slug: 'coupe',
  },
  {
    name: 'Crossover',
    slug: 'crossover',
  },
  {
    name: 'Diesel',
    slug: 'diesel',
  },
  {
    name: 'Electric',
    slug: 'electric-car',
  },
  {
    name: 'Hatchback',
    slug: 'hatchback',
  },
  {
    name: 'Hybrid',
    slug: 'hybrid',
  },
  {
    name: 'Luxury',
    slug: 'luxury',
  },
  {
    name: 'Minivan',
    slug: 'minivan',
  },
  {
    name: 'Sedan',
    slug: 'sedan',
  },
  {
    name: 'Performance/Sports',
    slug: 'sports-car',
  },
  {
    name: 'SUV',
    slug: 'suv',
  },
  {
    name: 'Truck',
    slug: 'truck',
  },
  {
    name: 'Wagon',
    slug: 'wagon',
  },
];

export const types = CAR_TYPES.map(type => type.slug);
