import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/car-reviews/consumer-ratings-reviews.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "consumer-ratings-reviews" */ '../pages/consumer-ratings-reviews/consumer-ratings-reviews-definition')
    ),
    chunkName: 'consumer-ratings-reviews',
  },
  {
    path: '/review-rating/write-review-thank-you.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "write-review-thank-you" */ '../pages/write-review-thank-you/write-review-thank-you')
    ),
    chunkName: 'write-review-thank-you',
  },
  {
    path: '/review-rating/write-review-already-published.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "write-review-already-published" */ '../pages/write-review-already-published/write-review-already-published-definition')
    ),
    chunkName: 'write-review-already-published',
  },
  {
    path: '/review-rating/write-review-thank-you-discard.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "write-review-thank-you-discard" */ '../pages/write-review-thank-you-discard/write-review-thank-you-discard')
    ),
    chunkName: 'write-review-thank-you-discard',
  },
];
