/**
 * This function can be called both on client and server, but on client it will do nothing.
 * On server, it will send custom metric to newrelic via new relic's node agent
 * this function was meant to be used by code in client/** folder given that /server/utils/monitoring
 * is not isomorphic thus cannot be imported by any code in client
 * @param {String} name
 * @param {Number} value
 */
export async function recordServerMetric(name, value) {
  if (global.newRelicNodeAgent) {
    global.newRelicNodeAgent.recordMetric(name, value);
  }
}
