import { REDIRECT } from 'client/actions/constants';

/**
 * @param state - state.redirect should be passed into this function (notice it is not the full state
 * @param action
 * @returns {{path: *}}
 */
export function redirectReducer(state = {}, action = {}) {
  if (REDIRECT === action.type) {
    return {
      ...state,
      path: action.path, // ex: '/dealerships/'
    };
  }

  return state;
}
