import React from 'react';
import { isPageChanged } from 'client/utils/location';
import { logger } from 'client/utils/isomorphic-logger';

class PageLoader {
  constructor(getPagePromise, getPageDefinitionParams) {
    this.getPagePromise = getPagePromise;
    this.getPageDefinitionParams = getPageDefinitionParams;
  }

  /**
   * Dynamically imports a page component
   * @param {Object} match - a match object, see https://reacttraining.com/react-router/web/api/match
   * @param {Object} location - current history location, have to contain at least 'pathname' property, see https://reacttraining.com/react-router/web/api/location
   */
  async loadPage(match, location, store) {
    if (store) {
      this.storeFromLastCall = store;
    }
    let page = await this.getPagePromise(match.params, store || this.storeFromLastCall);
    // possible return values from this.getPagePromise() to account for, are:
    // 1. es6 module, in this case the default export of it is either
    // 1.1. a component as in case of homepage,
    // 1.2. a callback that returns a component as in case of venom 1.0 /honda/accord/2020/
    // 2. react component as in case of venom 2.0 /internal/honda/accord/2020/
    // eslint-disable-next-line no-underscore-dangle
    page = page.default ? page.default : page;
    if ((page.isPage && page.isPage()) || page.isForm === true) {
      return page;
    }
    const pgDefParams = this.getPageDefinitionParams ? this.getPageDefinitionParams(match, location) : {};
    return page(pgDefParams);
  }
}

/**
 * Gets toute LoadablePage
 * @param {Function} dynamicallyImportPage - function that dynamically imports a page component
 * @param {Function} getPageDefinitionParams - function that gets some params to pass to pageDefinition function
 */
export function getLoadablePage(dynamicallyImportPage, getPageDefinitionParams) {
  const pageLoader = new PageLoader(dynamicallyImportPage, getPageDefinitionParams);
  return class LoadablePage extends React.Component {
    static getPageLoader() {
      return pageLoader;
    }

    constructor(props) {
      super(props);
      this.state = { page: null };
    }

    componentDidMount() {
      this.loadPage();
    }

    componentDidUpdate(prevProps) {
      // reload page (recall getPageDefinitionParams) if some url param has changed
      // e.g. as a result of venomHistory.push(link, { forceLocal: true });
      // if (getPageDefinitionParams && isPageChanged(prevProps.location, this.props.location)) {
      // todo: what was the reason for getPageDefinitionParams &&, had to remove it to support transition from /honda/accord/2020 => /toyota/camry/2020/
      if (isPageChanged(prevProps.location, this.props.location)) {
        this.loadPage();
      }
    }

    loadPage = () =>
      pageLoader
        .loadPage(this.props.match, this.props.location)
        .then(page => this.setState({ page }))
        .catch(err => logger('error', err, err.message));

    render() {
      const { page: Page } = this.state;
      return Page ? <Page {...this.props} /> : null;
    }
  };
}
