import { isNode } from 'client/utils/environment';

// The observer for the images on the page
if (!isNode()) {
  require('intersection-observer'); // eslint-disable-line global-require
}

export const { IntersectionObserver } = global;

/**
 * plays better with mocking
 * @returns {*}
 */
export function getIntersectionObserver() {
  return IntersectionObserver;
}
