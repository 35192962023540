import { URL_PATHS } from 'site-modules/shared/constants/appraisal/paths';

export const APPRAISAL_ARTICLES_PATHNAMES = {
  KBB: `${URL_PATHS.APPRAISAL_LANDING}kelley-blue-book.html`,
  NADA: `${URL_PATHS.APPRAISAL_LANDING}nada-guides/`,
  BBV: `${URL_PATHS.APPRAISAL_LANDING}black-book-value/`,
};

export const ARTICLES_URL_PATTERN_MAP = {
  [APPRAISAL_ARTICLES_PATHNAMES.KBB]: 'kelley-blue-book',
  [APPRAISAL_ARTICLES_PATHNAMES.NADA]: 'nada-guides',
  [APPRAISAL_ARTICLES_PATHNAMES.BBV]: 'black-book-value',
};

export const ARTICLES_URL_CONTENT_PATH = {
  [APPRAISAL_ARTICLES_PATHNAMES.KBB]: 'research/appraisal/kelley-blue-book',
  [APPRAISAL_ARTICLES_PATHNAMES.NADA]: 'research/appraisal/nada-guides/index',
  [APPRAISAL_ARTICLES_PATHNAMES.BBV]: 'research/appraisal/black-book-value/index',
};

export const BREADCRUMBS_TITLE_MAP = {
  [APPRAISAL_ARTICLES_PATHNAMES.KBB]: 'Kelley Blue Book',
  [APPRAISAL_ARTICLES_PATHNAMES.NADA]: 'NADAguides',
  [APPRAISAL_ARTICLES_PATHNAMES.BBV]: 'Black Book Value',
};
