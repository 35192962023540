export function isRelativeUrl(url) {
  return url.startsWith('/') && !url.startsWith('//');
}

export function isFullUrl(url) {
  return url.startsWith('http');
}

export function isFullUrlWithoutProtocol(url) {
  return !url.startsWith('//') && !url.startsWith('/') && !url.startsWith('http');
}
