import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';
import { ResponsiveToolkit } from 'client/utils/responsive-bootstrap-toolkit';
import { breakpointUpdate } from 'client/actions/responsive-breakpoint';

export function setupResizeHandler(dispatch, getState) {
  // exported for unit test
  return debounce(() => {
    ResponsiveToolkit.changed(() => {
      const breakpoint = ResponsiveToolkit.current();
      dispatch(breakpointUpdate(breakpoint, getState));
    });
    EventToolbox.fireTrackAction({
      browser_width: window.innerWidth,
    });
  }, 100);
}

/**
 * Breakpoint detection
 */
export const BreakpointEngagementHandler = {
  /**
   * Setups breakpoint engagement events listener
   *
   * @param  {Function} dispatch Dispatch app redux store actions
   * @return {void}
   */
  init({ dispatch, getState }) {
    EventToolbox.domReady(() => {
      window.addEventListener('resize', setupResizeHandler(dispatch, getState), false);
    });
  },
};
