/* eslint-disable no-underscore-dangle */

/**
 * returns U.S. Privacy User Signal API functions
 * @returns {Object} venom uspapi interface
 */
export function getUspapi(store) {
  /**
   * __uspapi function
   * @param {String} command The name of the command to execute, should always be "getUSPData" for now
   * @param {Number} version The IAB usprivacy spec version, should always be 1 for now
   * @param {Function} callback A callback that accept two parameters, uspData and success
   */
  function uspapi(command, version, callback) {
    if (command === 'getUSPData' && version === 1) {
      const state = store.getState();
      const visitor = state.visitor;
      const uspString = visitor && visitor.privacy && visitor.privacy.uspString;
      const success = !!uspString;
      callback({ version, uspString }, success);
    } else {
      callback({}, false);
    }
  }

  /**
   * handles uspapi message posted via window.postMessage()
   * @param {Object} message sent message:
   * {
   *    __uspapiCall:
   *    {
   *      command: "command",
   *      parameter: parameter,
   *      version: version,
   *      callId: uniqueId
   *    }
   *  }
   * The command,parameter and version object properties correspond to their namesake parameters
   * defined as function argument parameters for the __uspapi() method.
   */
  function handleUspapiMessage(message) {
    if (!message.data.__uspapiCall) return;

    const { command, version, callId } = message.data.__uspapiCall;
    const result = {
      __uspapiReturn: {
        returnValue: undefined,
        success: false,
        callId,
      },
    };
    uspapi(command, version, (res, success) => {
      if (success) {
        result.__uspapiReturn.success = success;
        result.__uspapiReturn.returnValue = res.uspString;
      }
    });

    // return message
    message.source.postMessage(result, message.origin);
  }

  return {
    __uspapi: uspapi,
    handleUspapiMessage,
  };
}
