import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { isEstimatedTmvConditions } from 'site-modules/shared/components/estimated-values/estimated-values-utils';
import { VisitorModel } from './visitor';

const Condition = PropTypes.shape({
  baseMSRP: PropTypes.number,
  baseInvoice: PropTypes.number,
  deliveryCharges: PropTypes.number,
  tmv: PropTypes.number,
  usedTmvRetail: PropTypes.number,
  usedPrivateParty: PropTypes.number,
  usedTradeIn: PropTypes.number,
  estimateTmv: PropTypes.number,
  tmvRecommendedRating: PropTypes.number,
});

const Tmv = PropTypes.shape({
  CLEAN: Condition,
  AVERAGE: Condition,
  OUTSTANDING: Condition,
  ROUGH: Condition,
});

const Style = PropTypes.shape({
  styleId: PropTypes.number,
  styleName: PropTypes.string,
  tmv: PropTypes.shape({
    conditions: Tmv,
  }),
});

const Styles = PropTypes.arrayOf(Style);

export const AppraisalEntities = {
  Styles,
  Style,
  Tmv,
  Condition,
};

export const VEHICLE_MILEAGE_PER_YEAR = 12000;

export function buildAllStylesAppraisalPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].styles.allconditionstmvs`;
}
export function buildAllStylesAppraisalFilteredPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].styles.allconditionstmvsFiltered`;
}

export const AppraisalModel = createModelSegment('appraisal', [
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].styles.allconditionstmvs',
    async resolve(match, context) {
      const { make, model, year } = match;
      const { zipCode } = await context.resolveValue('location', VisitorModel, false);
      const currentYear = new Date().getFullYear();
      const mileage = currentYear > year ? VEHICLE_MILEAGE_PER_YEAR * (currentYear - year) : 0;

      const url = `/usedtmv/v3/makes/${make}/models/${model}/years/${year}/styles/allconditionstmvs?zipcode=${zipCode}&mileage=${mileage}`;
      let appraisalData;
      try {
        appraisalData = await withMetrics(EdmundsAPI, context).fetchJson(url);
      } catch (e) {
        appraisalData = {
          results: [],
        };
      }
      return appraisalData.results;
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].styles.allconditionstmvsFiltered',
    async resolve(match, context) {
      const appraisalStyles = await context.resolveValue(buildAllStylesAppraisalPath(match));
      return appraisalStyles.filter(appraisalStyle => isEstimatedTmvConditions(appraisalStyle));
    },
  },
  {
    path: 'isAppraiseFinished',
  },
  {
    path: 'autoCompleteFilter',
  },
  {
    path: 'autoCompleteData',
    async resolve(match, context) {
      const text = await context.resolveValue('autoCompleteFilter');

      let results = null;

      if (!text) {
        return {
          results,
        };
      }

      try {
        results = await withMetrics(EdmundsAPI, context).fetchJson(
          `/autocomplete/v1/vehicle?type=NEW,NEW_USED,USED&text=${text}`
        );
      } catch (e) {
        results = [];
      }

      return {
        id: uuidv4(),
        results,
      };
    },
  },
]);
