import { get } from 'lodash';

export function getQAEntriesFromContent(content, visitorLocation) {
  const visitorCity = get(visitorLocation, 'city', '');

  return content.children().map(entry => ({
    question: entry
      .metadata('question')
      .value('')
      .replace(/\[INSERT LOCAL CITY\]/gim, visitorCity),
    answer: entry.metadata('answer').value(''),
  }));
}
