export const getMultiOfferResultsLink = ({ modsId, isAbsolute = false, isAppExtensionPage }) => {
  let path = `/sell-car/offers/${modsId}/`;

  // Check if SSR to avoid accessing the window.location
  if (typeof window !== 'undefined' && isAbsolute) {
    return `${location.origin}${path}`;
  }

  if (isAppExtensionPage) path += 'index.app';

  return path;
};
export const getOfferDetailsPageLink = ({ modsId, isAbsolute = false, isAppExtensionPage }) => {
  if (!modsId) {
    return undefined;
  }

  let path = `/sell-car/offer/carmax/${modsId}/`;

  // Check if SSR to avoid accessing the window.location
  if (typeof window !== 'undefined' && isAbsolute) {
    return `${location.origin}${path}`;
  }

  if (isAppExtensionPage) path += 'index.app';

  return path;
};
