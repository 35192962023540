import { UPDATE_EXT_TAG_ATTRIBUTE, PAGE_UNLOAD } from 'client/actions/constants';

export function extTagAttributeReducer(state = '', action = {}) {
  switch (action.type) {
    case UPDATE_EXT_TAG_ATTRIBUTE:
      return action.extTagAttribute;
    case PAGE_UNLOAD:
      return '';
    default:
      return state;
  }
}
