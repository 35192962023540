import { PAGE_CAR_TYPES, CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const carTypes = Object.values(PAGE_CAR_TYPES);

export const routes = [
  {
    path: `/:type(${carTypes.join('|')})`,
    exact: true,
    page: getLoadablePage(({ type }, store) =>
      import(/* webpackChunkName: "car_type" */ '../pages/type/type-definition').then(page =>
        page.default({
          type: type === PAGE_CAR_TYPES.ELECTRIC ? CAR_TYPES.ELECTRIC : type,
          featureFlags: store?.getState()?.featureFlags,
        })
      )
    ),
    chunkName: 'car_type',
  },
];
