import { getElementOffsetWidth } from './dom';

let breakpoint;
let visibilityDivs;
let toolkitDiv;

function isVisible(element) {
  return getElementOffsetWidth(element) > 0;
}

function createDomElement(className) {
  const element = document.createElement('div');
  element.className = className;

  return element;
}

function current() {
  const newBreakpoint =
    Object.keys(visibilityDivs).find(currentBreakpoint => isVisible(visibilityDivs[currentBreakpoint])) ||
    'unrecognized';
  if (!breakpoint) {
    breakpoint = newBreakpoint;
  }
  return newBreakpoint;
}

function changed(cb) {
  const newBreakpoint = current();
  if (breakpoint !== newBreakpoint) {
    breakpoint = newBreakpoint;
    cb(breakpoint);
  }
}

if (typeof document !== 'undefined') {
  visibilityDivs = {
    xs: createDomElement('hidden-sm-up'),
    sm: createDomElement('hidden-xs-down hidden-md-up'),
    md: createDomElement('hidden-sm-down hidden-lg-up'),
    lg: createDomElement('hidden-md-down hidden-xl-up'),
    xl: createDomElement('hidden-lg-down'),
  };

  toolkitDiv = createDomElement('responsive-bootstrap-toolkit');
  Object.keys(visibilityDivs).forEach(currentBreakpoint => toolkitDiv.appendChild(visibilityDivs[currentBreakpoint]));
  document.body.appendChild(toolkitDiv);
}

export const ResponsiveToolkit = {
  changed,
  current,
};
