import { BREAKPOINT_UPDATE, PAGE_START_LOADING } from 'client/actions/constants';

export function breakpointReducer(state = null, action = {}) {
  switch (action.type) {
    case BREAKPOINT_UPDATE:
      return action.breakpoint;
    case PAGE_START_LOADING:
      return action.breakpoint;
    default:
      return state;
  }
}

export function isSmallReducer(state = null, action = {}) {
  switch (action.type) {
    case BREAKPOINT_UPDATE:
      return ['xs', 'sm'].includes(action.breakpoint);
    case PAGE_START_LOADING:
      return ['xs', 'sm'].includes(action.breakpoint);
    default:
      return state;
  }
}
