/* eslint-disable no-console */
const EXCLUDED_CLASSES_LIST = ['venom-app', 'wt-active', 'wf-edmundsicons-n4-active'];

export class DomNodes {
  constructor() {
    this.allNodes = Array.from(document.getElementsByTagName('*'));
  }

  getVisibleNodes() {
    return this.allNodes.filter(node => {
      const { width, height } = node.getBoundingClientRect();
      return !this.isZeroPixelElement(width, height) && this.isInViewport(node);
    });
  }

  isZeroPixelElement = (width, height) => !width && !height;

  isInViewport = node => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const { top, left, bottom, right } = node.getBoundingClientRect();
    const isInsideWindowWidth = left >= 0 && right <= windowWidth;

    return (
      isInsideWindowWidth &&
      ((top < 0 && bottom > 0 && bottom <= windowHeight) || // Part of the node above the viewport
      (top >= 0 && bottom <= windowHeight) || // Full node inside viewport
      (top >= 0 && top < windowHeight && bottom > windowHeight) || // Part of the node below the viewport
        (top < 0 && bottom > windowHeight)) // Part of a node above and below the viewport
    );
  };

  /**
   * get the DOM nodes that are above the view port.
   * This can happen when we switch vdp back to srp and are in the middle of the page.
   * Then we want to get a list of class names that are above the viewport and can lead to an increase  the CLS.
   */
  getNodesAboveViewport() {
    return this.allNodes.filter(node => {
      const { bottom, width, height } = node.getBoundingClientRect();
      return !this.isZeroPixelElement(width, height) && bottom <= 0;
    });
  }

  getNodesNotInViewport() {
    return this.allNodes.filter(node => {
      const { width, height } = node.getBoundingClientRect();
      return !this.isZeroPixelElement(width, height) && !this.isInViewport(node);
    });
  }

  getClassNames = nodes => {
    const classNames = nodes
      .map(node => node.getAttribute('class'))
      .filter(className => !!className)
      .flatMap(className => className.trim().split(' '))
      .sort();

    return Array.from(new Set(classNames));
  };

  getVisibleClassNames() {
    const nodes = this.getVisibleNodes();
    return this.getClassNames(nodes);
  }

  getClassNamesAboveViewport() {
    const nodes = this.getNodesAboveViewport();
    return this.getClassNames(nodes);
  }

  getClassNamesNotInViewport() {
    const nodes = this.getNodesNotInViewport();
    return this.getClassNames(nodes);
  }
}

export class CriticalCssClassFinder {
  constructor() {
    this.checkedClassNames = {};
    this.criticalCssString = document.querySelector('#edm-critical-css')?.textContent || '';
  }

  searchClassNameInCriticalCss(className) {
    return this.criticalCssString.includes(className);
  }

  getAllMissingClassnames(classNamesArray) {
    if (!this.criticalCssString) return [];
    const missingClasses = [];
    classNamesArray.forEach(className => {
      if (!this.checkedClassNames[className]) {
        const isPresentInCriticalCss = this.searchClassNameInCriticalCss(className);
        this.checkedClassNames[className] = isPresentInCriticalCss;
        if (!isPresentInCriticalCss) missingClasses.push(className);
      }
    });

    return missingClasses;
  }

  filterOutRedundantClasses = classNames => classNames.filter(className => !EXCLUDED_CLASSES_LIST.includes(className));

  getMissingClassnames(classNamesArray) {
    const allMissingClassnames = this.getAllMissingClassnames(classNamesArray);
    return this.filterOutRedundantClasses(allMissingClassnames);
  }
}

export class MissingClassnamesFromCriticalCss {
  constructor(
    domNodes = new DomNodes(),
    criticalCssClassFinder = new CriticalCssClassFinder(),
    printer = {
      print(...args) {
        console.log(...args);
      },
    }
  ) {
    this.domNodes = domNodes;
    this.criticalCssClassFinder = criticalCssClassFinder;
    this.printer = printer;
  }

  run() {
    const visibleClassNames = this.domNodes.getVisibleClassNames();
    const classNamesAboveViewport = this.domNodes.getClassNamesAboveViewport();
    const classNamesNotInViewport = this.domNodes.getClassNamesNotInViewport();
    const missingInViewportClasses = this.criticalCssClassFinder.getMissingClassnames(visibleClassNames);
    const missingAboveViewportClasses = this.criticalCssClassFinder.getMissingClassnames(classNamesAboveViewport);
    const missingNonViewportClasses = this.criticalCssClassFinder.getMissingClassnames(classNamesNotInViewport);
    this.printer.print('[critical css class debugger] Classes logged', {
      missingAboveViewportClasses,
      missingInViewportClasses,
      missingNonViewportClasses,
    });
  }
}
