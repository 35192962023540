export const PAID_CODE = 'PAID';
export const FREE_CODE = 'FREE';
export const HOME_TYPE = 'HOME';
export const DEALERSHIP_TRANSFER_TYPE = 'DEALERSHIP_TRANSFER';

export const DELIVERY_AVAILABLE = 'Delivery Available';
export const DELIVERY_CODES = [PAID_CODE, FREE_CODE];

export const DELIVERY_TYPE_TITLES = {
  [PAID_CODE]: DELIVERY_AVAILABLE,
  [FREE_CODE]: 'Free Delivery Available',
};

export const DELIVERY_SHIP_TEXT = {
  [PAID_CODE]: 'Ships to',
  [FREE_CODE]: 'Ships free to',
  [`${PAID_CODE}_NTL`]: DELIVERY_AVAILABLE,
  [`${FREE_CODE}_NTL`]: 'Free Delivery Available',
  [`${PAID_CODE}_LOCAL`]: 'Ships to local',
  [`${FREE_CODE}_LOCAL`]: 'Free shipping to local',
};

export const STATES_WITH_DELIVERY_RESTRICTIONS = ['HI', 'AK'];

export const HOME_DELIVERY_TYPE_TITLES = {
  [PAID_CODE]: 'Home delivery available',
  [FREE_CODE]: 'Free home delivery available',
};

export const DELIVERY_MESSAGE = 'This dealer offers home delivery.';
