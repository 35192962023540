import {
  CLEAR_PROFILE_DATA,
  DELETE_PROFILE,
  SET_OPTIONS,
  SIGN_IN,
  SIGN_IN_FIREBASE,
  SIGN_OUT,
  TOGGLE_PROFILE_SCREEN,
} from 'client/actions/constants';

const defaultState = {
  screen: '',
  isAuthenticated: null,
  options: {},
};

export function profileReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case TOGGLE_PROFILE_SCREEN:
      return {
        ...state,
        screen: state.screen === action.screen ? '' : action.screen,
        options: action.options,
      };
    case SIGN_IN_FIREBASE:
      return {
        ...state,
        isAuthenticated: false,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        isAnonymousUser: action.isAnonymousUser,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isAnonymousUser: false,
        options: {},
        data: {},
        signInEmail: undefined,
      };
    case DELETE_PROFILE:
      return {
        ...state,
        isAuthenticated: false,
        isAnonymousUser: false,
        isDeletedUser: true,
      };
    case CLEAR_PROFILE_DATA:
      return {
        ...state,
        isDeletedUser: undefined,
        options: {},
        data: {},
        signInEmail: undefined,
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: { ...state.options, ...action.options },
      };
    default:
      return state;
  }
}
