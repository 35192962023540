/* eslint-disable class-methods-use-this */
import { ApolloCache } from 'apollo-cache';

export class NoCache extends ApolloCache {
  read() {
    return null;
  }

  write() {}

  diff() {
    return {
      result: {},
      complete: true,
    };
  }

  watch() {}

  evict() {
    throw new Error('eviction is not implemented on NoCache');
  }

  reset() {
    return Promise.resolve();
  }

  restore() {
    return this;
  }

  extract() {
    return {};
  }

  removeOptimistic() {}

  performTransaction() {}

  recordOptimisticTransaction() {}
}
