import { isNode } from 'client/utils/environment';
import { DFPManager } from '@edmunds/react-dfp';

export const pwtState = {
  pwtFinished: false,
  initAdserverSet: false,
  pwtBids: [],
  pwtAdUnits: [],
};

export const pwtHandlers = {
  refresh: () => {
    DFPManager.refresh();
    if (window.PWT) {
      window.PWT.adsPwtLoaded = true;
    }
  },
};

export const FAILSAFE_TIMEOUT = 1500; // initially 10000ms; this timeout should be more than OpenWrap timeout

export function initAdServer() {
  if (pwtState.initAdserverSet) return;

  let au;
  // Either gpt  or OpenWrap can be ready first.  We can not proceed until
  //  both are complete.  When both are complete, we add OpenWrap bids into
  //  targeting keys and then display the ad units
  if (window && window.PWT && pwtState.pwtFinished) {
    pwtState.initAdserverSet = true;

    while (pwtState.pwtBids.length) {
      au = pwtState.pwtBids.shift();
      window.PWT.addKeyValuePairsToGPTSlots(au);
      // For Venom implementation, we may forego making this addKeyValuePairsToGPTSlots and instead add it ourselves.
      // we will need to understand how the targeting is applied to the slots.
    }
  }
  pwtHandlers.refresh();
}

export function onLoadCallback(adUnitsArray) {
  // eslint-disable-next-line no-console
  if (window.PWT.debugPwtGpt) console.log('++++++++++', adUnitsArray);
  pwtState.pwtFinished = true;
  pwtState.pwtBids.push(adUnitsArray);

  initAdServer();
}

export function executeFailsafe() {
  // This failsafe is here so we proceed with GPT execution if OpenWrap does not return for some reason
  setTimeout(() => {
    if (!pwtState.pwtFinished) {
      // eslint-disable-next-line no-console
      console.error('++++ OpenWrap Failsafe Invoked ++++');
      pwtState.pwtFinished = true;
      initAdServer();
    }
  }, FAILSAFE_TIMEOUT);
}

export async function loadPWT() {
  if (typeof window.PWT.requestBids === 'function') {
    window.PWT.requestBids(pwtState.pwtAdUnits, onLoadCallback);
    executeFailsafe();
  }
}

export async function getPwtBids(adSlots) {
  if (isNode()) {
    return; // disabled on server side
  }

  DFPManager.configureDisableInitialLoad(true);

  adSlots.forEach((slot, i) => {
    pwtState.pwtAdUnits.push({
      code: slot.slotId,
      divId: slot.slotId,
      adUnitId: slot.adUnitId,
      adUnitIndex: `${i}`,
      mediaTypes: {
        banner: {
          sizes: slot.sizes,
        },
      },
    });
  });

  await loadPWT();
  // here we need to return the result of what loadPWT populates;
}
