import { DFPManager } from '@edmunds/react-dfp';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { shouldRefreshAds } from './ads-refresh';
import { BREAKPOINT_UPDATE } from './constants';

/**
 * BREAKPOINT_UPDATE action definition
 *
 * @param  {Object} breakpoint  Responsive breakpoint alist (xs, sm, md, lg, xl)
 * @return {Object}             BREAKPOINT_UPDATE action definition
 */
export function breakpointUpdate(breakpoint, getState) {
  return dispatch => {
    dispatch({
      type: BREAKPOINT_UPDATE,
      breakpoint,
    });

    if (RefreshRules.refreshOn.breakpoint.size && shouldRefreshAds('breakpoint', getState)) {
      const refreshRulesRefreshOnBreakpoint = Array.from(RefreshRules.refreshOn.breakpoint);
      DFPManager.refresh(...refreshRulesRefreshOnBreakpoint);
    }
  };
}
