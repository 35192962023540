import { matchPath } from 'react-router-dom';

/**
 * finds the first route that matches the pathname
 * @param {Array} routes routes (e.g. from client/routes.js)
 * @param {String} path location's pathname
 * @returns {Promise}
 */
export function matchRoute(routes, path) {
  return new Promise((resolve, reject) => {
    let matchedRoute = null;

    routes.find(route => {
      const match = matchPath(path, route);
      if (match) {
        matchedRoute = { match, route };
        return true;
      }
      return false;
    });

    if (matchedRoute) {
      resolve(matchedRoute);
    } else {
      const err = new Error(`Path ${path} not found`);
      err.status = 404;
      reject(err);
    }
  });
}

/**
 * loads page component of the route with `match, location` params
 * @param {Object} route route
 * @param {Object} match match object (https://reacttraining.com/react-router/web/api/match)
 * @param {Object} location location object (https://reacttraining.com/react-router/web/api/location)
 * @returns {Promise}
 */
export function loadRoutePage(route, match, location, store) {
  return route.page.getPageLoader().loadPage(match, location, store);
}
