export const SRP_BASE_PATHNAME = '/inventory/srp.html';

export const SPR_SCROLL_TO_TOP_ANCHOR = 'srp-scroll-to-top';

export const SKIP_TO_LINKS_CONFIG = {
  FILTERS_CONTAINER_ID: 'filters-container',
  FILTERS_CONTAINER_LABEL: 'Skip to Filters',
  RESULTS_CONTAINER_ID: 'results-container',
  RESULTS_CONTAINER_LABEL: 'Skip to Results',
};
