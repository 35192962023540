import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: `/:make(${makes.join('|')})/:model/build`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "new_model_core_build_and_price" */ '../pages/build-price/build-price-definition')
    ),
    chunkName: 'new_model_core_build_and_price',
  },
];
