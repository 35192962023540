// eslint-disable-next-line
import { onLCP, onCLS, onFID, onFCP, onINP } from 'web-vitals';
import { EventToolbox } from 'client/utils/event-toolbox';
import { MissingClassnamesFromCriticalCss } from 'client/engagement-handlers/performance-engagement-handler/find-missing-critical-css-classes';

let sendImmediately;
let bufferedMetrics;
let alreadyFiredOnLoad;

/**
 * the module vars are set from this init function as opposed to directly in the module so that they can be reset
 * from one unit test block to another
 */
function initModuleVars() {
  sendImmediately = false;
  bufferedMetrics = {
    webVitals: {
      CLS: [],
      INP: [],
    },
  };
}

function getPerformanceNavigationTiming() {
  // performance is not supported until Safari 11
  if (!performance || typeof performance.getEntriesByType !== 'function') {
    return null;
  }
  const [performanceNavigationTiming] = performance.getEntriesByType('navigation');
  // performanceNavigationTiming is not supported in Safari and IE
  if (!performanceNavigationTiming) {
    return null;
  }
  return performanceNavigationTiming;
}

function sendToAnalytics(metrics = bufferedMetrics) {
  const data = {
    event_type: 'action_completed',
    event_data: {
      action_category: 'system',
      action_name: 'site_performance',
      metrics: JSON.stringify(metrics),
    },
  };

  if (!alreadyFiredOnLoad) {
    data.event_data.action_cause = 'page_load';
    alreadyFiredOnLoad = true;
  } else {
    data.event_data.action_cause = 'data_update';
  }

  EventToolbox.fireTrackAction(data);
}

function logLcp(timing) {
  const { lcpEntry, ttfb, lcpRequestStart, lcpResponseEnd, lcpRenderTime } = timing;
  console.log('LCP element: ', lcpEntry.element, lcpEntry.url); // eslint-disable-line
  console.log('LCP url: ', lcpEntry.url); // eslint-disable-line

  const resourceLoadDelay = lcpRequestStart - ttfb;
  const resourceLoadTime = lcpResponseEnd - lcpRequestStart;
  const elementRenderDelay = lcpRenderTime - lcpResponseEnd;
  const measures = [
    ['ttfb', ttfb, 0],
    ['LCP Resource Request Start', lcpRequestStart, resourceLoadDelay],
    ['LCP Resource Response End', lcpResponseEnd, resourceLoadTime],
    ['LCP', lcpRenderTime, elementRenderDelay],
  ];

  const table = measures.map(measure => ({
    name: measure[0],
    timing: measure[1],
    'delay(diff from prev time)': measure[2],
  }));

  console.table(table); // eslint-disable-line

  // create visualization in chrome dev performance
  const LCP_SUB_PARTS = [
    ['Time to first byte', 0, ttfb],
    ['Resource load delay', ttfb, lcpRequestStart],
    ['Resource load time', lcpRequestStart, lcpResponseEnd],
    ['Element render delay', lcpResponseEnd, lcpRenderTime],
  ];
  LCP_SUB_PARTS.forEach(part => {
    performance.clearMeasures(part[0]);
    performance.measure(part[0], {
      start: part[1],
      end: part[2],
    });
  });
}

function processINPEntries(entries) {
  return entries.map(entry => {
    const inputDelay = entry.processingStart - entry.startTime;
    const procTime = entry.processingEnd - entry.processingStart;
    const presentationDelay = entry.duration - (inputDelay + procTime);
    return {
      name: entry.name,
      procTime: Math.round(procTime),
      inputDelay: Math.round(inputDelay),
      presentationDelay: Math.round(presentationDelay),
    };
  });
}

function logInp(data) {
  const entries = processINPEntries(data.entries);
  const filteredEntries = entries.filter((value, index) => {
    const valueString = JSON.stringify(value);
    return index === entries.findIndex(obj => JSON.stringify(obj) === valueString);
  });
  const baseStyle = `font-weight:bold`;
  const deltaStyle = `${baseStyle};color:${data.delta < 100 ? '#71df69' : '#e5473c'}`;
  const valueStyle = `${baseStyle};color:${data.value < 200 ? '#71df69' : '#e5473c'}`;
  /* istanbul ignore next */
  /* eslint-disable */
  console.groupCollapsed(
    `[performance-engagement-handler] INP value: %c${data.value} ms (${data.rating})%c, Delta from last: %c${
      data.delta
    } ms`,
    valueStyle,
    '',
    deltaStyle
  );

  console.log('INP element:', data.entries[0].target);
  filteredEntries.map(entry => {
    const { procTime, inputDelay, presentationDelay, name } = entry;
    const measures = [
      ['Input delay', inputDelay],
      ['Processing Time', procTime],
      ['Presentation Delay', presentationDelay],
    ];
    const table = measures.map(measure => ({
      'INP sub-part': measure[0],
      'Time (ms)': measure[1],
    }));

    console.log('Event type:', entry.name);
    console.table(table);
  });
  console.groupEnd();
  /* eslint-disable */
}

function getLCPSubparts(performanceNavigationTiming, callback, debugLcp = false) {
  const isLCPSubpartsSupported =
    typeof PerformanceObserver !== 'undefined' &&
    PerformanceObserver?.supportedEntryTypes?.includes?.('largest-contentful-paint');
  if (!isLCPSubpartsSupported) {
    return;
  }

  function determineLCPSubparts(list) {
    // list is a list of performance entries that have type LCP
    const lcpEntry = list?.getEntries?.().at?.(-1); // returns last element
    if (!lcpEntry) {
      return;
    }
    // get PerformanceResourceTiming for LCP element
    const lcpResourceEntry = performance.getEntriesByType('resource').find(e => e.name === lcpEntry.url);

    // ignore LCP entries that aren't images to reduce DevTools noise
    // comment this line out if you want to include text entries
    if (!lcpEntry.url) return;

    // compute start and end times of each LCP sub-part
    const ttfb = performanceNavigationTiming.responseStart;

    // if LCP resource is loaded cross-origin, add Timing-Allow-Origin (TAO) header to get the most accurate results
    // requestStart (if TAO is set), otherwise use startTime
    const lcpRequestStart = lcpResourceEntry ? lcpResourceEntry.requestStart || lcpResourceEntry.startTime : 0;
    const lcpResponseEnd = lcpResourceEntry ? lcpResourceEntry.responseEnd : 0;

    if (callback) {
      const lcpSubParts = {
        resourceRequestStart: lcpRequestStart,
        resourceResponseEnd: lcpResponseEnd,
      };
      callback(lcpSubParts);
    }

    if (debugLcp) {
      // renderTime (if TAO is set), otherwise use loadTime
      const lcpRenderTime = lcpEntry.renderTime || lcpEntry.loadTime;
      logLcp({ lcpEntry, ttfb, lcpRequestStart, lcpResponseEnd, lcpRenderTime });
    }
  }

  // callback will be invoked every LCP performance entry observed
  // if buffered is true, list includes past entries
  new PerformanceObserver(determineLCPSubparts).observe({ type: 'largest-contentful-paint', buffered: true });
}

function handleEmitPerfMetrics() {
  const performanceNavigationTiming = getPerformanceNavigationTiming();
  if (!performanceNavigationTiming) {
    return;
  }

  alreadyFiredOnLoad = false;
  bufferedMetrics.performanceNavigationTiming = performanceNavigationTiming;
  // core web-vitals: CLS, FCP, LCP, and FID (INP)
  onCLS(
    metrics => {
      const { entries, ...other } = metrics;
      if (!sendImmediately) {
        bufferedMetrics.webVitals.CLS.push(other);
        return;
      }
      sendToAnalytics({
        webVitals: {
          CLS: other,
        },
      });
    },
    { reportAllChanges: true }
  );
  onINP(
    metrics => {
      const { entries, ...other } = metrics;
      if (!sendImmediately) {
        bufferedMetrics.webVitals.INP.push(other);
        return;
      }
      sendToAnalytics({
        webVitals: {
          INP: other,
        },
      });
    },
    { reportAllChanges: true }
  );
  onFCP(metrics => {
    const { value } = metrics;
    bufferedMetrics.webVitals.FCP = value;
  });
  onLCP(
    metrics => {
      const { value } = metrics;
      bufferedMetrics.webVitals.LCP = value;
    },
    { reportAllChanges: true }
  );
  onFID(metrics => {
    const { value } = metrics;
    if (!sendImmediately) {
      bufferedMetrics.webVitals.FID = value;
      return;
    }
    sendToAnalytics({
      webVitals: {
        FID: value,
      },
    });
  });

  getLCPSubparts(performanceNavigationTiming, metrics => {
    bufferedMetrics.lcpSubParts = metrics;
  });

  window.setTimeout(() => {
    sendImmediately = true;
    sendToAnalytics();
  }, 10);
}

export const PerformanceEngagementHandler = {
  /**
   * Setups "pageLoad"" event listener
   *
   * @param  {Object} getState Returns App redux store state
   * @return {void}
   */
  init({ getState }) {
    if (!getState) {
      return;
    }
    initModuleVars();
    const {
      featureFlags: { emitPerfMetrics, debugCls, debugLcp, debugInp, debugCriticalCss },
    } = getState();
    if (emitPerfMetrics) {
      window.addEventListener('load', handleEmitPerfMetrics);
    }
    if (debugCriticalCss) {
      new MissingClassnamesFromCriticalCss().run();
    }
    if (debugCls) {
      // eslint-disable-next-line
      console.log('[performance-engagement-handler] CLS score changes will be logged here');
      onCLS(
        metrics => {
          /* istanbul ignore next */
          // eslint-disable-next-line
          console.log('[performance-engagement-handler] CLS score change', {
            /* the ... is necessary cause from one call to another metrics points to same reference */
            ...metrics,
            entries: [...metrics.entries],
          });
        },
        { reportAllChanges: true }
      );
    }
    if (debugLcp) {
      // eslint-disable-next-line
      const performanceNavigationTiming = getPerformanceNavigationTiming();
      if (performanceNavigationTiming) {
        console.log('[performance-engagement-handler] LCP subparts will be logged here'); // eslint-disable-line
        getLCPSubparts(performanceNavigationTiming, undefined, debugLcp);
      }
    }
    if (debugInp) {
      onINP(
        metrics => {
          logInp(metrics);
        },
        { reportAllChanges: true }
      );
    }
  },
};
