import { LOCATION_CHANGE } from 'client/actions/constants';
import { updateFeatureFlags } from 'client/actions/feature-flags';
import { ClientSyncEngagementHandler } from 'client/engagement-handlers/client-sync-engagement-handler/client-sync-engagement-handler';
import { isPageChanged } from './location';

export const featureFlagsMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE && isPageChanged(store.getState().pageContext.location, action.payload)) {
    const featureFlags = ClientSyncEngagementHandler.getFeatureFlags();
    if (featureFlags) {
      next(updateFeatureFlags(featureFlags));
    }
  }

  return next(action);
};
