export const DATA_PATH = {
  VDP_DEALER_PHOTO: 'vehicleInfo.photo.defaultPhoto.largePhotoCount',
  SRP_DEALER_PHOTO: 'vehicleInfo.photo.defaultPhoto.largePhotoCount',
  STOCK_PHOTO_PROVIDER: 'stockPhotoInfo.photos[0].provider',
  STOCK_PHOTOS: 'stockPhotoInfo.photos',
  STOCK_PHOTO_URL: 'stockPhotoInfo.photos[0].url',
  DEALER_RATING: 'dealerInfo.displayInfo.salesOverallRating',
  FIVE_STAR_DEALER: 'dealerInfo.displayInfo.fiveStarYears',
  PARTNER_ID: 'dealerInfo.rooftopId',
  PRICE: 'prices.displayPrice',
  DEAL_INDICATOR: 'computedDisplayInfo.priceValidation.dealType',
  DAYS_ON_EDMUNDS: 'computedDisplayInfo.daysOnEdmunds',
  BELOW_MARKET: 'computedDisplayInfo.priceValidation.priceDiscount',
  RECOMMENDED_OFFER: 'prices.pricePromise.guaranteedPrice',
  CONTACT_OPTIONS: 'computedDisplayInfo.ctas',
  FREE_HISTORY_REPORT: 'historyInfo.freeHistoryReport',
  HISTORY_PROVIDER: 'historyInfo.historyProvider',
  SALVAGE_HISTORY: 'historyInfo.salvageHistory',
  HISTORY_CLEAN_TITLE: 'historyInfo.cleanTitle',
  INVENTORY_TYPE: 'type',
  VIN: 'vin',
  DELIVERY_CODE: 'deliveryOptions.deliveryCode',
  SORT_BY_ALGORITHM: 'sort',
  DEALER_DISTANCE: 'dealerInfo.distance',
  PARENT_DEALERSHIP_NAME: 'dealerInfo.displayInfo.parentDealershipName',
  DIRECT_DEALER: 'dealerInfo.productFeatures.directDealer',
  HIDE_DESTINATION_FEE: 'computedDisplayInfo.transparentPricingCompliance.hideDestinationFee',
  DEALER_PRICE: 'computedDisplayInfo.transparentPricingCompliance.guaranteedPrice',
  YEAR: 'vehicleInfo.styleInfo.year',
  MILEAGE: 'vehicleInfo.mileage',
};

export const POSITIVE = 'yes';
export const NEGATIVE = 'no';

export const DEFAULT_VALUE = '';
export const DEFAULT_ARRAY_VALUE = [];
export const DEFAULT_INVENTORY_COUNT = 0;
export const DEFAULT_DELIVERY_CODE = 'no_delivery';

export const PHOTO_TYPE = {
  DEALER: 'Dealer',
  EVOX: 'EVOX',
  OEM: 'OEM',
  DEFAULT: 'EVOX/OEM',
  NO_IMAGE: 'silhouette',
  CAR_EXTERIOR: '360_exterior',
  CAR_INTERIOR: '360_interior',
  IZMO: 'IZMO',
};

export const MATCH_RESULT = {
  SUGGESTED: 'suggested',
  EXACT: 'exact',
  CPO: 'cpo',
  NOT_CPO: 'not_cpo',
  DELIVERY: {
    FREE: 'free_delivery',
    PAID: 'paid_delivery',
    LOCAL: 'local',
    [DEFAULT_DELIVERY_CODE]: 'local', // fallback value for when no delivery info is returned
    HOME: 'home_delivery',
  },
};

export const ACTIONS = {
  INVENTORY_LIST_VIEW: 'inventory_list_view',
  PRICE_PROMISE_PRICE_DISPLAY: 'price_promise_price_display',
  CPO_PANEL_VIEW: 'cpo_panel_view',
  CPO_LABEL_VIEW: 'cpo_label_view',
  DEAL_CHECK_VIEW: 'deal_check_view',
  BUY_ONLINE_CLICK: 'buy_online_click',
  BUY_ONLINE_SRP_CARD_BTN_CLICK: 'buy_online_srp_card_btn_click',
  PRICING_BOX_BTN_CLICK: 'pricing-box-btn-click',
  EMBEDDED_BOX_BTN_CLICK: 'embedded-box-btn-click',
  TOGGLE_DELIVERY_FEES: 'toggle-delivery-fees',
  OPEN_FINANCING_APP: 'open-financing-app',
};

export const VIN_OVERVIEW_ID = 'edm-entry-vin-overview';
