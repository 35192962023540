import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

// Add any paths patterns that should explicitly 404 here

export const routes = [
  {
    path: '/v/model-demo',
    page: getLoadablePage(() => import(/* webpackChunkName: "model_demo" */ '../pages/model-demo/model-demo')),
    chunkName: 'model_demo',
  },
];
