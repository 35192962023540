import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

export const aboutRoutes = [
  {
    path: '/about/editors.html',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "editors" */ '../pages/about/editors/editors-definition')),
    chunkName: 'editors',
  },
  {
    path: '/about/authors/:author.html',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "author" */ '../pages/about/author/author-definition')),
    chunkName: 'author',
  },
  {
    path: '/about/executive-bios.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "executive-bios" */ '../pages/about/executive-bios/executive-bios-definition')
    ),
    chunkName: 'executive-bios',
  },
  {
    path: '/about/press/:articleId.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "press-article" */ '../pages/about/press-article/press-article-definition')
    ),
    chunkName: 'press-article',
  },
  /**
   * Example: https://www.edmunds.com/about/accessibility.html
   */
  {
    path: '/about/:splat*/:articleId.html',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "about-article" */ '../pages/about/about-article/about-article-definition'), // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'about-article',
  },
  /**
   * Example: https://www.edmunds.com/about/accessibility.app
   */
  {
    path: '/about/:splat*/:articleId.app',
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "about-article-app" */ '../pages/about/about-article/about-article-app-definition'), // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'about-article-app',
  },
  {
    path: '/about/',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "about-index" */ '../pages/about/index/index-definition')),
    chunkName: 'about-index',
  },
  {
    path: '/careers/',
    exact: true,
    // eslint-disable-next-line no-empty-pattern
    page: getLoadablePage(({}, store) => {
      const isWP754RouteEnabled =
        ExperimentUtil.getForcedOrAssignedRecipeName({
          state: store.getState(),
          campaignName: 'wp-754',
          defaultVal: 'ctrl',
        }) === 'chal';

      if (isWP754RouteEnabled) {
        return import(/* webpackChunkName: "careers-v2" */ '../pages/about/careers/careers-definition-v2');
      }
      return import(/* webpackChunkName: "careers-v1" */ '../pages/about/careers/careers-definition');
    }),
  },
  {
    path: '/copyright-trademark-notices/',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "copyright-trademark" */ '../pages/about/copyright-trademark/copyright-trademark-definition')
    ),
    chunkName: 'copyright-trademark',
  },
  {
    path: ['/affiliate-program/', '/affiliate-program/:articleId.html'],
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "affiliate-article" */ '../pages/about/affiliate-article/affiliate-article-definition'),
      ({ params }) => params
    ),
    chunkName: 'affiliate-article',
  },
  {
    path: ['/edmunds-info/', '/edmunds-info/:articleId.html'],
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "edmunds-info" */ '../pages/about/edmunds-info/edmunds-info-definition'),
      ({ params }) => params
    ),
    chunkName: 'edmunds-info',
  },
  {
    path: '/mobile/:articleId.html',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "mobile-article" */ '../pages/about/mobile-article/mobile-article-definition'),
      ({ params }) => params
    ),
    chunkName: 'mobile-article',
  },
];
