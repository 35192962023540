import { get, join, includes } from 'lodash';
import { isNode } from 'client/utils/environment';
import {
  DATA_PATH,
  DEFAULT_DELIVERY_CODE,
  MATCH_RESULT,
} from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { NATIONWIDE_VALUE } from 'site-modules/shared/constants/inventory/static-facets';
import { isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';

export const ENTRY_GLOBAL_NAV_ID = 'edm-entry-global-nav';

/**
 * Finds a creative of the element and returns it's identifier
 * @param  {Object} element
 * @return {String|undefined}
 */
export function getCreativeId(element) {
  const creative = element && element.closest && element.closest('[data-tracking-parent], .modal-drawer');
  if (creative) {
    return creative.getAttribute('data-tracking-parent');
  } else if (element && element.closest && element.closest('[data-tracking-parent="nav-drawer"]')) {
    return ENTRY_GLOBAL_NAV_ID;
  }
  return undefined;
}

export function treatmentFromProductFeatures(inventory) {
  const trtSeq = [];
  const productFeatures = get(inventory, 'dealerInfo.productFeatures', {});
  const productFeatureTypes = get(productFeatures, 'types', []);
  const inventoryType = get(inventory, 'type', get(inventory, 'dealerInfo.newUsedType', '')).toLowerCase();

  if (productFeatures) {
    if (isPartnerDealer(inventory)) {
      trtSeq.push(`${inventoryType}_base`);
    } else if (includes(productFeatureTypes, 'DL')) {
      trtSeq.push(`${inventoryType}_oem`);
    } else {
      trtSeq.push(`${inventoryType}_3rdparty`);
    }
  }

  const result = join(trtSeq, '-');

  return result;
}

export function getMatchType(inventory, radius) {
  const inventoryRadius = Math.round(get(inventory, 'dealerInfo.distance', 0));
  let deliveryCode;
  if (!radius || radius < inventoryRadius || radius.toString() === NATIONWIDE_VALUE) {
    deliveryCode = get(inventory, DATA_PATH.DELIVERY_CODE, DEFAULT_DELIVERY_CODE);
  } else {
    deliveryCode = DEFAULT_DELIVERY_CODE;
  }
  return MATCH_RESULT.DELIVERY[deliveryCode];
}

export function getMatchTypeList(inventories, radius) {
  return join(inventories.map(inventory => getMatchType(inventory, radius)));
}

export function getInvTreatmentList(inventories) {
  return join(inventories.map(treatmentFromProductFeatures));
}

export function getCorrelatorTs() {
  return isNode() ? 0 : window.performance.now();
}
