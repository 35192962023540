// Use the runtime's default locale by passing undefined as the first argument.
const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

export function sortByName(a, b, field = 'name') {
  const aName = a[field] || '';
  const bName = b[field] || '';
  return aName.localeCompare(bName, { sensitivity: 'base' });
}

export function sortByNameViaCollator(a, b, field = 'name') {
  const aName = a[field] || '';
  const bName = b[field] || '';
  return collator.compare(aName, bName);
}

export function sortByTrim(a, b) {
  return a.trimName > b.trimName ? 1 : -1;
}

export function sortByYear(a, b) {
  return b.year - a.year;
}

export function sortByNumber(a, b) {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}
