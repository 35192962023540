import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';

export const routesNotFound = [
  {
    path: '*',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "test_microsite_not_found" */ '../pages/not-found/not-found')
    ),
    chunkName: 'test_microsite_not_found',
    status: HTTP_NOT_FOUND,
  },
];
