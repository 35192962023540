import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: [
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/st-:styleId/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/st-:styleId/features-specs`,
  ],
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "new_model_core_feature_specs" */ '../pages/features-specs/features-specs-definition')
  ),
  chunkName: 'new_model_core_feature_specs',
};
