import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

/**
 * route for each vehicle type
 * @type {Array}
 */
export const carReviewsRoutes = [
  /**
   * Route for "best-cars" article page
   * https://www.edmunds.com/best-cars/
   */
  {
    path: '/best-cars',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "best-cars" */ '../pages/best-cars/best-cars-definition')),
    chunkName: 'best-cars',
  },
  /**
   * Route for New Car Reviews and Road Tests page
   * https://www.edmunds.com/car-reviews/car-reviews-road-tests.html
   */
  {
    path: '/car-reviews/car-reviews-road-tests.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car-reviews-road-tests" */ '../pages/car-reviews-road-tests/car-reviews-road-tests-definition')
    ),
    chunkName: 'car-reviews-road-tests',
  },
];
