import set from 'lodash/set';
import get from 'lodash/get';
import { LOCATION_CHANGE, PAGE_LOAD } from 'client/actions/constants';
import {
  trySendLUXBeaconOnLoad,
  trySendLUXBeaconBeforeUnload,
} from 'client/engagement-handlers/speedcurve-engagement-handler/speedcurve-engagement-handler';
import { getSpeedcurveSampleRate } from './speedcurve-sample-rate';
import { isPageChanged } from './location';

const LABELS = {
  START_SPA_TRANSITION: 'edmStartSPATransition',
  SPA_PAGE_LOAD: 'edmSPAPageLoad',
  PAGE_LOAD: 'edmPageLoad',
};
let spaPageLoadStartTime;

export const performanceMiddleware = store => next => action => {
  // When SPA transition is initiated
  // e.g. user clicked on Link (LOCATION_CHANGE action is dispatched) and new location is a new page
  // then initialize LUX session
  // https://speedcurve.com/features/lux/#init
  if (action.type === LOCATION_CHANGE && isPageChanged(store.getState().router.location, action.payload.location)) {
    // start SPA page transition
    spaPageLoadStartTime = window.performance.now();

    // initialize speedcurve LUX session at the beginning of each SPA "page view"
    // but first check if speedcurve lib was downloaded, initialized and methods are available.
    // window.LUX will be undefined when `speedcurve` is disabled via feature flag
    // Also send LUX beacon from current page if not fired from its onload
    if (window.LUX && window.LUX.init) {
      trySendLUXBeaconBeforeUnload();
      window.LUX.init();
      // start measure spa page load duration
      window.LUX.mark(LABELS.START_SPA_TRANSITION);
    }
    return next(action);
  }

  if (action.type === PAGE_LOAD) {
    const state = store.getState();
    if (state.pageContext.previousLocation && isPageChanged(state.pageContext.previousLocation, action.page.location)) {
      // this PAGE_LOAD is a result of SPA transition
      // When SPA transition is finished
      // e.g. new page is loaded (PAGE_LOAD action is dispatched and page location changed)
      // then send LUX session data
      // https://speedcurve.com/features/lux/#send
      const actionWithMeta = { ...action };
      const spaPageLoadTime = window.performance.now() - spaPageLoadStartTime;
      set(actionWithMeta, ['meta', 'event', 'data', 'spaPageLoadTime'], spaPageLoadTime); // will be used to fire the EDW pixel

      // AC for page name for LUX is to use legacy page name (aka edw page name - i.e.
      // new_model_core, best_of_advice_best-family-cars_article) and fall back page name
      // from template if legacy pagename not there
      //
      // pageContext.legacy is resolved from firing an action during preload, which fires before PAGE_LOAD
      // so at this point pageContext.legacy should have the value from target page.
      const pageName = state.pageContext.legacy.pageName || action.page.name;

      const { category } = action.page;

      // window.LUX will be undefined when `speedcurve` is disabled via feature flag
      if (window.LUX) {
        window.LUX.label = pageName;
        window.LUX.samplerate = getSpeedcurveSampleRate(pageName, state.mobile);
        window.LUX.addData('page_name', pageName);
        window.LUX.addData('page_category', category);
        window.LUX.addData('is_spa', true);
        const { location } = window;
        window.LUX.addData('url', `${location.pathname}${location.search}`);
        // note that format of the url above should be same as one in speedcurve.ejs to avoid inconcistencies -
        // so for example no /honda/accord/ reported by one vs https://www.edmunds.com/honda/accord/ by other

        // for url_pattern: use 'none' as the default for undefined, null, '' (and other falsy values) from the state
        window.LUX.addData('url_pattern', get(state, 'url.context.urlPattern') || 'none');

        window.LUX.markLoadTime();

        // measure spa page load duration and send it to speedcurve
        // but first check if speedcurve lib was downloaded, initialized and methods are available
        if (window.LUX.measure) {
          window.LUX.measure(LABELS.SPA_PAGE_LOAD, LABELS.START_SPA_TRANSITION);
          trySendLUXBeaconOnLoad();
        }
      }

      // window.newrelic will be undefined when `newrelic` is disabled via a feature flag
      // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/setcustomattribute-browser-agent-api/
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('page_name', pageName);
        window.newrelic.setCustomAttribute('page_category', category);
      }

      return next(actionWithMeta);
    } else if (window.performance && window.performance.mark) {
      // this PAGE_LOAD is a result of hard load
      window.performance.mark(LABELS.PAGE_LOAD); // use performance.mark as LUX.mark might not have loaded yet
    }
  }

  return next(action);
};
