import { UPDATE_FACET_STATE, TOGGLE_DRAWER } from 'client/actions/constants';

export function facetStateReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FACET_STATE:
      return {
        ...state,
        [action.id]: action.value,
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        persistDrawer: action.value,
      };
    default:
      return state;
  }
}
