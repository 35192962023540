import {
  CAR_TYPES,
  PAGE_CAR_MAKE_TYPES,
  TYPE_SLUG_TO_FILTER_MAP,
} from 'site-modules/shared/constants/upper-funnel/car-types';
import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const carTypes = Object.values(PAGE_CAR_MAKE_TYPES);

export const routes = [
  // the only make/type that is associated with make/model path
  {
    path: `/:make(mini)/:model(convertible)`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "new_model_core" */ '../../core-page/pages/new-core/new-core-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => {
        const params = {
          ...match.params,
          make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
        };
        return params;
      }
    ),
    chunkName: 'new_model_core',
  },
  {
    path: `/:make(${makes.join('|')})/:type(${carTypes.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_make_type" */ '../pages/usurp/usurp-make-type-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
        // API CATEGORIZES ELECTRIC CARS AS 'ELECTRIC', NOT 'ELECTRIC-CAR'
        type: match.params.type === PAGE_CAR_MAKE_TYPES.ELECTRIC ? CAR_TYPES.ELECTRIC : match.params.type,
        typeFilter: TYPE_SLUG_TO_FILTER_MAP[match.params.type],
        isSrpOnMakeType: true,
      })
    ),
    chunkName: 'car_make_type',
  },
];
