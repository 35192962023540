import { get } from 'lodash';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
import { ADS_REFRESH, ADUNITS_REFRESH } from './constants';

// CPA Transition stuff
const year = getCurrentDate().getFullYear();
const CPA_REFRESH_ACTIONS = new Set(['scroll', 'carousel', 'engagement']);
const CPA_UNREFRESHABLE_YEARS = new Set([year + 1, year, year - 1, year - 2]);

/**
 * Checks if unit is eligible for ad refresh
 * This prevents certain refresh types for some GM makes
 * @param {String} trigger   The type of refresh action
 * @param {*} getState Gets  Function providing the current state
 * @param {Boolean} isLimitedAdRefreshForNew Apply for new and new used
 * @returns {boolean}        True when eligible for refresh
 */
export function shouldRefreshAds(trigger, getState, isLimitedAdRefreshForNew) {
  const vehYear = parseInt(get(getState(), 'pageContext.vehicle.modelYear.year', 0), 10);
  const vehModel = get(getState(), 'pageContext.vehicle.model.name', '');
  const isNew = CPA_UNREFRESHABLE_YEARS.has(vehYear);

  return !(
    CPA_REFRESH_ACTIONS.has(trigger) &&
    (!vehYear || (isLimitedAdRefreshForNew && !isNew) || (!isLimitedAdRefreshForNew && isNew)) &&
    vehModel
  );
}

/**
 * ADS_REFRESH action definition
 * @param {string} trigger (optional) trigger that caused the ad refresh
 * @param {string} timeout key for timeout map that determines the debounce.
 * @param {Array} refreshZones array of refresh zones which should be refreshed
 * @param {Boolean} isLimitedAdRefreshForNew Apply for new and new used
 * @return {Object} ADS_REFRESH action definition
 */
export function adsRefresh(trigger = '', timeout = 'ads_refresh', refreshZones = [], isLimitedAdRefreshForNew = false) {
  return (dispatch, getState) => {
    if (shouldRefreshAds(trigger, getState, isLimitedAdRefreshForNew)) {
      dispatch({
        type: ADS_REFRESH,
        adsts: {
          value: performance.now(),
          trigger,
        },
        refreshZones,
        meta: {
          debounce: timeout,
        },
      });
    }
  };
}

/**
 * ADUNITS_REFRESH action definition
 * @return {Object} ADUNITS_REFRESH action definition
 */
export function adUnitRefresh(adUnitNames = [], trigger = '') {
  return (dispatch, getState) => {
    if (shouldRefreshAds(trigger, getState)) {
      const ts = performance.now();
      const tsPerUnits = adUnitNames.reduce((acc, unitName) => {
        acc[unitName] = ts;
        return acc;
      }, {});

      dispatch({
        type: ADUNITS_REFRESH,
        tsPerUnits,
        trigger,
      });
    }
  };
}
