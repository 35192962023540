import {
  COMPUTED_AB_PARAMETERS,
  CHANGE_FORCED_AB_PARAMETER,
  CHANGE_FORCED_AX_DIMENSIONS,
} from 'client/actions/constants';

/**
 * Updates AB-testing Venom campaigns computed data
 *
 * @param  {Object} state  Current state
 * @param  {Object} action State update action
 * @return {Object}        New state
 */
export function computedExperiment(state = {}, action = {}) {
  switch (action.type) {
    case COMPUTED_AB_PARAMETERS:
      return action.data;
    case CHANGE_FORCED_AB_PARAMETER:
      return {
        ...state,
        [action.campaignName]: {
          ...state[action.campaignName],
          [action.paramName]: {
            ...(state[action.campaignName] && state[action.campaignName][action.paramName]),
            forced: action.paramValue,
          },
        },
      };
    case CHANGE_FORCED_AX_DIMENSIONS:
      return {
        ...state,
        [action.campaignName]: {
          ...state[action.campaignName],
          forcedDimensions: action.dimensions,
        },
      };
    default:
      return state;
  }
}
