import { get } from 'lodash';
import { UPDATE_VEHICLE, PAGE_UNLOAD } from 'client/actions/constants';

export function vehicleReducer(state = null, action = {}) {
  const dontResetVehicleContextOnUnload = get(action, 'meta.event.data.options.dontResetVehicleContextOnUnload');
  switch (action.type) {
    case UPDATE_VEHICLE:
      return action.vehicle;
    case PAGE_UNLOAD:
      return dontResetVehicleContextOnUnload ? state : null;
    default:
      return state;
  }
}
