import { PAGE_CAR_TYPES, CAR_TYPES, PAGE_CAR_SUBTYPES } from 'site-modules/shared/constants/upper-funnel/car-types';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const carTypes = Object.values(PAGE_CAR_TYPES);
const carSubtypes = Object.values(PAGE_CAR_SUBTYPES);

export const routes = [
  {
    path: `/:type(${carTypes.join('|')})/:subtype(${carSubtypes.join('|')})`,
    exact: true,
    page: getLoadablePage(({ type, subtype }) =>
      import(/* webpackChunkName: "car_subtype" */ '../pages/subtype/subtype-definition').then(page =>
        page.default({
          type: type === PAGE_CAR_TYPES.ELECTRIC ? CAR_TYPES.ELECTRIC : type,
          subtype,
        })
      )
    ),
    chunkName: 'car_subtype',
  },
];
