import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes } from 'client/data/constants/active-makes';

export const routes = {
  path: [
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/st-:styleId(\\d+)/review`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/st-:styleId(\\d+)/review`,
  ],
  exact: true,
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "model_core_style_reviews" */ '../pages/style/style-definition')
  ),
  chunkName: 'model_core_style_reviews',
};
