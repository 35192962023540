import { DFPManager } from '@edmunds/react-dfp';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { getCreativeId } from 'client/engagement-handlers/helper';
import { shouldRefreshAds } from 'client/actions/ads-refresh';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { Helper } from './helper';

export const DELEGATION_SELECTOR = `[${TrackingConstant.DATA_TRACKING_ID_ATTRIBUTE}]`;
const CORRECT_TRACKING_DISABLED_VALUES = [true, 'true', ''];
const TRIGGER = 'engagement';

/**
 *
 * @param event
 */
export function engagementHandler(currentEl, event, getState) {
  const targetEl = event.target;
  const trackingDisabled = currentEl.getAttribute('data-tracking-disabled');

  /**
   * For the cases when data-tracking-id element has more than one target and for second one
   * we have false clicked target (issue can be seen on srp page clicking filters)
   * or we are disabling tracking with data-tracking-disabled attribute
   */
  if (
    (Helper.isControlElement(targetEl) && event.type === 'click') ||
    CORRECT_TRACKING_DISABLED_VALUES.includes(trackingDisabled)
  )
    return;

  const trackingId = currentEl.getAttribute('data-tracking-id');

  if (!trackingId) return;

  const trackingValue = currentEl.getAttribute('data-tracking-value') || Helper.getActionValue(targetEl);
  const trackingPosition = currentEl.getAttribute('data-tracking-position');

  const state = getState();

  const options = {
    currentEl,
    targetEl,
    state,
  };

  const eventData = {
    action_cause: Helper.getActionCause(currentEl),
    creative_id: getCreativeId(targetEl),
  };

  if (!Helper.ignoreTrackingValue(currentEl) && trackingValue) {
    eventData.value = trackingValue;
  }

  if (trackingPosition) {
    eventData.position = trackingPosition;
  }

  if (!Helper.isAdsRefreshDisabled(currentEl, getState)) {
    if (RefreshRules.refreshOn.engagement.size && shouldRefreshAds(TRIGGER, getState)) {
      const refreshRulesOnEngagements = Array.from(RefreshRules.refreshOn.engagement);

      setTimeout(() => {
        DFPManager.refresh(...refreshRulesOnEngagements);
      }, 50);
    }
  }

  TrackingHandler.fireEvent(
    trackingId,
    {
      event_data: eventData,
    },
    options
  );
}

export const ElementEngagementHandler = {
  /**
   * Setups element events listener
   *
   * @param  {Function} getState Returns app redux store state
   * @return {void}
   */
  init({ getState }) {
    function engagementHandlerWrapper(event) {
      engagementHandler(this, event, getState);
    }

    EventToolbox.on('click change submit', engagementHandlerWrapper, true, DELEGATION_SELECTOR, window.document);
  },
};
