import { makes } from 'client/data/constants/active-makes';
import { types } from 'client/data/constants/car-types';
import { spaUrlsProd } from './spa/urls-prod';
import { spaUrlsQa11 } from './spa/urls-qa-11';
import { spaUrlsQa21 } from './spa/urls-qa-21';
import { spaUrlsSandbox } from './spa/urls-sandbox';
import { spaUrlsDev } from './spa/urls-dev';

// it's possible to use placeholders like [make] or [type] which will be replaced with the actual list of makes or types
const placeholders = [
  {
    placeholder: '[make]',
    replacement: `(${makes.join('|')})`,
  },
  {
    placeholder: '[type]',
    replacement: `(${types.join('|')})`,
  },
];

function replacePlaceholders(rule) {
  return placeholders.reduce((acc, { placeholder, replacement }) => acc.replace(placeholder, replacement), rule);
}

function compileRules(rules) {
  return rules.map(rule => new RegExp(replacePlaceholders(rule)));
}

const spaUrlsWhitelistProd = compileRules(spaUrlsProd);
const spaUrlsWhitelistQa11 = compileRules(spaUrlsQa11);
const spaUrlsWhitelistQa21 = compileRules(spaUrlsQa21);
const spaUrlsWhitelistSandbox = compileRules(spaUrlsSandbox);
const spaUrlsWhitelistDev = compileRules(spaUrlsDev);

export function getSpaUrlsWhitelist(venomEnv, edmundsEnv) {
  let spaUrlsWhitelist = spaUrlsWhitelistProd;

  if (venomEnv === 'dev') {
    spaUrlsWhitelist = spaUrlsWhitelistDev;
  } else if (venomEnv === 'sandbox') {
    spaUrlsWhitelist = spaUrlsWhitelistSandbox;
  } else if (venomEnv === 'qa') {
    spaUrlsWhitelist = edmundsEnv.includes('-11') ? spaUrlsWhitelistQa11 : spaUrlsWhitelistQa21;
  }

  return spaUrlsWhitelist;
}
