import { isArray, isBoolean } from 'lodash';

export class RefreshRules {
  static refreshOn = {
    carousel: new Set(),
    scroll: new Set(),
    engagement: new Set(),
    breakpoint: new Set(),
  };
  static refreshOnRefreshZones = {
    adhesion: new Set(),
    photoflippercarousel: new Set(),
    photoflipperFifthSlide: new Set(),
    photoflipperEverySlide: new Set(),
  };

  static addToRefreshableUnitsArray(id, props) {
    const { noRefresh, refreshZones, oneByOne } = props;

    if ((isBoolean(noRefresh) && noRefresh) || oneByOne) {
      return;
    }

    if (refreshZones.length) {
      refreshZones.forEach(zone => {
        RefreshRules.refreshOnRefreshZones[zone].add(id);
      });
    }

    Object.keys(RefreshRules.refreshOn).forEach(key => {
      if ((!isArray(noRefresh) && !noRefresh) || (isArray(noRefresh) && !noRefresh.includes(key))) {
        RefreshRules.refreshOn[key].add(id);
      }
    });
  }
}
