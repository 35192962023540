import { ADD_FINANCING_DATA } from 'client/actions/constants';

export function financingReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADD_FINANCING_DATA:
      return {
        ...state,
        financingResponse: action.financing,
      };
    default:
      return state;
  }
}
