import { CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';

const QUERY_MODEL_YEARS_TYPE_MAPPING = {
  [CAR_TYPES.MINIVAN]: 'types.vehicleType=Minivan',
  [CAR_TYPES.TRUCK]: 'types.vehicleType=Truck',
  [CAR_TYPES.COUPE]: 'types.vehicleStyle=Coupe',
  [CAR_TYPES.SEDAN]: 'types.vehicleStyle=Sedan',
  [CAR_TYPES.CONVERTIBLE]: 'types.vehicleStyle=Convertible',
  [CAR_TYPES.WAGON]: 'types.vehicleStyle=Wagon',
  [CAR_TYPES.DIESEL]: 'types.market=Diesel',
  [CAR_TYPES.LUXURY]: 'types.market=Luxury',
  [CAR_TYPES.HATCHBACK]: 'types.market=Hatchback',
  [CAR_TYPES.HYBRID]: 'types.market=Hybrid',
  [CAR_TYPES.CROSSOVER]: 'types.market=Crossover',
  [CAR_TYPES.SPORTS_CAR]: 'types.market=Performance',
  [CAR_TYPES.SUV]: 'types.vehicleType=SUV',
  [CAR_TYPES.ELECTRIC]: 'types.engineType=Electric&types.engineFuelType=Electric',
};

const TYPE_NAME_MAPPING = {
  [CAR_TYPES.MINIVAN]: 'Minivan',
  [CAR_TYPES.TRUCK]: 'Truck',
  [CAR_TYPES.COUPE]: 'Coupe',
  [CAR_TYPES.SEDAN]: 'Sedan',
  [CAR_TYPES.CONVERTIBLE]: 'Convertible',
  [CAR_TYPES.WAGON]: 'Wagon',
  [CAR_TYPES.DIESEL]: 'Diesel',
  [CAR_TYPES.LUXURY]: 'Luxury',
  [CAR_TYPES.HATCHBACK]: 'Hatchback',
  [CAR_TYPES.HYBRID]: 'Hybrid',
  [CAR_TYPES.CROSSOVER]: 'Crossover',
  [CAR_TYPES.SPORTS_CAR]: 'Performance',
  [CAR_TYPES.SUV]: 'SUV',
  [CAR_TYPES.ELECTRIC]: 'Electric',
  [CAR_TYPES.ELECTRICAWD]: 'Electric',
  [CAR_TYPES.ELECTRICSUVS]: 'SUV,Electric',
  [CAR_TYPES.HYBRIDSUVS]: 'SUV,Hybrid',
  [CAR_TYPES.LARGESUVS]: 'SUV,Hybrid,Electric',
  [CAR_TYPES.SMALLSUVS]: 'SUV,Hybrid,Electric',
  [CAR_TYPES.MIDSIZESUVS]: 'SUV,Hybrid,Electric',
  [CAR_TYPES.LUXURYSUVS]: 'SUV,Hybrid,Electric',
  [CAR_TYPES.THREEROWSUVS]: 'SUV,Hybrid,Electric',
  [CAR_TYPES.SMALLSEDANS]: 'Sedan',
  [CAR_TYPES.MIDSIZESEDANS]: 'Sedan',
  [CAR_TYPES.LARGESEDANS]: 'Sedan',
  [CAR_TYPES.LUXURYSEDANS]: 'Sedan',
  [CAR_TYPES.SPORTSEDANS]: 'Sedan',
  [CAR_TYPES.SMALLTRUCKS]: 'Truck',
  [CAR_TYPES.MIDSIZETRUCKS]: 'Truck',
  [CAR_TYPES.LARGETRUCKS]: 'Truck',
  [CAR_TYPES.HEAVYDUTYTRUCKS]: 'Truck',
  [CAR_TYPES.SMALLWAGONS]: 'Wagon',
  [CAR_TYPES.MIDSIZEWAGONS]: 'Wagon',
  [CAR_TYPES.LUXURYCOUPES]: 'Coupe',
  [CAR_TYPES.SPORTSCOUPES]: 'Coupe',
  [CAR_TYPES.MUSCLECARS]: 'Coupe',
  [CAR_TYPES.CONVERTIBLECARS]: 'Convertible',
  [CAR_TYPES.SMALLHATCHBACKS]: 'Hatchback',
  [CAR_TYPES.HOTHATCHES]: 'Hatchback',
  [CAR_TYPES.LUXURYELECTRICCARS]: 'Electric',
  [CAR_TYPES.LUXURYELECTRICSUVS]: 'Electric',
  [CAR_TYPES.LUXURYHYBRIDCARS]: 'Hybrid',
  [CAR_TYPES.PLUGINYHYBRIDCARS]: 'Hybrid',
  [CAR_TYPES.PLUGINYHYBRIDSUVS]: 'Hybrid',
};

export const getQueryTypeStringModelYears = typeSlug => QUERY_MODEL_YEARS_TYPE_MAPPING[typeSlug] || '';

export const getTypeNameBySlug = typeSlug => TYPE_NAME_MAPPING[typeSlug] || '';
