/**
 * Venom 2.0 MMY routes
 *  - /make/model/newyear
 *  - /make/model/usedyear
 *  - /make/model/newyear/submodel
 *  - /make/model/usedyear/submodel
 *  - /make/model/
 *
 *  more about dynamic routes on https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/-/wikis/Routing-in-Venom#venom-20-routes
 */
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import {
  VehicleDefaultModel,
  buildDefaultSubmodelPath,
  buildMakeModelDefaultYear,
} from 'client/data/models/vehicle-v2-default';
import { isUsed } from 'site-modules/shared/utils/publication-states';
import { create404Error } from 'site-modules/shared/utils/errors';
import { UrlModel } from 'client/data/models/url';

export const routes = [
  /**
   * New: new-core
   * Used: srp
   * [0]
   */
  {
    path: [`/:make(${makes.join('|')})/:model/:year`, `/:make(${makes.join('|')})/:model/:year/used`],
    exact: true,
    preload: (params, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      return preloader.load();
    },
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const vehicleSubmodel = modelState.get(buildDefaultSubmodelPath(params), VehicleDefaultModel);

      if (!vehicleSubmodel) {
        throw create404Error('Page not found');
      }

      const pageDefinitionParams = {
        ...params,
        make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
      };

      if (isUsed(vehicleSubmodel.pubStates)) {
        return import(/* webpackChunkName: "used_srp_core" */ '../../inventory/pages/usurp/usurp-core-definition').then(
          page => page.default({ ...pageDefinitionParams, isSrpOnUsedCore: true })
        );
      }

      return import(/* webpackChunkName: "new_model_core" */ '../../core-page/pages/new-core/new-core-definition').then(
        page => page.default(pageDefinitionParams)
      );
    }),
  },

  /**
   * New: model-price-checker
   * Used: srp
   * [1]
   */
  {
    path: `/:make(${makes.join('|')})/:model/:year/msrp`,
    exact: true,
    preload: (params, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      return preloader.load();
    },
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const vehicle = modelState.get(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      if (!vehicle) {
        throw create404Error('Page not found');
      }
      const pageDefinitionParams = {
        ...params,
        make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
      };

      if (isUsed(vehicle.pubStates)) {
        return import(/* webpackChunkName: "used_srp_core" */ '../../inventory/pages/usurp/usurp-core-definition').then(
          page => page.default({ ...pageDefinitionParams, isSrpOnUsedCore: true })
        );
      }

      return import(/* webpackChunkName: "model-price-checker" */ '../../price-checker/pages/model-price-checker/model-price-checker-definition');
    }),
  },

  /**
   * New: new-core
   * Used: used-core
   * [2]
   */
  {
    path: [`/:make(${makes.join('|')})/:model/:year/review`, `/:make(${makes.join('|')})/:model/:year/used/review`],
    exact: true,
    preload: (params, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      return preloader.load();
    },
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const vehicle = modelState.get(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      if (!vehicle) {
        throw create404Error('Page not found');
      }

      const pageDefinitionParams = {
        ...params,
        make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
      };

      if (isUsed(vehicle.pubStates)) {
        return import(/* webpackChunkName: "used_model_core" */ '../../core-page/pages/used-core/used-core-definition').then(
          page => page.default({ ...pageDefinitionParams, isUsed: true, isReviewSubpage: !params.submodel })
        );
      }
      return import(/* webpackChunkName: "new_model_core" */ '../../core-page/pages/new-core/new-core-definition').then(
        page => page.default(pageDefinitionParams)
      );
    }),
  },

  {
    path: [
      `/:make(${makes.join('|')})/:model/:year/:submodel`,
      `/:make(${makes.join('|')})/:model/:year/:submodel/used`,
    ],
    exact: true,
    shouldCallParseApi: true,
    preload: (params, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      return preloader.load();
    },
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const vehicle = modelState.get(buildDefaultSubmodelPath(params), VehicleDefaultModel);
      if (!vehicle) {
        throw create404Error('Page not found');
      }

      const pageDefinitionParams = {
        ...params,
        make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
      };

      if (isUsed(vehicle.pubStates)) {
        const isValidSrpUrl = modelState.get('context.isValidUrl', UrlModel);
        const isSupportedFilter = modelState.get('context.attributes.inventory.isSupportedFilter', UrlModel);
        // api/url/v2/parse publication state is cached for up to 1 week, so we need to check that it matches
        const publicationStateFromParseApi = modelState.get('context.vehicle.publicationState', UrlModel);

        if (isValidSrpUrl && isSupportedFilter && publicationStateFromParseApi === 'used') {
          return import(/* webpackChunkName: "used_srp_core" */ '../../inventory/pages/usurp/usurp-core-definition').then(
            page => page.default({ ...pageDefinitionParams, isSrpOnUsedCore: false })
          );
        }
        return import(/* webpackChunkName: "used_model_core" */ '../../core-page/pages/used-core/used-core-definition').then(
          page => page.default({ ...pageDefinitionParams, isUsed: true, isReviewSubpage: !params.submodel })
        );
      }
      return import(/* webpackChunkName: "new_model_core" */ '../../core-page/pages/new-core/new-core-definition').then(
        page => page.default(pageDefinitionParams)
      );
    }),
  },

  /**
   * without year
   * New: new-core
   * Used: srp
   * [3]
   */
  {
    path: `/:make(${makes.join('|')})/:model`,
    exact: true,
    preload: (params, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(buildMakeModelDefaultYear(params), VehicleDefaultModel);

      return preloader.load().then(modelState => {
        const defaultYear = modelState.get(buildMakeModelDefaultYear(params), VehicleDefaultModel);

        preloader.resolve(buildDefaultSubmodelPath({ ...params, year: defaultYear }), VehicleDefaultModel);

        return preloader.load();
      });
    },
    page: getLoadablePage((params, store) => {
      const modelState = getModelState(store.getState());
      const defaultYear = modelState.get(buildMakeModelDefaultYear(params), VehicleDefaultModel);
      const vehicleSubmodel = modelState.get(
        buildDefaultSubmodelPath({ ...params, year: defaultYear }),
        VehicleDefaultModel
      );
      if (!vehicleSubmodel) {
        throw create404Error('Page not found');
      }

      const pageDefinitionParams = {
        ...params,
        make: TWO_WORDS_MAKE_NAMES_MAP[params.make] || params.make,
      };

      if (isUsed(vehicleSubmodel.pubStates)) {
        return import(/* webpackChunkName: "used_srp_core" */ '../../inventory/pages/usurp/usurp-core-definition').then(
          page => page.default({ ...pageDefinitionParams, year: defaultYear, isSrpOnUsedCore: true })
        );
      }

      return import(/* webpackChunkName: "new_model_core" */ '../../core-page/pages/new-core/new-core-definition').then(
        page => page.default(pageDefinitionParams)
      );
    }),
    enableLooseEtag: true,
  },
];
