export const BRANDS = {
  GENERIC: 'generic',
  KMX: 'kmx',
  CARGURUS: 'cargurus',
  CARVANA: 'carvana',
  ECHOPARK: 'echopark',
  AUTONATION: 'autonation',
  PEDDLE: 'peddle',
  CASHFORCARS: 'cashforcars',
  CARWISER: 'carwiser',
  ICO: 'ico', // EMO-2490
  /* Below comment out due to brands not really being supported, and can easily bring back if we need */
  // SHIFT: 'shift',
};

export const BRAND_TYPES = {
  [BRANDS.GENERIC]: {
    disclaimer:
      "The offer is contingent on your providing accurate information on Edmunds.com. There will be an in-person verification of your vehicle and evaluation of other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive.",
  }, // This should catch all fallback if brand does not exist in BRAND_TYPES.
  [BRANDS.KMX]: {
    name: 'CarMax',
    logoPath: 'img/multi-offer/logos/carmax.svg',
    emailLogoPath: 'img/multi-offer/logos/carmax.png',
    blackLogoPath: 'img/multi-offer/logos/carmax-black.svg',
    logoHeightMultiplier: 0.1989375,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'CarMax Logo',
    disclaimer:
      "The offer from CarMax is contingent on your providing accurate information on Edmunds.com. CarMax will conduct an in-person verification of your vehicle and evaluate other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from CarMax.",
  },
  [BRANDS.CARGURUS]: {
    name: 'CarGurus',
    logoPath: 'img/multi-offer/logos/cargurus.svg',
    emailLogoPath: 'img/multi-offer/logos/cargurus.png',
    blackLogoPath: 'img/multi-offer/logos/cargurus-black.svg',
    logoHeightMultiplier: 0.1581875,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'CarGurus Logo',
    disclaimer:
      "The online offer is contingent on your providing accurate information on Edmunds.com. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from CarGurus.",
  },
  [BRANDS.CARVANA]: {
    name: 'Carvana',
    logoPath: 'img/multi-offer/logos/carvana.svg',
    emailLogoPath: 'img/multi-offer/logos/carvana.png',
    blackLogoPath: 'img/multi-offer/logos/carvana-black.svg',
    logoHeightMultiplier: 0.14275,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'Carvana Logo',
    disclaimer:
      "The online offer is contingent on your providing accurate information on Edmunds.com. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from Carvana.",
  },
  [BRANDS.ECHOPARK]: {
    name: 'EchoPark',
    logoPath: 'img/multi-offer/logos/echopark.svg',
    emailLogoPath: 'img/multi-offer/logos/echopark.png',
    blackLogoPath: 'img/multi-offer/logos/echopark-black.svg',
    logoHeightMultiplier: 0.2104375,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'EchoPark Logo',
    disclaimer:
      "Please be aware this offer may change based on the review of the vehicle’s condition.\n\nThe online offer is contingent on your providing accurate information on Edmunds.com. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from EchoPark.",
  },
  [BRANDS.AUTONATION]: {
    name: 'AutoNation',
    logoPath: 'img/multi-offer/logos/autonation.svg',
    emailLogoPath: 'img/multi-offer/logos/autonation.png',
    blackLogoPath: 'img/multi-offer/logos/autonation-black.svg',
    logoHeightMultiplier: 0.205128,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'AutoNation Logo',
    disclaimerObscured:
      'This price is based on data from thousands of similar market transactions as well as the information you provided. The offer provider may modify or revoke this price if the information you provided is inaccurate or if there is a significant present or prospective change in the used vehicle market beyond the offer provider’s control. Other terms and restrictions apply.',
    disclaimer:
      'This price is based on data from thousands of similar market transactions as well as the information you provided. AutoNation may modify or revoke this price if the information you provided is inaccurate or if there is a significant present or prospective change in the used vehicle market beyond AutoNation’s control. Other terms and restrictions apply.',
  },
  [BRANDS.PEDDLE]: {
    name: 'Peddle',
    logoPath: 'img/multi-offer/logos/peddle.svg',
    emailLogoPath: 'img/multi-offer/logos/peddle.png',
    blackLogoPath: 'img/multi-offer/logos/peddle-black.svg',
    logoHeightMultiplier: 0.262069,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'Peddle Logo',
    disclaimer:
      'The offer is contingent on your providing accurate information on Edmunds.com. There will be an in-person verification of your vehicle and evaluation of other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle’s actual condition, use, and history may impact the offer you receive.',
  },
  [BRANDS.CASHFORCARS]: {
    name: 'CashForCars.com',
    logoPath: 'img/multi-offer/logos/cashforcars.svg',
    emailLogoPath: 'img/multi-offer/logos/cashforcars.png',
    blackLogoPath: 'img/multi-offer/logos/cashforcars-black.svg',
    logoHeightMultiplier: 0.1681818,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'CashForCars.com Logo',
    disclaimer:
      "The offer is contingent on your providing accurate information on Edmunds.com. There will be an in-person verification of your vehicle and evaluation of other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive.",
  },
  [BRANDS.CARWISER]: {
    name: 'Carwiser',
    logoPath: 'img/multi-offer/logos/carwiser.svg',
    logoHeightMultiplier: 0.196787,
    get logoWidthMultiplier() {
      return 1.0 / this.logoHeightMultiplier;
    },
    logoAltText: 'Carwiser Logo',
  },
  // brand type for EMO-2490.
  [BRANDS.ICO]: {
    disclaimer: ({ dealerName }) =>
      `The offer from ${dealerName} is contingent on your providing accurate information on Edmunds.com. ${dealerName} will conduct an in-person verification of your vehicle and evaluate other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from our partners.`,
  },
  /* Below comment out due to brands not really being supported, and can easily bring back if we need */
  // [BRANDS.SHIFT]: {
  //   name: 'Shift',
  //   logoPath: 'img/multi-offer/logos/shift.svg',
  //   emailLogoPath: 'img/multi-offer/logos/shift.png',
  //   blackLogoPath: 'img/multi-offer/logos/shift-black.svg',
  //   logoHeightMultiplier: null,
  //   get logoWidthMultiplier() {
  //     return 1.0 / this.logoHeightMultiplier;
  //   },
  //   logoAltText: 'Shift Logo',
  // },
};

/**
 * Contains the brands Edmunds is working with (i.e. is getting offers from) currently.
 *
 * @type {(string)[]}
 */
export const CURRENT_BRANDS = [
  BRANDS.KMX,
  BRANDS.CARWISER,
  BRANDS.AUTONATION,
  BRANDS.ECHOPARK,
  BRANDS.PEDDLE,
  BRANDS.CASHFORCARS,
];

export const CURRENT_OFFER_PROVIDERS = [
  BRAND_TYPES[BRANDS.KMX].name,
  'Carwiser',
  BRAND_TYPES[BRANDS.ECHOPARK].name,
  BRAND_TYPES[BRANDS.AUTONATION].name,
  BRAND_TYPES[BRANDS.PEDDLE].name,
  BRAND_TYPES[BRANDS.CASHFORCARS].name,
];
