import { UPDATE_FEATURE_FLAGS } from 'client/actions/constants';

export function featureFlagsReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FEATURE_FLAGS:
      return action.data;
    default:
      return state;
  }
}
