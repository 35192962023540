import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = {
  path: '/basicadpagespeed',
  page: getLoadablePage(() =>
    import(/* webpackChunkName: "basic_speed" */ 'site-modules/basic-ad-page-speed/pages/basic/basic')
  ),
  chunkName: 'basic_speed',
  inlineCritical: false,
};
