import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/data-privacy-request/',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "data-privacy-request" */ '../pages/data-privacy-request/data-privacy-request-definition')
    ),
    chunkName: 'data-privacy-request',
  },
  {
    path: '/data-privacy-request/index.app',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "data-privacy-request-app" */ '../pages/data-privacy-request/data-privacy-request-app-definition')
    ),
    chunkName: 'data-privacy-request-app',
  },
];
