import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/examples/post-form',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "example_post_form" */ '../pages/post-form/post-form')),
    chunkName: 'example_post_form',
    methods: 'get,post',
  },
  {
    path: '/examples/hash-link',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "example_hash_link" */ '../pages/hash-link/hash-link')),
    chunkName: 'example_hash_link',
  },
  {
    path: '/examples/graphql',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "example_graphql" */ '../pages/graphql/graphql')),
    chunkName: 'example_graphql',
  },
];
