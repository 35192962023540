import { reduce } from 'lodash';
import { isUsedState } from 'site-modules/shared/utils/publication-states';
import { PageModel } from 'client/data/models/page';

const PAGE_NAMES_TO_FILTER = [
  'new_model_core',
  'new_model_core_trims',
  'new_model_core_feature_specs',
  'new_model_core_consumer_reviews',
  'new_model_core_mpg',
  'price_checker',
  'new_model_msrp',
  'new_model_core_tco',
];
const FLEET = 'fleet';

export async function filterFleetTrims(styles, context) {
  const [pageName, legacyPageName] = await Promise.all([
    context.resolveValue('page.name', PageModel, false),
    context.resolveValue('legacy.pageName', PageModel, false),
  ]);

  const nonFleetStyle =
    styles && styles.find(({ trim, trimName }) => !(trim || trimName).toLowerCase().includes(FLEET));

  if (
    !styles?.length ||
    !PAGE_NAMES_TO_FILTER.includes(legacyPageName || pageName) ||
    isUsedState((nonFleetStyle || styles?.[0])?.publicationState)
  ) {
    return styles;
  }

  return styles.filter(({ trim, trimName }) => !(trim || trimName).toLowerCase().includes(FLEET));
}

export async function filterFleetSubmodelStyles(submodelStyles, context) {
  const [pageName, legacyPageName] = await Promise.all([
    context.resolveValue('page.name', PageModel, false),
    context.resolveValue('legacy.pageName', PageModel, false),
  ]);

  if (!submodelStyles || !PAGE_NAMES_TO_FILTER.includes(legacyPageName || pageName)) {
    return submodelStyles;
  }

  return reduce(
    submodelStyles,
    (acc, styles, submodel) => {
      acc[submodel] = styles.filter(({ styleName }) => !styleName.toLowerCase().includes(FLEET));
      return acc;
    },
    {}
  );
}
