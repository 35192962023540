import { uniq, inRange } from 'lodash';
import { getDifferenceInDays } from 'site-modules/shared/utils/date-utils';

const RE_TWO_AT_SIGNS = /@.*@/;
const RE_HANDLER_HAS_NO_WRONG_CHARACTERS = /^[^*^<>\\";:,[\]!@#\s]+@/;
const RE_DOMAIN_HAS_NO_WRONG_CHARACTERS = /@[^*^<>\\";:[\]!@#\s]+$/;
const RE_DOMAIN_HAS_PERIOD = /@([^.]+\.)+[^.]+$/;
const RE_NAME_ALLOWED_CHARACTERS = /^[a-zA-Z\s-]+$/;
const RE_ALPHABETIC_CHARACTERS = /^[a-zA-Z\s]+$/;
const RE_USERNAME_WRONG_CHARACTER = /@/;
const RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY = /(.)\1\1/i;
const RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY = /[bcdfghjklmnpqrstvwxz]{7,}/i;
const RE_NAME_HAS_VOWEL = /[aeiouy]/i;
const RE_PHONE_HAS_TEN_DIGITS = /^\d{10}$/;
const RE_PHONE_SEVEN_SAME_LAST_DIGITS = /(\d)\1{6}$/;
const RE_PHONE_STARTS_WITH_01 = /^[01]/;
const RE_PHONE_WRONG_AREA_CODE = /^(222|333|411|444|456|500|555|666|777|911|900|999)/;
const RE_PHONE_WRONG_PREFIX = /^\d{3}(411|555|611|911)/;
const RE_PHONE_STARTS_WITH_DIGIT = /^[0-9]/;
const RE_ADDRESS_ALLOWED_CHARACTERS = /^[a-zA-Z0-9#./\s-]+$/;
const RE_ADDRESS_HAS_NO_WRONG_CHARACTERS = /^[-a-zA-Z0-9-().]+(\s+[-a-zA-Z0-9-().]+)*$/;
const RE_ZIP_HAS_FIVE_DIGITS = /^\d{5}$/;
const RE_DIGITS = /^\d+$/;
const RE_CITY_ALLOWED_CHARACTERS = /^[a-zA-Z\s-]+$/;
const RE_LICENSE_PLATE = /^.{2,9}$/i;
const RE_WRONG_VIN_LETTERS = /[oiq]+/gi;
const RE_VIN_LETTERS = /[a-hj-npr-z]/gi;
const RE_VIN_DIGITS = /\d/g;
const RE_DISPLAY_NAME_ALLOWED_CHARACTERS = /^[a-zA-Z0-9 ]+$/;
const VIN_REQUIRED_LENGTH = 17;
const RE_SSN = /^(?!666|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
const RE_SSN_LAST_4 = /^\d{4}$/;
const RE_SSN_SIMPLE = /^\d{3}[- ]\d{2}[- ]\d{4}$/;
const SSN_BLACKLIST = ['078051120', '219099999', '457555462'];
const NAME_EXCEPTIONS = ['ng'];
const MAX_YEARS_DOB = 130;
const MIN_YEARS_DOB = 18;
const DAYS_IN_YEAR = 365;
const MAX_DAYS_DOB = MAX_YEARS_DOB * DAYS_IN_YEAR;
const MIN_DAYS_DOB = MIN_YEARS_DOB * DAYS_IN_YEAR;

/**
 * Validate email address
 *
 * Validation criteria:
 * - Required
 * - Must have an @ symbol
 * - Cannot contain more than one @ symbol
 * - Handle (portion before @) cannot be empty
 * - Handle cannot contains special characters (*^<>\";:[]!@#)
 * - Domain (portion after @) cannot be empty
 * - Domain cannot contains special characters (*^<>\";:[]!@#)
 * - Domain must contain at least one period
 *
 * @param {string} email User email
 * @returns {boolean}
 */
function validateEmail(email) {
  if (!email) return false;
  const trimmedEmail = email.trim();
  return (
    !RE_TWO_AT_SIGNS.test(trimmedEmail) &&
    RE_HANDLER_HAS_NO_WRONG_CHARACTERS.test(trimmedEmail) &&
    RE_DOMAIN_HAS_NO_WRONG_CHARACTERS.test(trimmedEmail) &&
    RE_DOMAIN_HAS_PERIOD.test(trimmedEmail)
  );
}

/**
 * Validate minimal input length
 *
 * @param {string, array} field Input value
 * @param {number} minLength Minimal length
 * @returns {boolean}
 */
function validateMinLength(field, minLength) {
  if (!minLength) {
    return true;
  }
  return field ? field.length >= minLength : false;
}

/**
 * Validate US phone number
 *
 * Validation criteria:
 * - Required
 * - Must contain at least 2 characters
 * - Cannot contain numeric values (0-9)
 * - Cannot contains special characters (*^<>\";:[]!@#)
 * - Cannot contain a period
 * - Cannot contain the same 3 letters consecutively
 * - Cannot contain 7 consonants consecutively
 * - Must include at least one vowel
 *
 * @param {string} phone User phone
 * @returns {boolean}
 */
function validatePhoneNumber(phone) {
  if (!phone) return false;
  const trimmedPhone = phone.trim();
  return (
    RE_PHONE_HAS_TEN_DIGITS.test(trimmedPhone) &&
    !RE_PHONE_SEVEN_SAME_LAST_DIGITS.test(trimmedPhone) &&
    !RE_PHONE_STARTS_WITH_01.test(trimmedPhone) &&
    !RE_PHONE_WRONG_AREA_CODE.test(trimmedPhone) &&
    !RE_PHONE_WRONG_PREFIX.test(trimmedPhone)
  );
}

/**
 * Validate Name
 *
 * Validation criteria:
 * - Required
 * - Must contain at least 2 characters
 * - Can contain only alphabetic characters, spaces and "-"
 * - Cannot contain a period
 * - Cannot contain the same 3 letters consecutively
 * - Cannot contain 7 consonants consecutively
 * - Must include at least one vowel
 *
 * @param {string} name User name
 * @returns {boolean}
 */
function validateName(name) {
  if (!name) return false;
  const trimmedName = name.trim();
  return (
    NAME_EXCEPTIONS.includes(trimmedName.toLowerCase()) ||
    (trimmedName.length > 1 &&
      RE_NAME_ALLOWED_CHARACTERS.test(name) &&
      !RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY.test(name) &&
      !RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY.test(name) &&
      RE_NAME_HAS_VOWEL.test(name))
  );
}

/**
 * Validate First name
 *
 * Validation criteria:
 * - Required
 * - Must contain at least 2 characters
 * - Can contain only alphabetic characters and spaces
 * - Cannot contain a period
 * - Cannot contain the same 3 letters consecutively
 * - Cannot contain 7 consonants consecutively
 * - Must include at least one vowel
 *
 * @param {string} name User name
 * @returns {boolean}
 */
function validateFirstName(name) {
  return validateName(name) && RE_ALPHABETIC_CHARACTERS.test(name);
}

/**
 * Validate First name if given. Not required otherwise
 *
 * Validation criteria if given:
 *
 * - Must contain at least 2 characters
 * - Can contain only alphabetic characters and spaces
 * - Cannot contain a period
 * - Cannot contain the same 3 letters consecutively
 * - Cannot contain 7 consonants consecutively
 * - Must include at least one vowel
 *
 * @param {string} name User name
 * @returns {boolean}
 */
function validateFirstNameIfGiven(name) {
  return !name || validateFirstName(name);
}

/**
 * Validate Username
 *
 * Validation criteria:
 * - Must contain at least 1 character
 * - Cannot contain @ symbol
 *
 * @param {string} username
 * @returns {boolean}
 */
function validateUsername(username) {
  if (!username) return false;
  const trimmedUsername = username.trim();
  return trimmedUsername.length > 0 && !RE_USERNAME_WRONG_CHARACTER.test(username);
}

/**
 * Validate DisplayName
 *
 * Validation criteria:
 * - Must contain at least 1 character
 * - Must contain only letters and numbers
 *
 * @param {string} username
 * @returns {boolean}
 */
function validateDisplayName(username) {
  if (!username) return false;
  const trimmedUsername = username.trim();
  return trimmedUsername.length > 0 && RE_DISPLAY_NAME_ALLOWED_CHARACTERS.test(username);
}

/**
 * Validate address
 *
 * Validation criteria:
 * - Required
 * - allowed only (#-/.)
 *
 * @param {string} address
 * @returns {boolean}
 */
function validateAddress(address) {
  if (!address.trim()) return false;
  return RE_ADDRESS_ALLOWED_CHARACTERS.test(address);
}

/**
 * Validate city
 *
 * Validation criteria:
 * - Required
 * - Alphanumeric characters allowed
 *
 * @param {string} city
 * @returns {boolean}
 */
function validateCity(city) {
  if (!city.trim()) return false;
  return RE_CITY_ALLOWED_CHARACTERS.test(city);
}

/**
 * Validate zip code
 *
 * Validation criteria:
 * -Must be 5 digits long
 */
function validateZip(zipStr) {
  if (!zipStr) return false;
  const trimmedZip = zipStr.trim();
  return RE_ZIP_HAS_FIVE_DIGITS.test(trimmedZip);
}

/**
 * Check if value exists
 *
 * @param value
 * @returns {boolean}
 */
function validateValue(value) {
  return !!value;
}

function validateDigits(value) {
  if (!value) return false;
  return RE_DIGITS.test(value);
}

/**
 * Checks if provided VIN is correct
 *
 * Validation criteria:
 * - Required
 * - Must be 17 digits long
 * - At least 2 different digits
 * - At least 2 different letters
 * - Cannot contain i, I, o, O, q and Q
 * - Last five characters must be digits
 *
 * @param {string} vin VIN number
 * @returns {boolean}
 */

function validateVIN(vin) {
  if (!vin || vin.length !== VIN_REQUIRED_LENGTH) return false;

  const vinDigits = vin.match(RE_VIN_DIGITS) || [];
  const isValidDigitsCount = validateMinLength(uniq(vinDigits), 2);

  const vinLetters = vin.match(RE_VIN_LETTERS) || [];
  const isValidLettersCount = validateMinLength(uniq(vinLetters), 2);

  const isValidVinLength = vinDigits.length + vinLetters.length === VIN_REQUIRED_LENGTH;

  const isValidLetters = !vin.match(RE_WRONG_VIN_LETTERS);

  const lastFiveCharacters = vin.slice(-5);
  const isLastFiveCharactersDigits = validateDigits(lastFiveCharacters);

  return (
    isValidVinLength && isLastFiveCharactersDigits && !!isValidDigitsCount && !!isValidLettersCount && isValidLetters
  );
}

/**
 * Checks if provided License Plate is correct
 *
 * Validation criteria:
 * - Required
 * - Any character, symbol, and spaces
 * - Length between 2 to 9.
 *
 * @param {string} licensePlate
 * @returns {boolean}
 */
function validateLicensePlate(licensePlate) {
  if (!licensePlate) return false;
  return RE_LICENSE_PLATE.test(licensePlate);
}

function validateVinLength(field) {
  return field ? field.length === VIN_REQUIRED_LENGTH : false;
}

function validateSSN(field) {
  if (!RE_SSN.test(field)) {
    return false;
  }

  return SSN_BLACKLIST.indexOf(field.replace(/\D/g, '')) === -1;
}

function validateSSNLastFour(field) {
  return RE_SSN_LAST_4.test(field);
}

function validateSimpleSSN(field) {
  if (!RE_SSN_SIMPLE.test(field)) {
    return false;
  }

  return SSN_BLACKLIST.indexOf(field.replace(/\D/g, '')) === -1;
}

function validateDateOfBirth(field) {
  const DATE_LENGTH = 8;
  let dateString;
  if (field.length === DATE_LENGTH) {
    dateString = `${field.slice(0, 4)}-${field.slice(4, 6)}-${field.slice(6)}`;
  }
  if (dateString) {
    const date = Date.parse(dateString);
    if (date) {
      return date ? inRange(getDifferenceInDays(date), MIN_DAYS_DOB, MAX_DAYS_DOB) : false;
    }
  }
  return false;
}

export const validation = {
  validateValue,
  validateEmail,
  validateMinLength,
  validatePhoneNumber,
  validateName,
  validateFirstName,
  validateFirstNameIfGiven,
  validateUsername,
  validateDisplayName,
  validateZip,
  validateAddress,
  validateDigits,
  validateCity,
  validateVIN,
  validateLicensePlate,
  validateVinLength,
  validateSSN,
  validateSSNLastFour,
  validateSimpleSSN,
  validateDateOfBirth,
  RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY,
  RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY,
};

export const validationRegex = {
  RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY,
  RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY,
  RE_ADDRESS_HAS_NO_WRONG_CHARACTERS,
  RE_PHONE_STARTS_WITH_DIGIT,
  RE_PHONE_STARTS_WITH_01,
  RE_PHONE_HAS_TEN_DIGITS,
  RE_PHONE_SEVEN_SAME_LAST_DIGITS,
  RE_PHONE_WRONG_AREA_CODE,
  RE_PHONE_WRONG_PREFIX,
  RE_ZIP_HAS_FIVE_DIGITS,
};
